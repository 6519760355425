import { APIClient } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

const api = new APIClient()

export const getAllActiveSectorsApi = () => api.get(url.GET_ALL_ACTIVE_SECTOR)

//
export const insertSectorApi = (data) => api.create(url.INSERT_SECTOR_URL, data)

//
export const findByIdSectorApi = (params) =>
  api.get(url.FIND_BY_ID_SECTOR_URL, params)

//
export const updateSectorApi = (data) => api.create(url.UPDATE_SECTOR_URL, data)

//
export const getAllSectorApi = (data) => api.get(url.FIND_ALL_SECTOR_URL, data)

//
export const deleteSectorApi = (data) => api.create(url.DELETE_SECTOR_URL, data)

//
export const ActiveInActiveSectorApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_SECTOR_URL, data)
