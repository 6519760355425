import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllStateApi=()=>api.get(url.GET_ALL_STATE_LIST);

export const getAllActiveStatesApi = () => api.get(url.GET_ALL_ACTIVE_STATES);

export const findByIdStateApi=(params)=>
api.get(url.FIND_BY_ID_STATE_URL,params);

export const insertStateApi=(data)=>api.create(url.INSERT_STATE_URL,data);

export const updateStateApi=(data)=>api.create(url.UPDATE_STATE_URL,data);

export const deleteStateApi=(data)=>api.create(url.DELETE_STATE_URL,data);

export const ActiveInActiveStateApi=(data)=>
api.create(url.ACTIVE_INACTIVE_STATE_URL,data);
//get state by countryID
export const getStatesByCountryIdApi = (CountryID) => api.get(`${url.GET_STATES_BY_COUNTRYID}?CountryID=${CountryID}`);


