import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { showModal } from "../../../slices/quotation/reducer"
import {
  AccommodationDetail,
  AdditionalServicesDetailsPerDay,
  AdditionalServicesDetailsPerPerson,
  getQuotationDetail,
  getTransportation,
} from "../../../slices/thunks"
import Accommodation from "../Accommodation"
import { useLocation } from "react-router-dom"
import Transportation from "../Transportation"
import AdditionalServicePerDay from "../AdditionalServicePerDay"
import AdditionalServicePerPerson from "../AdditionalServicePerPerson"
const Modals = () => {
  const {
    modal: { modal, modalType, modalData },
  } = useSelector((state) => state.Quotation)

  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const qID = urlParams.get("q")

  const dispatch = useDispatch()
  const modalClose = () => {
    let obj = {
      modal: false,
      modalType: "",
      modalData: {},
    }
    dispatch(showModal(obj))
  }

  useEffect(() => {
    if (modalType === "accommodation" && modal) {
      dispatch(AccommodationDetail(modalData))
    }
    if (modalType === "transportation" && modal) {
      dispatch(getTransportation(modalData))
    }
    if (modalType === "additionalServicePerDay" && modal) {
      dispatch(AdditionalServicesDetailsPerDay(modalData))
    }
    if (modalType === "additionalServicePerPerson" && modal) {
      dispatch(AdditionalServicesDetailsPerPerson(modalData))
    }
  }, [modalType, modal, modalData])
  useEffect(() => {
    if (qID && !modal) {
      let quotationDetail = {
        ID: Number(qID),
      }

      dispatch(getQuotationDetail(quotationDetail))
    }
  }, [qID, modal])
  return (
    <Modal isOpen={modal} toggle={modalClose} size="xl">
      <ModalHeader toggle={modalClose}></ModalHeader>
      <ModalBody>
        {modalType === "accommodation" ? (
          <Accommodation showTable={false} />
        ) : modalType === "transportation" ? (
          <Transportation showTable={false} />
        ) : modalType === "additionalServicePerDay" ? (
          <AdditionalServicePerDay showTable={false} />
        ) : modalType === "additionalServicePerPerson" ? (
          <AdditionalServicePerPerson
            showTable={false}
            showQuotationBTN={false}
          />
        ) : null}
      </ModalBody>
    </Modal>
  )
}

export default Modals
