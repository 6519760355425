import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import {
  CONVERT_BOOL,
  REQUIRED,
  SelectionField,
  TextField,
} from "../../Components/constants/field";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import * as Yup from "yup";
import { findByIdVehicleType, insertVehicleType, updateVehicleType } from "../../slices/vehicletype/thunk";


 const CreateVehicleType = () => {
  const history = useNavigate();
  const { ID } = useParams();
  const dispatch = useDispatch();
  const {loading,vehicleTypeDetail:{form},
}=useSelector((state)=>state.VehicleType);
useEffect(() => {
  if (ID) {
    let params = {
      ID: ID,
    };
    dispatch(findByIdVehicleType(params));
  }
}, [ID]);

const handleSubmit = (values) => {
  let obj = {
    ...values,
  };

  obj.IsActive = CONVERT_BOOL.test(obj?.IsActive);

  if (obj?.ID) {
    dispatch(updateVehicleType(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        history("/vehicletype");
      }
    });
  } else {
    delete obj?.ID;
    dispatch(insertVehicleType(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        history(`/vehicletype`);
      }
    });
  }
};
if (loading) {
  return <Loader />;
}
  return (
    <div className="page-content">
    <Container fluid>
      <ToastContainer />
      <BreadCrumb title="Vehicle Type" pageTitle="Vehicle Type" />
      <Row>
        <Col xl={12}>
          <Card color="dark" outline>
            <CardHeader className="bg-light">
              <Row className="align-items-center">
                <Col md="11">
                  <h4 className="card-title mb-0">
                    {ID ? "Update Vehicle Type" : " Create Vehicle Type"}
                  </h4>
                </Col>
                <Col md="1">
                  <Link to="/vehicletype">
                    <Button color="primary" block>
                      Back
                    </Button>
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg={12}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={form}
                    validationSchema={Yup.object({
                      VehicleType: Yup.string()
                        .nullable(true)
                        .required(REQUIRED),
                    })}
                    onSubmit={handleSubmit}
                  >
                    {() => {
                      return (
                        <Form noValidate autoComplete="off">
                          <div>
                            <Row>
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="VehicleType"
                                  >
                                    Vehicle Type:
                                  </Label>
                                  <Field
                                    name="VehicleType"
                                    className="form-control"
                                    id="VehicleType"
                                    placeholder="Enter Vehicle Type"
                                    component={TextField}
                                  />
                                </div>
                              </Col>{" "}
                            
                              <Col lg={6}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="IsActive"
                                  >
                                    Status
                                  </Label>
                                  <Field
                                    name="IsActive"
                                    id="IsActive"
                                    options={[
                                      {
                                        label: "Active",
                                        value: true,
                                      },
                                      {
                                        label: "Inactive",
                                        value: false,
                                      },
                                    ]}
                                    component={SelectionField}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col lg={12}>
                                <hr></hr>
                              </Col>
                              <Col md="12" className="text-end">
                                <Button type="submit" className="success">
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
  )
}
export default CreateVehicleType