import { createAsyncThunk } from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {
    getAPIKey as getAPIKeyApi
} from "../../helpers/fakebackend_helper";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";

export const getAPIKey = createAsyncThunk("apiKey/getAPIKey", async () => {
    try {
        const response = await getAPIKeyApi();
        if (response?.Status !== 200) {
            toast.error(response?.Details, { autoClose: AUTO_CLOSE });
            return response;
          } else {
            return response;
          }
    } catch (error) {
        return error;
    }
});

