import { createSlice } from "@reduxjs/toolkit"
import {
  getAllTours,
  getAllToursBySectorID,
  getItinerariesByTourID,
  getTourByID,
  getTourFilters,
  getToursByFilter,
  insertTour,
  insertTourItineraries,
  updateTour,
} from "./thunk"
import _ from "lodash"
import { FAILURE_STATUS } from "../../Components/constants/field"
import { toast } from "react-toastify"
export const emptyTourPlace = {
  ID: 0,
  DayNo: undefined,
  ItineraryID: undefined,
  ItineraryDescription: undefined,
  CityID: undefined,
  HotelID: undefined,
  ItineraryFacilityID: undefined,
}

export const initialState = {
  activeTourStep: 0,
  tourDetails: {
    SectorTypeID: undefined,
    TravelTypeID: undefined,
    SectorID: undefined,
    SubSectorID: 0,
    TermsConditionID: undefined,
    CancellationPolicyID: undefined,
    TourSpecialityTypeID: undefined,
    TourName: undefined,
    TourCode: undefined,
    NoOfNights: undefined,
    NoOfDays: undefined,
    OverallKm: undefined,
    SpecialRemarks: null,
    IsActive: true,
    TourImages: [],
  },
  itineraruDetails: {
    IsActive: true,
    TourID: undefined,
    ItinerariesData: [
      {
        DayNo: undefined,
        ItineraryID: undefined,
        ItineraryDescription: undefined,
        CityID: undefined,
        HotelID: undefined,
        ItineraryFacilityID: undefined,
      },
    ],
  },
  TourID: undefined,
  tourFilters: {},
  tours: [],
  error: {},
  loading: false,
}

const TourReducer = createSlice({
  name: "Tour",
  initialState,
  reducers: {
    setActiveTourTab: (state, action) => {
      state.activeTourStep = action.payload
    },
    resetTour: (state) => {
      state.tourDetails = initialState.tourDetails
      state.TourID = undefined
      state.itineraruDetails = initialState.itineraruDetails
      state.activeTourStep = initialState.activeTourStep
    },
    resetTourFilters: (state) => {
      state.tourFilters = initialState.tourFilters
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTours.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllTours.fulfilled, (state, action) => {
      // eslint-disable-next-line no-debugger
      state.tours =
        action.payload && action.payload.Data ? action.payload.Data : []
      state.loading = false
    })
    builder.addCase(getAllTours.rejected, (state, action) => {
      state.error = action.payload.error || null
      state.loading = false
    })
    builder.addCase(getAllToursBySectorID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllToursBySectorID.fulfilled, (state, action) => {
      state.tours = action.payload.Data
      state.loading = false
    })
    builder.addCase(getAllToursBySectorID.rejected, (state, action) => {
      state.error = action.payload.error || null
      state.loading = false
    })
    builder.addCase(insertTour.pending, (state) => {
      state.loading = false
    })
    builder.addCase(insertTour.fulfilled, (state, action) => {
      // console.log("Tour Inserted", action.payload)
      state.loading = false
      // eslint-disable-next-line no-debugger
      // debugger
      if (_.upperCase(action?.payload?.Message) === FAILURE_STATUS) {
        toast.error(action?.payload.Details)
      } else if (action?.payload?.Data?.ID) {
        state.TourID = action.payload.Data.ID
      }
    })
    builder.addCase(insertTour.rejected, (state, action) => {
      console.log("Tour Inserted failed", action)
      state.loading = false
    })
    builder.addCase(updateTour.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateTour.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(updateTour.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getTourByID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTourByID.fulfilled, (state, action) => {
      if (action.payload && action.payload.Data) {
        state.tourDetails = {
          ...action.payload.Data,
          TourSpecialityTypeID:
            action.payload.Data.TourSpecialityType &&
            action.payload.Data.TourSpecialityType.length > 0
              ? action.payload.Data.TourSpecialityType.map(
                  (tourSpeciality) => tourSpeciality.TourSpecialityTypeID
                )
              : [],
        }

        let tourKeys = Object.keys(emptyTourPlace)

        let tourItineraryList = action?.payload?.Data?.TourItineraryList?.map(
          (tour) => {
            return {
              ...tour,
              ItineraryFacilityID: Array.isArray(tour?.tourItineraryImages)
                ? _.map(tour?.tourItineraryImages, "TourItineraryFacilityID")
                : [],
            }
          }
        )
        if (Array.isArray(tourItineraryList) && tourItineraryList.length) {
          state.itineraruDetails = {
            IsActive: true,
            TourID: action?.payload?.Data?.ID,
            ItinerariesData: tourItineraryList.map((qt) => {
              return _.pick(qt, tourKeys)
            }),
          }
        }

        // state.tourDetails
        state.TourID = action.payload.Data.ID
      }
      state.loading = false
    })
    builder.addCase(getTourByID.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(insertTourItineraries.pending, (state) => {
      state.loading = true
    })
    builder.addCase(insertTourItineraries.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(insertTourItineraries.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getItinerariesByTourID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getItinerariesByTourID.fulfilled, (state, action) => {
      state.loading = false
      if (action.payload.Data) {
        let ItinerariesData = action.payload.Data
          ? [...action.payload.Data]
          : []
        ItinerariesData = ItinerariesData.map((iten) => {
          return {
            ...iten,
            ItineraryFacilityID:
              iten.Facilities && iten.Facilities.length > 0
                ? iten.Facilities.map((fac) => fac.ItineraryFacilityID)
                : [],
          }
        })
        state.itineraruDetails = {
          IsActive: true,
          TourID: action.payload.Data[0].TourID,
          ItinerariesData: ItinerariesData,
        }
      } else {
        state.itineraruDetails = {
          IsActive: true,
          TourID: state.TourID,
          ItinerariesData: [emptyTourPlace],
        }
      }
    })
    builder.addCase(getItinerariesByTourID.rejected, (state, action) => {
      console.log("Tour insertTourItineraries failed", action)
      state.loading = false
      state.itineraruDetails = {
        ItinerariesData: [],
      }
    })
    builder.addCase(getTourFilters.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTourFilters.fulfilled, (state, action) => {
      console.log("Tour Filters Fetched", action.payload)
      state.tourFilters = action.payload.Data
    })
    builder.addCase(getTourFilters.rejected, (state, action) => {
      console.log("Tour Filters Fetched failed", action)
      state.loading = false
    })
    builder.addCase(getToursByFilter.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getToursByFilter.fulfilled, (state, action) => {
      console.log("Tours Fetched", action.payload)
      state.tours = action.payload.Data
      state.loading = false
    })
    builder.addCase(getToursByFilter.rejected, (state, action) => {
      console.log("Tours Fetched failed", action)
      state.loading = false
    })
  },
})

export const { setActiveTourTab, resetTour, resetTourFilters } =
  TourReducer.actions
export default TourReducer.reducer
