import { createSlice } from "@reduxjs/toolkit"
import {
  findByIdSector,
  getAllActiveSectors,
  getAllSector,
  insertSector,
  updateSector,
} from "./thunk"
import _ from "lodash"
export const SectorForm = {
  ID: null,
  IsActive: true,
  RegionID: null,
  SectorName: "",
  SectorType: "",
  CityID: [],
  Prefix: "",
}
export const initialState = {
  sectorDetail: {
    form: SectorForm,
  },
  sectors: [],
  error: {},
  loading: false,
}

const Sector = createSlice({
  name: "Sector",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.sectorDetail.form = SectorForm
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllActiveSectors.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllActiveSectors.fulfilled, (state, action) => {
      state.sectors = action.payload.Data
      state.loading = false
    })
    builder.addCase(getAllActiveSectors.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })

    // Insert Sector
    builder.addCase(insertSector.pending, (state) => {
      state.loading = true
    })
    builder.addCase(insertSector.fulfilled, (state) => {
      state.sectorDetail.form = SectorForm
      state.loading = false
    })
    builder.addCase(insertSector.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })

    // Update Sector
    builder.addCase(updateSector.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateSector.fulfilled, (state) => {
      state.sectorDetail.form = SectorForm
      state.loading = false
    })
    builder.addCase(updateSector.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })

    // Get By Id Sector
    builder.addCase(findByIdSector.pending, (state) => {
      state.loading = true
    })
    builder.addCase(findByIdSector.fulfilled, (state, action) => {
      let formObj = action?.payload?.Data

      formObj["CityID"] = Array.isArray(action?.payload?.Data?.Cities)
        ? _.map(action?.payload?.Data?.Cities, "CityID")
        : []
      let formKeys = Object.keys(SectorForm)
      formObj = _.pick(formObj, formKeys)

      state.sectorDetail.form = formObj

      state.loading = false
    })
    builder.addCase(findByIdSector.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })

    // Get By Id Sector
    builder.addCase(getAllSector.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllSector.fulfilled, (state, action) => {
      state.sectors = action.payload.Data
      state.loading = false
    })
    builder.addCase(getAllSector.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })
  },
})

export const { resetForm } = Sector.actions

export default Sector.reducer
