import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllActiveSpeciallityTypesApi = () =>
    api.get(url.GET_ALL_ACTIVE_SPECIALITY_TYPES);

    export const getAllSpecialityTypeApi=()=>api.get(url.GET_ALL_SPECIALITY_TYPE_LIST);

    export const findByIdSpecialityTypeApi = (params) =>
    api.get(url.FIND_BY_ID_SPECIALITY_TYPE_URL, params);

    
export const insertSpecialityTypeApi = (data) => api.create(url.INSERT_SPECIALITY_TYPE_URL, data);

export const updateSpecialityTypeApi = (data) => api.create(url.UPDATE_SPECIALITY_TYPE_URL, data);

export const deleteSpecialityTypeApi = (data) => api.create(url.DELETE_SPECIALITY_TYPE_URL, data);


export const ActiveInActiveSpecialityTypeApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_SPECIALITY_TYPE_URL, data);