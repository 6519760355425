import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  AccommodationCopyApi,
  AccommodationDetailApi,
  AdditionalServicesDetailsPerDayApi,
  AdditionalServicesDetailsPerPersonApi,
  createAccommodationApi,
  createAdditionalServicesApi,
  createAutoQuotationApi,
  createQuotationStep1Api,
  createTransportationApi,
  deleteQuotationApi,
  getAllSectorsApi,
  getAllToursApi,
  getQuotationAllApi,
  getQuotationDetailApi,
  getRatesApi,
  getTransportationApi,
  searchAgentUsingNameApi,
  updateAccommodationApi,
  updateAdditionalServicesApi,
  updateQuotationStep1Api,
  updateTransportationApi,
} from "./quatation.service"

export const getRates = createAsyncThunk(
  "Quotation/GetRates",
  async (rateDetails) => {
    try {
      const response = getRatesApi(rateDetails)
      return response
    } catch (error) {
      return error
    }
  }
)

export const searchAgentUsingName = createAsyncThunk(
  "Quotation/SearchAgent",
  async (Name) => {
    try {
      const response = searchAgentUsingNameApi(Name)
      return response
    } catch (error) {
      return error
    }
  }
)

export const getAllSectors = createAsyncThunk(
  "Quotation/GetSectors",
  async () => {
    try {
      const response = getAllSectorsApi()
      return response
    } catch (error) {
      return error
    }
  }
)

export const getAllActiveTours = createAsyncThunk(
  "Quotation/GetTours",
  async () => {
    try {
      const response = getAllToursApi()
      return response
    } catch (error) {
      return error
    }
  }
)

//  Quotation

export const createQuotationStep1 = createAsyncThunk(
  "Quotation/CreateQuotationStep1",
  async (data) => {
    try {
      const response = createQuotationStep1Api(data)
      return response
    } catch (error) {
      return error
    }
  }
)
export const deleteQuotation = createAsyncThunk(
  "Quotation/delete",
  async (data) => {
    try {
      const response = deleteQuotationApi(data)
      return response
    } catch (error) {
      return error
    }
  }
)

export const updateQuotationStep1 = createAsyncThunk(
  "Quotation/UpdateQuotationStep1",
  async (data) => {
    try {
      const response = updateQuotationStep1Api(data)
      return response
    } catch (error) {
      return error
    }
  }
)

export const getQuotationDetail = createAsyncThunk(
  "Quotation/QuotationDetail",
  async (data) => {
    try {
      const response = getQuotationDetailApi(data)
      return response
    } catch (error) {
      return error
    }
  }
)
export const getQuotationAll = createAsyncThunk(
  "Quotation/All",
  async (data) => {
    try {
      const response = getQuotationAllApi(data)
      return response
    } catch (error) {
      return error
    }
  }
)

// Accommodation

export const createAccommodation = createAsyncThunk(
  "Accommodation/CreateAccommodation",
  async (data) => {
    try {
      const res = createAccommodationApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)
export const updateAccommodation = createAsyncThunk(
  "Accommodation/UpdateAccommodation",
  async (data) => {
    try {
      const res = updateAccommodationApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)
export const AccommodationDetail = createAsyncThunk(
  "Accommodation/AccommodationDetail",
  async (data) => {
    try {
      const res = AccommodationDetailApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)
export const AccommodationCopy = createAsyncThunk(
  "Accommodation/AccommodationCopy",
  async (data) => {
    try {
      const res = AccommodationCopyApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)

// Transportation

export const createTransportation = createAsyncThunk(
  "Transportation/Create",
  async (data) => {
    try {
      const res = createTransportationApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)
export const updateTransportation = createAsyncThunk(
  "Transportation/Update",
  async (data) => {
    try {
      const res = updateTransportationApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)
export const getTransportation = createAsyncThunk(
  "Transportation/Detail",
  async (data) => {
    try {
      const res = getTransportationApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)

// Others Additional Services per day and per person

export const createAdditionalServices = createAsyncThunk(
  "OthersAdditionalServices/CreateAdditionalServices",
  async (data) => {
    try {
      const res = createAdditionalServicesApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)
export const updateAdditionalServices = createAsyncThunk(
  "OthersAdditionalServices/updateAdditionalServices",
  async (data) => {
    try {
      const res = updateAdditionalServicesApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)

export const deleteAdditionalServices = createAsyncThunk(
  "OthersAdditionalServices/deleteAdditionalServices",
  async (data) => {
    try {
      const res = updateAdditionalServicesApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)
export const AdditionalServicesDetailsPerDay = createAsyncThunk(
  "OthersAdditionalServices/AdditionalServicesDetailsPerDay",
  async (data) => {
    try {
      const res = AdditionalServicesDetailsPerDayApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)
export const AdditionalServicesDetailsPerPerson = createAsyncThunk(
  "OthersAdditionalServices/AdditionalServicesDetailsPerPerson",
  async (data) => {
    try {
      const res = AdditionalServicesDetailsPerPersonApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)
export const createAutoQuotation = createAsyncThunk(
  "AutoQuotation/create",
  async (data) => {
    try {
      const res = createAutoQuotationApi(data)
      return res
    } catch (err) {
      return err
    }
  }
)
