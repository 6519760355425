import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";
import { getAllActiveVehicleTypeApi } from "../vehicle/vehicle.services";
import {
  ActiveInActiveVehicleTypesApi,
  deleteVehicleTypesApi,
  findByIdVehicleTypesApi,
  getAllVehicleTypesApi,
  insertVehicleTypesApi,
  updateVehicleTypesApi,
} from "./vehicletype.services";

export const getAllActiveVehicleType = createAsyncThunk(
  "VehicleTypes/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveVehicleTypeApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getAllVehicleType = createAsyncThunk(
  "VehicleTypes/FindAll",
  async () => {
    try {
      const response = await getAllVehicleTypesApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const findByIdVehicleType = createAsyncThunk(
  "VehicleTypes/FindByID",
  async (params) => {
    try {
      const response = await findByIdVehicleTypesApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
        toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const insertVehicleType = createAsyncThunk(
  "VehicleTypes/Insert",
  async (data) => {
    try {
      const response = await insertVehicleTypesApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const updateVehicleType = createAsyncThunk(
  "VehicleTypes/Update",
  async (data) => {
    try {
      const response = await updateVehicleTypesApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
        toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const ActiveInActiveVehicleType = createAsyncThunk(
  "VehicleTypes/Active-InActive",
  async (data) => {
    try {
      const response = await ActiveInActiveVehicleTypesApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
        toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const deleteVehicleType = createAsyncThunk(
  "VehicleTypes/Delete",
  async (data) => {
    try {
      const response = await deleteVehicleTypesApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
        toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);
