import { Field, FieldArray } from "formik";
import React, { useState } from "react";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { TextField } from "../../Components/constants/field";
import { emptyContactDetails } from "../../slices/agent/reducer";

function ContactDetailsArray() {
  const [isInitialRender, setIsInitialRender] = useState(true);
  const handleRoomAdd = (arrayHelpers) => {
    let obj = {
      ...emptyContactDetails,
    };

    arrayHelpers.push(obj);
  };

  const handleDeleteClick = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  };
  return (
    <FieldArray
      name="ContactDetails"
      render={(arrayHelpers) => {
        if (
          isInitialRender &&
          (!arrayHelpers.form.values ||
            !arrayHelpers.form.values.ContactDetails ||
            arrayHelpers.form.values.ContactDetails.length === 0)
        ) {
          handleRoomAdd(arrayHelpers);
          setIsInitialRender(false);
        }
        return (
          <Row>
            <Col lg={4}>
              <Button
                color="primary"
                onClick={() => handleRoomAdd(arrayHelpers)}
              >
                Add Contact
              </Button>
            </Col>
            <Col lg={12}>
              {arrayHelpers?.form?.values?.ContactDetails &&
              arrayHelpers?.form?.values?.ContactDetails.length > 0
                ? arrayHelpers?.form?.values?.ContactDetails.map(
                    (contact, index) => (
                      <Row key={index}>
                        <Col lg={12}>
                          <Card className="border card-border-primary mt-4">
                            <CardBody className="p-4">
                            <Row className="contact-add-row">
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="ContactDetails-Name"
                                    >
                                      Name:
                                    </Label>
                                    <Field
                                      name={`ContactDetails.${index}.Name`}
                                      className="form-control"
                                      id="ContactDetails-Name"
                                      placeholder="Name"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="ContactDetails-MobileNo"
                                    >
                                      Mobile No:
                                    </Label>
                                    <Field
                                      name={`ContactDetails.${index}.MobileNo`}
                                      type="number"
                                      className="form-control"
                                      id="ContactDetails-MobileNo"
                                      placeholder="Mobile No"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="ContactDetails-EmailID"
                                    >
                                      Email ID:
                                    </Label>
                                    <Field
                                      name={`ContactDetails.${index}.EmailID`}
                                      className="form-control"
                                      id="ContactDetails-EmailID"
                                      placeholder="Email ID"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={2}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="ContactDetails-Designation"
                                    >
                                      Designation:
                                    </Label>
                                    <Field
                                      name={`ContactDetails.${index}.Designation`}
                                      className="form-control"
                                      id="ContactDetails-Designation"
                                      placeholder="Designation"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={1} className="text-end">
                                  <Button
                                    color="danger"
                                    className="btn-icon mb-2"
                                    onClick={() =>
                                      handleDeleteClick(arrayHelpers, index)
                                    }
                                  >
                                    <i className=" ri-delete-bin-5-line"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )
                  )
                : null}
            </Col>
          </Row>
        );
      }}
    />
  );
}

export default ContactDetailsArray;
