import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllActiveVehicleTypesApi = () =>
    api.get(url.GET_ALL_ACTIVE_VEHICLE_TYPE);

    export const getAllVehicleTypesApi=()=>api.get(url.GET_ALL_VEHICLE_TYPE);

    export const findByIdVehicleTypesApi = (params) =>
    api.get(url.FIND_BY_ID_VEHICLE_TYPE, params);

    
export const insertVehicleTypesApi = (data) => api.create(url.INSERT_VEHICLE_TYPE, data);

export const updateVehicleTypesApi = (data) => api.create(url.UPDATE_VEHICLE_TYPE, data);

export const deleteVehicleTypesApi = (data) => api.create(url.DELETE_VEHICLE_TYPE_URL, data);


export const ActiveInActiveVehicleTypesApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_VEHICLE_TYPE, data);