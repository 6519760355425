import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import axios from "axios";

const api = new APIClient();

export const getAllActiveAgentsApi = () => api.get(url.GET_ALL_ACTIVE_AGENTS);
export const getAllAgentApi = () => api.get(url.GET_ALL_AGENT);

export const findByIdAgentApi = (params) =>
  api.create(url.FIND_BY_ID_AGENT_URL, params);

export const insertAgentApi = (data) => api.create(url.INSERT_AGENT_URL, data);

export const insertAgentDocumetsApi = (data) =>
  axios.post(url.INSERT_AGENT_DOCUMENTS_URL, data, {
    headers: { "content-type": "multipart/form-data" },
  });

export const updateAgentApi = (data) => api.create(url.UPDATE_AGENT_URL, data);

export const updateAgentDocumetsApi = (data) =>
  axios.post(url.UPDATE_AGENT_DOCUMENTS_URL, data, {
    headers: { "content-type": "multipart/form-data" },
  });

export const ActiveInActiveAgentApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_AGENT_URL, data);

export const deleteAgentApi = (data) => api.create(url.DELETE_AGENT_URL, data);
