import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { FAILED } from "../../Components/constants/field";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { toast } from "react-toastify";
import {
  getAllToursApi,
  getAllToursBySectorIDApi,
  insertTourApi,
  getTourByIDApi,
  updateTourApi,
  insertTourItinerariesApi,
  updateTourItinerariesApi,
  deleteTourItinerariesApi,
  getItinerariesByTourIDApi,
  getTourFiltersApi,
  getToursByFilterApi,
  tourActiveAndInActiveApi,
  tourDeleteApi,
} from "./tour.services";

export const getAllTours = createAsyncThunk("Tours/FindAllActive", async () => {
  try {
    const response = await getAllToursApi();
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const getAllToursBySectorID = createAsyncThunk(
  "Tours/FindBySectorID",
  async (SectorID) => {
    try {
      const response = await getAllToursBySectorIDApi(SectorID);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const insertTour = createAsyncThunk("Tours/Insert", async (tour) => {
  try {
    const response = await insertTourApi(tour);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const getTourByID = createAsyncThunk("Tours/FindByID", async (ID) => {
  try {
    const response = await getTourByIDApi(ID);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
});

export const updateTour = createAsyncThunk("Tours/Update", async (tour) => {
  try {
    const response = await updateTourApi(tour);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const insertTourItineraries = createAsyncThunk(
  "TourItineraries/Insert",
  async (tourItineraries) => {
    try {
      const response = await insertTourItinerariesApi(tourItineraries);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const updateTourItineraries = createAsyncThunk(
  "TourItineraries/Update",
  async (tourItineraries) => {
    try {
      const response = await updateTourItinerariesApi(tourItineraries);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const deleteTourItineraries = createAsyncThunk(
  "TourItineraries/Delete",
  async (tourItineraries) => {
    try {
      const response = await deleteTourItinerariesApi(tourItineraries);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getItinerariesByTourID = createAsyncThunk(
  "Tours/TourIDwiseItineraryList",
  async (tourItineraries) => {
    try {
      const response = await getItinerariesByTourIDApi(tourItineraries);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getTourFilters = createAsyncThunk(
  "Tours/TourFilterCriteriaData",
  async () => {
    try {
      const response = await getTourFiltersApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getToursByFilter = createAsyncThunk(
  "Tours/GetTourListbyFilter",
  async (filter) => {
    try {
      const response = await getToursByFilterApi(filter);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
export const tourActiveAndInActive = createAsyncThunk(
  "Tours/active-and-inactive",
  async (data) => {
    try {
      const response = await tourActiveAndInActiveApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
export const tourDelete = createAsyncThunk("Tours/delete", async (data) => {
  try {
    const response = await tourDeleteApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    return error;
  }
});
