import React from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import { Field, Form, Formik } from "formik"
import {
  CONVERT_BOOL,
  HOTEL_CATEGORIES,
  REQUIRED,
  SelectionField,
  TextField,
} from "../../Components/constants/field"
import Weeks from "./Weeks"
import HotelContact from "./HotelContact"
import RoomCategory from "./RoomCategory"
import HotelSpecialRate from "./HotelSpecialRate"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useEffect } from "react"
import {
  getAllCities,
  getHotelByID,
  insertHotel,
  updateHotel,
} from "../../slices/thunks"
import Loader from "../../Components/Common/Loader"
import { useNavigate, useParams } from "react-router"
import "react-toastify/dist/ReactToastify.css"
// import { ToastContainer } from "react-toastify"
import { resetHotel } from "../../slices/hotel/reducer"
import { Link } from "react-router-dom"

const CreateHotel = () => {
  const dispatch = useDispatch()
  const history = useNavigate()
  const { ID } = useParams()
  const { form, loading } = useSelector((state) => state.Hotel.hotelDetails)

  const { cities, loading: cityLoading } = useSelector((state) => state.City)

  const handleSubmit = (values) => {
    let obj = {
      ...values,
    }

    if (Array.isArray(obj?.WeekDays)) {
      console.log(obj?.WeekDays)
      obj.WeekDays = obj?.WeekDays.toString()
    }
    if (Array.isArray(obj?.WeekEnds)) {
      obj.WeekEnds = obj?.WeekEnds.toString()
    }
    if (obj?.CityID) {
      obj.CityID = +obj?.CityID
    }
    if (obj?.IsActive) {
      obj.IsActive = CONVERT_BOOL.test(obj?.IsActive)
    }

    if (ID) {
      obj[`OperationType`] = 2
      dispatch(updateHotel(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/hotels/${res?.payload?.Data?.HotelID}`)
        }
      })
    } else {
      obj[`OperationType`] = 1
      dispatch(insertHotel(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/hotels/${res?.payload?.Data?.HotelID}`)
        }
      })
    }
  }

  useEffect(() => {
    dispatch(getAllCities())
    return () => {
      dispatch(resetHotel())
    }
  }, [])

  useEffect(() => {
    if (ID) {
      dispatch(getHotelByID(ID))
    }
  }, [ID])

  if (loading || cityLoading) return <Loader />

  return (
    <div className="page-content">
      <Container fluid>
        {/* <ToastContainer /> */}
        <BreadCrumb title="Hotel" pageTitle="Hotel" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">
                      Create Hotel
                      {/* {ID ? "Update Hotel" : "Create Hotel"} */}
                    </h4>
                  </Col>
                  <Col md="1">
                    <Link to="/hotels">
                      <Button color="primary" block>
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Formik
                      initialValues={form}
                      validationSchema={Yup.object({
                        HotelName: Yup.string().required(REQUIRED),
                        HotelCategory: Yup.string().required(REQUIRED),
                        Address: Yup.string().nullable(true).required(REQUIRED),
                        CityID: Yup.string().required(REQUIRED),
                        WeekDays: Yup.array()
                          .min(1, "Please select at least one day.")
                          .required("Please select at least one day."),
                        WeekEnds: Yup.array()
                          .min(1, "Please select at least one day.")
                          .required("Please select at least one day."),
                        IsActive: Yup.boolean().required(REQUIRED),
                      })}
                      onSubmit={handleSubmit}
                    >
                      <Form noValidate autoComplete="off">
                        <div>
                          <Row>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="hotel-name"
                                >
                                  Hotel Name
                                </Label>
                                <Field
                                  name="HotelName"
                                  type="text"
                                  className="form-control"
                                  id="hotel-name"
                                  placeholder="Enter Name"
                                  component={TextField}
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="hotel-HotelCategory"
                                >
                                  Hotel Category
                                </Label>
                                <Field
                                  name="HotelCategory"
                                  options={HOTEL_CATEGORIES.map((hc, index) => {
                                    return {
                                      value: index + 1,
                                      label: hc,
                                    }
                                  })}
                                  component={SelectionField}
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="hotel-HotelCity"
                                >
                                  Hotel City
                                </Label>
                                <Field
                                  name="CityID"
                                  options={
                                    Array.isArray(cities)
                                      ? cities.map((city) => {
                                          return {
                                            value: Number(city.ID),
                                            label: city.CityName,
                                          }
                                        })
                                      : []
                                  }
                                  component={SelectionField}
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="hotel-address"
                                >
                                  Hotel Address
                                </Label>
                                <Field
                                  name="Address"
                                  type="textarea"
                                  className="form-control"
                                  id="hotel-Address"
                                  placeholder="Enter Address"
                                  component={TextField}
                                />
                              </div>
                            </Col>
                            <Col lg={4}>
                              <div className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="hotel-status"
                                >
                                  Status
                                </Label>
                                <Field
                                  name="IsActive"
                                  id="hotel-status"
                                  options={[
                                    {
                                      label: "Active",
                                      value: true,
                                    },
                                    {
                                      label: "Inactive",
                                      value: false,
                                    },
                                  ]}
                                  component={SelectionField}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="mt-2">
                            <Weeks title={"Weekdays"} name={"WeekDays"} />
                          </Row>
                          <Row className="mt-2">
                            <Weeks title={"WeekEnd"} name={"WeekEnds"} />
                          </Row>

                          <Row className="mt-2">
                            <Col lg={12}>
                              <hr></hr>
                            </Col>
                            <Col md="12" className="text-end">
                              <Button className="success">Save</Button>
                            </Col>
                          </Row>
                        </div>
                      </Form>
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {ID ? (
              <React.Fragment>
                {/* Hotel Contact */}
                <HotelContact />
                {/* Room Category */}
                <RoomCategory />
                {/* Hotel Special Rate */}
                <HotelSpecialRate />
              </React.Fragment>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CreateHotel
