import { createSlice } from "@reduxjs/toolkit";
import { findByIdCanelationPolicy, getAllActiveCanelationPolicy, getAllCanelationPolicy, insertCanelationPolicy, updateCanelationPolicy } from "./thunk";
import _ from "lodash"

export const cancellationPolicyForm={
    ID:null,
    Title:"",
    Description:"",
    IsActive: true,
}


export const initialState = {
    cancellationPolicyDetail:{
        form:cancellationPolicyForm
    },
    cancelationPolicies: [],
    error: {},
    loading: false,
};

const CancelationPolicyReducer = createSlice({
    name: "CancelationPolicy",
    initialState,
    reducers: {
        resetForm: (state) => {
            state.cancellationPolicyDetail.form = cancellationPolicyForm;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllActiveCanelationPolicy.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getAllActiveCanelationPolicy.fulfilled,
            (state, action) => {
                state.cancelationPolicies = action.payload.Data;
                state.loading = false;
            }
        );
        builder.addCase(
            getAllActiveCanelationPolicy.rejected,
            (state, action) => {
                state.error = action.payload.error || null;
                state.loading = false;
            }
        );
        builder.addCase(getAllCanelationPolicy.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(getAllCanelationPolicy.fulfilled,(state,action)=>{
            state.cancelationPolicies=action.payload.Data || []
            state.loading=false
        });
        builder.addCase(getAllCanelationPolicy.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(insertCanelationPolicy.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(insertCanelationPolicy.fulfilled,(state)=>{
            state.cancellationPolicyDetail.form=cancellationPolicyForm
            state.loading=false
        });
        builder.addCase(insertCanelationPolicy.rejected, (state,action) => {
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(updateCanelationPolicy.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(updateCanelationPolicy.fulfilled,(state)=>{
            state.cancellationPolicyDetail.form=cancellationPolicyForm
            state.loading=false
        });
        builder.addCase(updateCanelationPolicy.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(findByIdCanelationPolicy.pending,(state)=>{
            state.loading=true
           });
           builder.addCase(findByIdCanelationPolicy.fulfilled,(state,action)=>{
            let formObj = action?.payload?.Data
    
            let formKeys=Object.keys(cancellationPolicyForm)
            formObj = _.pick(formObj,formKeys)
            state.cancellationPolicyDetail.form=formObj
            state.loading=false
           });
           builder.addCase(findByIdCanelationPolicy.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
           })

    },
});

export const {resetForm}= CancelationPolicyReducer.actions
export default CancelationPolicyReducer.reducer;
