//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup"

//LOGIN
export const POST_LOGIN = "/Login/userlogin"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/user"

// Calendar
export const GET_EVENTS = "/events"
export const GET_CATEGORIES = "/categories"
export const GET_UPCOMMINGEVENT = "/upcommingevents"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"

// Chat
export const GET_DIRECT_CONTACT = "/chat"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "add/message"
export const GET_CHANNELS = "/channels"
export const DELETE_MESSAGE = "delete/message"

//Mailbox
export const GET_MAIL_DETAILS = "/mail"
export const DELETE_MAIL = "/delete/mail"

// Ecommerce
// Product
export const GET_PRODUCTS = "/apps/product"
export const DELETE_PRODUCT = "/apps/product"
export const ADD_NEW_PRODUCT = "/apps/product"
export const UPDATE_PRODUCT = "/apps/product"

// Orders
export const GET_ORDERS = "/apps/order"
export const ADD_NEW_ORDER = "/apps/order"
export const UPDATE_ORDER = "/apps/order"
export const DELETE_ORDER = "/apps/order"

// Customers
export const GET_CUSTOMERS = "/apps/customer"
export const ADD_NEW_CUSTOMER = "/apps/customer"
export const UPDATE_CUSTOMER = "/apps/customer"
export const DELETE_CUSTOMER = "/apps/customer"

// Sellers
export const GET_SELLERS = "/sellers"

// Project list
export const GET_PROJECT_LIST = "/project/list"

// Task
export const GET_TASK_LIST = "/apps/task"
export const ADD_NEW_TASK = "/apps/task"
export const UPDATE_TASK = "/apps/task"
export const DELETE_TASK = "/apps/task"

// CRM
// Conatct
export const GET_CONTACTS = "/apps/contact"
export const ADD_NEW_CONTACT = "/apps/contact"
export const UPDATE_CONTACT = "/apps/contact"
export const DELETE_CONTACT = "/apps/contact"

// Companies
export const GET_COMPANIES = "/apps/company"
export const ADD_NEW_COMPANIES = "/apps/company"
export const UPDATE_COMPANIES = "/apps/company"
export const DELETE_COMPANIES = "/apps/company"

// Lead
export const GET_LEADS = "/apps/lead"
export const ADD_NEW_LEAD = "/apps/lead"
export const UPDATE_LEAD = "/apps/lead"
export const DELETE_LEAD = "/apps/lead"

// Deals
export const GET_DEALS = "/deals"

// Crypto
export const GET_TRANSACTION_LIST = "/transaction-list"
export const GET_ORDRER_LIST = "/order-list"

// Invoice
export const GET_INVOICES = "/apps/invoice"
export const ADD_NEW_INVOICE = "/apps/invoice"
export const UPDATE_INVOICE = "/apps/invoice"
export const DELETE_INVOICE = "/apps/invoice"

// TicketsList
export const GET_TICKETS_LIST = "/apps/ticket"
export const ADD_NEW_TICKET = "/apps/ticket"
export const UPDATE_TICKET = "/apps/ticket"
export const DELETE_TICKET = "/apps/ticket"

// Dashboard Analytics

// Sessions by Countries
export const GET_ALL_DATA = "/all-data"
export const GET_HALFYEARLY_DATA = "/halfyearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

// Audiences Metrics
export const GET_ALLAUDIENCESMETRICS_DATA = "/allAudiencesMetrics-data"
export const GET_MONTHLYAUDIENCESMETRICS_DATA = "/monthlyAudiencesMetrics-data"
export const GET_HALFYEARLYAUDIENCESMETRICS_DATA =
  "/halfyearlyAudiencesMetrics-data";
export const GET_YEARLYAUDIENCESMETRICS_DATA = "/yearlyAudiencesMetrics-data";

// Users by Device
export const GET_TODAYDEVICE_DATA = "/todayDevice-data";
export const GET_LASTWEEKDEVICE_DATA = "/lastWeekDevice-data";
export const GET_LASTMONTHDEVICE_DATA = "/lastMonthDevice-data";
export const GET_CURRENTYEARDEVICE_DATA = "/currentYearDevice-data";



// Audiences Sessions by Country
export const GET_TODAYSESSION_DATA = "/todaySession-data"
export const GET_LASTWEEKSESSION_DATA = "/lastWeekSession-data"
export const GET_LASTMONTHSESSION_DATA = "/lastMonthSession-data"
export const GET_CURRENTYEARSESSION_DATA = "/currentYearSession-data"

// Dashboard CRM

// Balance Overview
export const GET_TODAYBALANCE_DATA = "/todayBalance-data"
export const GET_LASTWEEKBALANCE_DATA = "/lastWeekBalance-data"
export const GET_LASTMONTHBALANCE_DATA = "/lastMonthBalance-data"
export const GET_CURRENTYEARBALANCE_DATA = "/currentYearBalance-data"

// Deal type
export const GET_TODAYDEAL_DATA = "/todayDeal-data"
export const GET_WEEKLYDEAL_DATA = "/weeklyDeal-data"
export const GET_MONTHLYDEAL_DATA = "/monthlyDeal-data"
export const GET_YEARLYDEAL_DATA = "/yearlyDeal-data"

// Sales Forecast

export const GET_OCTSALES_DATA = "/octSales-data"
export const GET_NOVSALES_DATA = "/novSales-data"
export const GET_DECSALES_DATA = "/decSales-data"
export const GET_JANSALES_DATA = "/janSales-data"

// Dashboard Ecommerce
// Revenue
export const GET_ALLREVENUE_DATA = "/allRevenue-data"
export const GET_MONTHREVENUE_DATA = "/monthRevenue-data"
export const GET_HALFYEARREVENUE_DATA = "/halfYearRevenue-data"
export const GET_YEARREVENUE_DATA = "/yearRevenue-data"

// Dashboard Crypto
// Portfolio
export const GET_BTCPORTFOLIO_DATA = "/btcPortfolio-data"
export const GET_USDPORTFOLIO_DATA = "/usdPortfolio-data"
export const GET_EUROPORTFOLIO_DATA = "/euroPortfolio-data"

// Market Graph
export const GET_ALLMARKETDATA_DATA = "/allMarket-data"
export const GET_YEARMARKET_DATA = "/yearMarket-data"
export const GET_MONTHMARKET_DATA = "/monthMarket-data"
export const GET_WEEKMARKET_DATA = "/weekMarket-data"
export const GET_HOURMARKET_DATA = "/hourMarket-data"

// Dashboard Crypto
// Project Overview
export const GET_ALLPROJECT_DATA = "/allProject-data"
export const GET_MONTHPROJECT_DATA = "/monthProject-data"
export const GET_HALFYEARPROJECT_DATA = "/halfYearProject-data"
export const GET_YEARPROJECT_DATA = "/yearProject-data"

// Project Status
export const GET_ALLPROJECTSTATUS_DATA = "/allProjectStatus-data"
export const GET_WEEKPROJECTSTATUS_DATA = "/weekProjectStatus-data"
export const GET_MONTHPROJECTSTATUS_DATA = "/monthProjectStatus-data"
export const GET_QUARTERPROJECTSTATUS_DATA = "/quarterProjectStatus-data"

// Dashboard NFT
// Marketplace
export const GET_ALLMARKETPLACE_DATA = "/allMarketplace-data"
export const GET_MONTHMARKETPLACE_DATA = "/monthMarketplace-data"
export const GET_HALFYEARMARKETPLACE_DATA = "/halfYearMarketplace-data"
export const GET_YEARMARKETPLACE_DATA = "/yearMarketplace-data"

// Project
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

// Pages > Team
export const GET_TEAMDATA = "/teamData"
export const DELETE_TEAMDATA = "/delete/teamData"
export const ADD_NEW_TEAMDATA = "/add/teamData"
export const UPDATE_TEAMDATA = "/update/teamData"

// File Manager
// Folder
export const GET_FOLDERS = "/folder"
export const DELETE_FOLDER = "/delete/folder"
export const ADD_NEW_FOLDER = "/add/folder"
export const UPDATE_FOLDER = "/update/folder"

// File
export const GET_FILES = "/file"
export const DELETE_FILE = "/delete/file"
export const ADD_NEW_FILE = "/add/file"
export const UPDATE_FILE = "/update/file"

// To do
export const GET_TODOS = "/todo"
export const DELETE_TODO = "/delete/todo"
export const ADD_NEW_TODO = "/add/todo"
export const UPDATE_TODO = "/update/todo"

// To do Project
export const GET_PROJECTS = "/projects"
export const ADD_NEW_TODO_PROJECT = "/add/project"

//JOB APPLICATION
export const GET_APPLICATION_LIST = "/application-list"

//JOB APPLICATION
export const GET_API_KEY = "/api-key"

// Quotation
export const CREATE_QUOTATION_STEP1 = "/Quotation/QuotationStep1"
export const GET_ALL_QUOTATION = "/Quotation/FindAll"

//
export const GET_RATES = "/Quotation/GetRates"
export const GET_QUOTATION_BY_ID = "/Quotation/FindQuotationByID"
export const GET_VEHICLE_RATES_BY_ID = "/Quotation/GetVehicleRates"
export const GET_TOUR_FILTERS = "/Tours/TourFilterCriteriaData"
export const GET_TOUR_BY_FILTER = "/Tours/GetTourListbyFilter"
export const GET_VEHICLE_TYPE_BY_VEHICLE_TYPE =
  "/VehicleTypes/GetRatesByNoOfPax";
export const GET_AUTO_QUOTATION_BY_QUOTATION_ID =
  "/Quotation/FindQuotationByID";
export const GET_AUTO_QUOTATION_RATES = "/Quotation/GetRates";
export const GET_ROOM_CATEGORY_BY_HOTEL_AUTO =
  "/Quotation/GetRoomCategoriesByHotel";
export const EXPORT_CUSTOM_QUOTE_PDF = "/QuotationPrint/CustomQuotationPDF";
export const EXPORT_FIXED_QUOTE_PDF = "/QuotationPrint/fixedtourquotationpdf";
// Accommodation
export const CREATE_ACCOMMODATION = "/Quotation/AccomodationStep2"
export const DETAIL_ACCOMMODATION = "/Quotation/AccomodationFindByID"
export const INSERT_QUOTATION = "/Quotation/InsertAccomodation"
export const COPY_ACCOMMODATION_URL = "/Quotation/AccommodationCopy"
// Additional Services
export const CREATE_ADDITIONAL_SERVICES = "/Quotation/OthersStep4"
export const DETAIL_ADDITIONAL_SERVICES_PER_DAY = "/Quotation/MiscDaysFindByID"
export const DETAIL_ADDITIONAL_SERVICES_PER_PERSON =
  "/Quotation/MiscPaxFindByID";

// Transportation
export const INSERT_TRANSPORTATION = "/Quotation/TransportStep3"
export const GET_TRANSPORTATION = "/Quotation/TransportationFindByID"
export const INSERT_MISCELLANEOUS = "/Quotation/InsertMiscs"
export const INSERT_OTHERS = "/Quotation/InsertOthers"

export const UPDATE_ACCOMODATION = "/Quotation/UpdateAccomodation"
export const UPDATE_TRANSPORTATION = "/Quotation/TransportStep3"
export const UPDATE_MISCELLANEOUS = "/Quotation/UpdateMiscs"
export const UPDATE_AUTO_QUOTATION = "/Quotation/Updateitinerary"

export const DELETE_TRANSPORTATION = "/Quotation/DeleteQuotationTransportation"
export const DELETE_MISC = "/Quotation/DeleteQuotationMiscs"

// Auto Quotation

export const INSERT_AUTO_QUOTATION = "/Quotation/AutoQuotation"

// Sector
export const GET_ALL_ACTIVE_SECTOR = "/Sectors/FindAllActive"
export const INSERT_SECTOR_URL = "/Sectors/Insert"
export const UPDATE_SECTOR_URL = "/Sectors/Update"
export const FIND_BY_ID_SECTOR_URL = "/Sectors/FindByID"
export const FIND_ALL_SECTOR_URL = "/Sectors/FindAll"
export const DELETE_SECTOR_URL = "/Sectors/Delete"
export const ACTIVE_INACTIVE_SECTOR_URL = "/Sectors/Active-InActive"

// Sub Sectors
export const GET_SUBSECTOR_BY_SECTOR_ID = "/SubSectors/FindBySectorID";
export const GET_ALL_ACTIVE_SUBSECTOR = "/SubSectors/FindAllActive";
export const INSERT_SUBSECTOR_URL = "/SubSectors/Insert";
export const UPDATE_SUBSECTOR_URL = "/SubSectors/Update";
export const FIND_BY_ID_SUBSECTOR_URL = "/SubSectors/FindByID";
export const FIND_ALL_SUBSECTOR_URL = "/SubSectors/FindAll";
export const DELETE_SUBSECTOR_URL = "/SubSectors/Delete";
export const ACTIVE_INACTIVE_SUBSECTOR_URL = "/SubSectors/ActiveInActive";

// Tour
export const GET_ALL_ACTIVE_TOURS = "/Tours/FindAllActive";
export const GET_TOUR_BY_ID = "/Tours/FindByID";
export const GET_TOUR_BY_SECTOR_ID = "/Tours/FindBySectorID";
export const GET_TOUR_ITENERARIES_BY_TOUR_ID = "/Tours/TourIDwiseItineraryList";
export const DELETE_TOUR_URL = "/Tours/Delete";
export const INSERT_TOUR = "/Tours/insert";
export const INSERT_TOUR_ITENERARIES = "/TourItineraries/Insert";
export const UPDATE_TOUR = "/Tours/Update";
export const TOUR_ACTIVE_INACTIVE = "/Tours/Active-InActive";



// TourItineraries
export const UPDATE_TOUR_ITENERARIES = "/TourItineraries/Update"

export const DELETE_TOUR_ITENERARIES = "/TourItineraries/Delete"


// _ITENERARIES fecility

export const UPDATE_TOUR_FECILITY_ITENERARIES = "/ItineraryFacilities/Update"
export const INSERTTOUR_FECILITY_ITENERARIES = "/ItineraryFacilities/Insert"
export const DELETE_TOUR_FECILITY_ITENERARIES = "/ItineraryFacilities/Delete"
export const GET_ALL_TOUR_ITENERARIES = "/ItineraryFacilities/FindAll"
export const GET_ALL_ACTIVE_TOUR_ITENERARIES = "/ItineraryFacilities/FindAllActive"
export const FIND_BY_ID_TOUR_ITENERARIES="/ItineraryFacilities/FindByID"
export const ACTIVE_INACTIVE_TOUR_ITENERARIES = "/ItineraryFacilities/Active-InActive"
// Agents
export const GET_ALL_ACTIVE_AGENTS = "/SubAgents/FindAllActive";
export const SEARCH_AGENT_USING_NAME = "/SubAgents/SearchAgent";
export const GET_ALL_AGENT = "/SubAgents/FindAll";
export const FIND_BY_ID_AGENT_URL = "/SubAgents/FindByID";
export const INSERT_AGENT_URL = "/SubAgents/Insert";
export const INSERT_AGENT_DOCUMENTS_URL = "/SubAgents/DocumentsInsert";
export const UPDATE_AGENT_URL = "/SubAgents/Insert";
export const UPDATE_AGENT_DOCUMENTS_URL = "/SubAgents/DocumentsInsert";
export const ACTIVE_INACTIVE_AGENT_URL = "/SubAgents/Active-InActive";
export const DELETE_AGENT_URL = "/SubAgents/Delete";

// Cities
export const GET_ALL_ACTIVE_CITIES = "/Cities/ActiveCities";
export const GET_ALL_ACIN_ACTIVE_CITIES = "/Cities/Cities";
export const GET_CITIES_BY_SECTOR_ID = "/Sectors/FindByID";
export const GET_CITIES_BY_TOUR_ID = "/TourItineraries/TourWiseCityData";
export const FIND_BY_ID_CITY_URL = "/Cities/CitiesByID";
export const INSERT_CITY_URL = "/Cities/InsertCities";
export const UPDATE_CITY_URL = "/Cities/UpdateCities";
export const ACTIVE_INACTIVE_CITY_URL = "/Cities/Active-InActive";
export const DELETE_CITY_URL = "/Cities/DeleteCities";

//Branches
export const GET_ALL_ACTIVE_BRANCHES = "/Branch/FindAllActive";

// State
export const GET_ALL_STATE_LIST = "/States/FindAll";
export const GET_ALL_ACTIVE_STATES = "/States/FindAllActive";
export const GET_STATES_BY_COUNTRYID = "/States/FindAllActive";
export const FIND_BY_ID_STATE_URL = "/States/FindByID";
export const INSERT_STATE_URL = "/States/Insert";
export const UPDATE_STATE_URL = "/States/Update";
export const ACTIVE_INACTIVE_STATE_URL = "/States/Active-InActive";
export const DELETE_STATE_URL = "/States/Delete";

export const GET_ALL_CITIES_BY_SECTOR_ID = "/Sectors/GetCitiesBySectorID";
// Hotels
export const GET_ALL_ACTIVE_HOTELS = "/Hotels/FindAllActive"
export const GET_ROOM_CATEGORY_BY_HOTEL = "/Hotels/GetRoomCategory"
export const GET_HOTEL_BY_ID = "/Hotels/HotelFindByID"
export const GET_HOTEL_BY_ROOM_CATEGORY = "/Hotels/GetHotelsbyHotelCategory"
export const GET_HOTEL_ROOM_CATEGORY_BY_HOTEL_ID =
  "/Hotels/HotelRoomcatgeoryByHotelID";
export const INSERT_HOTEL = "/Hotels/HotelsInsert";
export const GET_ALL_HOTEL_LIST = "/Hotels/HotelFindAll";
export const DELETE_HOTEL = "/Hotels/HotelsDelete";
export const GET_HOTEL_CITY_WISE_URL =
  "/Hotels/CityWiseHotelAndRoomcategoryList";
export const GET_HOTEL_CITY_WISE_URL_FOR_TOUR = "/Hotels/CitywiseHotelList";

// Room Category
export const ADD_ROOM_CATEGORIES = "/Hotels/HotelRoomCategories"

export const UPDATE_HOTEL_RROM_CATEGORY = "/Hotels/UpdateHotelRoomCategory"
export const UPDATE_HOTEL = "/Hotels/HotelsInsert"
export const GET_HOTEL_ROOM_CATEGORY_BY_ID = "/Hotels/HotelRoomcatgeoryByID"
export const DELETE_HOTEL_RROM_CATEGORY = "/Hotels/DeleteHotelRoomCategory"

// FOR INSERT/UPDATE/DELETE Hotel Rates
export const INSERT_HOTEL_RATES = "/Hotels/InsertHotelRates"
export const UPDATE_HOTEL_RATES = "/Hotels/InsertHotelRates"
export const DELETE_HOTEL_RATES = "/Hotels/InsertHotelRates"

// GET Hotel Rates by ID
export const GET_HOTEL_RATES_BY_ID = "/Hotels/HotelRatesFindByID"

// Hotel Contact
export const GET_ALL_HOTEL_CONTACT = "/Hotels/HotelContactsFindByHotelID"
export const GET_HOTEL_CONTACT = "/Hotels/HotelContactsFindByID"
export const INSERT_HOTEL_CONTACT = "/Hotels/HotelContacts"
export const GET_HOTEL_CONTACT_BY_ID = "/Hotels/HotelContactsFindByID"
export const ACTIVE_INACTIVE_HOTEL_CONTACT = "/Hotels/ActiveInActive"

// Rooms
export const GET_ALL_ACTIVE_ROOM_CATAGORIES = "/RoomCategories/FindAllActive"
export const GET_ROOM_CATEGORIES_BY_HOTELID =
  "/Quotation/GetRoomCategoriesByHotel";

// Meal
export const GET_ALL_ACTIVE_MEAL_TYPES = "/MealTypes/FindAllActive"

// Vehicle

export const GET_ALL_ACTIVE_VEHICLE = "/Vehicles/FindAllActive";
export const GET_ALL_VEHICLE = "/Vehicles/FindAll";
export const GET_SEAT_BY_VEHICLE_TYPE = "/Quotation/GetSeatsListByVehicleType";
export const GET_VEHICLE_TYPE_BY_TOUR = "/VehicleTypes/FindVehicleTypeByTour";
export const FIND_BY_ID_VEHICLE_URL = "/Vehicles/FindByID";
export const INSERT_VEHICLE_URL = "/Vehicles/Insert";
export const UPDATE_VEHICLE_URL = "/Vehicles/Update";
export const ACTIVE_INACTIVE_VEHICLE_URL = "/Vehicles/Active-InActive";
export const DELETE_VEHICLE_URL = "/Vehicles/Delete";

// PaymentReceipt
export const GET_ALL_PAYMENTRECEIPT = "/PaymentReceipts/FindAll";
export const FIND_BY_ID_PAYMENTRECEIPT_URL = "/PaymentReceipts/FindByID";
export const FIND_BY_BOOKINGNO_PAYMENTRECEIPT_URL = "/PaymentReceipts/GetCustomerReceiptData";
export const INSERT_PAYMENTRECEIPT_URL = "/PaymentReceipts/Insert";
export const UPDATE_PAYMENTRECEIPT_URL = "/PaymentReceipts/Update";
// export const ACTIVE_INACTIVE_PAYMENTRECEIPT_URL = "/PAYMENTRECEIPTs/Active-InActive";
export const DELETE_PAYMENTRECEIPT_URL = "/PaymentReceipts/Delete";

// Inquiry
export const GET_ALL_INQUIRY_BY_FILTER = "/Inquiries/GetAssignLeadList";
export const GET_ALL_PENDING_INQUIRY_BY_FILTER =
  "/Inquiries/GetPendingLeadList";
export const FIND_BY_ID_INQUIRY_URL = "/Inquiries/FindByID";
export const INSERT_INQUIRY_URL = "/Inquiries/Insert";
export const UPDATE_INQUIRY_URL = "/Inquiries/Update";
export const DELETE_INQUIRY_URL = "/Inquiries/Delete";
export const ASSIGN_USER_URL = "/AssignedUser/AssignedUsers";

// Vehicle type
export const GET_ALL_ACTIVE_VEHICLE_TYPE = "/VehicleTypes/FindAllActive";
export const GET_ALL_VEHICLE_TYPE = "/VehicleTypes/FindAll";
export const FIND_BY_ID_VEHICLE_TYPE = "/VehicleTypes/FindByID";
export const INSERT_VEHICLE_TYPE = "/VehicleTypes/Insert";
export const UPDATE_VEHICLE_TYPE = "/VehicleTypes/Update";
export const ACTIVE_INACTIVE_VEHICLE_TYPE = "/VehicleTypes/Active-InActive";
export const DELETE_VEHICLE_TYPE_URL = "/VehicleTypes/Delete";
//User
export const GET_ALL_ACTIVE_USER = "/Users/FindAllActive";
export const GET_ALL_USER = "/Users/FindAll";
export const FIND_BY_ID_USER_URL = "/Users/FindByID";
export const INSERT_USER_URL = "/Users/Insert";
export const UPDATE_USER_URL = "/Users/Update";
export const ACTIVE_INACTIVE_USER_URL = "/Users/ActiveInActive";
export const DELETE_USER_URL = "/Users/Delete";

//lead source
export const GET_ALL_ACTIVE_LEAD_SOURCE = "/InquirySources/FindAllActive";
export const GET_ALL_LEAD_SOURCE = "/InquirySources/FindAll";
export const INSERT_LEAD_SOURCE = "/InquirySources/Insert";
export const UPDATE_LEAD_SOURCE = "/InquirySources/Update";
export const FIND_BY_ID_LEAD_SOURCE = "/InquirySources/FindByID";
export const DELETE_LEAD_SOURCE = "/InquirySources/Delete";
export const ACTIVE_INACTIVE_LEAD_SOURCE = "/InquirySources/ActiveInActive";

// Country
export const GET_ALL_ACTIVE_COUNTRIES = "/Countries/FindAllActive";
export const GET_ALL_COUNTRY_LIST = "/Countries/FindAll";
export const INSERT_COUNTRY_URL = "/Countries/Insert";
export const UPDATE_COUNTRY_URL = "/Countries/Update";
export const FIND_BY_ID_COUNTRY_URL = "/Countries/FindByID";
export const DELETE_COUNTRY_URL = "/Countries/Delete";
export const ACTIVE_INACTIVE_COUNTRY_URL = "/Countries/Active-InActive";

// Festivals
export const GET_ALL_ACTIVE_FESTIVALS = "/Festivals/FindAllActive";
export const GET_ALL_FESTIVAL = "/Festivals/FindAll";
export const INSERT_FESTIVAL = "/Festivals/Insert";
export const UPDATE_FESTIVAL = "/Festivals/Update";
export const FIND_BY_ID_FESTIVAL = "/Festivals/FindByID";
export const DELETE_FESTIVAL = "/Festivals/Delete";
export const ACTIVE_INACTIVE_FESTIVAL = "/Festivals/Active-InActive";

// Speciality types
export const GET_ALL_ACTIVE_SPECIALITY_TYPES = "/SpecialityTypes/FindAllActive";
export const GET_ALL_SPECIALITY_TYPE_LIST = "/SpecialityTypes/FindAll";
export const INSERT_SPECIALITY_TYPE_URL = "/SpecialityTypes/Insert";
export const UPDATE_SPECIALITY_TYPE_URL = "/SpecialityTypes/Update";
export const FIND_BY_ID_SPECIALITY_TYPE_URL = "/SpecialityTypes/FindByID";
export const DELETE_SPECIALITY_TYPE_URL = "/SpecialityTypes/Delete";
export const ACTIVE_INACTIVE_SPECIALITY_TYPE_URL =
  "/SpecialityTypes/Active-InActive";

// Terms and Conditions
export const GET_ALL_ACTIVE_TERMS_AND_CONDITIONS =
  "/TermsAndConditions/FindAllActive";
export const GET_ALL_TERM_CONDITION_LIST = "/TermsAndConditions/FindAll";
export const INSERT_TERM_CONDITION_URL = "/TermsAndConditions/Insert";
export const UPDATE_TERM_CONDITION_URL = "/TermsAndConditions/Update";
export const FIND_BY_ID_TERM_CONDITION_URL = "/TermsAndConditions/FindByID";
export const DELETE_TERM_CONDITION_URL = "/TermsAndConditions/Delete";
export const ACTIVE_INACTIVE_TERM_CONDITION_URL =
  "/TermsAndConditions/Active-InActive";

// Canelation Ploicy
export const GET_CANCELATION_POLICY = "/CancellationPolicies/FindAllActive";
export const INSERT_CANCELLATION_POLICY = "/CancellationPolicies/Insert";
export const UPDATE_CANCELLATION_POLICY = "/CancellationPolicies/Update";
export const FIND_BY_ID_CANCELLATION_POLICY = "/CancellationPolicies/FindByID";
export const FIND_ALL_CANCELLATION_POLICY = "/CancellationPolicies/FindAll";
export const DELETE_CANCELLATION_POLICY = "/CancellationPolicies/Delete";
export const ACTIVE_INACTIVE_CANCELLATION_POLICY = "/CancellationPolicies/Active-InActive";

// Itinerary
export const GET_ITINERARY_BY_CITY_ID = "/Itinerary/CityItinerary"
export const GET_ALL_ACTIVE_ITINERARY = "/Itinerary/FindAllActive"
export const GET_ALL_ACTIVE_ITINERARY_FACILITIES =
  "/ItineraryFacilities/FindAllActive";
export const GET_ALL_ITINERARY = "/Itinerary/FindAll";
export const FIND_BY_ID_ITINERARY_URL = "/Itinerary/FindByID";
export const INSERT_ITINERARY_URL = "/Itinerary/Insert";
export const UPDATE_ITINERARY_URL = "/Itinerary/Update";
export const ACTIVE_INACTIVE_ITINERARY_URL = "/Itinerary/Active-InActive";
export const DELETE_ITINERARY_URL = "/Itinerary/Delete";

// GST
export const GET_ALL_GST_ACTIVE = "/GST/FindAllActive";
export const GET_ALL_GST = "/GST/FindAll";
export const INSERT_GST = "/GST/Insert";
export const UPDATE_GST = "/GST/Update";
export const FIND_BY_ID_GST = "/GST/FindByID";
export const DELETE_GST = "/GST/Delete";
export const ACTIVE_INACTIVE_GST = "/GST/Active-InActive";

// Regions
export const GET_ALL_REGIONS_LIST = "/Regions/FindAllActive"
