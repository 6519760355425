/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import {
  deleteSector,
  getToursByFilter,
  tourDelete,
} from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
// import _ from "lodash"
// import Loader from "../../Components/Common/Loader"
import Switch from "../../Components/Common/Switch";
import DeleteModal from "../../Components/Common/DeleteModal";
import { PAGE_LIMIT } from "../../Components/constants/field";
import {
  nextAndPrevPage,
  pageCount,
  resetPage,
} from "../../slices/pagination/reducer"
import Loader from "../../Components/Common/Loader"
import _ from "lodash"
import Pagination from "../../Components/Common/Pagination"
// import { ToastContainer } from "react-toastify"
const TourMaster = () => {
  const dispatch = useDispatch();
  const { page, totalPage } = useSelector((state) => state.Pagination);
  const { tours, loading } = useSelector((state) => state.Tour);
  //
  const [reload, setReload] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    const filters = {
      SectorID: "",
      TourSpecialityTypeID: "",
      NoOfNights: "",
      PageNo: page,
      PageSize: PAGE_LIMIT,
    };
    dispatch(getToursByFilter(filters)).then((res) => {
      const TotalPages = res?.payload?.TotalPages || 1;
      dispatch(pageCount(TotalPages || 1));
    });
    // return () => {
    //   dispatch(resetPage())
    //   // return () => {
    //   //   // dispatch(resetTabs())
    //   // }
    // }
  }, [page, PAGE_LIMIT, reload]);

  useEffect(() => {
    return () => {
      dispatch(resetPage());
    };
  }, []);

  // const handleChange = (data) => {
  //   let obj = { ID: +data.ID, IsActive: !data.IsActive }

  //   // setChecked(!checked)
  //   dispatch(ActiveInActiveSector(obj)).then((res) => {
  //     if (res.payload && res.payload.Status === 200) {
  //       setReload(!reload)
  //     }
  //   })
  // }

  const onClickDelete = (data) => {
    setDeleteModal(true);
    setDeleteData(data);
  };

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.TourID,
    };
    dispatch(tourDelete(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload);
        setDeleteModal(false);
      }
    });
  };
  const handlePageClick = ({ selected }) => {
    let page = (Number(selected) || 0) + 1;
    dispatch(nextAndPrevPage(page));
  };
  return (
    <div className="page-content">
      <Container fluid>
        {/* <ToastContainer /> */}
        <BreadCrumb title="Tour Master" pageTitle="Tour" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md={4}>
                    <h4 className="card-title mb-0">Tour Master</h4>
                  </Col>
                  <Col md={8} className="text-end">
                    <Link to="/tours/create">
                      <Button color="primary">
                        <i className="las la-plus"></i>Add
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <Table
                        className="align-middle table-nowrap mb-0"
                        striped
                        responsive
                        hover
                      >
                        <thead>
                          <tr>
                            <th scope="col">Tour Name</th>
                            <th scope="col">Sector Type</th>
                            <th scope="col">Travel Type</th>
                            <th scope="col">Sector</th>
                            <th scope="col">Types of Package</th>
                            <th scope="col">Night</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading && Array.isArray(tours) && tours.length ? (
                            tours.map((tour, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={`/tours/update/${tour.TourID}?t=${tour.TourID}`}
                                  >
                                    {tour.TourName}
                                  </Link>
                                </td>
                                <td>{tour.SectorTypeName}</td>
                                <td>{tour.SpecialityType}</td>
                                <td>{tour.SectorName}</td>
                                <td>{tour.TravelTypeID}</td>
                                <td>{tour.NoOfNights}</td>
                                <td>{tour.IsActive ? "Active" : "Inactive"}</td>
                                {/* <td>
                                  {Array.isArray(tour?.Cities)
                                    ? _.join(
                                        _.map(tour.Cities, "CityName"),
                                        ", "
                                      )
                                    : ""}
                                </td> */}

                                <td>
                                  <div className="hstack gap-3 flex-wrap">
                                    <Link
                                      to={`/tours/update/${tour.TourID}?t=${tour.TourID}`}
                                      className="link-success fs-18"
                                    >
                                      <i className="ri-edit-line"></i>
                                    </Link>
                                    <div
                                      className="hstack gap-3 flex-wrap "
                                      role="button"
                                      onClick={() => onClickDelete(tour)}
                                    >
                                      <i className=" ri-delete-bin-fill text-danger fs-18"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : loading ? (
                            <tr>
                              <td colSpan={10}>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={10}>No hotel found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      pageCount={totalPage}
                      handlePageClick={handlePageClick}
                      page={page}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <DeleteModal
            show={deleteModal}
            onCloseClick={() => setDeleteModal(false)}
            onDeleteClick={() => onDeleted()}
          />
        </Row>
      </Container>
    </div>
  );
};

export default TourMaster;
