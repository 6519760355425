import { createSlice } from "@reduxjs/toolkit";
import {
  getItineraryByCityID,
  getItineraries,
  getAllActiveItineraryFacilities,
  getAllACINItineraries,
  insertItinerary,
  updateItinerary,
  findByIdItinerary,
} from "./thunk";

export const ItineraryForm = {
  ID: undefined,
  IsActive: true,
  CityID: null,
  ItineraryTitle: "",
  Description: "",
  ItineraryImage: "",
};

export const initialState = {
  itineraryDetail: {
    form: ItineraryForm,
  },
  itineraries: [],
  allItineraries: [],
  itineraryFacilities: [],
  error: {},
  loading: false,
};

const ItineraryReducer = createSlice({
  name: "Itinerary",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.itineraryDetail.form = ItineraryForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getItineraryByCityID.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getItineraryByCityID.fulfilled, (state, action) => {
      state.itineraries = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getItineraryByCityID.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });
    builder.addCase(getItineraries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getItineraries.fulfilled, (state, action) => {
      state.itineraries = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getItineraries.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });
    builder.addCase(getAllActiveItineraryFacilities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllActiveItineraryFacilities.fulfilled,
      (state, action) => {
        state.itineraryFacilities = action.payload.Data;
        state.loading = false;
      }
    );
    builder.addCase(
      getAllActiveItineraryFacilities.rejected,
      (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
      }
    );

    builder.addCase(getAllACINItineraries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllACINItineraries.fulfilled, (state, action) => {
      state.allItineraries = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllACINItineraries.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    // Insert Itinerary
    builder.addCase(insertItinerary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(insertItinerary.fulfilled, (state) => {
      state.itineraryDetail.form = ItineraryForm;
      state.loading = false;
    });
    builder.addCase(insertItinerary.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    // Update Itinerary
    builder.addCase(updateItinerary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateItinerary.fulfilled, (state) => {
      state.itineraryDetail.form = ItineraryForm;
      state.loading = false;
    });
    builder.addCase(updateItinerary.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    // Get By Id Itinerary
    builder.addCase(findByIdItinerary.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findByIdItinerary.fulfilled, (state, action) => {
      if (action.payload && action.payload.Data) {
        state.itineraryDetail.form = {
          ...action.payload.Data,
        };
      }
    });
    builder.addCase(findByIdItinerary.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
  },
});

export const { resetForm } = ItineraryReducer.actions;

export default ItineraryReducer.reducer;
