import React from "react";
import {
  Accordion,
  AccordionItem,
  Col,
  Collapse,
  Row,
  Table,
} from "reactstrap";
import classnames from "classnames";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteHotelRates,
  getHotelByID,
  getHotelRatesByID,
} from "../../slices/thunks";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
const HotelRateList = ({ Title, Type, List }) => {
  const [accordionStates, setAccordionStates] = useState([]);
  const { ID } = useParams();

  const dispatch = useDispatch();

  const handleAccordionToggle = (index) => {
    const newAccordionStates = [...accordionStates];
    newAccordionStates[index] = !newAccordionStates[index];
    setAccordionStates(newAccordionStates);
  };

  let WeekDaysKeyValueList = [];

  List.forEach((item) => {
    if (Type !== "SpecialRates") {
      WeekDaysKeyValueList.push({
        Category: item.RoomCategoryName,
        "From Date": item.FromDate,
        "To Date": item.ToDate,
        SOEPAI: item.SOEPAI,
        DOEPAI: item.DOEPAI,
        // EAEPAI: item.EAEPAI,
        CWBEPAI: item.CWBEPAI,
        CNBEPAI: item.CNBEPAI,
        "Kitchen Charges": item.KitchenCharges,
      });
    } else {
      WeekDaysKeyValueList.push({
        Category: item.RoomCategoryName,
        Festival: item.FestivalName,
        "From Date": item.FromDate,
        "To Date": item.ToDate,
        "Kitchen Charges": item.KitchenCharges,
      });
    }
  });
  const WeekDaysAndWeekendRateList = ({ WeekDaysKeyValue }) => {
    return (
      <>
        {Object.entries(WeekDaysKeyValue).map(([key, value], i) => (
          <Col md="4" sm="4" className="mb-3" key={i}>
            <Row key={key}>
              <Col md={6} sm={6} lg={6} className="text-muted">
                {key}:
              </Col>
              <Col md={6} sm={6} lg={6}>
                <strong>{value}</strong>
              </Col>
            </Row>
          </Col>
        ))}
      </>
    );
  };
  WeekDaysAndWeekendRateList.propTypes = {
    WeekDaysKeyValue: PropTypes.any,
  };
  const handleEdit = (e, item) => {
    dispatch(getHotelRatesByID({ ID: +item.ID }));
  };

  const handleDelete = (e, item) => {
    let payload = {
      ID: +item.ID,
      HotelID: +item.HotelID,
      OperationType: 9,
    };
    dispatch(deleteHotelRates(payload)).then((res) => {
      if (res.payload.Status == "200") dispatch(getHotelByID(ID));
    });
  };

  return (
    <>
      <Col lg={12} className="mb-2">
        <h4>{Title}</h4>
        <hr></hr>
        {WeekDaysKeyValueList.map((WeekDaysKeyValue, index) => {
          return (
            <Accordion key={index} className="my-3">
              <AccordionItem style={{ background: "aliceblue" }}>
                <p className="accordion-header m-3">
                  <Row
                    className={classnames("", {
                      collapsed: accordionStates[index],
                    })}
                    role="button"
                  >
                    <Col md={10} onClick={() => handleAccordionToggle(index)}>
                      <Row>
                        <WeekDaysAndWeekendRateList
                          WeekDaysKeyValue={WeekDaysKeyValue}
                        />
                      </Row>
                    </Col>
                    <Col
                      md={2}
                      className="d-flex flex-row align-items-center justify-content-evenly"
                    >
                      <div className="hstack gap-3 flex-wrap " role="button">
                        <i
                          className="ri-edit-line text-success fs-18"
                          onClick={(e) => handleEdit(e, List[index])}
                        ></i>
                        <div className="gap-3 " role="button">
                          <i
                            className="ri-delete-bin-fill text-danger fs-18"
                            onClick={(e) => handleDelete(e, List[index])}
                          ></i>
                        </div>
                      </div>
                      {!accordionStates[index] ? (
                        <i
                          className="las la-angle-down"
                          onClick={() => handleAccordionToggle(index)}
                        ></i>
                      ) : (
                        <i
                          className="las la-angle-up"
                          onClick={() => handleAccordionToggle(index)}
                        ></i>
                      )}
                    </Col>
                  </Row>
                </p>
                <Collapse
                  isOpen={accordionStates[index]}
                  className="accordion-collapse"
                  style={{ background: "white" }}
                >
                  <div className="accordion-body">
                    <Row>
                      <Col lg={12}>
                        <div className="table-responsive mt-2">
                          <Table
                            className="align-middle table-nowrap mb-0"
                            striped
                            responsive
                            hover
                          >
                            <thead style={{ background: "aliceblue" }}>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">EPAI</th>
                                <th scope="col">CPAI</th>
                                <th scope="col">MAPAI</th>
                                <th scope="col">APAI</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td scope="row">Single Occupancy</td>
                                <td scope="row">{List[index].SOEPAI}</td>
                                <td scope="row">{List[index].SOCPAI}</td>
                                <td scope="row">{List[index].SOMAPAI}</td>
                                <td scope="row">{List[index].SOAPAI}</td>
                              </tr>
                              <tr>
                                <td scope="row">Double Occupancy</td>
                                <td scope="row">{List[index].DOEPAI}</td>
                                <td scope="row">{List[index].DOCPAI}</td>
                                <td scope="row">{List[index].DOMAPAI}</td>
                                <td scope="row">{List[index].DOAPAI}</td>
                              </tr>
                              {/* <tr>
                                <td scope="row">Extra Adult</td>
                                <td scope="row">{List[index].EAEPAI}</td>
                                <td scope="row">{List[index].EACPAI}</td>
                                <td scope="row">{List[index].EAMAPAI}</td>
                                <td scope="row">{List[index].EAAPAI}</td>
                              </tr> */}
                              <tr>
                                <td scope="row">ExtraAdults / CWB.</td>
                                <td scope="row">{List[index].CWBEPAI}</td>
                                <td scope="row">{List[index].CWBCPAI}</td>
                                <td scope="row">{List[index].CWBMAPAI}</td>
                                <td scope="row">{List[index].CWBAPAI}</td>
                              </tr>
                              <tr>
                                <td scope="row">CNB</td>
                                <td scope="row">{List[index].CNBEPAI}</td>
                                <td scope="row">{List[index].CNBCPAI}</td>
                                <td scope="row">{List[index].CNBMAPAI}</td>
                                <td scope="row">{List[index].CNBAPAI}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </AccordionItem>
            </Accordion>
          );
        })}
      </Col>
    </>
  );
};
HotelRateList.propTypes = {
  Title: PropTypes.string,
  Type: PropTypes.string,
  List: PropTypes.array,
};
export default HotelRateList;
