/* eslint-disable react/prop-types */
import React, { useEffect } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
  UncontrolledCollapse,
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import { useDispatch, useSelector } from "react-redux"
import { getTourFilters, getToursByFilter } from "../../slices/thunks"
import { Link, useNavigate } from "react-router-dom"
import Loader from "../../Components/Common/Loader"
import { Field, Form, Formik, useFormikContext } from "formik"
import * as Yup from "yup"
import { isEmpty } from "lodash"
import CommingSoon from "../../assets/images/comingsoon.png"
import { PAGE_LIMIT } from "../../Components/constants/field"
import Pagination from "../../Components/Common/Pagination"
import {
  nextAndPrevPage,
  pageCount,
  resetPage,
} from "../../slices/pagination/reducer"
// import { setTourDetailsInQuotation } from "../../slices/quotation/reducer";

function ChangeWatcher() {
  const dispatch = useDispatch()
  const { page } = useSelector((state) => state.Pagination)
  const { values } = useFormikContext()
  useEffect(() => {
    const filters = {
      SectorID: "",
      TourSpecialityTypeID: "",
      NoOfNights: "",
      PageNo: page,
      PageSize: PAGE_LIMIT,
    }
    if (values.SectorID && values.SectorID.length > 0) {
      filters.SectorID = values.SectorID.join(",")
    }
    if (values.TourSpecialityTypeID && values.TourSpecialityTypeID.length > 0) {
      filters.TourSpecialityTypeID = values.TourSpecialityTypeID.join(",")
    }
    if (values.NoOfNights && values.NoOfNights.length > 0) {
      filters.NoOfNights = values.NoOfNights.join(",")
    }

    if (!isEmpty(filters)) {
      dispatch(getToursByFilter(filters)).then((res) => {
        const TotalPages = res?.payload?.TotalPages || 1
        dispatch(pageCount(TotalPages || 1))
      })
    }
  }, [values])
}

function AutoQuotation() {
  const history = useNavigate()
  const dispatch = useDispatch()
  const { page, totalPage } = useSelector((state) => state.Pagination)
  const { tours } = useSelector((state) => ({
    tours: state.Tour.tours,
  }))

  const { tourFilters } = useSelector((state) => ({
    tourFilters: state.Tour.tourFilters,
  }))

  const { loading } = useSelector((state) => ({
    loading: state.Tour.loading,
  }))
  useEffect(() => {
    const filters = {
      SectorID: "",
      TourSpecialityTypeID: "",
      NoOfNights: "",
      PageNo: page,
      PageSize: PAGE_LIMIT,
    }
    dispatch(getToursByFilter(filters)).then((res) => {
      const TotalPages = res?.payload?.TotalPages || 1
      dispatch(pageCount(TotalPages || 1))
    })
    // return () => {
    //   dispatch(resetPage())
    //   // return () => {
    //   //   // dispatch(resetTabs())
    //   // }
    // }
  }, [page, PAGE_LIMIT])
  useEffect(() => {
    dispatch(getTourFilters())
    return () => {
      dispatch(resetPage());
    };
  }, [])

  const handleSubmit = (values) => {
    console.log(values)
  }

  const handleClearAll = (props) => {
    props.handleReset()
  }

  const handleRateCalculateClick = (tour) => {
    // dispatch(setTourDetailsInQuotation(tour));
    history(`/auto-quotations/${tour?.TourID || ""}/create`)
    //
  }
  const handlePageClick = ({ selected }) => {
    let page = (Number(selected) || 0) + 1
    dispatch(nextAndPrevPage(page))
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Auto Quotation Master" pageTitle="Auto Quotation" />
        <Row>
          <Col xl={3} lg={4}>
            <Formik
              initialValues={{
                SectorID: undefined,
                TourSpecialityTypeID: undefined,
                NoOfNights: undefined,
              }}
              validationSchema={Yup.object({
                SectorID: Yup.array().of(Yup.string().nullable(true)),
              })}
              onSubmit={handleSubmit}
            >
              {(props) => (
                <Form>
                  <ChangeWatcher />
                  <Card>
                    <CardHeader>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h5 className="fs-16">Filters</h5>
                        </div>
                        <div className="flex-shrink-0">
                          <Link
                            to="#"
                            className="text-decoration-underline"
                            onClick={() => handleClearAll(props)}
                          >
                            Clear All
                          </Link>
                        </div>
                      </div>
                    </CardHeader>
                    <div className="accordion accordion-flush">
                      {tourFilters.sectorList &&
                      tourFilters.sectorList.length > 0 ? (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button bg-transparent shadow-none"
                              type="button"
                              id="flush-headingBrands"
                            >
                              <span className="text-muted text-uppercase fs-12 fw-medium">
                                Destinations
                              </span>{" "}
                            </button>
                          </h2>
                          <UncontrolledCollapse
                            toggler="#flush-headingBrands"
                            defaultOpen
                          >
                            <div
                              id="flush-collapseBrands"
                              className="accordion-collapse collapse show"
                              aria-labelledby="flush-headingBrands"
                            >
                              <div className="accordion-body text-body pt-0">
                                <div className="d-flex flex-column gap-2 mt-3">
                                  {tourFilters.sectorList.map(
                                    (sector, index) => (
                                      <div
                                        key={index}
                                        role="group"
                                        aria-labelledby="checkbox-group"
                                      >
                                        <div className="form-check mb-3">
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="SectorID"
                                            value={sector.ID.toString()}
                                          />
                                          <Label
                                            className="form-check-label"
                                            htmlFor="formCheck6"
                                          >
                                            {sector.SectorName}
                                          </Label>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </UncontrolledCollapse>
                        </div>
                      ) : null}
                      {tourFilters.specialitytypeList &&
                      tourFilters.specialitytypeList.length > 0 ? (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button bg-transparent shadow-none"
                              type="button"
                              id="flush-headingBrands"
                            >
                              <span className="text-muted text-uppercase fs-12 fw-medium">
                                Speciallity Type
                              </span>{" "}
                            </button>
                          </h2>
                          <UncontrolledCollapse
                            toggler="#flush-headingBrands"
                            defaultOpen
                          >
                            <div
                              id="flush-collapseBrands"
                              className="accordion-collapse collapse show"
                              aria-labelledby="flush-headingBrands"
                            >
                              <div className="accordion-body text-body pt-0">
                                <div className="d-flex flex-column gap-2 mt-3">
                                  {tourFilters.specialitytypeList.map(
                                    (specialitytype, index) => (
                                      <div
                                        key={index}
                                        role="group"
                                        aria-labelledby="checkbox-group"
                                      >
                                        <div className="form-check mb-3">
                                          <Field
                                            className="form-check-input"
                                            type="checkbox"
                                            name="TourSpecialityTypeID"
                                            value={specialitytype.ID.toString()}
                                          />
                                          <Label
                                            className="form-check-label"
                                            htmlFor="formCheck6"
                                          >
                                            {specialitytype.SpecialityType}
                                          </Label>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </UncontrolledCollapse>
                        </div>
                      ) : null}
                      {tourFilters.specialitytypeList &&
                      tourFilters.specialitytypeList.length > 0 ? (
                        <div className="accordion-item">
                          <h2 className="accordion-header">
                            <button
                              className="accordion-button bg-transparent shadow-none"
                              type="button"
                              id="flush-headingBrands"
                            >
                              <span className="text-muted text-uppercase fs-12 fw-medium">
                                No Of Nights
                              </span>{" "}
                            </button>
                          </h2>
                          <UncontrolledCollapse
                            toggler="#flush-headingBrands"
                            defaultOpen
                          >
                            <div
                              id="flush-collapseBrands"
                              className="accordion-collapse collapse show"
                              aria-labelledby="flush-headingBrands"
                            >
                              <div className="accordion-body text-body pt-0">
                                <div className="d-flex flex-column gap-2 mt-3">
                                  {tourFilters.nightList.map((night, index) => (
                                    <div
                                      key={index}
                                      role="group"
                                      aria-labelledby="checkbox-group"
                                    >
                                      <div className="form-check mb-3">
                                        <Field
                                          className="form-check-input"
                                          type="checkbox"
                                          name="NoOfNights"
                                          value={night.NoOfNights.toString()}
                                        />
                                        <Label
                                          className="form-check-label"
                                          htmlFor="formCheck6"
                                        >
                                          {night.NightDays}
                                        </Label>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </UncontrolledCollapse>
                        </div>
                      ) : null}
                    </div>
                  </Card>
                </Form>
              )}
            </Formik>
          </Col>
          {loading ? <Loader /> : null}
          <div className="col-xl-9 col-lg-8">
            <div>
              {tours && tours.length > 0 && !loading
                ? tours.map((tour) => (
                    <Card key={tour.ID}>
                      <CardBody>
                        <div className="d-flex position-relative">
                          <img
                            src={
                              tour.TourImages ? tour.TourImages : CommingSoon
                            }
                            className="flex-shrink-0 me-3 avatar-xl rounded"
                            alt="..."
                          />

                          <div style={{ width: "100%" }}>
                            <div className="d-flex  justify-content-between flex-wrap align-items-center ">
                              <h5 className="mt-0">{tour.TourName}</h5>
                            </div>
                            <p>
                              {tour.NoOfNights} Nights / {tour.Days} Days
                            </p>
                            <Badge color="primary" className="me-2" pill>
                              Tour code: {tour.TourCode ? tour.TourCode : null}{" "}
                            </Badge>

                            <Row>
                              <Col lg={12} className="text-end">
                                <Button
                                  color="primary"
                                  outline
                                  onClick={() => handleRateCalculateClick(tour)}
                                >
                                  Calculate Rate
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  ))
                : null}
            </div>
            <Pagination
              pageCount={totalPage}
              handlePageClick={handlePageClick}
              page={page}
            />
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default AutoQuotation
