import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Formik, Form, useFormikContext } from "formik";
import {
  CONVERT_BOOL,
  INVALID,
  INVALID_EMAIL,
  INVALID_PHONE,
  REQUIRED,
  SelectionField,
  SingleFileField,
  TextField,
  phoneRegex,
} from "../../Components/constants/field";
import {
  getAllActiveCountries,
  getCitiesByStateID,
  getStatesByCountryID,
} from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import * as Yup from "yup";
import ContactDetailsArray from "./ContactDetailsArray";
import BankDetailsArray from "./BankDetailsArray";
import {
  findByIdAgent,
  insertAgent,
  insertAgentDocument,
  updateAgent,
  updateAgentDocument,
} from "../../slices/agent/thunk";

const CreateAgent = () => {
  const history = useNavigate();
  const { ID } = useParams();
  const dispatch = useDispatch();
  const { stateWiseCities } = useSelector((state) => state.City);
  const { countryWiseState } = useSelector((state) => state.State);
  const { countries, loading: CountryLoading } = useSelector(
    (state) => state.Country
  );
  const {
    agentDetail: { form },
  } = useSelector((state) => state.Agent);

  useEffect(() => {
    dispatch(getAllActiveCountries());
  }, []);

  useEffect(() => {
    if (ID) {
      let params = {
        ID: ID,
      };
      dispatch(findByIdAgent(params));
    }
  }, [ID]);

  const handleSubmit = (values) => {
    let { AadharDocument, GSTDocument, Logo, PanDocument, ...obj } = values;

    let agentDocsObj = {
      AadharDocument,
      GSTDocument,
      Logo,
      PanDocument,
    };

    let agentDocs = new FormData();

    Object.keys(agentDocsObj).map((key) => {
      agentDocs.append(key, values[key][0]);
    });

    obj.IsActive = CONVERT_BOOL.test(obj?.IsActive);
    obj.CityID = obj.CityID ? +obj.CityID : null;
    obj.StateID = obj.StateID ? +obj.StateID : null;
    obj.CountryID = obj.CountryID ? +obj.CountryID : null;
    obj.UniversalMarkupTypeID = obj.UniversalMarkupTypeID
      ? +obj.UniversalMarkupTypeID
      : null;

    obj.ContactNo = obj.ContactNo ? obj.ContactNo.toString() : "";
    obj.ContactDetails = obj.ContactDetails
      ? obj.ContactDetails.map((contact) => {
          const { ID: _, ...newContact } = contact; // eslint-disable-line no-unused-vars
          return { ...newContact, MobileNo: contact.MobileNo.toString() };
        })
      : [];

    obj.BankDetails = obj.BankDetails
      ? obj.BankDetails.map((bank) => {
          const { ID: _, ...newBank } = bank; // eslint-disable-line no-unused-vars
          return { ...newBank, AccountNo: bank.AccountNo.toString() };
        })
      : [];

    if (obj?.ID) {
      delete obj?.Message;
      delete obj?.StateName;
      delete obj?.CityName;
      delete obj?.CountryName;
      delete obj?.Details;
      delete obj?.Message;
      delete obj?.UniversalMarkupType;
      delete obj?.UserID;
      obj.OperationType = 2;

      dispatch(updateAgent(obj)).then((res) => {
        if (
          res.payload &&
          res.payload.Status === 200 &&
          res.payload.Data &&
          res.payload.Data.ID
        ) {
          agentDocs.append("ID", res.payload.Data.ID);
          if (typeof agentDocs.get("AadharDocument") === "string") {
            agentDocs.delete("AadharDocument");
            agentDocs.append("AadharDocument", "");
          }
          if (typeof agentDocs.get("PanDocument") === "string") {
            agentDocs.delete("PanDocument");
            agentDocs.append("PanDocument", "");
          }
          if (typeof agentDocs.get("GSTDocument") === "string") {
            agentDocs.delete("GSTDocument");
            agentDocs.append("GSTDocument", "");
          }
          if (typeof agentDocs.get("Logo") === "string") {
            agentDocs.delete("Logo");
            agentDocs.append("Logo", "");
          }
          dispatch(updateAgentDocument(agentDocs)).then((res) => {
            if (res.payload && res.payload.Status === 200) {
              history(`/agents`);
            }
          });
        }
      });
    } else {
      delete obj?.ID;
      obj.OperationType = 1;
      dispatch(insertAgent(obj)).then((res) => {
        if (
          res.payload &&
          res.payload.Status === 200 &&
          res.payload.Data &&
          res.payload.Data.ID
        ) {
          if (typeof agentDocs.get("AadharDocument") === "string") {
            agentDocs.delete("AadharDocument");
            agentDocs.append("AadharDocument", "");
          }
          if (typeof agentDocs.get("PanDocument") === "string") {
            agentDocs.delete("PanDocument");
            agentDocs.append("PanDocument", "");
          }
          if (typeof agentDocs.get("GSTDocument") === "string") {
            agentDocs.delete("GSTDocument");
            agentDocs.append("GSTDocument", "");
          }
          if (typeof agentDocs.get("Logo") === "string") {
            agentDocs.delete("Logo");
            agentDocs.append("Logo", "");
          }
          agentDocs.append("ID", res.payload.Data.ID);
          dispatch(insertAgentDocument(agentDocs)).then((res) => {
            if (res.payload && res.payload.Status === 200) {
              history(`/agents`);
            }
          });
        }
      });
    }
  };

  if (CountryLoading) {
    return <Loader />;
  }
  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <BreadCrumb title="Agent" pageTitle="Agent" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">
                      {ID ? "Update Agent" : " Create Agent"}
                    </h4>
                  </Col>
                  <Col md="1">
                    <Link to="/agents">
                      <Button color="primary" block>
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={form}
                      validationSchema={Yup.object({
                        SubAgentName: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        CompanyName: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        CompanyAddress: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        ContactNo: Yup.string()
                          .typeError(INVALID_PHONE)
                          .matches(phoneRegex, INVALID)
                          .nullable(true)
                          .required(REQUIRED),
                        EmailID: Yup.string()
                          .email(INVALID_EMAIL)
                          .nullable(true)
                          .required(REQUIRED),
                        Password:
                          !ID &&
                          Yup.string()
                            .min(8, "Password must be at least 8 characters")
                            .matches(
                              RegExp("(.*[a-z].*)"),
                              "At least lowercase letter"
                            )
                            .matches(
                              RegExp("(.*[A-Z].*)"),
                              "At least uppercase letter"
                            )
                            .matches(
                              RegExp("(.*[0-9].*)"),
                              "At least one number"
                            )
                            .required(REQUIRED),
                        CPassword:
                          !ID &&
                          Yup.string()
                            .when("Password", {
                              is: (val) =>
                                val && val.length > 0 ? true : false,
                              then: Yup.string().oneOf(
                                [Yup.ref("Password")],
                                "Both password need to be the same"
                              ),
                            })
                            .required(REQUIRED),
                        CityID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                        StateID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                        CountryID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                        UniversalMarkupTypeID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                        // PinCode: Yup.string().nullable(true).required(REQUIRED),
                        // GSTNo: Yup.string().nullable(true).required(REQUIRED),
                        // PanNo: Yup.string().nullable(true).required(REQUIRED),
                        // AadharNo: Yup.string()
                        //   .nullable(true)
                        //   .required(REQUIRED),
                        UniversalMarkup: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        // ContactDetails: Yup.array().of(
                        //   Yup.object({
                        //     Name: Yup.string()
                        //       .nullable(true)
                        //       .required(REQUIRED),
                        //     MobileNo: Yup.string()
                        //       .typeError(INVALID_PHONE)
                        //       .matches(phoneRegex, INVALID)
                        //       .nullable(true)
                        //       .required(REQUIRED),
                        //     EmailID: Yup.string()
                        //       .nullable(true)
                        //       .required(REQUIRED),
                        //     Designation: Yup.string()
                        //       .nullable(true)
                        //       .required(REQUIRED),
                        //   })
                        // ),
                        // BankDetails: Yup.array().of(
                        //   Yup.object({
                        //     BankName: Yup.string()
                        //       .nullable(true)
                        //       .required(REQUIRED),
                        //     BranchName: Yup.string()
                        //       .nullable(true)
                        //       .required(REQUIRED),
                        //     AccountNo: Yup.number()
                        //       .transform((value) =>
                        //         Number.isNaN(value) ? null : value
                        //       )
                        //       .nullable()
                        //       .required(REQUIRED),
                        //     IFSCCode: Yup.string()
                        //       .nullable(true)
                        //       .required(REQUIRED),
                        //   })
                        // ),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {() => {
                        return (
                          <Form noValidate autoComplete="off">
                            <div>
                              <Row>
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="SubAgentName"
                                    >
                                      Agent Name:
                                    </Label>
                                    <Field
                                      name="SubAgentName"
                                      className="form-control"
                                      id="SubAgentName"
                                      placeholder="Enter First Name"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="CompanyName"
                                    >
                                      Company Name:
                                    </Label>
                                    <Field
                                      name="CompanyName"
                                      className="form-control"
                                      id="CompanyName"
                                      placeholder="Enter First Name"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="ContactNo"
                                    >
                                      Contact No:
                                    </Label>
                                    <Field
                                      name="ContactNo"
                                      type="number"
                                      className="form-control"
                                      id="ContactNo"
                                      placeholder="Enter Contact No"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="EmailID"
                                    >
                                      Email ID:
                                    </Label>
                                    <Field
                                      name="EmailID"
                                      type="email"
                                      className="form-control"
                                      id="EmailID"
                                      placeholder="Enter Email"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>
                                {!ID && (
                                  <Col lg={4}>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="Password"
                                      >
                                        Password:
                                      </Label>
                                      <Field
                                        name="Password"
                                        type="password"
                                        className="form-control"
                                        id="Password"
                                        placeholder="Enter Password"
                                        component={TextField}
                                      />
                                    </div>
                                  </Col>
                                )}
                                {!ID && (
                                  <Col lg={4}>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="CPassword"
                                      >
                                        Confirm Password:
                                      </Label>
                                      <Field
                                        name="CPassword"
                                        type="password"
                                        className="form-control"
                                        id="CPassword"
                                        placeholder="Enter Confirm Password"
                                        component={TextField}
                                      />
                                    </div>
                                  </Col>
                                )}{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="CompanyAddress"
                                    >
                                      Company Address:
                                    </Label>
                                    <Field
                                      name="CompanyAddress"
                                      className="form-control"
                                      id="CompanyAddress"
                                      placeholder="Enter Company Address"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={!ID ? 2 : 4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="CountryID"
                                    >
                                      Country:
                                    </Label>
                                    <Field
                                      name="CountryID"
                                      id="CountryID"
                                      options={
                                        Array.isArray(countries) &&
                                        countries.map((country) => {
                                          return {
                                            value: Number(country.ID),
                                            label: country.CountryName,
                                          };
                                        })
                                      }
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={!ID ? 2 : 4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="StateID"
                                    >
                                      State:
                                    </Label>
                                    <Field
                                      name="StateID"
                                      id="StateID"
                                      options={
                                        Array.isArray(countryWiseState) &&
                                        countryWiseState.map((state) => {
                                          return {
                                            value: Number(state.ID),
                                            label: state.StateName,
                                          };
                                        })
                                      }
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="CityID"
                                    >
                                      City:
                                    </Label>
                                    <Field
                                      name="CityID"
                                      id="CityID"
                                      options={
                                        Array.isArray(stateWiseCities) &&
                                        stateWiseCities.map((city) => {
                                          return {
                                            value: Number(city.ID),
                                            label: city.CityName,
                                          };
                                        })
                                      }
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="PinCode"
                                    >
                                      PinCode:
                                    </Label>
                                    <Field
                                      name="PinCode"
                                      className="form-control"
                                      id="PinCode"
                                      placeholder="Enter PinCode"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Row>
                                      <Label
                                        className="form-label"
                                        htmlFor="UniversalMarkup"
                                      >
                                        Universal Markup:
                                      </Label>
                                      <Col lg={8}>
                                        <Field
                                          name="UniversalMarkup"
                                          className="form-control"
                                          id="UniversalMarkup"
                                          placeholder="Enter Universal Markup"
                                          component={TextField}
                                        />
                                      </Col>{" "}
                                      <Col lg={4}>
                                        <Field
                                          name="UniversalMarkupTypeID"
                                          id="UniversalMarkup"
                                          options={[
                                            {
                                              label: "%",
                                              value: 1,
                                            },
                                            {
                                              label: "Flat",
                                              value: 2,
                                            },
                                          ]}
                                          component={SelectionField}
                                        />
                                      </Col>{" "}
                                    </Row>
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="GSTNo"
                                    >
                                      GST No:
                                    </Label>
                                    <Field
                                      name="GSTNo"
                                      className="form-control"
                                      id="GSTNo"
                                      placeholder="Enter GST No"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="PanNo"
                                    >
                                      Pan No:
                                    </Label>
                                    <Field
                                      name="PanNo"
                                      className="form-control"
                                      id="PanNo"
                                      placeholder="Enter Pan No"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="AadharNo"
                                    >
                                      Aadhar No:
                                    </Label>
                                    <Field
                                      name="AadharNo"
                                      className="form-control"
                                      id="AadharNo"
                                      placeholder="Enter Aadhar No"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="IsActive"
                                    >
                                      Status:
                                    </Label>
                                    <Field
                                      name="IsActive"
                                      id="IsActive"
                                      options={[
                                        {
                                          label: "Active",
                                          value: true,
                                        },
                                        {
                                          label: "Inactive",
                                          value: false,
                                        },
                                      ]}
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                {ID && <Col lg={3}></Col>}
                                <Col lg={3}>
                                  <Label className="form-label" htmlFor="Logo">
                                    Company Logo:
                                  </Label>
                                  <Field
                                    type="file"
                                    name="Logo"
                                    id="Logo"
                                    maxFiles={1}
                                    component={SingleFileField}
                                  />
                                </Col>{" "}
                                <Col lg={3}>
                                  <Label
                                    className="form-label"
                                    htmlFor="AadharDocument"
                                  >
                                    Aadhar Document:
                                  </Label>
                                  <Field
                                    type="file"
                                    name="AadharDocument"
                                    id="AadharDocument"
                                    maxFiles={1}
                                    component={SingleFileField}
                                  />
                                </Col>{" "}
                                <Col lg={3}>
                                  <Label
                                    className="form-label"
                                    htmlFor="GSTDocument"
                                  >
                                    GST Document:
                                  </Label>
                                  <Field
                                    type="file"
                                    name="GSTDocument"
                                    id="GSTDocument"
                                    maxFiles={1}
                                    component={SingleFileField}
                                  />
                                </Col>{" "}
                                <Col lg={3}>
                                  <Label
                                    className="form-label"
                                    htmlFor="PanDocument"
                                  >
                                    Pan Document:
                                  </Label>
                                  <Field
                                    type="file"
                                    name="PanDocument"
                                    id="PanDocument"
                                    maxFiles={1}
                                    component={SingleFileField}
                                  />
                                </Col>{" "}
                                <Col lg={12}>
                                  <ContactDetailsArray />
                                </Col>
                                <Col lg={12}>
                                  <BankDetailsArray />
                                </Col>
                              </Row>

                              <Row className="mt-2">
                                <Col lg={12}>
                                  <hr></hr>
                                </Col>
                                <Col md="12" className="text-end">
                                  <Button type="submit" className="success">
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                            <CountryChangeWatcher />
                            <StateChangeWatcher />
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const CountryChangeWatcher = () => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (values.CountryID) {
      dispatch(getStatesByCountryID(values.CountryID));
    }
  }, [values.CountryID]);
  return null;
};

const StateChangeWatcher = () => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (values.StateID) {
      dispatch(getCitiesByStateID(values.StateID));
    }
  }, [values.StateID]);
  return null;
};

export default CreateAgent;
