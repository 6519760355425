import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";
import {
  ActiveInActiveTourFacilitiesApi,
  deleteTourFacilitiesApi,
  findByIdTourFacilitiesApi,
  getAllActiveTourFacilitiesApi,
  getAllTourFacilitiesApi,
  insertTourFacilitieMasterApi,
  updateTourFacilitiesApi,
} from "./tourfacilities.services";

export const getAllActiveTourFacilities = createAsyncThunk(
  "ItineraryFacilities/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveTourFacilitiesApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getAllTourFacilities = createAsyncThunk(
  "ItineraryFacilities/FindAll",
  async () => {
    try {
      const response = await getAllTourFacilitiesApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const findByIdTourFacilities = createAsyncThunk(
  "ItineraryFacilities/FindByID",
  async (params) => {
    try {
      const response = await findByIdTourFacilitiesApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const insertTourFacilities = createAsyncThunk(
  "ItineraryFacilities/Insert",
  async (data) => {
    try {
      const response = await insertTourFacilitieMasterApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const updateTourFacilities = createAsyncThunk(
  "ItineraryFacilities/Update",
  async (data) => {
    try {
      const response = await updateTourFacilitiesApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const ActiveInActiveTourFacilities = createAsyncThunk(
  "ItineraryFacilities/Active-InActive",
  async (data) => {
    try {
      const response = await ActiveInActiveTourFacilitiesApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const deleteTourFacilities = createAsyncThunk(
  "ItineraryFacilities/Delete",
  async (data) => {
    try {
      const response = await deleteTourFacilitiesApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
