import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllLeadSourceApi = () => api.get(url.GET_ALL_LEAD_SOURCE);

export const getAllActiveLeadSourceApi = () =>
  api.get(url.GET_ALL_ACTIVE_LEAD_SOURCE);

export const findByIdLeadSourceApi = (params) =>
  api.get(url.FIND_BY_ID_LEAD_SOURCE, params);

export const insertLeadSourceApi = (data) =>
  api.create(url.INSERT_LEAD_SOURCE, data);

export const updateLeadSourceApi = (data) =>
  api.create(url.UPDATE_LEAD_SOURCE, data);

export const deleteLeadSourceApi = (data) =>
  api.create(url.DELETE_LEAD_SOURCE, data);

export const ActiveInActiveLeadSourceApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_LEAD_SOURCE, data);
