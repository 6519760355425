import { createSlice } from "@reduxjs/toolkit";

export const PageData = {
  page: 1,
  totalPage: 1,
};
export const initialState = PageData;
const PaginationReducer = createSlice({
  name: "Pagination",
  initialState,
  reducers: {
    nextAndPrevPage: (state, action) => {
      state.page = action?.payload || 1;
    },
    pageCount: (state, action) => {
      state.totalPage = action?.payload || 1;
    },
    resetPage: (state) => {
      state.page = PageData.page;
      state.totalPage = PageData.totalPage;
    },
  },
});
export const { nextAndPrevPage, pageCount, resetPage } =
  PaginationReducer.actions;
export default PaginationReducer.reducer;
