import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import {
  ActiveInActiveCountryApi,
  deleteFestivalsApi,
  findByIdFestivalsApi,
  getAllActiveFestivalsApi,
  getAllFestivalsApi,
  insertFestivalsApi,
  updateFestivalsApi,
} from "./festival.services";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";

export const getAllActiveFestivals = createAsyncThunk(
  "Festivals/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveFestivalsApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getAllFestivals = createAsyncThunk(
  "Festivals/FindAll",
  async () => {
    try {
      const response = await getAllFestivalsApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const findByIdFestivals = createAsyncThunk(
  "Festivals/FindByID",
  async (params) => {
    try {
      const response = await findByIdFestivalsApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const insertFestivals = createAsyncThunk(
  "Festivals/Insert",
  async (data) => {
    try {
      const response = await insertFestivalsApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const updateFestivals = createAsyncThunk(
  "Festivals/Update",
  async (data) => {
    try {
      const response = await updateFestivalsApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const ActiveInActiveFestivals = createAsyncThunk(
  "Festivals/Active-InActive",
  async (data) => {
    try {
      const response = await ActiveInActiveCountryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const deleteFestivals = createAsyncThunk(
  "Festivals/Delete",
  async (data) => {
    try {
      const response = await deleteFestivalsApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
