export const budgetListArray = [
    {
      Value: "Budget ≤ 10000",
      Budget: "Below 10,000",
    },
    {
      Value: "Budget BETWEEN 10000 AND 20000",
      Budget: "10,000 - 20,000",
    },
    {
      Value: "Budget BETWEEN 50000 AND 60000",
      Budget: "50000 - 60000",
    },
    {
      Value: "Budget BETWEEN 70000 AND 80000",
      Budget: "70000 - 80000",
    },
    {
      Value: "Budget BETWEEN 80000 AND 90000",
      Budget: "80000 - 90000",
    },
    {
      Value: "Budget > 100000",
      Budget: "More Than 1,00,000",
    },
  ];