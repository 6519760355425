import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { deleteQuotation, getQuotationAll } from "../../slices/thunks";
import {
  nextAndPrevPage,
  pageCount,
  resetPage,
} from "../../slices/pagination/reducer";
import Pagination from "../../Components/Common/Pagination";

import Flatpickr from "react-flatpickr";
import Loader from "../../Components/Common/Loader";
import DeleteModal from "../../Components/Common/DeleteModal";
import { useState } from "react";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import {
  PAGE_LIMIT,
  STANDARD_INPUT_DATE,
  STANDARD_OUTPUT_DATE,
} from "../../Components/constants/field";
import moment from "moment";
import { resetTabs } from "../../slices/quotation/reducer";
// PAGE_LIMIT
function QuotaitonMaster() {
  const { quotationList, loading } = useSelector((state) => state.Quotation);
  const { page, totalPage } = useSelector((state) => state.Pagination);
  const dispatch = useDispatch();

  //

  const [reload, setReload] = useState(false);
  //
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  //
  const [NoOfDays, setNoOfDays] = useState("");
  const [SectorName, setSectorName] = useState("");
  const [TourName, setTourName] = useState("");
  const [Status, setStatus] = useState("");
  const [DepartureDate, setDepartureDate] = useState("");
  //

  //
  useEffect(() => {
    let data = {
      NoOfDays: NoOfDays,
      SectorName: SectorName,
      TourName: TourName,
      Status: Status,
      DepartureDate: DepartureDate,
      PageNo: page,
      PageSize: PAGE_LIMIT,
    };

    dispatch(getQuotationAll(data)).then((res) => {
      const TotalPages = res?.payload?.TotalPages || 1;
      dispatch(pageCount(TotalPages || 1));
    });
    // return () => {
    //   dispatch(resetPage())
    //   dispatch(resetTabs())

    //   // return () => {
    //   //   // dispatch(resetTabs())
    //   // }
    // }
  }, [page, NoOfDays, SectorName, TourName, Status, DepartureDate, reload]);
  useEffect(() => {
    return () => {
      dispatch(resetPage());
      dispatch(resetTabs());
    };
  }, []);
  const handlePageClick = ({ selected }) => {
    let page = (Number(selected) || 0) + 1;
    dispatch(nextAndPrevPage(page));
  };
  // if (loading) return <Loader />
  const onClickDelete = (data) => {
    setDeleteModal(true);
    setDeleteData(data);
  };

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
      OperationType: 3,
    };
    dispatch(deleteQuotation(obj)).then((res) => {
      setReload(!reload);
      if (res.payload && res.payload.Status === 200) {
        setDeleteModal(false);
      }
    });
  };

  function DepartureDatePicker() {
    const { values, setFieldValue, submitForm } = useFormikContext();

    const handleDateChange = (ev) => {
      setFieldValue(
        "DepartureDate",
        Array.isArray(ev) && ev.length
          ? moment(ev[0]).format(STANDARD_INPUT_DATE)
          : ""
      );
    };

    return (
      <React.Fragment>
        <Field>
          {({ field }) => (
            <Flatpickr
              name="DepartureDate"
              id="DepartureDate"
              {...field}
              value={values.DepartureDate}
              placeholder="Select Date.."
              className="form-control form-control-sm"
              options={{
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "d-m-Y",
              }}
              onChange={(e) => {
                handleDateChange(e);
                submitForm();
              }}
            />
          )}
        </Field>
        <ErrorMessage
          name={"DepartureDate"}
          component="div"
          className="text-danger"
        />
      </React.Fragment>
    );
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Quotation Master" pageTitle="Quotations" />
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md={4}>
                    <h4 className="card-title mb-0">Quotation Master</h4>
                  </Col>
                  <Col md={8} className="text-end mb-2 bg-light">
                    <Link to="/quotations/create">
                      <Button color="primary">
                        <i className="las la-plus"></i>Add
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              {/* <hr></hr> */}

              <CardBody>
                <Formik
                  className="bg-body"
                  enableReinitialize={true}
                  initialValues={{
                    NoOfDays: NoOfDays,
                    SectorName: SectorName,
                    TourName: TourName,
                    Status: Status,
                    DepartureDate: DepartureDate,
                  }}
                  onSubmit={(values) => {
                    setNoOfDays(String(values?.NoOfDays) || "");
                    setSectorName(values?.SectorName || "");
                    setTourName(values?.TourName || "");
                    setStatus(values?.Status || "");

                    setDepartureDate(
                      values?.DepartureDate
                        ? moment(
                            values?.DepartureDate,
                            STANDARD_INPUT_DATE
                          ).format(STANDARD_OUTPUT_DATE) || ""
                        : ""
                    );
                  }}
                >
                  {({ handleChange, submitForm }) => (
                    <Form noValidate autoComplete="off">
                      <Row>
                        <Col md="3">
                          <div className="d-flex justify-content-between align-items-center">
                            <Label
                              htmlFor="NoOfDays"
                              className="mb-0 w-50 small "
                            >
                              No of days
                            </Label>
                            <Field
                              name="NoOfDays"
                              type="number"
                              onChange={(e) => {
                                handleChange(e);
                                submitForm();
                              }}
                              className="form-control  form-control-sm"
                              id="NoOfDays"
                              placeholder="No of Days"
                            />
                          </div>
                        </Col>{" "}
                        <Col md="3">
                          <div className="d-flex justify-content-between align-items-center">
                            <Label
                              htmlFor="TourName"
                              className="mb-0 w-50 small "
                            >
                              Tour Name
                            </Label>
                            <Field
                              name="TourName"
                              type="text"
                              onChange={(e) => {
                                handleChange(e);
                                submitForm();
                              }}
                              className="form-control  form-control-sm"
                              id="TourName"
                              placeholder="Tour Name"
                            />
                          </div>
                        </Col>{" "}
                        <Col md="3">
                          <div className="d-flex justify-content-between align-items-center">
                            <Label
                              htmlFor="SectorName"
                              className="mb-0 w-50 small "
                            >
                              Sector Name
                            </Label>
                            <Field
                              name="SectorName"
                              type="text"
                              onChange={(e) => {
                                handleChange(e);
                                submitForm();
                              }}
                              className="form-control  form-control-sm"
                              id="SectorName"
                              placeholder="Sector Name"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="d-flex justify-content-between align-items-center">
                            <Label
                              htmlFor="Status"
                              className="mb-0 w-50 small "
                            >
                              Status
                            </Label>
                            <Field
                              name="Status"
                              type="text"
                              onChange={(e) => {
                                handleChange(e);
                                submitForm();
                              }}
                              className="form-control  form-control-sm"
                              id="Status"
                              placeholder="Status"
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <Label
                              htmlFor="Status"
                              className="mb-0 w-50 small "
                            >
                              Departure Date
                            </Label>
                            <DepartureDatePicker />
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
                <hr></hr>
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <Table
                        className="align-middle table-nowrap mb-0"
                        striped
                        responsive
                        hover
                      >
                        <thead>
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Tour Name</th>
                            <th scope="col">Sector Name</th>
                            <th scope="col">Customer Name</th>
                            <th scope="col">Mobile No</th>
                            <th scope="col">Travel Agent</th>
                            <th scope="col">Dept Date</th>
                            <th scope="col">No of Pax</th>
                            <th scope="col">Days</th>
                            <th scope="col">Total Rooms</th>
                            <th scope="col">Final Total</th>
                            <th scope="col">Inquiry Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                          Array.isArray(quotationList) &&
                          quotationList.length ? (
                            quotationList.map((quotation, index) => (
                              <tr key={index}>
                                <th scope="row">{quotation?.ID || ""}</th>
                                <td>{quotation?.TourName || ""}</td>
                                <td>{quotation?.SectorName || ""}</td>
                                <td>{quotation?.Name || ""}</td>
                                <td>{quotation?.MobileNo || ""}</td>
                                <td>{quotation?.TravelAgent || ""}</td>
                                <td>{quotation?.DepartureDate || ""}</td>
                                <td>{quotation?.TotalPax || 0}</td>
                                <td>{quotation?.NoOfDays || 0}</td>
                                <td>{quotation?.TotalRooms || 0}</td>
                                <td>{quotation?.FinalTotal || 0}</td>
                                <td>{quotation?.StatusName || ""}</td>
                                <td>
                                  <div className="hstack gap-3 flex-wrap">
                                    <Link
                                      to={
                                        quotation?.IsAutoQuote
                                          ? `/auto-quotations/${quotation?.TourID}/generate-quotation/${quotation?.ID}`
                                          : `/quotations/update/${quotation.ID}?q=${quotation.ID}`
                                      }
                                      className="link-success fs-15"
                                    >
                                      <i className="ri-edit-line"></i>
                                    </Link>
                                    <div className="gap-3 " role="button">
                                      <i
                                        className="ri-delete-bin-fill text-danger fs-18"
                                        onClick={() => onClickDelete(quotation)}
                                      ></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : quotationList.length === 0 ? (
                            <tr>
                              <td colSpan={15} className="text-center">
                                {" "}
                                No quotation found
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={15} className="text-center">
                                {" "}
                                <Loader />
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      pageCount={totalPage}
                      handlePageClick={handlePageClick}
                      page={page}
                    />
                  </Col>
                  <DeleteModal
                    show={deleteModal}
                    onCloseClick={() => setDeleteModal(false)}
                    onDeleteClick={() => onDeleted()}
                  />
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default QuotaitonMaster;
