import _ from "lodash"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Table } from "reactstrap"
import { showModal } from "../../../slices/quotation/reducer"
import { useLocation } from "react-router-dom"
import DeleteModal from "../../../Components/Common/DeleteModal"
import {
  deleteAdditionalServices,
  getQuotationDetail,
} from "../../../slices/thunks"

const AdditionalServicePerPerson = () => {
  const dispatch = useDispatch()
  const {
    addtionalServicePerPerson: { list },
  } = useSelector((state) => state.Quotation)

  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const qID = urlParams.get("q")
  //

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  //

  const editAdditionalServicePerPerson = (data) => {
    let obj = {
      modal: true,
      modalType: "additionalServicePerPerson",
      modalData: {
        ID: data?.ID || "",
        QuoteID: data?.QuoteID || "",
      },
    }
    dispatch(showModal(obj))
  }

  const onClickDelete = (data) => {
    setDeleteModal(true)
    setDeleteData(data)
  }

  const onDeleted = () => {
    let detailObj = {
      ID: +deleteData?.ID || "",
      QuoteID: +deleteData?.QuoteID || "",
      OperationType: 19,
      IsActive: false,
    }
    dispatch(deleteAdditionalServices(detailObj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setDeleteModal(false)
        let quotationDetail = {
          ID: Number(qID),
        }

        dispatch(getQuotationDetail(quotationDetail))
      }
    })
  }
  return (
    <Col lg={12} className="mt-5">
      <hr></hr>
      <div className="table-responsive mt-2">
        <Table
          className="align-middle table-nowrap mb-0"
          striped
          responsive
          hover
        >
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Per Person Rate</th>
              <th scope="col">Total Person</th>
              <th scope="col">Total</th>
              <th scope="col">Margin</th>
              <th scope="col">18% on GST</th>
              <th scope="col">Total With GST</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(list) && list.length ? (
              list.map((aspp, i) => {
                return (
                  <tr key={i}>
                    <td scope="col">{aspp?.Title}</td>
                    <td scope="col">{aspp?.Rate}</td>
                    <td scope="col">{aspp?.NoOfPax}</td>
                    <td scope="col">{aspp?.Total}</td>
                    <td scope="col">{aspp?.Margin}</td>
                    <td scope="col">{aspp?.GSTAmount}</td>
                    <td scope="col">{aspp?.GrandTotal}</td>
                    <td scope="col">
                      {" "}
                      <div className="hstack gap-3 flex-wrap " role="button">
                        <i
                          className="ri-edit-line text-success fs-18"
                          //
                          onClick={() => editAdditionalServicePerPerson(aspp)}
                        ></i>
                        <div className="gap-3 " role="button">
                          <i
                            className="ri-delete-bin-fill text-danger fs-18"
                            onClick={() => onClickDelete(aspp)}
                          ></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })
            ) : list.length === 0 ? (
              <tr>
                <td>No Additional Service Per Person found</td>
              </tr>
            ) : null}
          </tbody>
          <tfoot>
            <tr>
              <td className="text-end" colSpan={3}>
                Total
              </td>
              <td>{_.round(_.sumBy(list, "Total"))}</td>
              <td>{_.round(_.sumBy(list, "Margin"))}</td>
              <td>{_.round(_.sumBy(list, "GSTAmount"))}</td>
              <td colSpan={2}>{_.round(_.sumBy(list, "GrandTotal"))}</td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <DeleteModal
        show={deleteModal}
        onCloseClick={() => setDeleteModal(false)}
        onDeleteClick={() => onDeleted()}
      />
    </Col>
  )
}

export default AdditionalServicePerPerson
