import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Formik, Form, useFormikContext, ErrorMessage } from "formik";
import {
  INVALID,
  INVALID_EMAIL,
  INVALID_PHONE,
  POSITIVE,
  REQUIRED,
  STANDARD_INPUT_DATE,
  STANDARD_OUTPUT_DATE,
  SelectionField,
  TextField,
  phoneRegex,
} from "../../Components/constants/field";
import Flatpickr from "react-flatpickr";
import { getAllActiveSectors } from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import * as Yup from "yup";
import moment from "moment";
import ContactDetailsArray from "./ContactDetailsArray";
import { getAllActiveLeadSource } from "../../slices/leadsource/thunk";
import {
  findByIdInquiry,
  insertInquiry,
  updateInquiry,
} from "../../slices/inquiry/thunk";

const InquiryCreate = () => {
  const history = useNavigate();
  const { ID } = useParams();
  const dispatch = useDispatch();
  const { sectors, loading: SectorLoading } = useSelector((state) => state.Sector);
  const {
    inquiryDetail: { form },
  } = useSelector((state) => state.Inquiry);
  const { leadSources, loading: LeadSourceLoading } = useSelector(
    (state) => state.LeadSource
  );

  useEffect(() => {
    dispatch(getAllActiveSectors());
    dispatch(getAllActiveLeadSource());
  }, []);

  useEffect(() => {
    if (ID) {
      let params = {
        ID: ID,
      };
      dispatch(findByIdInquiry(params));
    }
  }, [ID]);

  const handleSubmit = (values) => {
    let obj = {
      ...values,
    };

    obj.Budget = obj.Budget ? obj.Budget.toString() : "";
    obj.InquirySourceID = obj.InquirySourceID ? +obj.InquirySourceID : null;
    obj.SectorID = obj.SectorID ? +obj.SectorID : null;

    obj.ContactDetails = obj.ContactDetails
      ? obj.ContactDetails.map((contact) => {
          const { ID: _, ...newContact } = contact; // eslint-disable-line no-unused-vars
          return { ...newContact, ContactNo: contact.ContactNo.toString() };
        })
      : [];

    obj.TravelDate = obj?.TravelDate
      ? moment(obj.TravelDate, STANDARD_INPUT_DATE).format(STANDARD_OUTPUT_DATE)
      : "";

    if (obj?.ID) {
      dispatch(updateInquiry(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/inquiry`);
        }
      });
    } else {
      delete obj?.ID;
      dispatch(insertInquiry(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/inquiry`);
        }
      });
    }
  };

  if (SectorLoading || LeadSourceLoading) {
    return <Loader />;
  }
  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <BreadCrumb title="Inquiry" pageTitle="Inquiry" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">
                      {ID ? "Update Inquiry" : " Create Inquiry"}
                    </h4>
                  </Col>
                  <Col md="1">
                    <Link to="/inquiry">
                      <Button color="primary" block>
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={form}
                      validationSchema={Yup.object({
                        Name: Yup.string().nullable(true).required(REQUIRED),
                        InquirySourceID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                        SectorID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                        TravelDate: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        NoOfNights: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable(true)
                          .positive(POSITIVE)
                          .required(REQUIRED),
                        NoOfPax: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable(true)
                          .positive(POSITIVE)
                          .required(REQUIRED),
                        PriorityID: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        ContactDetails: Yup.array().of(
                          Yup.object({
                            ContactNo: Yup.string()
                              .typeError(INVALID_PHONE)
                              .matches(phoneRegex, INVALID)
                              .nullable(true)
                              .required(REQUIRED),
                            EmailID: Yup.string()
                              .email(INVALID_EMAIL)
                              .nullable(true)
                              .required(REQUIRED),
                          })
                        ),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {() => {
                        return (
                          <Form noValidate autoComplete="off">
                            <div>
                              <Row>
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="Name"
                                    >
                                      Name:
                                    </Label>
                                    <Field
                                      name="Name"
                                      className="form-control"
                                      id="Name"
                                      placeholder="Enter Name"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="SectorID"
                                    >
                                      Destination:
                                    </Label>
                                    <Field
                                      name="SectorID"
                                      id="SectorID"
                                      options={
                                        Array.isArray(sectors) &&
                                        sectors.map((sector) => {
                                          return {
                                            value: Number(sector.ID),
                                            label: sector.SectorName,
                                          };
                                        })
                                      }
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="TravelDate"
                                    >
                                      Travel Date:
                                    </Label>
                                    <Date />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="NoOfNights"
                                    >
                                      No Of Nights:
                                    </Label>
                                    <Field
                                      name="NoOfNights"
                                      type="number"
                                      className="form-control"
                                      id="NoOfNights"
                                      placeholder="Enter No Of Nights"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="NoOfPax"
                                    >
                                      No Of Pax:
                                    </Label>
                                    <Field
                                      name="NoOfPax"
                                      type="number"
                                      className="form-control"
                                      id="NoOfPax"
                                      placeholder="Enter No Of Pax"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="Budget"
                                    >
                                      Budget:
                                    </Label>
                                    <Field
                                      name="Budget"
                                      type="number"
                                      className="form-control"
                                      id="Budget"
                                      placeholder="Enter Budget"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="PriorityID"
                                    >
                                      Priority:
                                    </Label>
                                    <Field
                                      name="PriorityID"
                                      id="PriorityID"
                                      options={[
                                        {
                                          label: "Hot",
                                          value: "Hot",
                                        },
                                        {
                                          label: "Warm",
                                          value: "Warm",
                                        },
                                        {
                                          label: "Cold",
                                          value: "Cold",
                                        },
                                      ]}
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="InquirySourceID"
                                    >
                                      Lead Source:
                                    </Label>
                                    <Field
                                      name="InquirySourceID"
                                      id="InquirySourceID"
                                      options={
                                        Array.isArray(leadSources) &&
                                        leadSources.map((leadsource) => {
                                          return {
                                            value: Number(leadsource.ID),
                                            label: leadsource.Source,
                                          };
                                        })
                                      }
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="Reference"
                                    >
                                      Reference:
                                    </Label>
                                    <Field
                                      name="Reference"
                                      className="form-control"
                                      id="Reference"
                                      placeholder="Enter Reference"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={12}>
                                  <ContactDetailsArray />
                                </Col>
                              </Row>

                              <Row className="mt-2">
                                <Col lg={12}>
                                  <hr></hr>
                                </Col>
                                <Col md="12" className="text-end">
                                  <Button type="submit" className="success">
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Date = () => {
  const { values, setFieldValue, errors } = useFormikContext();
  const handleDateChange = (ev) => {
    setFieldValue("TravelDate", moment(ev[0]).format(STANDARD_INPUT_DATE));
  };

  return (
    <React.Fragment>
      <Field>
        {({ field }) => (
          <div>
            <Flatpickr
              name="TravelDate"
              id="Rate-from-date"
              {...field}
              value={values.TravelDate}
              placeholder="Select Date.."
              className="form-control"
              options={{
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "d-m-Y",
              }}
              onChange={handleDateChange}
            />
            {errors.TravelDate && (
              <div className="text-danger">{errors.TravelDate}</div>
            )}
          </div>
        )}
      </Field>
    </React.Fragment>
  );
};

export default InquiryCreate;
