import { APIClient } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
import axios from "axios"
import { api } from "../../config"

const client = new APIClient()

export const getAllToursApi = () => client.get(url.GET_ALL_ACTIVE_TOURS)

export const getAllToursBySectorIDApi = (SectorID) =>
  client.create(url.GET_TOUR_BY_SECTOR_ID, { SectorID })

export const insertTourApi = (tour) =>
  axios.post(api.API_URL + url.INSERT_TOUR, tour, {
    headers: { "content-type": "multipart/form-data" },
  })

export const getTourByIDApi = (ID) => client.create(url.GET_TOUR_BY_ID, { ID })

export const updateTourApi = (tour) =>
  axios.post(api.API_URL + url.UPDATE_TOUR, tour, {
    headers: { "content-type": "multipart/form-data" },
  })
export const insertTourItinerariesApi = (tourItineraries) =>
  client.create(url.INSERT_TOUR_ITENERARIES, tourItineraries)

export const updateTourItinerariesApi = (tourItineraries) =>
  client.create(url.UPDATE_TOUR_ITENERARIES, tourItineraries)

export const deleteTourItinerariesApi = (ID) =>
  client.create(url.DELETE_TOUR_ITENERARIES, ID)

export const getItinerariesByTourIDApi = (TourID) =>
  client.create(url.GET_TOUR_ITENERARIES_BY_TOUR_ID, { TourID })

export const getTourFiltersApi = () => client.get(url.GET_TOUR_FILTERS)

export const getToursByFilterApi = (filters) =>
  client.create(url.GET_TOUR_BY_FILTER, filters)

export const tourActiveAndInActiveApi = (data) =>
  client.create(url.TOUR_ACTIVE_INACTIVE, data)
export const tourDeleteApi = (data) => client.create(url.DELETE_TOUR_URL, data)
