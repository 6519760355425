import React, { useEffect } from "react"

//import Scss
import "./assets/scss/themes.scss"

//imoprt Route
import Route from "./Routes"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend
import fakeBackend from "./helpers/AuthType/fakeBackend"
import { v4 as uuidv4 } from "uuid"

// Activating fake backend
fakeBackend()

function App() {
  useEffect(() => {
    sessionStorage.setItem("SessionID", uuidv4())
  })
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  )
}

export default App
