import { createAsyncThunk } from "@reduxjs/toolkit"
import "react-toastify/dist/ReactToastify.css"
import { FAILED, SUCCESS } from "../../Components/constants/field"
import { AUTO_CLOSE } from "../../Components/constants/toast"
import { toast } from "react-toastify"
import {
  getAllActiveHotelsApi,
  addRoomCategoriesApi,
  getRoomCategoriesByHotelIDOrSessionIDApi,
  deleteRoomCategoryApi,
  updateRoomCategoriesApi,
  insertHotelApi,
  getHotelByIDApi,
  updateHotelApi,
  getHotelByRoomCategoryApi,
  createHotelContactApi,
  insertHotelRatesApi,
  deleteHotelRatesApi,
  hotelRoomcatgeoryByHotelIDApi,
  getAllHotelContactApi,
  getAllHotelApi,
  getHotelContactByIdApi,
  getRoomCategoryByIdApi,
  ActiveInActiveHotelStatusApi,
  deleteHotelApi,
  getHotelRatesByIDApi,
  getHotelCityWiseAndRoomCategoryApi,
  getHotelCityWiseTourApi,
} from "./hotel.services"

// Hotel
export const getAllActiveHotels = createAsyncThunk(
  "Hotels/FindAllActive",
  async () => {
    try {
      const response = getAllActiveHotelsApi()
      // toast.success(SUCCESS, { autoClose: AUTO_CLOSE })
      return response
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE })
      return error
    }
  }
)

export const getAllHotelList = createAsyncThunk(
  "Hotel/FindAll",
  async (hotelList) => {
    try {
      const response = getAllHotelApi(hotelList)
      return response
    } catch (er) {
      // toast.error(FAILED, { autoClose: AUTO_CLOSE })
      return er
    }
  }
)
export const insertHotel = createAsyncThunk(
  "Hotels/Insert",
  async (hotelDetails) => {
    try {
      const response = insertHotelApi(hotelDetails)
      return response
    } catch (error) {
      return error
    }
  }
)

export const updateHotel = createAsyncThunk(
  "Hotels/Update",
  async (hotelDetails) => {
    try {
      const response = updateHotelApi(hotelDetails)
      return response
    } catch (error) {
      return error
    }
  }
)

export const getHotelByID = createAsyncThunk("Hotels/FindByID", async (ID) => {
  try {
    const response = getHotelByIDApi(ID)
    return response
  } catch (error) {
    return error
  }
})
export const deleteHotel = createAsyncThunk(
  "Hotels/Delete",
  async (hotelData) => {
    try {
      const response = deleteHotelApi(hotelData)
      return response
    } catch (error) {
      return error
    }
  }
)

// Room Category
export const addRoomCategories = createAsyncThunk(
  "Hotels/InsertHotelRoomCategory",
  async (roomDetails) => {
    try {
      const response = addRoomCategoriesApi(roomDetails)

      return response
    } catch (error) {
      return error
    }
  }
)

export const getRoomCategoriesByHotelIDOrSessionID = createAsyncThunk(
  "Hotels/GetRoomCategory",
  async (hotelDetails) => {
    try {
      const response = getRoomCategoriesByHotelIDOrSessionIDApi(hotelDetails)
      return response
    } catch (error) {
      return error
    }
  }
)

export const updateRoomCategories = createAsyncThunk(
  "Hotels/UpdateHotelRoomCategory",
  async (roomDetails) => {
    try {
      const response = updateRoomCategoriesApi(roomDetails)
      return response
    } catch (error) {
      return error
    }
  }
)

export const deleteRoomCategory = createAsyncThunk(
  "Hotels/DeleteHotelRoomCategory",
  async (ID) => {
    try {
      const response = deleteRoomCategoryApi(ID)
      return response
    } catch (error) {
      return error
    }
  }
)
export const getRoomCategoryById = createAsyncThunk(
  "Hotels/GetRoomCategoryById",
  async (ID) => {
    try {
      const response = getRoomCategoryByIdApi(ID)
      return response
    } catch (error) {
      return error
    }
  }
)

export const getHotelByRoomCategory = createAsyncThunk(
  "Hotels/GetHotelsbyHotelCategory",
  async (hotelDetails) => {
    try {
      const response = getHotelByRoomCategoryApi(hotelDetails)
      return response
    } catch (error) {
      return error
    }
  }
)

export const getHotelRoomcatgeoryByHotelID = createAsyncThunk(
  "/Hotels/HotelRoomcatgeoryByHotelID",
  async (hotelID) => {
    try {
      const response = hotelRoomcatgeoryByHotelIDApi(hotelID)
      return response
    } catch (error) {
      return error
    }
  }
)

// Hotel Rates
export const insertHotelRates = createAsyncThunk(
  "Hotels/InsertHotelRates",
  async (rateDetails) => {
    try {
      const response = insertHotelRatesApi(rateDetails)
      return response
    } catch (error) {
      return error
    }
  }
)

export const deleteHotelRates = createAsyncThunk(
  "Hotels/DeleteHotelRates",
  async (rateDetails) => {
    try {
      const response = deleteHotelRatesApi(rateDetails)
      return response
    } catch (error) {
      return error
    }
  }
)

export const getHotelRatesByID = createAsyncThunk(
  "Hotels/HotelRatesFindByID",
  async (ID) => {
    try {
      const response = getHotelRatesByIDApi(ID)
      return response
    } catch (error) {
      return error
    }
  }
)

// HOtel Contact

export const createHotelContact = createAsyncThunk(
  "Hotel/Contact/Insert",
  async (hotelContact) => {
    try {
      const response = createHotelContactApi(hotelContact)

      return response
    } catch (err) {
      return err
    }
  }
)

export const getAllHotelContact = createAsyncThunk(
  "Hotel/Contact/GetAll",
  async (HotelID) => {
    try {
      const res = getAllHotelContactApi(HotelID)

      return res
    } catch (err) {
      return err
    }
  }
)

export const getHotelContactById = createAsyncThunk(
  "Hotel/Contact/GetById",
  async (contentById) => {
    try {
      const res = getHotelContactByIdApi(contentById)

      return res
    } catch (err) {
      return err
    }
  }
)
export const ActiveInActiveHotelStatus = createAsyncThunk(
  "Hotel/Contact/ActiveInActive",
  async (contact) => {
    try {
      const res = ActiveInActiveHotelStatusApi(contact)

      return res
    } catch (err) {
      return err
    }
  }
)

export const getHotelCityWiseAndRoomCategory = createAsyncThunk(
  "Hotel/getHotelCityWiseAndRoomCategory",
  async (payload) => {
    try {
      const res = getHotelCityWiseAndRoomCategoryApi(payload)
      return res
    } catch (err) {
      return err
    }
  }
)
export const getHotelCityWiseTour = createAsyncThunk(
  "Hotel/getHotelListByCity/for/tour",
  async (payload) => {
    try {
      const res = getHotelCityWiseTourApi(payload)
      return res
    } catch (err) {
      return err
    }
  }
)
