import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllInquiryByFilterApi = (data) =>
  api.create(url.GET_ALL_INQUIRY_BY_FILTER, data);

export const getAllPendingInquiryByFilterApi = (data) =>
  api.create(url.GET_ALL_PENDING_INQUIRY_BY_FILTER, data);

export const findByIdInquiryApi = (params) =>
  api.get(url.FIND_BY_ID_INQUIRY_URL, params);

export const insertInquiryApi = (data) =>
  api.create(url.INSERT_INQUIRY_URL, data);

export const updateInquiryApi = (data) =>
  api.create(url.UPDATE_INQUIRY_URL, data);

export const deleteInquiryApi = (data) =>
  api.create(url.DELETE_INQUIRY_URL, data);

export const AssignedUserApi = (data) =>
  api.create(url.ASSIGN_USER_URL, data);
