import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllCitiesApi = () => api.get(url.GET_ALL_ACTIVE_CITIES);
export const getAllACINCitiesApi = () => api.get(url.GET_ALL_ACIN_ACTIVE_CITIES);

export const getCitiesBySectorIDApi = (SectorID) =>
  // api.get(url.GET_CITIES_BY_SECTOR_ID, { ID: SectorID });
  api.create(url.GET_ALL_CITIES_BY_SECTOR_ID, { SectorID: SectorID })
export const getCityByTourIDApi = (tourData) =>
  api.create(url.GET_CITIES_BY_TOUR_ID, tourData);
export const getCitiesByStateIDApi = (StateID) =>
  api.get(`${url.GET_ALL_ACTIVE_CITIES}?StateID=${StateID}`);
  
export const findByIdCityApi = (params) =>
  api.get(url.FIND_BY_ID_CITY_URL, params);

export const insertCityApi = (data) => api.create(url.INSERT_CITY_URL, data);

export const updateCityApi = (data) => api.create(url.UPDATE_CITY_URL, data);

export const ActiveInActiveCityApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_CITY_URL, data);

export const deleteCityApi = (data) => api.create(url.DELETE_CITY_URL, data);
