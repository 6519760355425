import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash"
import { getAllActiveVehicleType } from "../thunks";
import { findByIdVehicleType, getAllVehicleType, insertVehicleType, updateVehicleType } from "./thunk";

export const vehicleTypeForm={
    ID:null,
    VehicleType:"",
    IsActive: true,
}

export const initialState = {
    vehicleTypeDetail:{
        form:vehicleTypeForm
    },
    vehicleTypes: [],
    error: {},
    loading: false,
};

const VehicleTypeReducer = createSlice({
    name: "VehicleType",
    initialState,
    reducers: {
        resetForm: (state) => {
            state.vehicleTypeDetail.form = vehicleTypeForm;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllActiveVehicleType.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllActiveVehicleType.fulfilled, (state, action) => {
            state.vehicleTypes = action.payload.Data;
            state.loading = false;
        });
        builder.addCase(getAllActiveVehicleType.rejected, (state, action) => {
            state.error = action.payload?.error || null;
            state.loading = false;
        });
        builder.addCase(getAllVehicleType.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(getAllVehicleType.fulfilled,(state,action)=>{
            state.vehicleTypes=action.payload.Data || []
            state.loading=false
        });
        builder.addCase(getAllVehicleType.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(insertVehicleType.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(insertVehicleType.fulfilled,(state)=>{
            state.vehicleTypeDetail.form=vehicleTypeForm
            state.loading=false
        });
        builder.addCase(insertVehicleType.rejected, (state,action) => {
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(updateVehicleType.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(updateVehicleType.fulfilled,(state)=>{
            state.vehicleTypeDetail.form=vehicleTypeForm
            state.loading=false
        });
        builder.addCase(updateVehicleType.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
       builder.addCase(findByIdVehicleType.pending,(state)=>{
        state.loading=true
       });
       builder.addCase(findByIdVehicleType.fulfilled,(state,action)=>{
        let formObj = action?.payload?.Data

        let formKeys=Object.keys(vehicleTypeForm)
        formObj = _.pick(formObj,formKeys)
        state.vehicleTypeDetail.form=formObj
        state.loading=false
       });
       builder.addCase(findByIdVehicleType.rejected,(state,action)=>{
        state.error=action.payload?.error || null
        state.loading=false
       })
    },
});

export const {resetForm}=VehicleTypeReducer.actions

export default VehicleTypeReducer.reducer;
