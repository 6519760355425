import { APIClient } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

const api = new APIClient()

// Hotel Service
export const getAllActiveHotelsApi = () => api.get(url.GET_ALL_ACTIVE_HOTELS)

export const getAllHotelApi = (hotelList) =>
  api.create(url.GET_ALL_HOTEL_LIST, hotelList)

export const insertHotelApi = (hotelDetails) =>
  api.create(url.INSERT_HOTEL, hotelDetails)

export const getHotelByIDApi = (ID) => api.create(url.GET_HOTEL_BY_ID, { ID })

export const updateHotelApi = (hotelDetails) =>
  api.create(url.UPDATE_HOTEL, hotelDetails)
export const deleteHotelApi = (hotelData) =>
  api.create(url.DELETE_HOTEL, hotelData)
// Room Category
export const addRoomCategoriesApi = (roomDetails) =>
  api.create(url.ADD_ROOM_CATEGORIES, roomDetails)

export const getRoomCategoriesByHotelIDOrSessionIDApi = (hotelDetails) =>
  api.create(url.GET_ROOM_CATEGORY_BY_HOTEL, hotelDetails)

export const updateRoomCategoriesApi = (roomDetails) =>
  api.create(url.UPDATE_HOTEL_RROM_CATEGORY, roomDetails)

export const deleteRoomCategoryApi = (ID) =>
  api.create(url.DELETE_HOTEL_RROM_CATEGORY, { ID })

export const getHotelByRoomCategoryApi = (hotelDetails) =>
  api.create(url.GET_HOTEL_BY_ROOM_CATEGORY, hotelDetails)
export const getRoomCategoryByIdApi = (ID) =>
  api.create(url.GET_HOTEL_ROOM_CATEGORY_BY_ID, ID)
// Hotel Contact
export const createHotelContactApi = (hotelContact) =>
  api.create(url.INSERT_HOTEL_CONTACT, hotelContact)

export const getAllHotelContactApi = (HotelID) =>
  api.create(url.GET_ALL_HOTEL_CONTACT, HotelID)

export const getHotelContactByIdApi = (contactById) =>
  api.create(url.GET_HOTEL_CONTACT_BY_ID, contactById)

export const ActiveInActiveHotelStatusApi = (contact) =>
  api.create(url.ACTIVE_INACTIVE_HOTEL_CONTACT, contact)
//

export const insertHotelRatesApi = (rateDetails) =>
  api.create(url.INSERT_HOTEL_RATES, rateDetails)

export const deleteHotelRatesApi = (rateDetails) =>
  api.create(url.DELETE_HOTEL_RATES, rateDetails)

export const hotelRoomcatgeoryByHotelIDApi = (hotelID) =>
  api.create(url.GET_HOTEL_ROOM_CATEGORY_BY_HOTEL_ID, hotelID)

export const getHotelRatesByIDApi = (ID) =>
  api.create(url.GET_HOTEL_RATES_BY_ID, ID)

export const getHotelCityWiseAndRoomCategoryApi = (payload) =>
  api.create(url.GET_HOTEL_CITY_WISE_URL, payload)
export const getHotelCityWiseTourApi = (payload) =>
  api.create(url.GET_HOTEL_CITY_WISE_URL_FOR_TOUR, payload)
// GET_HOTEL_CITY_WISE_URL_FOR_TOUR
