import { createSlice } from "@reduxjs/toolkit";
import {
  getAllUser,
  insertUser,
  findByIdUser,
  updateUser,
  getAllActiveUser,
} from "./thunk";

export const UserForm = {
  ID: undefined,
  IsActive: true,
  UserTypeID: null,
  FirstName: "",
  LastName: "",
  MobileNo: "",
  AlternateMobileNo: "",
  EmailID: "",
  AlternateEmailID: "",
  Password: "",
  CPassword: "",
  BranchID: null,
  SectorID: [],
  AadharCard: "",
  PanCard: "",
  License: "",
};

export const initialState = {
  userDetail: {
    form: UserForm,
  },
  users: [],
  activeUsers: [],
  error: {},
  loading: false,
};

const UserReducer = createSlice({
  name: "User",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.userDetail.form = UserForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllUser.fulfilled, (state, action) => {
      state.users = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllUser.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    builder.addCase(getAllActiveUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllActiveUser.fulfilled, (state, action) => {
      state.activeUsers = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllActiveUser.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    // Insert User
    builder.addCase(insertUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(insertUser.fulfilled, (state) => {
      state.userDetail.form = UserForm;
      state.loading = false;
    });
    builder.addCase(insertUser.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    // Update User
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state) => {
      state.userDetail.form = UserForm;
      state.loading = false;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    // Get By Id User
    builder.addCase(findByIdUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findByIdUser.fulfilled, (state, action) => {
      if (action.payload && action.payload.Data) {
        state.userDetail.form = {
          ...action.payload.Data,
          SectorID:
            action.payload.Data.SectorID &&
            action.payload.Data.SectorID.length > 0
              ? action.payload.Data.SectorID.map((sector) => sector.SectorID)
              : [],
        };
      }
    });
    builder.addCase(findByIdUser.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
  },
});

export const { resetForm } = UserReducer.actions;

export default UserReducer.reducer;
