import { createSlice } from "@reduxjs/toolkit";
import { findByIdState, getAllActiveStates, getAllState, getStatesByCountryID, insertState, updateState } from "./thunk";
import _ from "lodash"
export const stateForm={
    ID: null,
    CountryID: null,
    StateName:"",
    StateCode:"",
    IsActive: true,
}

export const initialState = {
   stateDetail:{
    form:stateForm,
   },
    states: [],
    countryWiseState: [],
    error: {},
    loading: false,
};

const StateReducer = createSlice({
    name: "State",
    initialState,
    reducers: {
        resetForm: (state) => {
            state.stateDetail.form = stateForm;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllActiveStates.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllActiveStates.fulfilled, (state, action) => {
            state.states = action.payload.Data;
            state.loading = false;
        });
        builder.addCase(getAllActiveStates.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.loading = false;
        });

        builder.addCase(getStatesByCountryID.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getStatesByCountryID.fulfilled, (state, action) => {
            state.countryWiseState = action.payload.Data;
            state.loading = false;
        });
        builder.addCase(getStatesByCountryID.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.loading = false;
        });
        builder.addCase(getAllState.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(getAllState.fulfilled,(state,action)=>{
            state.states=action.payload.Data || []
            state.loading=false
        });
        builder.addCase(getAllState.rejected,(state,action)=>{
            state.error = action.payload?.error || null
            state.loading=false
        })
        builder.addCase(insertState.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(insertState.fulfilled,(state)=>{
            state.stateDetail.form=stateForm
            state.loading=false
        });
        builder.addCase(insertState.rejected,(state,action)=>{
            state.error = action.payload?.error || null
            state.loading = false
        });
        //update
        builder.addCase(updateState.pending, (state) => {
            state.loading = true
          })
          builder.addCase(updateState.fulfilled, (state) => {
            state.stateDetail.form = stateForm
            state.loading = false
          })
          builder.addCase(updateState.rejected, (state, action) => {
            state.error = action.payload?.error || null
            state.loading = false
          });
          //get id
          builder.addCase(findByIdState.pending, (state) => {
            state.loading = true
          });
          builder.addCase(findByIdState.fulfilled,(state,action)=>{
            let formObj = action?.payload?.Data
            let formKeys=Object.keys(stateForm)
            formObj = _.pick(formObj,formKeys);
            state.stateDetail.form=formObj
            state.loading = false
          });
          builder.addCase(findByIdState.rejected, (state, action) => {
            state.error = action.payload?.error || null
            state.loading = false
          })
    },
});

export const { resetForm } = StateReducer.actions

export default StateReducer.reducer;
