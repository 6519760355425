import { Field, Form, Formik } from "formik"
import React, { useEffect, useState } from "react"
import {
  CONVERT_BOOL,
  REQUIRED,
  SelectionField,
  TextField,
} from "../../Components/constants/field"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  Table,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import {
  ActiveInActiveHotelStatus,
  addRoomCategories,
  getHotelRoomcatgeoryByHotelID,
  getRoomCategoryById,
} from "../../slices/thunks"
import { useParams } from "react-router-dom"
import Loader from "../../Components/Common/Loader"
import DeleteModal from "../../Components/Common/DeleteModal"
import Switch from "../../Components/Common/Switch"

const RoomCategory = () => {
  const { ID } = useParams()
  const dispatch = useDispatch()
  const {
    roomCategories: { form, loading: roomCategoryLoading, roomCategoryList },
  } = useSelector((state) => state.Hotel)
  const [reload, setReload] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  //

  useEffect(() => {
    if (ID) {
      let obj = {
        HotelID: +ID,
        OperationType: 16,
      }
      dispatch(getHotelRoomcatgeoryByHotelID(obj))
    }
  }, [ID, reload])
  //
  const handleSubmit = (values, { resetForm }) => {
    let obj = {
      ...values,
    }
    if (ID) {
      obj.HotelID = +ID
    }
    if (obj?.IsActive) {
      obj.IsActive = CONVERT_BOOL.test(obj.IsActive)
    }
    if (obj?.ID) {
      obj.ID = +obj?.ID
      obj["OperationType"] = 17
    } else {
      delete obj?.ID
      obj["OperationType"] = 16
    }

    dispatch(addRoomCategories(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload)
        resetForm()
      }
    })
  }

  const handleChange = (data) => {
    let obj = { ID: +data.ID, IsActive: !data.IsActive, OperationType: 25 }

    // setChecked(!checked)
    dispatch(ActiveInActiveHotelStatus(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload)
      }
    })
  }

  const handleEdit = (contact) => {
    let obj = {
      ID: +contact?.ID,
    }
    dispatch(getRoomCategoryById(obj))
  }

  const onClickDelete = (data) => {
    setDeleteModal(true)
    setDeleteData(data)
  }

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
      HotelID: +deleteData?.HotelID,
      OperationType: 18,
    }
    dispatch(addRoomCategories(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload)
        setDeleteModal(false)
      }
    })
  }

  return (
    <Col xl={12}>
      <Card color="dark" outline>
        <CardHeader className="bg-light">
          <h4 className="card-title mb-0">Room Category</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12}>
              <Formik
                initialValues={form}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  RoomCategoryName: Yup.string().required(REQUIRED),
                  IsActive: Yup.bool().required(REQUIRED),
                })}
                onSubmit={handleSubmit}
              >
                <Form noValidate autoComplete="off">
                  <div>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="room-category">
                            Room Category
                          </Label>
                          <Field
                            name="RoomCategoryName"
                            type="text"
                            className="form-control"
                            id="room-category"
                            placeholder="Enter room category"
                            component={TextField}
                          />
                        </div>
                      </Col>

                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="contact-status"
                          >
                            Status
                          </Label>
                          <Field
                            name="IsActive"
                            id="contact-status"
                            options={[
                              {
                                label: "Active",
                                value: true,
                              },
                              {
                                label: "Inactive",
                                value: false,
                              },
                            ]}
                            component={SelectionField}
                          />
                        </div>
                      </Col>
                      <Col lg={4} className="mt-4 text-end">
                        <Button className="success" type="submit">
                          Add
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Formik>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col lg={12}>
              <div className="table-responsive mt-2">
                <Table
                  className="align-middle table-nowrap mb-0"
                  striped
                  responsive
                  hover
                >
                  <thead>
                    <tr>
                      <th scope="col">Room Category</th>
                      {/* <th scope="col">Hotel Number</th> */}
                      <th scope="col">Status </th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!roomCategoryLoading &&
                    Array.isArray(roomCategoryList) &&
                    roomCategoryList.length ? (
                      roomCategoryList.map((category, index) => {
                        return (
                          <tr key={index}>
                            <td scope="row">
                              {category?.RoomCategoryName || ""}
                            </td>

                            <td scope="row">
                              <Switch
                                onChange={() => handleChange(category)}
                                checked={category?.IsActive}
                              />
                            </td>
                            <td scope="row">
                              <div
                                className="hstack gap-3 flex-wrap "
                                role="button"
                              >
                                <i
                                  className="ri-edit-line text-success fs-18"
                                  //
                                  onClick={() => handleEdit(category)}
                                ></i>
                                <div className="gap-3 " role="button">
                                  <i
                                    className=" ri-delete-bin-fill text-danger fs-18"
                                    onClick={() => onClickDelete(category)}
                                  ></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : roomCategoryLoading ? (
                      <tr>
                        <td>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No room category found found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
            <DeleteModal
              show={deleteModal}
              onCloseClick={() => setDeleteModal(false)}
              onDeleteClick={() => onDeleted()}
            />
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

export default RoomCategory
