import _ from "lodash"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Card, CardBody, Col, Row, Table } from "reactstrap"
import { showModal } from "../../../slices/quotation/reducer"
import DeleteModal from "../../../Components/Common/DeleteModal"
import { getQuotationDetail, updateAccommodation } from "../../../slices/thunks"
import { useLocation } from "react-router-dom"
// import DeleteModal from "../../../Components/Common/DeleteModal"

const Accommodation = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const qID = urlParams.get("q")
  const {
    accommodation: { list },
  } = useSelector((state) => state.Quotation)

  //
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)

  //
  const editAccommodation = (data) => {
    let obj = {
      modal: true,
      modalType: "accommodation",
      modalData: {
        ID: data?.ID || "",
        QuoteID: data?.QuoteID || "",
      },
    }
    dispatch(showModal(obj))
  }
  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
      QuoteID: +deleteData?.QuoteID,
      OperationType: 8,
      IsActive: false,
    }
    dispatch(updateAccommodation(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setDeleteModal(false)

        let quotationDetail = {
          ID: Number(qID),
        }

        dispatch(getQuotationDetail(quotationDetail))
      }
    })
  }
  const onClickDelete = (data) => {
    setDeleteModal(true)
    setDeleteData(data)
  }
  return (
    <Card className="border border-black">
      <CardBody>
        <Row>
          <Col md={12}>
            <h5>Accommodation</h5>
            <hr></hr>
          </Col>
        </Row>
        <Col lg={12}>
          <div className="table-responsive mt-2">
            <Table
              className="align-middle table-nowrap mb-0"
              striped
              responsive
              hover
            >
              <thead>
                <tr>
                  <th scope="col">No of Day</th>
                  <th scope="col">Date</th>
                  <th scope="col">Days</th>
                  <th scope="col">City</th>
                  <th scope="col">Hotel Category</th>
                  <th scope="col">Hotel Name</th>
                  <th scope="col">Room Category</th>
                  <th scope="col">Meal Plan</th>
                  <th scope="col">Total Cost</th>
                  <th scope="col">Margin</th>
                  <th scope="col">Total With GST</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(list) && list.length ? (
                  list.map((acc, i) => {
                    return (
                      <tr key={i}>
                        <td scope="col">{acc?.ItineraryNoOfDay}</td>
                        <td scope="col">{acc?.ItineraryDate}</td>
                        <td scope="col">{acc?.ItineraryDay}</td>
                        <td scope="col">{acc?.CityName}</td>
                        <td scope="col">{acc?.HotelCategory} star</td>
                        <td scope="col">{acc?.HotelName}</td>
                        <td scope="col">{acc?.RoomCategoryName}</td>
                        <td scope="col">{acc?.MealPlanName}</td>
                        <td scope="col">{acc?.Total}</td>
                        <td scope="col">{acc?.Margin}</td>
                        <td scope="col">{acc?.GrandTotal}</td>

                        <td scope="col">
                          {" "}
                          <div
                            className="hstack gap-3 flex-wrap "
                            role="button"
                          >
                            <i
                              className="ri-edit-line text-success fs-18"
                              //
                              onClick={() => editAccommodation(acc)}
                            ></i>
                            <div className="gap-3 " role="button">
                              <i
                                className="ri-delete-bin-fill text-danger fs-18"
                                onClick={() => onClickDelete(acc)}
                              ></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  })
                ) : list.length === 0 ? (
                  <tr>
                    <td colSpan={10} className="text-center">
                      No accommodation found
                    </td>
                  </tr>
                ) : null}
              </tbody>
              <tfoot>
                <tr>
                  <td className="text-end" colSpan={8}>
                    Total
                  </td>
                  <td>{_.round(_.sumBy(list, "Total"))}</td>
                  <td>{_.round(_.sumBy(list, "Margin"))}</td>
                  <td colSpan={2}>{_.round(_.sumBy(list, "GrandTotal"))}</td>
                </tr>
              </tfoot>
            </Table>
          </div>
          <DeleteModal
            show={deleteModal}
            onCloseClick={() => setDeleteModal(false)}
            onDeleteClick={() => onDeleted()}
          />
        </Col>
      </CardBody>
    </Card>
  )
}

export default Accommodation
