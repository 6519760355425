import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import { Link } from "react-router-dom"
import {
  ActiveInActiveSector,
  deleteSector,
  getAllSector,
} from "../../slices/thunks"
import { useDispatch, useSelector } from "react-redux"
import _ from "lodash"
import Loader from "../../Components/Common/Loader"
import Switch from "../../Components/Common/Switch"
import DeleteModal from "../../Components/Common/DeleteModal"
import { resetForm } from "../../slices/sector/reducer"
const MasterSector = () => {
  const dispatch = useDispatch()
  const { sectors, loading } = useSelector((state) => state.Sector)

  //
  const [reload, setReload] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)

  //
  useEffect(() => {
    dispatch(resetForm())
    dispatch(getAllSector())
  }, [reload])

  const handleChange = (data) => {
    let obj = { ID: +data.ID, IsActive: !data.IsActive }

    // setChecked(!checked)
    dispatch(ActiveInActiveSector(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload)
      }
    })
  }

  const onClickDelete = (data) => {
    setDeleteModal(true)
    setDeleteData(data)
  }

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
    }
    dispatch(deleteSector(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload)
        setDeleteModal(false)
      }
    })
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Sector Master" pageTitle="Sector" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md={4}>
                    <h4 className="card-title mb-0">Sector Master</h4>
                  </Col>
                  <Col md={8} className="text-end">
                    <Link to="/sector/create">
                      <Button color="primary">
                        <i className="las la-plus"></i>Add
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <Table
                        className="align-middle table-nowrap mb-0"
                        striped
                        responsive
                        hover
                      >
                        <thead>
                          <tr className="table-header">
                            <th scope="col">Sector Name</th>
                            <th scope="col">Sector Type</th>
                            <th scope="col">Region</th>
                            <th scope="col">City</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                          Array.isArray(sectors) &&
                          sectors.length ? (
                            sectors.map((sector, index) => (
                              <tr key={index}>
                                <td>
                                  <Link to={`/sector/update/${sector.ID}`}>
                                    {sector.SectorName}
                                  </Link>
                                </td>
                                <td>{sector.SectorType}</td>
                                <td>{sector.RegionName}</td>
                                <td>
                                  {Array.isArray(sector?.Cities)
                                    ? _.join(
                                        _.map(sector.Cities, "CityName"),
                                        ", "
                                      )
                                    : ""}
                                </td>
                                <td>
                                  <Switch
                                    onChange={() => handleChange(sector)}
                                    checked={sector?.IsActive}
                                  />
                                </td>

                                <td>
                                  <div className="hstack gap-3 flex-wrap">
                                    <Link
                                      to={`/sector/update/${sector.ID}`}
                                      className="link-success fs-18"
                                    >
                                      <i className="ri-edit-line"></i>
                                    </Link>
                                    <div
                                      className="hstack gap-3 flex-wrap "
                                      role="button"
                                      onClick={() => onClickDelete(sector)}
                                    >
                                      <i className=" ri-delete-bin-fill text-danger fs-18"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : loading ? (
                            <tr>
                              <td colSpan={10}>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={10}>No hotel found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <DeleteModal
            show={deleteModal}
            onCloseClick={() => setDeleteModal(false)}
            onDeleteClick={() => onDeleted()}
          />
        </Row>
      </Container>
    </div>
  )
}

export default MasterSector
