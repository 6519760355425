import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllActiveTersAndConditionsApi = () =>
    api.get(url.GET_ALL_ACTIVE_TERMS_AND_CONDITIONS);


    export const getAllTersAndConditionsApi=()=>api.get(url.GET_ALL_TERM_CONDITION_LIST);

    export const findByIdTersAndConditionsApi = (params) =>
    api.create(url.FIND_BY_ID_TERM_CONDITION_URL, params);

    
export const insertTersAndConditionsApi = (data) => api.create(url.INSERT_TERM_CONDITION_URL, data);

export const updateTersAndConditionsApi = (data) => api.create(url.UPDATE_TERM_CONDITION_URL, data);

export const deleteTersAndConditionsApi = (data) => api.create(url.DELETE_TERM_CONDITION_URL, data);


export const ActiveInActiveTersAndConditionsApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_TERM_CONDITION_URL, data);
  