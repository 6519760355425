import { createSlice } from "@reduxjs/toolkit";
import {
  getAllVehicle,
  getAllActiveVehicleType,
  getSeatByVehicleType,
  getVehicleTypeByNoOfPax,
  getVehicleTypeByTour,
  insertVehicle,
  findByIdVehicle,
  updateVehicle,
} from "./thunk";
import _ from "lodash"

export const VehicleForm = {
  ID: null,
  IsActive: true,
  CityID: null,
  VehicleTypeID: null,
  NoOfSeats: null,
  KmPerDay: null,
  RatePerKm: null,
  DriverAllowancePerDay: null,
  PickUpWithinCity: null,
  DropWithinCity: null,
  EightHrEightyKmWithinCity: null
}

export const initialState = {
  vehicleDetail: {
    form: VehicleForm,
  },
  vehicleTypes: [],
  vehicles: [],
  seats: [],
  error: {},
  loading: false,
};

const VehicleReducer = createSlice({
  name: "Vehicle",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.vehicleDetail.form = VehicleForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllActiveVehicleType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllActiveVehicleType.fulfilled, (state, action) => {
      state.vehicleTypes = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllActiveVehicleType.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });

    builder.addCase(getAllVehicle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllVehicle.fulfilled, (state, action) => {
      state.vehicles = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllVehicle.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });

    builder.addCase(getVehicleTypeByTour.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVehicleTypeByTour.fulfilled, (state, action) => {
      state.vehicleTypes = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getVehicleTypeByTour.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });

    builder.addCase(getSeatByVehicleType.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getSeatByVehicleType.fulfilled, (state, action) => {
      state.seats = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getSeatByVehicleType.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });
    builder.addCase(getVehicleTypeByNoOfPax.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getVehicleTypeByNoOfPax.fulfilled, (state, action) => {
      state.vehicleTypes = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getVehicleTypeByNoOfPax.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });

    // Insert Vehicle
    builder.addCase(insertVehicle.pending, (state) => {
      state.loading = true
    })
    builder.addCase(insertVehicle.fulfilled, (state) => {
      state.vehicleDetail.form = VehicleForm
      state.loading = false
    })
    builder.addCase(insertVehicle.rejected, (state, action) => {
      state.error = action.payload.error || null
      state.loading = false
    })

    // Update Vehicle
    builder.addCase(updateVehicle.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateVehicle.fulfilled, (state) => {
      state.vehicleDetail.form = VehicleForm
      state.loading = false
    })
    builder.addCase(updateVehicle.rejected, (state, action) => {
      state.error = action.payload.error || null
      state.loading = false
    })

    // Get By Id Vehicle
    builder.addCase(findByIdVehicle.pending, (state) => {
      state.loading = true
    })
    builder.addCase(findByIdVehicle.fulfilled, (state, action) => {
      let formObj = action?.payload?.Data

      let formKeys = Object.keys(VehicleForm)
      formObj = _.pick(formObj, formKeys)

      state.vehicleDetail.form = formObj

      state.loading = false
    })
    builder.addCase(findByIdVehicle.rejected, (state, action) => {
      state.error = action.payload.error || null
      state.loading = false
    })
  },
});

export const { resetForm } = VehicleReducer.actions

export default VehicleReducer.reducer;
