import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"
import Loader from "../../Components/Common/Loader"
import Switch from "../../Components/Common/Switch"
import DeleteModal from "../../Components/Common/DeleteModal"
import { resetForm } from "../../slices/tourfacilities/reducer";
import { ActiveInActiveTourFacilities, deleteTourFacilities, getAllTourFacilities } from "../../slices/tourfacilities/thunk";


 const MasterTourFacility = () => {
    const dispatch = useDispatch();

    const { tourfacilities, loading } = useSelector((state) => state.TourFacility);
  
    const [reload, setReload] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteData, setDeleteData] = useState(null);

    useEffect(() => {
        dispatch(resetForm());
        dispatch(getAllTourFacilities());
      }, [reload]);

      const handleChange = (data) => {
        let obj = { ID: +data.ID, IsActive: !data.IsActive };
    
        dispatch(ActiveInActiveTourFacilities(obj)).then((res) => {
          if (res.payload && res.payload.Status === 200) {
            setReload(!reload);
          }
        });
      };
      const onClickDelete = (data) => {
        setDeleteModal(true);
        setDeleteData(data);
      };

      const onDeleted = () => {
        let obj = {
          ID: +deleteData?.ID,
        };
        dispatch(deleteTourFacilities(obj)).then((res) => {
          if (res.payload && res.payload.Status === 200) {
            setReload(!reload);
            setDeleteModal(false);
          }
        });
      };
  
    return (
        <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Tour Facility Master" pageTitle="Tour Facility" />
          <Row>
            <Col xl={12}>
              <Card color="dark" outline>
                <CardHeader className="bg-light">
                  <Row className="align-items-center">
                    <Col md={4}>
                      <h4 className="card-title mb-0">Tour Facility Master</h4>
                    </Col>
                    <Col md={8} className="text-end">
                      <Link to="/tourfacility/create">
                        <Button color="primary">
                          <i className="las la-plus"></i>Add
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      <div className="table-responsive">
                        <Table
                          className="align-middle table-nowrap mb-0"
                          striped
                          responsive
                          hover
                        >
                          <thead>
                            <tr className="table-header">
                              <th scope="col">
                              Name
                              </th>
                              <th scope="col" className="col-1 text-center">
                              Status
                            </th>
                            <th scope="col" className="col-1 text-center">
                              Action
                            </th>
                            </tr>
                          </thead>
                          <tbody>
                            {!loading &&
                            Array.isArray(tourfacilities) &&
                            tourfacilities.length ? (
                                tourfacilities.map((tourfacility, index) => (
                                <tr key={index}>
                                  <td>
                                    <Link to={`/tourfacility/update/${tourfacility.ID}`}>
                                      {tourfacility.Name}
                                    </Link>
                                  </td>
                                  <td className="text-center">
                                    <Switch
                                      onChange={() => handleChange(tourfacility)}
                                      checked={tourfacility?.IsActive}
                                    />
                                  </td>
  
                                  <td className="text-center">
                                  <div className="hstack gap-3 justify-content-center">
                                      <Link
                                        to={`/tourfacility/update/${tourfacility.ID}`}
                                        className="link-success fs-18"
                                      >
                                        <i className="ri-edit-line"></i>
                                      </Link>
                                      <div
                                        className="hstack gap-3 flex-wrap "
                                        role="button"
                                        onClick={() => onClickDelete(tourfacility)}
                                      >
                                        <i className=" ri-delete-bin-fill text-danger fs-18"></i>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : loading ? (
                              <tr>
                                <td colSpan={10}>
                                  <Loader />
                                </td>
                              </tr>
                            ) : (
                              <tr>
                                <td colSpan={10}>No Tour Facitity found</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <DeleteModal
              show={deleteModal}
              onCloseClick={() => setDeleteModal(false)}
              onDeleteClick={() => onDeleted()}
            />
          </Row>
        </Container>
      </div>
  )
}

export default MasterTourFacility;