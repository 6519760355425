/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { getIn } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  UncontrolledAlert,
} from "reactstrap";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import _, { findIndex, isEmpty } from "lodash";
import classNames from "classnames";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export const REQUIRED = "Required";
export const INVALID_EMAIL = "Invalid email";
export const POSITIVE = "Only positive value allowed";
export const AMOUNT = "Invalid Amount";
export const SUCCESS = "Success";
export const INVALID = "Invalid";
export const FAILED = "Failed";
export const phoneRegex = RegExp(
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3,4})$/
);

// APi Response Message
export const SUCCESS_STATUS = "SUCCESS";
export const FAILURE_STATUS = "FAILURE";
export const INVALID_PHONE = "That doesn't look like a phone number";
export const STANDARD_INPUT_DATE = "DD-MM-YYYY";
export const STANDARD_OUTPUT_DATE = "YYYY-MM-DD";
export const NO_OF_DAYS = "This field should not be more than 2 digits";

export const SECTOR_TYPES = ["Domestic", "International"];
export const PAGE_LIMIT = 10;
export const TRAVEL_TYPES = ["Tour", "Package"];
export const HOTEL_CATEGORIES = ["1*", "2*", "3*", "4*", "5*", "6*", "7*"];

export const dayTypeOptions = [
  {
    label: "Special Rate",
    value: 1,
  },
  {
    label: "Weekdays",
    value: 2,
  },
  {
    label: "Weekend",
    value: 3,
  },
];
// status dropdown options
export const statusOptions = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "Inactive",
    value: false,
  },
];
// labels for rate input fields
export const labels = [
  {
    rateType: "MAPAI",
    seq: 3,
  },
  {
    rateType: "CPAI",
    seq: 2,
  },
  {
    rateType: "EPAI",
    seq: 1,
  },
  {
    rateType: "APAI",
    seq: 4,
  },
];

// row labels for rate input fields
export const columns = [
  { id: 1, label: "Single Occupancy", type: "SO" },
  { id: 2, label: "Double Occupancy", type: "DO" },
  // { id: 3, label: "Extra Adult", type: "EA" },
  { id: 4, label: "ExtraAdults / CWB.", type: "CWB" },
  { id: 5, label: "CNB", type: "CNB" },
];
// CWB drop-down
export const CWBAgeOption = [
  {
    value: 5,
    label: 5,
  },
  {
    value: 6,
    label: 6,
  },
  {
    value: 7,
    label: 7,
  },
  {
    value: 8,
    label: 8,
  },
  {
    value: 9,
    label: 9,
  },
  {
    value: 10,
    label: 10,
  },
  {
    value: 11,
    label: 11,
  },
  {
    value: 12,
    label: 12,
  },
];

// Accommodation Server

export const services = [
  {
    label: "Accommodation",
    value: "Accommodation",
    id: 1,
    activeTab: [0, 1, 4],
  },
  {
    label: "Transportation",
    value: "Transportation",
    id: 2,
    activeTab: [0, 2, 4],
  },
  {
    label: "Package",
    value: "Package",
    id: 1,
    activeTab: [0, 1, 2, 3, 4],
  },
];

// to compare with regex
export const CONVERT_BOOL = /true/i;
///
export function DateField(props) {
  const errorMessage = getIn(props.form.errors, props.field.name);

  const { form, field, ...rest } = props;
  const [inputValue, setInputValue] = useState(field?.value || "");

  useEffect(() => {
    const val = field.value === null ? "" : field.value;
    setInputValue(val);
  }, [field.value]);

  const handleInputChange = (event) => {
    const date = moment(event[0]).format(STANDARD_INPUT_DATE);
    setInputValue(date);
    form.setFieldValue(field.name, date);
  };
  return (
    <>
      <Flatpickr
        {...rest} // includes any Material-UI specific props
        {...field} // includes all props contributed by the Formik Field/FastField
        name={field.name}
        value={inputValue}
        placeholder="Select Date.."
        className="form-control"
        options={props.options}
        onChange={handleInputChange}
      />
      <div className="invalid-feedback">{errorMessage}</div>
    </>
  );
}

export function TextField(props) {
  const errorMessage = getIn(props.form.errors, props.field.name);
  const isTouched = getIn(props.form.touched, props.field.name);

  const { error, form, field, type, ...rest } = props;
  const [inputValue, setInputValue] = useState(field?.value || "");

  useEffect(() => {
    let val = field.value === null ? "" : field.value;
    if (type === "number" && typeof field.value === "string") {
      val = parseFloat(val);
    }
    setInputValue(val);
  }, [field.value]);

  const handleInputChange = (event) => {
    event.stopPropagation();
    let inputValue = event.target;
    if (type === "number" && typeof inputValue.value === "string") {
      inputValue = inputValue.valueAsNumber;
    } else {
      inputValue = inputValue.value;
    }
    setInputValue(inputValue);
    form.setFieldValue(field.name, inputValue);
  };

  return (
    <>
      <Input
        className={"form-control"}
        {...rest} // includes any Material-UI specific props
        {...field} // includes all props contributed by the Formik Field/FastField
        invalid={error ?? Boolean(isTouched && errorMessage)}
        type={type || "text"}
        name={field.name}
        value={inputValue}
        onChange={handleInputChange}
      />
      <div className="invalid-feedback">{errorMessage}</div>
    </>
  );
}

export function SelectionField(props) {
  const errorMessage = getIn(props.form.errors, props.field.name);
  const isTouched = getIn(props.form.touched, props.field.name);

  const { error, form, multiple, field, options, className, placeholder = "Choose...", ...rest } = props;

  const [selectedValue, setValue] = useState(
    field.value ? field.value : multiple ? [] : ""
  );

  useEffect(() => {
    let val = field.value;
    if (typeof val === "number" && !multiple) {
      return setValue(val);
    } else if (typeof val === "number" && multiple) {
      return setValue([`${val}`]);
    } else if (typeof val === "boolean") {
      setValue(val);
    } else {
      val =
        isEmpty(val) && !multiple ? "" : isEmpty(val) && multiple ? [] : val;
      setValue(val);
    }
  }, [field.value, multiple]);

  const handleSelectionChange = (ev) => {
    const {
      target: { value },
    } = ev;
    setValue(value);
    form.setFieldValue(field.name, value);
  };

  return (
    <>
      <select
        className={classNames(`form-select ${className}`, {
          "is-invalid": error ?? Boolean(isTouched && errorMessage),
        })}
        id="validationDefault04"
        {...rest} // includes any Material-UI specific props
        {...field} // includes all props contributed by the Formik Field/FastField
        multiple={multiple}
        onChange={handleSelectionChange}
        value={selectedValue}
      >
        <option value={""}>{placeholder}</option>
        {options && options.length > 0
          ? options.map((op) => {
              if (op && typeof op.value === "boolean") {
                return (
                  <option key={op.value} value={op.value}>
                    {op.label}
                  </option>
                );
              } else if (op && op.value && op.label) {
                return (
                  <option key={op.value} value={op.value}>
                    {op.label}
                  </option>
                );
              }
              return;
            })
          : null}
      </select>
      <div className="invalid-feedback">{errorMessage}</div>
    </>
  );
}

export function MultiSelectField(props) {
  const errorMessage = getIn(props.form.errors, props.field.name);

  const isTouched = getIn(props.form.touched, props.field.name);

  const { error, form, field, options, disabled, ...rest } = props;

  const [selectedValue, setValue] = useState(field.value ? field.value : []);

  useEffect(() => {
    if (
      Array.isArray(field.value) &&
      field.value &&
      field.value.length > 0 &&
      options &&
      options.length
    ) {
      let selectedOptions = [];
      field.value.forEach((val) => {
        if (val && typeof val === "object") {
          const index = findIndex(options, { value: val.value });
          if (index > -1) {
            selectedOptions.push(options[index]);
          }
        } else {
          const index = findIndex(options, { value: val });
          if (index > -1) {
            selectedOptions.push(options[index]);
          }
        }
      });
      setValue(selectedOptions);
    }
  }, [field.value, options]);

  const handleSelectionChange = (selectionValues) => {
    setValue(selectionValues);
    form.setFieldValue(field.name, selectionValues);
  };

  return (
    <>
      <Select
        className={classNames({
          "is-invalid": error ?? Boolean(isTouched && errorMessage),
        })}
        closeMenuOnSelect={false}
        components={animatedComponents}
        {...rest} // includes any Material-UI specific props
        {...field} // includes all props contributed by the Formik Field/FastField
        value={selectedValue}
        isMulti
        disabled={disabled}
        options={options}
        onChange={handleSelectionChange}
      />
      <div className="invalid-feedback">{errorMessage}</div>
    </>
  );
}

export function MultiSelectFieldV2(props) {
  const errorMessage = getIn(props.form.errors, props.field.name);

  const isTouched = getIn(props.form.touched, props.field.name);

  const { error, form, field, options, disabled, ...rest } = props;

  const handleSelectionChange = (selectionValues) => {
    let valArr = _.map(selectionValues, "value");

    form.setFieldValue(field.name, valArr);
  };

  return (
    <>
      <Select
        className={classNames({
          "is-invalid": error ?? Boolean(isTouched && errorMessage),
        })}
        closeMenuOnSelect={false}
        // components={animatedComponents}
        {...rest} // includes any Material-UI specific props
        {...field} // includes all props contributed by the Formik Field/FastField
        value={options.filter((v) => field?.value?.includes(v?.value))}
        isMulti
        disabled={disabled}
        options={options}
        onChange={handleSelectionChange}
      />
      <div className="invalid-feedback">{errorMessage}</div>
    </>
  );
}

export function FileField(props) {
  const { placeholder, form, field, maxFiles } = props

  const [errorMessage, setErrorMessage] = useState();

  const handleFileChange = (fileItems) => {
    form.setFieldValue(
      field.name,
      fileItems.map((fileItem) => fileItem.file)
    );
  };

  return (
    <Card>
      <CardHeader>
        <h4 className="card-title mb-0">{placeholder}</h4>
      </CardHeader>
      <CardBody>
        <FilePond
          files={field.value}
          onupdatefiles={(fileItems) => handleFileChange(fileItems)}
          allowMultiple={true}
          maxFiles={maxFiles || 1}
          onwarning={(error) => setErrorMessage(error.body)}
          name={props.name}
          className="filepond filepond-input-multiple"
        />
      </CardBody>
      {errorMessage ? (
        <UncontrolledAlert
          color="danger"
          className="alert-solid alert-label-icon mb-xl-0"
          closeClassName="btn-close-white"
        >
          <i className="ri-error-warning-line label-icon"></i>
          <strong>{errorMessage}</strong>
        </UncontrolledAlert>
      ) : null}
    </Card>
  );
}

export function SingleFileField(props) {
  const { form, field, maxFiles } = props;

  const [errorMessage, setErrorMessage] = useState();

  const initialFiles =
    field.value && field.value[0]?.source
      ? [
          {
            source: field.value[0]?.source,
            options: {
              type: "local",
            },
          },
        ]
      : [];

  const handleFileChange = (fileItems) => {
    form.setFieldValue(
      field.name,
      fileItems.map((fileItem) => fileItem.file)
    );
  };

  useEffect(() => {
    // Set initial file array with existing files
    if (field.value && field.value[0]?.source) {
      form.setFieldValue(field.name, initialFiles);
    }
  }, [field.name, field.value, form]);

  const handleFileError = (file, error) => {
    console.error("Error occurred while loading file:", error);
  };

  return (
    <Card>
      <CardBody>
        <FilePond
          files={field.value || initialFiles}
          onupdatefiles={(fileItems) => handleFileChange(fileItems)}
          allowMultiple={true}
          maxFiles={maxFiles || 1}
          onwarning={(error) => setErrorMessage(error.body)}
          name={props.name}
          className="filepond filepond-input-multiple"
          onerror={handleFileError} // Add the onerror callback
        />
      </CardBody>
      {errorMessage ? (
        <UncontrolledAlert
          color="danger"
          className="alert-solid alert-label-icon mb-xl-0"
          closeClassName="btn-close-white"
        >
          <i className="ri-error-warning-line label-icon"></i>
          <strong>{errorMessage}</strong>
        </UncontrolledAlert>
      ) : null}
    </Card>
  );
}
