import React, { useEffect } from "react"
// import { ToastContainer } from "react-toastify"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import { Link, useNavigate, useParams } from "react-router-dom"
import { Field, Formik, Form } from "formik"
import {
  CONVERT_BOOL,
  MultiSelectFieldV2,
  REQUIRED,
  SelectionField,
  TextField,
} from "../../Components/constants/field"
import {
  findByIdSector,
  getAllCities,
  getAllRegions,
  insertSector,
  updateSector,
} from "../../slices/thunks"
import { useDispatch, useSelector } from "react-redux"
import Loader from "../../Components/Common/Loader"
import _ from "lodash"
import * as Yup from "yup"
// import { resetForm } from "../../slices/sector/reducer"

const CreateSector = () => {
  const history = useNavigate()
  const { ID } = useParams()
  const dispatch = useDispatch()
  const { cities, loading: CityLoading } = useSelector((state) => state.City)
  const {
    sectorDetail: { form },
  } = useSelector((state) => state.Sector)
  const { regionsList, loading: RegionLoading } = useSelector(
    (state) => state.Region
  )

  useEffect(() => {
    dispatch(getAllCities())
    dispatch(getAllRegions())
  }, [])

  useEffect(() => {
    if (ID) {
      let params = {
        ID: ID,
      }
      dispatch(findByIdSector(params))
    }

    // return () => dispatch(resetForm())
  }, [ID])

  const handleSubmit = (values) => {
    let obj = {
      ...values,
    }

    obj.CityID = Array.isArray(obj.CityID) ? _.join(obj.CityID, ",") : ""
    obj.IsActive = CONVERT_BOOL.test(obj?.IsActive)

    if (obj?.ID) {
      dispatch(updateSector(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/sector`)
        }
      })
    } else {
      delete obj?.ID
      dispatch(insertSector(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/sector`)
        }
      })
    }
  }

  if (CityLoading || RegionLoading) {
    return <Loader />
  }
  return (
    <div className="page-content">
      <Container fluid>
        {/* <ToastContainer /> */}
        <BreadCrumb title="Sector" pageTitle="Sector" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">
                      {ID ? "Update Sector" : " Create Sector"}
                    </h4>
                  </Col>
                  <Col md="1">
                    <Link to="/sector">
                      <Button color="primary" block>
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={form}
                      validationSchema={Yup.object({
                        RegionID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                        SectorType: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        SectorName: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        Prefix: Yup.string().nullable(true).required(REQUIRED),
                        CityID: Yup.array().min(1, REQUIRED).required(REQUIRED),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {() => {
                        return (
                          <Form noValidate autoComplete="off">
                            <div>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="RegionID"
                                    >
                                      Region
                                    </Label>
                                    <Field
                                      name="RegionID"
                                      options={
                                        Array.isArray(regionsList)
                                          ? regionsList.map((region) => {
                                              return {
                                                value: Number(region.ID),
                                                label: region.RegionName,
                                              }
                                            })
                                          : []
                                      }
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="SectorType"
                                    >
                                      Sector Type
                                    </Label>
                                    <Field
                                      name="SectorType"
                                      id="SectorType"
                                      options={[
                                        {
                                          label: "Domestic",
                                          value: "Domestic",
                                        },
                                        {
                                          label: "International",
                                          value: "International",
                                        },
                                      ]}
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label htmlFor="SectorName">
                                      Sector Name
                                    </Label>
                                    <Field
                                      name="SectorName"
                                      className="form-control"
                                      type="text"
                                      id="SectorName"
                                      component={TextField}
                                      placeholder="Enter Name"
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="Prefix"
                                    >
                                      Prefix
                                    </Label>
                                    <Field
                                      name="Prefix"
                                      type="text"
                                      id="Prefix"
                                      component={TextField}
                                      placeholder="Enter Prefix"
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="CityID"
                                    >
                                      City
                                    </Label>
                                    <Field
                                      name="CityID"
                                      id="CityID"
                                      options={
                                        Array.isArray(cities) &&
                                        cities.map((city) => {
                                          return {
                                            value: Number(city.ID),
                                            label: city.CityName,
                                          }
                                        })
                                      }
                                      component={MultiSelectFieldV2}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="hotel-status"
                                    >
                                      Status
                                    </Label>
                                    <Field
                                      name="IsActive"
                                      id="hotel-status"
                                      options={[
                                        {
                                          label: "Active",
                                          value: true,
                                        },
                                        {
                                          label: "Inactive",
                                          value: false,
                                        },
                                      ]}
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mt-2">
                                <Col lg={12}>
                                  <hr></hr>
                                </Col>
                                <Col md="12" className="text-end">
                                  <Button className="success">Save</Button>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CreateSector
