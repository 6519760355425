import { createAsyncThunk } from "@reduxjs/toolkit"
import "react-toastify/dist/ReactToastify.css"
import { FAILED } from "../../Components/constants/field"
import { AUTO_CLOSE } from "../../Components/constants/toast"
import { toast } from "react-toastify"
import {
  ActiveInActiveSectorApi,
  deleteSectorApi,
  findByIdSectorApi,
  getAllActiveSectorsApi,
  getAllSectorApi,
  insertSectorApi,
  updateSectorApi,
} from "./sector.services"

export const getAllActiveSectors = createAsyncThunk(
  "Sectors/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveSectorsApi()
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE })
      return error
    }
  }
)

export const insertSector = createAsyncThunk("Sectors/Insert", async (data) => {
  try {
    const response = await insertSectorApi(data)
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    return error
  }
})

export const updateSector = createAsyncThunk("Sectors/Update", async (data) => {
  try {
    const response = await updateSectorApi(data)
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    return error
  }
})

export const findByIdSector = createAsyncThunk(
  "Sectors/FindById",
  async (params) => {
    try {
      const response = await findByIdSectorApi(params)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error
    }
  }
)

export const getAllSector = createAsyncThunk(
  "Sectors/GetAllSector",
  async (params) => {
    try {
      const response = await getAllSectorApi(params)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error
    }
  }
)

export const ActiveInActiveSector = createAsyncThunk(
  "Sectors/ActiveInActive",
  async (data) => {
    try {
      const response = await ActiveInActiveSectorApi(data)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error
    }
  }
)
export const deleteSector = createAsyncThunk("Sectors/Delete", async (data) => {
  try {
    const response = await deleteSectorApi(data)
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    return error
  }
})
