import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { toast } from "react-toastify";
import {
  AssignedUserApi,
  deleteInquiryApi,
  findByIdInquiryApi,
  getAllInquiryByFilterApi,
  getAllPendingInquiryByFilterApi,
  insertInquiryApi,
  updateInquiryApi,
} from "./inquiry.services";

export const getAllInquiryByFilter = createAsyncThunk(
  "Inquiry/FindAllByFilter",
  async (data) => {
    try {
      const response = await getAllInquiryByFilterApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(error?.message, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getAllPendingInquiryByFilter = createAsyncThunk(
  "Inquiry/FindAllPendingByFilter",
  async (data) => {
    try {
      const response = await getAllPendingInquiryByFilterApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(error?.message, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const findByIdInquiry = createAsyncThunk(
  "Inquiry/FindById",
  async (params) => {
    try {
      const response = await findByIdInquiryApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(error?.message, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const insertInquiry = createAsyncThunk(
  "Inquiry/Insert",
  async (data) => {
    try {
      const response = await insertInquiryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(error?.message, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const updateInquiry = createAsyncThunk(
  "Inquiry/Update",
  async (data) => {
    try {
      const response = await updateInquiryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(error?.message, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const deleteInquiry = createAsyncThunk(
  "Inquiry/Delete",
  async (data) => {
    try {
      const response = await deleteInquiryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(error?.message, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const AssignedUser = createAsyncThunk(
  "Inquiry/AssignedUser",
  async (data) => {
    try {
      const response = await AssignedUserApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(error?.message, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);
