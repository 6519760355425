/* eslint-disable no-unused-vars */
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import { showModal } from "../../../slices/autoquotation/reducer"
import AutoQuotationModal from "./AutoQuotationModal"
import { useParams } from "react-router-dom"
import { getCityByTourID } from "../../../slices/thunks"
import Loader from "../../../Components/Common/Loader"
import _ from "lodash"

const GenerateQuotationList = () => {
  const { TOURID } = useParams()
  const {
    accommodation: { list, loading },
    newQuotation: { form },
  } = useSelector((state) => state.Quotation)

  const dispatch = useDispatch()
  const generalQuote = (quote) => {
    let obj = {
      modalShow: true,
      data: {
        ID: quote?.ID || "",
        QuoteID: quote?.QuoteID || "",
      },
    }
    dispatch(showModal(obj))
  }
  useEffect(() => {
    if (TOURID) {
      let cityObj = {
        TourID: TOURID,
      }
      dispatch(getCityByTourID(cityObj))
      //
    }
  }, [TOURID])

  return (
    <Row>
      <Col xl={12}>
        <Card color="dark" outline>
          <CardHeader>
            <Row className="align-items-center">
              <Col md="12">
                <h4 className="card-title mb-0">Generate Quote</h4>
              </Col>
            </Row>
          </CardHeader>
          <CardBody className="form-steps">
            <Row>
              <Col lg={12}>
                <Table
                  className="align-middle table-nowrap mb-0"
                  striped
                  responsive
                  hover
                >
                  <thead className="tour-table-header">
                    <tr>
                      <th scope="col">Day</th>
                      <th scope="col">Date </th>
                      <th scope="col">Days</th>
                      <th scope="col">City</th>
                      <th scope="col">Hotel Category</th>
                      <th scope="col">Hotel Name</th>
                      <th scope="col">Room Category</th>
                      <th scope="col">Meal Plan</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(list) && list.length ? (
                      list.map((quote, i) => {
                        return (
                          <tr key={i}>
                            <td scope="col">{quote?.ItineraryNoOfDay || ""}</td>
                            <td scope="col">{quote?.ItineraryDate || ""}</td>
                            <td scope="col">{quote?.ItineraryDay || ""}</td>
                            <td scope="col">{quote?.CityName || ""}</td>
                            <td scope="col">
                              {quote?.HotelCategory || "0"} star
                            </td>
                            <td scope="col">{quote?.HotelName || ""} </td>
                            <td scope="col">
                              {quote?.RoomCategoryName || ""}{" "}
                            </td>
                            <td scope="col">{quote?.MealPlanName || ""} </td>

                            <td scope="col">
                              <div
                                className="hstack gap-3 flex-wrap "
                                role="button"
                              >
                                <i
                                  className="ri-edit-line text-success fs-18"
                                  //
                                  onClick={() => generalQuote(quote)}
                                ></i>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : loading ? (
                      <tr>
                        <td colSpan={10} className="text-center">
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No generate quote found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md="3" className="d-flex align-items-center">
                <Label className="w-50 m-0">Grand Total</Label>
                <Input disabled value={form?.ExpensesTotal || "0.00"} />
              </Col>
            </Row>
          </CardBody>
        </Card>
        <AutoQuotationModal />
      </Col>
    </Row>
  )
}

GenerateQuotationList.propTypes = {}

export default GenerateQuotationList
