import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";
import {
  ActiveInActiveLeadSourceApi,
  deleteLeadSourceApi,
  findByIdLeadSourceApi,
  getAllActiveLeadSourceApi,
  getAllLeadSourceApi,
  insertLeadSourceApi,
  updateLeadSourceApi,
} from "./leadsource.service";

export const getAllActiveLeadSource = createAsyncThunk(
  "InquirySources/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveLeadSourceApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getAllLeadSource = createAsyncThunk(
  "InquirySources/FindAll",
  async () => {
    try {
      const response = await getAllLeadSourceApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const findByIdLeadSource = createAsyncThunk(
  "InquirySources/FindByID",
  async (params) => {
    try {
      const response = await findByIdLeadSourceApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const insertLeadSource = createAsyncThunk(
  "InquirySources/Insert",
  async (data) => {
    try {
      const response = await insertLeadSourceApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const updateLeadSource = createAsyncThunk(
  "InquirySources/Update",
  async (data) => {
    try {
      const response = await updateLeadSourceApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const ActiveInActiveLeadSource = createAsyncThunk(
  "InquirySources/ActiveInActive",
  async (data) => {
    try {
      const response = await ActiveInActiveLeadSourceApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const deleteLeadSource = createAsyncThunk(
  "InquirySources/Delete",
  async (data) => {
    try {
      const response = await deleteLeadSourceApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
