import React, { useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
  UncontrolledCollapse,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getTourFilters } from "../../slices/thunks";
import { Link } from "react-router-dom";
import Loader from "../../Components/Common/Loader";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import {
  deleteInquiry,
  getAllInquiryByFilter,
} from "../../slices/inquiry/thunk";
import { useState } from "react";
import DeleteModal from "../../Components/Common/DeleteModal";
import { resetForm } from "../../slices/inquiry/reducer";
import { budgetListArray } from "../../common/data/budgetListData";

function ChangeWatcher({ reload }) {
  const dispatch = useDispatch();
  const { values } = useFormikContext();
  useEffect(() => {
    const filters = {
      NoOfNights: "",
      Budget: "",
      SectorID: "",
    };
    if (values.NoOfNights && values.NoOfNights.length > 0) {
      filters.NoOfNights = values.NoOfNights.join(",");
    }
    if (values.SectorID && values.SectorID.length > 0) {
      filters.SectorID = values.SectorID.join(",");
    }
    if (values.Budget && values.Budget.length > 0) {
      filters.Budget = values.Budget;
    }

    if (!isEmpty(filters)) {
      dispatch(getAllInquiryByFilter(filters));
    }
  }, [values, reload]);
}

function InquiryMaster() {
  const dispatch = useDispatch();
  const [isFilter, setIsFilter] = useState(false);

  const { tourFilters } = useSelector((state) => ({
    tourFilters: state.Tour.tourFilters,
  }));

  const { inquiries, loading } = useSelector((state) => state.Inquiry);

  const [reload, setReload] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    const filters = {
      NoOfNights: "",
      Budget: "",
      SectorID: "",
    };
    dispatch(getAllInquiryByFilter(filters));
  }, [reload]);

  useEffect(() => {
    dispatch(getTourFilters());
  }, []);

  useEffect(() => {
    dispatch(resetForm());
  }, [reload]);

  const handleSubmit = (values) => {
    console.log(values);
  };

  const handleClearAll = (props) => {
    props.handleReset();
  };

  const onClickDelete = (data) => {
    setDeleteModal(true);
    setDeleteData(data);
  };

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
    };
    dispatch(deleteInquiry(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload);
        setDeleteModal(false);
      }
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Inquiry Master" pageTitle="Inquiry" />
        <Row>
          {isFilter && (
            <Col xl={3} lg={4}>
              <Formik
                initialValues={{
                  NoOfNights: "",
                  Budget: "",
                  SectorID: "",
                }}
                validationSchema={Yup.object({
                  SectorID: Yup.array().of(Yup.string().nullable(true)),
                })}
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <Form>
                    <ChangeWatcher reload={reload} />
                    <Card>
                      <CardHeader>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h5 className="fs-16">Filters</h5>
                          </div>
                          <div className="flex-shrink-0">
                            <Link
                              to="#"
                              className="text-decoration-underline"
                              onClick={() => handleClearAll(props)}
                            >
                              Clear All
                            </Link>
                          </div>
                        </div>
                      </CardHeader>
                      <div className="accordion accordion-flush">
                        {budgetListArray && budgetListArray?.length > 0 ? (
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button bg-transparent shadow-none"
                                type="button"
                                id="flush-collapseBudget"
                                aria-expanded="true"
                              >
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                  Budget
                                </span>{" "}
                              </button>
                            </h2>
                            <UncontrolledCollapse
                              toggler="#flush-collapseBudget"
                              defaultOpen
                            >
                              <div
                                id="flush-collapseBudgetList"
                                className="accordion-collapse collapse show"
                                aria-labelledby="radio-group"
                              >
                                <div className="accordion-body text-body pt-0">
                                  <div className="d-flex flex-column gap-2 mt-3">
                                    {budgetListArray.map((budget, index) => (
                                      <div
                                        key={index}
                                        role="group"
                                        aria-labelledby="checkbox-group"
                                      >
                                        <div className="form-check mb-3">
                                          <Field
                                            className="form-check-input"
                                            type="radio"
                                            name="Budget"
                                            value={budget.Value}
                                          />
                                          <Label
                                            className="form-check-label"
                                            htmlFor="formCheck6"
                                          >
                                            {budget.Budget}
                                          </Label>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </UncontrolledCollapse>
                          </div>
                        ) : null}

                        {tourFilters.nightList &&
                        tourFilters.nightList?.length > 0 ? (
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button bg-transparent shadow-none"
                                type="button"
                                id="flush-collapseNoOfNights"
                                aria-expanded="true"
                              >
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                  No Of Nights
                                </span>{" "}
                              </button>
                            </h2>
                            <UncontrolledCollapse
                              toggler="#flush-collapseNoOfNights"
                              defaultOpen
                            >
                              <div
                                id="flush-collapseNoOfNightList"
                                className="accordion-collapse collapse show"
                                aria-labelledby="flush-headingBrands"
                              >
                                <div className="accordion-body text-body pt-0">
                                  <div className="d-flex flex-column gap-2 mt-3">
                                    {tourFilters.nightList.map(
                                      (night, index) => (
                                        <div
                                          key={index}
                                          role="group"
                                          aria-labelledby="checkbox-group"
                                        >
                                          <div className="form-check mb-3">
                                            <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="NoOfNights"
                                              value={night.NoOfNights.toString()}
                                            />
                                            <Label
                                              className="form-check-label"
                                              htmlFor="formCheck6"
                                            >
                                              {night.NightDays}
                                            </Label>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </UncontrolledCollapse>
                          </div>
                        ) : null}
                        {tourFilters.sectorList &&
                        tourFilters.sectorList?.length > 0 ? (
                          <div className="accordion-item">
                            <h2 className="accordion-header">
                              <button
                                className="accordion-button bg-transparent shadow-none"
                                type="button"
                                id="flush-collapseDestinations"
                                aria-expanded="true"
                              >
                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                  Destinations
                                </span>{" "}
                              </button>
                            </h2>
                            <UncontrolledCollapse
                              toggler="#flush-collapseDestinations"
                              defaultOpen
                            >
                              <div
                                id="flush-collapseDestinationList"
                                className="accordion-collapse collapse show"
                                aria-labelledby="flush-headingBrands"
                              >
                                <div className="accordion-body text-body pt-0">
                                  <div className="d-flex flex-column gap-2 mt-3">
                                    {tourFilters.sectorList.map(
                                      (sector, index) => (
                                        <div
                                          key={index}
                                          role="group"
                                          aria-labelledby="checkbox-group"
                                        >
                                          <div className="form-check mb-3">
                                            <Field
                                              className="form-check-input"
                                              type="checkbox"
                                              name="SectorID"
                                              value={sector.ID.toString()}
                                            />
                                            <Label
                                              className="form-check-label"
                                              htmlFor="formCheck6"
                                            >
                                              {sector.SectorName}
                                            </Label>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </UncontrolledCollapse>
                          </div>
                        ) : null}
                      </div>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Col>
          )}
          <Col xl={isFilter ? 9 : 12} lg={isFilter ? 8 : 12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md={4}>
                    <h4 className="card-title mb-0">Inquiry Master</h4>
                  </Col>
                  <Col md={8} className="text-end">
                    <Button
                      className="me-4"
                      color="primary"
                      outline={isFilter}
                      onClick={() => {
                        setIsFilter(!isFilter);
                      }}
                    >
                      <i className="ri-filter-fill"></i>
                    </Button>
                    <Link to="/pendinginquiry" className="me-3">
                      <Button color="primary">Assign</Button>
                    </Link>
                    <Link to="/inquiry/create">
                      <Button color="primary">
                        <i className="las la-plus"></i>Add
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <Table
                        className="align-middle table-nowrap mb-0"
                        striped
                        responsive
                        hover
                      >
                        <thead>
                          <tr className="table-header">
                            <th scope="col">No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile No</th>
                            <th scope="col">Email ID</th>
                            <th scope="col">Destination</th>
                            <th scope="col">Lead Status</th>
                            <th scope="col">Date</th>
                            <th scope="col">Lead Source</th>
                            <th scope="col">Assign Person</th>
                            <th scope="col" className="col-1 text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                          Array.isArray(inquiries) &&
                          inquiries.length ? (
                            inquiries.map((inquiry, index) => (
                              <tr key={index}>
                                <td>{inquiry.InquiryNo}</td>
                                <td>
                                  <Link to={`/inquiry/update/${inquiry.ID}`}>
                                    {inquiry.Name}
                                  </Link>
                                </td>
                                <td>{inquiry.ContactNo}</td>
                                <td>{inquiry.EmailID}</td>
                                <td>{inquiry.SectorName}</td>
                                <td>{inquiry.LeadStatus}</td>
                                <td>{inquiry.Date}</td>
                                <td>{inquiry.Source}</td>
                                <td>{inquiry.AssignedUserName}</td>
                                <td className="text-center">
                                  <div className="hstack gap-3 justify-content-center">
                                    <Link
                                      to={`/inquiry/update/${inquiry.ID}`}
                                      className="link-success fs-18"
                                    >
                                      <i className="ri-edit-line"></i>
                                    </Link>
                                    <div
                                      className="hstack gap-3 flex-wrap "
                                      role="button"
                                      onClick={() => onClickDelete(inquiry)}
                                    >
                                      <i className=" ri-delete-bin-fill text-danger fs-18"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : loading ? (
                            <tr>
                              <td colSpan={10}>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={10}>No Inquiries found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <DeleteModal
            show={deleteModal}
            onCloseClick={() => setDeleteModal(false)}
            onDeleteClick={() => onDeleted()}
          />
        </Row>
      </Container>
    </div>
  );
}

export default InquiryMaster;
