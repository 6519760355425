import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  TabPane,
} from "reactstrap"
import {
  SelectionField,
  TextField,
  REQUIRED,
  INVALID_PHONE,
  phoneRegex,
  INVALID_EMAIL,
  STANDARD_INPUT_DATE,
  services,
  INVALID,
  STANDARD_OUTPUT_DATE,
  POSITIVE,
  NO_OF_DAYS,
} from "../../Components/constants/field"
import Flatpickr from "react-flatpickr"

import CreatableSelect from "react-select/creatable"
import * as Yup from "yup"
import moment from "moment"
import {
  createQuotationStep1,
  getAllActiveTours,
  getAllSectors,
  getQuotationDetail,
  searchAgentUsingName,
} from "../../slices/quotation/thunk"
import {
  ActiveTab,
  activeTabsList,
  addAgentInList,
} from "../../slices/quotation/reducer"
import _ from "lodash"
import { useLocation, useNavigate } from "react-router-dom"
import Loader from "../../Components/Common/Loader"

const CreateQuotation = () => {
  const history = useNavigate()
  const [reload, setReload] = useState(false)
  const location = useLocation()

  const dispatch = useDispatch()
  const urlParams = new URLSearchParams(location.search)
  const qID = urlParams.get("q")
  const { activeTab, newQuotation } = useSelector((state) => state.Quotation)
  const { loading, form, sectors } = newQuotation

  //
  useEffect(() => {
    dispatch(getAllSectors())
    dispatch(getAllActiveTours())
  }, [])
  useEffect(() => {
    let data = {
      AgentName: "",
    }
    dispatch(searchAgentUsingName(data))
  }, [])
  useEffect(() => {
    if (qID) {
      let quotationDetail = {
        ID: Number(qID),
      }

      dispatch(getQuotationDetail(quotationDetail))
    }
  }, [qID, reload])

  const handleSubmit = (values) => {
    let obj = {
      ...values,
    }
    delete obj.form
    delete obj.agentList
    delete obj.sectors
    delete obj.tours
    delete obj.loading

    obj.MobileNo = obj.MobileNo ? obj.MobileNo.toString() : ""
    obj.AgentMobileNo = obj.AgentMobileNo ? obj.AgentMobileNo.toString() : ""
    obj.AgentEmailID = obj?.AgentEmailID || ""
    obj.CompanyName = obj?.CompanyName || ""
    obj.CompanyPanNo = obj?.CompanyPanNo || ""
    obj.CompanyGSTNo = obj?.CompanyGSTNo || ""
    obj.PickUpPlace = obj?.PickUpPlace || ""
    obj.DropPlace = obj?.DropPlace || ""
    obj.ArrivalAt = obj?.ArrivalAt || ""
    obj.ArrivalRemarks = obj?.ArrivalRemarks || ""
    obj.DropAt = obj?.DropAt || ""
    obj.DropRemarks = obj?.DropRemarks || ""
    obj.TermsAndCondition = obj?.TermsAndCondition || ""
    obj.Services = obj?.Services || ""
    obj.PickUpDate = obj?.PickUpDate
      ? moment(obj.PickUpDate, STANDARD_INPUT_DATE).format(STANDARD_OUTPUT_DATE)
      : ""
    obj.PickUpTime = obj?.PickUpTime || ""
    obj.DropDate = obj?.DropDate
      ? moment(obj.DropDate, STANDARD_INPUT_DATE).format(STANDARD_OUTPUT_DATE)
      : "" || ""
    obj.DropTime = obj?.DropTime || ""
    obj.NoOfDays = obj?.NoOfDays || 0
    obj.CWB = obj?.CWB || 0
    obj.CNB = obj?.CNB || 0
    obj.SORoom = obj?.SORoom || 0
    obj.DORoom = obj?.DORoom || 0
    obj.ExtraBedAdults = obj?.ExtraBedAdults || 0
    obj.DepartureDate = obj.DepartureDate
      ? moment(obj.DepartureDate, STANDARD_INPUT_DATE).format(
          STANDARD_OUTPUT_DATE
        )
      : ""
    obj.SessionID = sessionStorage.getItem("SessionID")
    obj.DeviceType = 1
    obj.IsActive = true
    if (!obj.ID) {
      obj.OperationType = 1
      dispatch(createQuotationStep1(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          let getActiveTabs = _.find(services, (o) => o.value === obj.Services)

          dispatch(ActiveTab(getActiveTabs?.id))
          dispatch(activeTabsList(getActiveTabs?.activeTab))

          setReload(!reload)
          if (res.payload?.Data?.ID) {
            history(`/quotations/create?q=${res.payload?.Data?.ID || ""}`)
          }
        }
      })
    } else {
      obj[`OperationType`] = 2
      dispatch(createQuotationStep1(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          // resetForm()
          let getActiveTabs = _.find(services, (o) => o.value === obj.Services)

          dispatch(ActiveTab(getActiveTabs?.id))
          dispatch(activeTabsList(getActiveTabs.activeTab))
          // setActiveTab(obj)
          setReload(!reload)
        }
      })
    }
  }
  if (loading) return <Loader />

  return (
    <TabPane id="steparrow-gen-info" tabId={activeTab}>
      <Card className="border border-black">
        <Formik
          enableReinitialize={true}
          initialValues={form}
          validationSchema={Yup.object({
            // TourID: Yup.number().nullable(true).required(REQUIRED),
            SectorID: Yup.number().nullable(true).required(REQUIRED),
            Name: Yup.string().nullable(true).required(REQUIRED),
            MobileNo: Yup.string()
              .typeError(INVALID_PHONE)
              .matches(phoneRegex, INVALID)
              .nullable(true)
              .required(REQUIRED),
            AgentMobileNo: Yup.string()
              .typeError(INVALID_PHONE)
              .matches(phoneRegex, INVALID)
              .nullable(true)
              .required(REQUIRED),
            EmailID: Yup.string()
              .email(INVALID_EMAIL)
              .nullable(true)
              .required(REQUIRED),
            AgentEmailID: Yup.string()
              .email(INVALID_EMAIL)
              .nullable(true)
              .required(REQUIRED),
            AgentID: Yup.string().nullable(true).required(REQUIRED),
            CompanyName: Yup.string().required(REQUIRED),
            CompanyGSTNo: Yup.string().required(REQUIRED),
            CompanyPanNo: Yup.string().required(REQUIRED),
            Address: Yup.string().nullable(true).required(REQUIRED),
            CWB: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(true),
            Adults: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(true)
              .required(REQUIRED),
            NoOfDays: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(true)
              .positive(POSITIVE)
              .lessThan(100, NO_OF_DAYS)
              .required(REQUIRED),
            DepartureDate: Yup.string().nullable(true).required(REQUIRED),
            Services: Yup.string().nullable(true).required(REQUIRED),
          })}
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <Form noValidate autoComplete="off">
                <CardHeader>
                  <Row>
                    <Col md="9">
                      <h3>New Quotation</h3>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <div>
                    <Row>
                      <AgentNameDropDown />
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="AgentMobileNo">
                            Agent Mobile No:
                          </Label>
                          <Field
                            name="AgentMobileNo"
                            type="number"
                            className="form-control"
                            id="AgentMobileNo"
                            placeholder="Enter Agent Mobile No"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="AgentEmailID">
                            Agent Email:
                          </Label>
                          <Field
                            name="AgentEmailID"
                            type="email"
                            className="form-control"
                            id="AgentEmailID"
                            placeholder="Enter Agent Email ID"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="CompanyName">
                            Company Name:
                          </Label>
                          <Field
                            name="CompanyName"
                            type="text"
                            className="form-control"
                            id="CompanyName"
                            placeholder="Enter Company Name"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="CompanyGSTNo">
                            GSTIN:
                          </Label>
                          <Field
                            name="CompanyGSTNo"
                            type="text"
                            className="form-control"
                            id="CompanyGSTNo"
                            placeholder="Enter GSTIN"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="CompanyPanNo">
                            PAN Card:
                          </Label>
                          <Field
                            name="CompanyPanNo"
                            type="text"
                            className="form-control"
                            id="CompanyPanNo"
                            placeholder="Enter PAN Card"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="Address">
                            Address:
                          </Label>
                          <Field
                            name="Address"
                            type="text"
                            className="form-control"
                            id="Address"
                            placeholder="Enter Address"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="Name">
                            Guest Name:
                          </Label>
                          <Field
                            name="Name"
                            type="text"
                            className="form-control"
                            id="Name"
                            placeholder="Enter Guest Name"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="MobileNo">
                            Mobile No:
                          </Label>
                          <Field
                            name="MobileNo"
                            type="number"
                            className="form-control"
                            id="MobileNo"
                            placeholder="Enter Mobile No:"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="EmailID">
                            Email:
                          </Label>
                          <Field
                            name="EmailID"
                            type="email"
                            className="form-control"
                            id="EmailID"
                            placeholder="Enter Email"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="SectorID">
                            Sector:
                          </Label>
                          <Field
                            name="SectorID"
                            id="SectorID"
                            options={
                              Array.isArray(sectors)
                                ? sectors.map((sector) => {
                                    return {
                                      value: sector?.ID || "",
                                      label: sector?.SectorName || "",
                                    }
                                  })
                                : []
                            }
                            component={SelectionField}
                          />
                        </div>
                      </Col>
                      {/* <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="TourID">
                            Tour:
                          </Label>
                          <Field
                            name="TourID"
                            id="TourID"
                            options={
                              Array.isArray(tours)
                                ? tours.map((tour) => {
                                    return {
                                      value: tour?.ID || "",
                                      label: tour?.TourName || "",
                                    }
                                  })
                                : []
                            }
                            component={SelectionField}
                          />
                        </div>
                      </Col> */}
                      {/* Date */}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="DepartureDate">
                            Tour Date:
                          </Label>
                          <Date fieldName={"DepartureDate"} />
                        </div>
                      </Col>
                      {/*  */}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="NoOfDays">
                            No of Days:
                          </Label>
                          <Field
                            name="NoOfDays"
                            type="number"
                            className="form-control"
                            id="NoOfDays"
                            placeholder="Enter No of Days"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="PickUpDate">
                            Pickup Date:
                          </Label>
                          <Date fieldName={"PickUpDate"} />
                        </div>
                      </Col>
                      {/*  */}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="PickUpPlace">
                            Pickup Place:
                          </Label>
                          <Field
                            name="PickUpPlace"
                            type="text"
                            className="form-control"
                            id="PickUpPlace"
                            placeholder="Enter Pickup Place"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="PickUpTime">
                            Pickup Time:
                          </Label>
                          <Time fieldName={"PickUpTime"} />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="DropDate">
                            Drop Date:
                          </Label>
                          <Date fieldName={"DropDate"} />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="DropPlace">
                            Drop Place:
                          </Label>
                          <Field
                            name="DropPlace"
                            type="text"
                            className="form-control"
                            id="DropPlace"
                            placeholder="Enter Drop Place"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="DropTime">
                            Drop Time:
                          </Label>
                          <Time fieldName={"DropTime"} />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="ArrivalAt">
                            Arrival At:
                          </Label>
                          <Field
                            name="ArrivalAt"
                            type="text"
                            className="form-control"
                            id="ArrivalAt"
                            placeholder="Enter Arrival At"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="ArrivalRemarks"
                          >
                            Arrival Remarks:
                          </Label>
                          <Field
                            name="ArrivalRemarks"
                            type="text"
                            className="form-control"
                            id="ArrivalRemarks"
                            placeholder="Enter Arrival Remarks"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="DropAt">
                            Drop At:
                          </Label>
                          <Field
                            name="DropAt"
                            type="text"
                            className="form-control"
                            id="DropAt"
                            placeholder="Enter Drop At"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="DropRemarks">
                            Dropped Remarks:
                          </Label>
                          <Field
                            name="DropRemarks"
                            type="text"
                            className="form-control"
                            id="DropRemarks"
                            placeholder="Enter Dropped Remarks"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      {/* Person information */}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="Adults">
                            NoOfAdults({">"}12 yrs.)
                          </Label>
                          <Field
                            name="Adults"
                            type="number"
                            className="form-control"
                            id="Adults"
                            placeholder="Enter Total Adults"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="Child">
                            Child (0-5 yrs):
                          </Label>
                          <Field
                            name="Child"
                            type="number"
                            className="form-control"
                            id="Child"
                            placeholder="Enter Child (0-5)"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="CNB">
                            CNB (6-12 yrs):
                          </Label>
                          <Field
                            name="CNB"
                            type="number"
                            className="form-control"
                            id="CNB"
                            placeholder="Enter CNB (6-12 yrs)"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="CWB">
                            CWB (6-12 yrs):
                          </Label>
                          <Field
                            name="CWB"
                            type="number"
                            className="form-control"
                            id="CWB"
                            placeholder="Enter CWB (6-12 yrs)"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      {/* <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="CWB">
                            Total Child (6-12):
                          </Label>
                          <Field
                            name="CWB"
                            type="number"
                            className="form-control"
                            id="CWB"
                            placeholder="Enter Total Child (6-12)"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "} */}
                      <Total />
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="TotalPax">
                            Total No of Pax:
                          </Label>
                          <Field
                            name="TotalPax"
                            type="text"
                            disabled
                            className="form-control"
                            id="TotalPax"
                            placeholder="Total No of Pax"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="SORoom">
                            No of Single Room:
                          </Label>
                          <Field
                            name="SORoom"
                            type="number"
                            className="form-control"
                            id="SORoom"
                            placeholder="Enter No of Single Room"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="DORoom">
                            No of Double Room:
                          </Label>
                          <Field
                            name="DORoom"
                            type="number"
                            className="form-control"
                            id="DORoom"
                            placeholder="Enter No of Double Room"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="ExtraBedAdults"
                          >
                            No of Extra Bed:
                          </Label>
                          <Field
                            name="ExtraBedAdults"
                            type="number"
                            className="form-control"
                            id="ExtraBedAdults"
                            placeholder="Enter No of Extra Bed"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="TermsAndCondition"
                          >
                            Terms & Condition:
                          </Label>
                          <Field
                            name="TermsAndCondition"
                            type="textarea"
                            className="form-control"
                            id="TermsAndCondition"
                            placeholder="Enter Terms & Condition"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label className="form-label" htmlFor="Services">
                            Services
                          </Label>
                          <Field
                            name="Services"
                            id="Services"
                            options={services}
                            component={SelectionField}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <Row className="mt-2">
                    <Col lg={12}>
                      <hr></hr>
                    </Col>
                    <Col md="12" className="text-end">
                      <Button type="submit" className="success">
                        Save & Next
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            )
          }}
        </Formik>
      </Card>
    </TabPane>
  )
}

export default CreateQuotation

// eslint-disable-next-line react/prop-types
function Date({ fieldName }) {
  const { values, setFieldValue } = useFormikContext()
  const handleDateChange = (ev) => {
    setFieldValue(
      `${fieldName}`,
      Array.isArray(ev) && ev.length
        ? moment(ev[0]).format(STANDARD_INPUT_DATE)
        : ""
    )
  }
  return (
    <React.Fragment>
      <Field>
        {({ field }) => (
          <Flatpickr
            name={`${fieldName}`}
            id={`${fieldName}`}
            {...field}
            value={values[`${fieldName}`]}
            placeholder="Select Date"
            className="form-control"
            options={{
              altInput: true,
              altFormat: "d-m-Y",
              dateFormat: "d-m-Y",
              // minDate:
            }}
            onChange={handleDateChange}
          />
        )}
      </Field>
      <ErrorMessage name={fieldName} component="div" className="text-danger" />
    </React.Fragment>
  )
}

// eslint-disable-next-line react/prop-types
function Time({ fieldName }) {
  const { values, setFieldValue } = useFormikContext()

  const handleDateChange = (ev) => {
    setFieldValue(
      `${fieldName}`,
      Array.isArray(ev) && ev.length ? moment(ev[0]).format("HH:mm") : ""
    )
  }

  return (
    <React.Fragment>
      <Flatpickr
        name={fieldName}
        id={fieldName}
        //{...field}
        value={values[`${fieldName}`]}
        placeholder="Select Time"
        className="form-control"
        options={{
          enableTime: true,
          noCalendar: true,
          altInput: true,
          allowInput: true,
          dateFormat: "H:i",
          showIcon: true,
        }}
        // las la-clock
        // children={

        // }

        onChange={handleDateChange}
      />
      {/* <React.Fragment>
          <a className="input-button" title="toggle" data-toggle>
            <i className="las la-clock"></i>
          </a>
        </React.Fragment>
      </Flatpickr> */}

      <ErrorMessage name={fieldName} component="div" className="text-danger" />
    </React.Fragment>
  )
}

export function TotalSumComponent() {
  const { values, setFieldValue } = useFormikContext()

  const Sum = useCallback(
    (values, subArray) => {
      let value = _.sum(
        _.filter(_.values(_.pick(values, subArray)), function (num) {
          return Number(num)
        })
      )
      return _.round(value)
    },
    [values]
  )

  useMemo(() => {
    setFieldValue("TotalPax", Sum(values, ["Adults", "Child", "CWB", "CNB"]))
  }, [values?.Adults, values?.CWB, values?.CNB, values?.Child])
  return null
}

//

function AgentNameDropDown() {
  const dispatch = useDispatch()
  const { values, setFieldValue } = useFormikContext()
  const { newQuotation } = useSelector((state) => state.Quotation)
  const { agentList } = newQuotation
  const agentValue = useMemo(() => {
    if (Array.isArray(agentList) && agentList.length) {
      let agent = null
      agent = agentList.find((q) => q.AgentID === values?.AgentID)
      if (agent) {
        setFieldValue("AgentID", agent?.AgentID)
        setFieldValue("AgentMobileNo", agent?.AgentMobileNo || "")
        setFieldValue("AgentEmailID", agent?.AgentEmailID || "")
        setFieldValue("CompanyName", agent?.CompanyName || "")
        setFieldValue("Address", agent?.Address || "")
        setFieldValue("CompanyPanNo", agent?.CompanyPanNo || "")
        setFieldValue("CompanyGSTNo", agent?.CompanyGSTNo || "")
      } else {
        setFieldValue("AgentID", 0)
        setFieldValue("AgentMobileNo", "")
        setFieldValue("AgentEmailID", "")
        setFieldValue("CompanyName", "")
        setFieldValue("Address", "")
        setFieldValue("CompanyPanNo", "")
        setFieldValue("CompanyGSTNo", "")
      }
      if (agent) {
        return {
          value: agent?.AgentID || "",
          label: agent?.AgentName || "",
        }
      }
      return null
    }
  }, [agentList, values.AgentID])

  // on Change input
  const onChangeInput = (value) => {
    setFieldValue("AgentName", value?.label)
    setFieldValue("AgentID", _.isNumber(value?.value) ? value?.value : 0)
    if (!_.isNumber(value?.value)) {
      let agentObj = {
        AgentID: 0,
        AgentName: value?.label || "",
        AgentMobileNo: "",
        AgentEmailID: "",
        CompanyName: "",
        Address: "",
        CompanyPanNo: "",
        CompanyGSTNo: "",
      }
      let agentData = [agentObj, ...agentList]
      if (!value?.value) {
        agentData = agentData.filter((q) => q.AgentID !== 0)
      }
      agentData = _.unionBy(agentData, "AgentID")
      dispatch(addAgentInList(agentData))
    }
  }
  return (
    <Col lg={4}>
      <div className="mb-3">
        <Label className="form-label" htmlFor="AgentID">
          Agent Name:
        </Label>
        <CreatableSelect
          value={agentValue}
          // defaultValue={agentValue}
          onChange={(v) => {
            onChangeInput(v)
          }}
          options={
            Array.isArray(agentList)
              ? agentList.map((agent) => {
                  return {
                    value: agent?.AgentID || "",
                    label: agent?.AgentName || "",
                  }
                })
              : []
          }
          name="AgentID"
          placeholder="Enter Agent Name"
          id="AgentID"
          isClearable
        />
        <ErrorMessage
          name={"AgentID"}
          component="div"
          className="text-danger"
        />
      </div>
    </Col>
  )
}

export const Total = React.memo(TotalSumComponent)
