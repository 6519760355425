import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash"
import { findByIdLeadSource, getAllActiveLeadSource, getAllLeadSource, insertLeadSource, updateLeadSource } from "./thunk";


export const leadSourceForm={
    ID:null,
    Source:"",
    IsActive: true,
}

export const initialState = {
    leadSourceDetail:{
        form:leadSourceForm
    },
    leadSources: [],
    error: {},
    loading: false,
}; 

const LeadSourceReducer=createSlice({
    name:"LeadSource",
    initialState,
    reducers:{
        resetForm: (state) => {
            state.leadSourceDetail.form = leadSourceForm;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllActiveLeadSource.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllActiveLeadSource.fulfilled, (state, action) => {
            state.leadSources = action.payload.Data;
            state.loading = false;
        });
        builder.addCase(getAllActiveLeadSource.rejected, (state, action) => {
            state.error = action.payload?.error || null;
            state.loading = false;
        });
        builder.addCase(getAllLeadSource.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(getAllLeadSource.fulfilled,(state,action)=>{
            state.leadSources=action.payload.Data || []
            state.loading=false
        });
        builder.addCase(getAllLeadSource.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(insertLeadSource.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(insertLeadSource.fulfilled,(state)=>{
            state.leadSourceDetail.form=leadSourceForm
            state.loading=false
        });
        builder.addCase(insertLeadSource.rejected, (state,action) => {
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(updateLeadSource.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(updateLeadSource.fulfilled,(state)=>{
            state.leadSourceDetail.form=leadSourceForm
            state.loading=false
        });
        builder.addCase(updateLeadSource.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
       builder.addCase(findByIdLeadSource.pending,(state)=>{
        state.loading=true
       });
       builder.addCase(findByIdLeadSource.fulfilled,(state,action)=>{
        let formObj = action?.payload?.Data

        let formKeys=Object.keys(leadSourceForm)
        formObj = _.pick(formObj,formKeys)
        state.leadSourceDetail.form=formObj
        state.loading=false
       });
       builder.addCase(findByIdLeadSource.rejected,(state,action)=>{
        state.error=action.payload?.error || null
        state.loading=false
       })
    },
})

export const {resetForm}=LeadSourceReducer.actions

export default LeadSourceReducer.reducer;