import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { FAILED } from "../../Components/constants/field";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { toast } from "react-toastify";
import {
  ActiveInActiveCityApi,
  deleteCityApi,
  findByIdCityApi,
  getAllACINCitiesApi,
  getAllCitiesApi,
  getCitiesBySectorIDApi,
  getCitiesByStateIDApi,
  getCityByTourIDApi,
  insertCityApi,
  updateCityApi,
} from "./city.services";

export const getAllCities = createAsyncThunk(
  "Cities/ActiveCities",
  async () => {
    try {
      const response = await getAllCitiesApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getAllACINCities = createAsyncThunk(
  "Cities/AllCities",
  async () => {
    try {
      const response = await getAllACINCitiesApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getCitiesBySectorID = createAsyncThunk(
  "Sectors/FindByID",
  async (SectorID) => {
    try {
      const response = await getCitiesBySectorIDApi(SectorID);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
export const getCityByTourID = createAsyncThunk(
  "Cities/TourWiseCity",
  async (SectorID) => {
    try {
      const response = await getCityByTourIDApi(SectorID);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getCitiesByStateID = createAsyncThunk(
  "Cities/StateWiseCity",
  async (StateID) => {
    try {
      const response = await getCitiesByStateIDApi(StateID);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const findByIdCity = createAsyncThunk(
  "Cities/FindById",
  async (params) => {
    try {
      const response = await findByIdCityApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const insertCity = createAsyncThunk("Cities/Insert", async (data) => {
  try {
    const response = await insertCityApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const updateCity = createAsyncThunk("Cities/Update", async (data) => {
  try {
    const response = await updateCityApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const ActiveInActiveCity = createAsyncThunk(
  "Cities/ActiveInActive",
  async (data) => {
    try {
      const response = await ActiveInActiveCityApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const deleteCity = createAsyncThunk("Cities/Delete", async (data) => {
  try {
    const response = await deleteCityApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});
