import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { FAILED } from "../../Components/constants/field";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { toast } from "react-toastify";
import {
  ActiveInActiveAgentApi,
  deleteAgentApi,
  findByIdAgentApi,
  getAllActiveAgentsApi,
  getAllAgentApi,
  insertAgentApi,
  insertAgentDocumetsApi,
  updateAgentApi,
  updateAgentDocumetsApi,
} from "./agent.services";

export const getAllActiveAgents = createAsyncThunk(
  "Tours/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveAgentsApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getAllAgents = createAsyncThunk("Agents/FindAll", async () => {
  try {
    const response = await getAllAgentApi();
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const findByIdAgent = createAsyncThunk(
  "Agents/FindById",
  async (params) => {
    try {
      const response = await findByIdAgentApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const insertAgent = createAsyncThunk("Agents/Insert", async (data) => {
  try {
    const response = await insertAgentApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const insertAgentDocument = createAsyncThunk(
  "Agents/InsertDocumets",
  async (data) => {
    try {
      const response = await insertAgentDocumetsApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const updateAgent = createAsyncThunk("Agents/Update", async (data) => {
  try {
    const response = await updateAgentApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const updateAgentDocument = createAsyncThunk(
  "Agents/UpdateDocumets",
  async (data) => {
    try {
      const response = await updateAgentDocumetsApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const ActiveInActiveAgent = createAsyncThunk(
  "Agents/ActiveInActive",
  async (data) => {
    try {
      const response = await ActiveInActiveAgentApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const deleteAgent = createAsyncThunk("Agents/Delete", async (data) => {
  try {
    const response = await deleteAgentApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});
