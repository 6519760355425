import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllPaymentReceiptApi = (params) =>
  api.create(url.GET_ALL_PAYMENTRECEIPT, params);

export const findByBookingNoPaymentReceiptApi = (params) =>
  api.create(url.FIND_BY_BOOKINGNO_PAYMENTRECEIPT_URL, params);

export const findByIdPaymentReceiptApi = (params) =>
  api.create(url.FIND_BY_ID_PAYMENTRECEIPT_URL, params);

export const insertPaymentReceiptApi = (data) =>
  api.create(url.INSERT_PAYMENTRECEIPT_URL, data);

export const updatePaymentReceiptApi = (data) =>
  api.create(url.UPDATE_PAYMENTRECEIPT_URL, data);

// export const ActiveInActivePaymentReceiptApi = (data) =>
//   api.create(url.ACTIVE_INACTIVE_PAYMENTRECEIPT_URL, data);

export const deletePaymentReceiptApi = (data) =>
  api.create(url.DELETE_PAYMENTRECEIPT_URL, data);
