import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";
import { toast } from "react-toastify";
import {
  deletePaymentReceiptApi,
  findByBookingNoPaymentReceiptApi,
  findByIdPaymentReceiptApi,
  getAllPaymentReceiptApi,
  insertPaymentReceiptApi,
  updatePaymentReceiptApi,
} from "./payment.service";

export const getAllPaymentReceipt = createAsyncThunk(
  "PaymentReceipts/FindAll",
  async (params) => {
    try {
      const response = await getAllPaymentReceiptApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const findByBookingNoPaymentReceipt = createAsyncThunk(
  "PaymentReceipts/FindByBookingNo",
  async (params) => {
    try {
      const response = await findByBookingNoPaymentReceiptApi(params);
      if (response?.Status !== 200) {
        // toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const findByIdPaymentReceipt = createAsyncThunk(
  "PaymentReceipts/FindById",
  async (params) => {
    try {
      const response = await findByIdPaymentReceiptApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const insertPaymentReceipt = createAsyncThunk(
  "PaymentReceipts/Insert",
  async (data) => {
    try {
      const response = await insertPaymentReceiptApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const updatePaymentReceipt = createAsyncThunk(
  "PaymentReceipts/Update",
  async (data) => {
    try {
      const response = await updatePaymentReceiptApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

// export const ActiveInActivePaymentReceipt = createAsyncThunk(
//   "PaymentReceipts/ActiveInActive",
//   async (data) => {
//     try {
//       const response = await ActiveInActivePaymentReceiptApi(data)
//       if (response?.Status !== 200) {
//         toast.error(response?.Details, { autoClose: AUTO_CLOSE });
//         return response;
//       } else {
//         toast.success(response?.Details, { autoClose: AUTO_CLOSE });
//         return response;
//       }
//     } catch (error) {
//       toast.error(FAILED, { autoClose: AUTO_CLOSE });
//       return error
//     }
//   }
// )

export const deletePaymentReceipt = createAsyncThunk(
  "PaymentReceipts/Delete",
  async (data) => {
    try {
      const response = await deletePaymentReceiptApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);
