import moment from "moment";
import { STANDARD_INPUT_DATE } from "../Components/constants/field";

// Returns an array of dates between the two dates
export const enumerateDaysBetweenDates = (startDate, endDate) => {
    startDate = moment(startDate);
    endDate = moment(endDate);

    var now = startDate,
        dates = [];

    while (now.isBefore(endDate) || now.isSame(endDate)) {
        dates.push(now.format(STANDARD_INPUT_DATE));
        now.add(1, "days");
    }
    return dates;
};

export const WeekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
