import { createSlice } from "@reduxjs/toolkit"
import { getAllActiveBranches } from "./thunk";


export const initialState = {
    branches: [],
    activeBranches: [],
    error: {},
    loading: false,
  }

  const BranchReducer = createSlice({
    name: "Branch",
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        builder.addCase(getAllActiveBranches.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllActiveBranches.fulfilled, (state, action) => {
            state.activeBranches = action.payload.Data;
            state.loading = false;
        });
        builder.addCase(getAllActiveBranches.rejected, (state, action) => {
            state.error = action.payload?.error || null;
            state.loading = false;
        });
    },
});

export default BranchReducer.reducer;
