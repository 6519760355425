import { createSlice } from "@reduxjs/toolkit"

export const emptyRoom = {
  ID: 0,
  RoomNo: 1,
  Adults: 0,
  CWB: 0,
  CNB: 0,
}

export const autoQuotationDetails = {
  TourID: "",
  SectorID: "",
  SubSectorID: "",
  Name: "",
  MobileNo: "",
  EmailID: "",
  DepartureDate: "",
  Adults: 0,
  CWB: 0,
  CNB: 0,
  HotelCategory: "",
  VehicleTypeID: "",
  MealPlanID: "",
  SessionID: "",
  DeviceType: "",
  RoomList: [emptyRoom],
}

export const initialState = {
  autoQuotationDetails: {
    form: autoQuotationDetails,
  },
  modal: {
    modalShow: false,
    modalData: {},
  },
  loading: false,
}

const AutoQuotationReducer = createSlice({
  name: "AutoQuotation",
  initialState,
  reducers: {
    showModal: (state, action) => {
      state.modal.modalShow = action?.payload?.modalShow || false
      state.modal.modalData = action?.payload?.data || {}
    },
  },
  extraReducers: () => {},
})

export const { showModal } = AutoQuotationReducer.actions

export default AutoQuotationReducer.reducer
