import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import axios from "axios";

const api = new APIClient();

export const getItineraryByCityIDApi = (CityID) =>
  api.create(url.GET_ITINERARY_BY_CITY_ID, { CityID });

export const getItinerariesApi = () => api.get(url.GET_ALL_ACTIVE_ITINERARY);

export const getAllActiveItineraryFacilitiesApi = () =>
  api.get(url.GET_ALL_ACTIVE_ITINERARY_FACILITIES);

export const getAllItineraryApi = () => api.get(url.GET_ALL_ITINERARY);

export const findByIdItineraryApi = (params) =>
  api.get(url.FIND_BY_ID_ITINERARY_URL, params);

export const insertItineraryApi = (data) =>
  axios.post(url.INSERT_ITINERARY_URL, data, {
    headers: { "content-type": "multipart/form-data" },
  });

export const updateItineraryApi = (data) =>
  axios.post(url.UPDATE_ITINERARY_URL, data, {
    headers: { "content-type": "multipart/form-data" },
  });

export const ActiveInActiveItineraryApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_ITINERARY_URL, data);

export const deleteItineraryApi = (data) => api.create(url.DELETE_ITINERARY_URL, data);
