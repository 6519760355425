import {
  findByIdTourFacilities,
  getAllActiveTourFacilities,
  getAllTourFacilities,
  insertTourFacilities,
  updateTourFacilities,
} from "./thunk";
import _ from "lodash";
const { createSlice } = require("@reduxjs/toolkit");

export const tourfacilityForm = {
  ID: null,
  Name: "",
  FacilityImage: "",
  IsActive: true,
};

export const initialState = {
  tourfacilityDetail: {
    form: tourfacilityForm,
  },
  tourfacilities: [],
  error: {},
  loading: false,
};

const TourFacilitieReducer = createSlice({
  name: "TourFacility",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.tourfacilityDetail.form = tourfacilityForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTourFacilities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllTourFacilities.fulfilled, (state, action) => {
      state.tourfacilities = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllTourFacilities.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
    builder.addCase(getAllActiveTourFacilities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllActiveTourFacilities.fulfilled, (state, action) => {
      state.tourfacilities = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllActiveTourFacilities.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
    builder.addCase(insertTourFacilities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(insertTourFacilities.fulfilled, (state) => {
      state.tourfacilityDetail.form = tourfacilityForm;
      state.loading = false;
    });
    builder.addCase(insertTourFacilities.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
    builder.addCase(updateTourFacilities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateTourFacilities.fulfilled, (state) => {
      state.tourfacilityDetail.form = tourfacilityForm;
      state.loading = false;
    });
    builder.addCase(updateTourFacilities.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
    builder.addCase(findByIdTourFacilities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findByIdTourFacilities.fulfilled, (state, action) => {
      let formObj = action?.payload?.Data;

      let formKeys = Object.keys(tourfacilityForm);
      formObj = _.pick(formObj, formKeys);
      state.tourfacilityDetail.form = formObj;
      state.loading = false;
    });
  },
});

export const { resetForm } = TourFacilitieReducer.actions;
export default TourFacilitieReducer.reducer;
