import { Field, Form, Formik } from "formik"
import React, { useState } from "react"
import {
  CONVERT_BOOL,
  // INVALID_EMAIL,
  // INVALID_PHONE,
  // REQUIRED,
  SelectionField,
  TextField,
  // phoneRegex,
} from "../../Components/constants/field"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  Table,
} from "reactstrap"

import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import { useParams } from "react-router-dom"
import {
  ActiveInActiveHotelStatus,
  createHotelContact,
  getAllHotelContact,
  getHotelContactById,
} from "../../slices/thunks"
import { useEffect } from "react"
import Loader from "../../Components/Common/Loader"
import DeleteModal from "../../Components/Common/DeleteModal"
import Switch from "../../Components/Common/Switch"
const HotelContact = () => {
  const dispatch = useDispatch()
  const { ID } = useParams()
  const {
    form,
    loading: hotelLoading,
    hotelContactList,
  } = useSelector((state) => state.Hotel.hotelContact)

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  const [reload, setReload] = useState(false)
  //

  useEffect(() => {
    if (ID) {
      let obj = {
        HotelID: ID,
      }
      dispatch(getAllHotelContact(obj))
    }
  }, [ID, reload])

  //
  const handleSubmit = (values, { resetForm }) => {
    let obj = {
      ...values,
    }
    if (ID) {
      obj.HotelID = +ID
    }
    if (obj?.IsActive) {
      obj.IsActive = CONVERT_BOOL.test(obj.IsActive)
    }

    if (obj.ID) {
      obj["OperationType"] = 4
      obj.ID = +obj.ID
    } else {
      delete obj.ID
      obj["OperationType"] = 3
      // dispatch(createHotelContact(obj)).then((res) => {
      //   if (res.payload && res.payload.Status === 200) {
      //     resetForm()
      //   }
      // })
    }
    dispatch(createHotelContact(obj)).then((res) => {
      setReload(!reload)
      if (res.payload && res.payload.Status === 200) {
        resetForm()
      }
    })
  }

  const handleChange = (data) => {
    let obj = { ID: +data.ID, IsActive: !data.IsActive, OperationType: 14 }

    // setChecked(!checked)
    dispatch(ActiveInActiveHotelStatus(obj)).then(() => {
      setReload(!reload)
    })
  }

  const editContact = (contact) => {
    let obj = {
      ID: contact?.ID,
    }
    dispatch(getHotelContactById(obj))
  }

  const onClickDelete = (data) => {
    setDeleteModal(true)
    setDeleteData(data)
  }

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
      HotelID: +deleteData?.HotelID,
      OperationType: 8,
    }
    dispatch(createHotelContact(obj)).then((res) => {
      setReload(!reload)
      if (res.payload && res.payload.Status === 200) {
        setDeleteModal(false)
      }
    })
  }
  return (
    <Col xl={12}>
      <Card color="dark" outline>
        <CardHeader className="bg-light">
          <h4 className="card-title mb-0">Hotel Contacts</h4>
        </CardHeader>
        <CardBody>
          <Row>
            <Col lg={12}>
              <Formik
                initialValues={form}
                enableReinitialize={true}
                validationSchema={Yup.object({
                  // ContactPersonName: Yup.string().required(REQUIRED),
                  // ContactPersonMobileNo: Yup.string()
                  //   .typeError(INVALID_PHONE)
                  //   .matches(phoneRegex, "Invalid phone")
                  //   .required(REQUIRED),
                  // ContactPersonEmailID: Yup.string()
                  //   .required(REQUIRED)
                  //   .email(INVALID_EMAIL),
                })}
                onSubmit={handleSubmit}
              >
                <Form noValidate autoComplete="off">
                  <div>
                    <Row>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="Contact-personName"
                          >
                            Contact Name
                          </Label>
                          <Field
                            name="ContactPersonName"
                            type="text"
                            className="form-control"
                            id="Contact-personName"
                            placeholder="Enter Name"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="contact-personMobile-no"
                          >
                            Mobile No
                          </Label>
                          <Field
                            name="ContactPersonMobileNo"
                            type="text"
                            className="form-control"
                            id="contact-personMobile-no"
                            placeholder="Enter Mobile No."
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="contact-person-email-id"
                          >
                            Email
                          </Label>
                          <Field
                            name="ContactPersonEmailID"
                            type="email"
                            className="form-control"
                            id="contact-person-email-id"
                            placeholder="Enter Email"
                            component={TextField}
                          />
                        </div>
                      </Col>{" "}
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="contact-person-designation"
                          >
                            Designation
                          </Label>
                          <Field
                            name="ContactPersonDesignation"
                            type="text"
                            className="form-control"
                            id="contact-person-designation"
                            placeholder="Enter Designation"
                            component={TextField}
                          />
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className="mb-3">
                          <Label
                            className="form-label"
                            htmlFor="contact-status"
                          >
                            Status
                          </Label>
                          <Field
                            name="IsActive"
                            id="contact-status"
                            options={[
                              {
                                label: "Active",
                                value: true,
                              },
                              {
                                label: "Inactive",
                                value: false,
                              },
                            ]}
                            component={SelectionField}
                          />
                        </div>
                      </Col>
                      <Col lg={4} className="mt-4 text-end">
                        <Button className="success">Add</Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Formik>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col lg={12}>
              <div className="table-responsive mt-2">
                <Table
                  className="align-middle table-nowrap mb-0"
                  striped
                  responsive
                  hover
                >
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      {/* <th scope="col">Hotel Number</th> */}
                      <th scope="col">Mobile no. </th>
                      <th scope="col">Email ID</th>
                      <th scope="col">Designation</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!hotelLoading &&
                    Array.isArray(hotelContactList) &&
                    hotelContactList.length ? (
                      hotelContactList.map((contact, i) => {
                        return (
                          <tr key={i}>
                            <td scope="row">
                              {contact?.ContactPersonName || ""}
                            </td>
                            <td scope="row">
                              {contact?.ContactPersonMobileNo || ""}
                            </td>{" "}
                            <td scope="row">
                              {contact?.ContactPersonEmailID || ""}
                            </td>{" "}
                            <td scope="row">
                              {contact?.ContactPersonDesignation || ""}
                            </td>
                            <td scope="row">
                              <Switch
                                onChange={() => handleChange(contact)}
                                checked={contact?.IsActive}
                              />
                            </td>
                            <td scope="row">
                              <div
                                className="hstack gap-3 flex-wrap "
                                role="button"
                              >
                                <i
                                  className="ri-edit-line text-success fs-18"
                                  //
                                  onClick={() => editContact(contact)}
                                ></i>
                                <div className="gap-3 " role="button">
                                  <i
                                    className="ri-delete-bin-fill text-danger fs-18"
                                    onClick={() => onClickDelete(contact)}
                                  ></i>
                                </div>
                              </div>

                              {/* <Button size="sm">
                                <i className="bx bxs-pencil bx-xs"></i>
                                <i className="las la-pencil-alt la-lg "></i>
                              </Button> */}
                              {/* <Button color="danger" className="mx-1" size="sm">
                                <i className="bx bxs-trash bx-xs"></i>
                              </Button> */}
                            </td>
                          </tr>
                        )
                      })
                    ) : hotelLoading ? (
                      <tr>
                        <td>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No Hotel Contact found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>

            <DeleteModal
              show={deleteModal}
              onCloseClick={() => setDeleteModal(false)}
              onDeleteClick={() => onDeleted()}
            />
          </Row>
        </CardBody>
      </Card>
    </Col>
  )
}

export default HotelContact
