import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { FAILED } from "../../Components/constants/field";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { toast } from "react-toastify";
import {
  ActiveInActiveSubSectorApi,
  deleteSubSectorApi,
  findByIdSubSectorApi,
  getAllActiveSubSectorApi,
  getAllSubSectorApi,
  getSubSectorBySectorIDApi,
  insertSubSectorApi,
  updateSubSectorApi,
} from "./subsector.service";

export const getSubSectorBySectorID = createAsyncThunk(
  "SubSectors/FindBySectorID",
  async (SubSectorID) => {
    try {
      const response = await getSubSectorBySectorIDApi(SubSectorID);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getAllActiveSubSector = createAsyncThunk(
  "SubSectors/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveSubSectorApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getAllSubSector = createAsyncThunk(
  "SubSectors/FindAll",
  async () => {
    try {
      const response = await getAllSubSectorApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const findByIdSubSector = createAsyncThunk(
  "SubSectors/FindByID",
  async (params) => {
    try {
      const response = await findByIdSubSectorApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const insertSubSector = createAsyncThunk(
  "SubSectors/Insert",
  async (data) => {
    try {
      const response = await insertSubSectorApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const updateSubSector = createAsyncThunk(
  "SubSectors/Update",
  async (data) => {
    try {
      const response = await updateSubSectorApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const ActiveInActiveSubSector = createAsyncThunk(
  "SubSectors/ActiveInActive",
  async (data) => {
    try {
      const response = await ActiveInActiveSubSectorApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const deleteSubSector = createAsyncThunk(
  "SubSectors/Delete",
  async (data) => {
    try {
      const response = await deleteSubSectorApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
