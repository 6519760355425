import { APIClient } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"
const api = new APIClient()
export const getActiveGstApi = (payload) =>
  api.get(url.GET_ALL_GST_ACTIVE, payload)

  export const getAllGstApi=()=>api.get(url.GET_ALL_GST);

  
  export const findByIdGstApi = (params) =>
  api.create(url.FIND_BY_ID_GST, params);

  
export const insertGstApi = (data) => api.create(url.INSERT_GST, data);

export const updateGstApi = (data) => api.create(url.UPDATE_GST, data);

export const deleteGstApi = (data) => api.create(url.DELETE_GST, data);


export const ActiveInActiveGstApi = (data) =>
api.create(url.ACTIVE_INACTIVE_GST, data);
