import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllActiveCanelationPolicyApi = () =>
    api.get(url.GET_CANCELATION_POLICY);

    export const getAllCanelationPolicyApi=()=>api.get(url.FIND_ALL_CANCELLATION_POLICY);

    export const findByIdCanelationPolicyApi = (params) =>
    api.create(url.FIND_BY_ID_CANCELLATION_POLICY, params);

    
export const insertCanelationPolicyApi = (data) => api.create(url.INSERT_CANCELLATION_POLICY, data);

export const updateCanelationPolicyApi = (data) => api.create(url.UPDATE_CANCELLATION_POLICY, data);

export const deleteCanelationPolicyApi = (data) => api.create(url.DELETE_CANCELLATION_POLICY, data);


export const ActiveInActiveCanelationPolicyApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_CANCELLATION_POLICY, data);