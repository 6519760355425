import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import {
  INVALID,
  INVALID_EMAIL,
  INVALID_PHONE,
  MultiSelectFieldV2,
  REQUIRED,
  SelectionField,
  SingleFileField,
  TextField,
  phoneRegex,
} from "../../Components/constants/field";
import { getAllActiveSectors } from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import * as Yup from "yup";
import { getAllActiveBranches } from "../../slices/branch/thunk";
import { findByIdUser, insertUser, updateUser } from "../../slices/user/thunk";

const CreateUser = () => {
  const history = useNavigate();
  const { ID } = useParams();
  const dispatch = useDispatch();
  const { activeBranches, loading: BranchLoading } = useSelector(
    (state) => state.Branch
  );
  const { sectors, loading: SectorLoading } = useSelector(
    (state) => state.Sector
  );
  const {
    userDetail: { form },
  } = useSelector((state) => state.User);

  useEffect(() => {
    dispatch(getAllActiveBranches());
    dispatch(getAllActiveSectors());
  }, []);

  useEffect(() => {
    if (ID) {
      let params = {
        ID: ID,
      };
      dispatch(findByIdUser(params));
    }
  }, [ID]);

  const handleSubmit = (values) => {
    let user = new FormData();

    Object.keys(values).map((key) => {
      if (key === "AadharCard" || key === "PanCard" || key === "License") {
        user.append(key, values[key][0]);
      } else if (key === "SectorID") {
        values[key] = values[key]
          .map((val) => {
            if (typeof val === "object") {
              return val.value;
            }
            return val;
          })
          .join(",");
        user.append(key, values[key]);
      } else {
        user.append(key, values[key]);
      }
    });

    if (ID) {
      if (typeof user.get("PanCard") === "string") {
        user.delete("PanCard");
        user.append("PanCard", "");
      }
      if (typeof user.get("AadharCard") === "string") {
        user.delete("AadharCard");
        user.append("AadharCard", "");
      }
      if (typeof user.get("License") === "string") {
        user.delete("License");
        user.append("License", "");
      }
      user.delete("UpdatedBy");
      user.append("ID", ID);
      dispatch(updateUser(user)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/users`);
        }
      });
    } else {
      user.delete("ID");
      dispatch(insertUser(user)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/users`);
        }
      });
    }
  };

  if (BranchLoading || SectorLoading) {
    return <Loader />;
  }
  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <BreadCrumb title="User" pageTitle="User" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">
                      {ID ? "Update User" : " Create User"}
                    </h4>
                  </Col>
                  <Col md="1">
                    <Link to="/users">
                      <Button color="primary" block>
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={form}
                      validationSchema={Yup.object({
                        FirstName: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        LastName: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        MobileNo: Yup.string()
                          .typeError(INVALID_PHONE)
                          .matches(phoneRegex, INVALID)
                          .nullable(true)
                          .required(REQUIRED),
                        EmailID: Yup.string()
                          .email(INVALID_EMAIL)
                          .nullable(true)
                          .required(REQUIRED),
                        AlternateEmailID: Yup.string()
                          .email(INVALID_EMAIL)
                          .nullable(true),
                        Password: !ID
                          ? Yup.string()
                              .min(8, "Password must be at least 8 characters")
                              .matches(
                                RegExp("(.*[a-z].*)"),
                                "At least lowercase letter"
                              )
                              .matches(
                                RegExp("(.*[A-Z].*)"),
                                "At least uppercase letter"
                              )
                              .matches(
                                RegExp("(.*[0-9].*)"),
                                "At least one number"
                              )
                              .required(REQUIRED)
                          : Yup.string(),
                        CPassword: !ID
                          ? Yup.string()
                              .when("Password", {
                                is: (val) =>
                                  val && val.length > 0 ? true : false,
                                then: Yup.string().oneOf(
                                  [Yup.ref("Password")],
                                  "Both password need to be the same"
                                ),
                              })
                              .required(REQUIRED)
                          : Yup.string(),
                        BranchID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                        SectorID: Yup.array()
                          .min(1, REQUIRED)
                          .required(REQUIRED),
                        UserTypeID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {() => {
                        return (
                          <Form noValidate autoComplete="off">
                            <div>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="FirstName"
                                    >
                                      First Name:
                                    </Label>
                                    <Field
                                      name="FirstName"
                                      className="form-control"
                                      id="FirstName"
                                      placeholder="Enter First Name"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="LastName"
                                    >
                                      Last Name:
                                    </Label>
                                    <Field
                                      name="LastName"
                                      className="form-control"
                                      id="LastName"
                                      placeholder="Enter Last Name"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="MobileNo"
                                    >
                                      Contact No:
                                    </Label>
                                    <Field
                                      name="MobileNo"
                                      type="number"
                                      className="form-control"
                                      id="MobileNo"
                                      placeholder="Enter Contact No"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="AlternateMobileNo"
                                    >
                                      Alternate Contact No:
                                    </Label>
                                    <Field
                                      name="AlternateMobileNo"
                                      type="number"
                                      className="form-control"
                                      id="AlternateMobileNo"
                                      placeholder="Enter Alternate Contact No"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="EmailID"
                                    >
                                      Email ID:
                                    </Label>
                                    <Field
                                      name="EmailID"
                                      type="email"
                                      className="form-control"
                                      id="EmailID"
                                      placeholder="Enter Email"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="AlternateEmailID"
                                    >
                                      Alternate Email ID:
                                    </Label>
                                    <Field
                                      name="AlternateEmailID"
                                      type="email"
                                      className="form-control"
                                      id="AlternateEmailID"
                                      placeholder="Enter Alternate Email ID"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                {!ID && (
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="Password"
                                      >
                                        Password:
                                      </Label>
                                      <Field
                                        name="Password"
                                        type="password"
                                        className="form-control"
                                        id="Password"
                                        placeholder="Enter Password"
                                        component={TextField}
                                      />
                                    </div>
                                  </Col>
                                )}
                                {!ID && (
                                  <Col lg={6}>
                                    <div className="mb-3">
                                      <Label
                                        className="form-label"
                                        htmlFor="CPassword"
                                      >
                                        Confirm Password:
                                      </Label>
                                      <Field
                                        name="CPassword"
                                        type="password"
                                        className="form-control"
                                        id="CPassword"
                                        placeholder="Enter Confirm Password"
                                        component={TextField}
                                      />
                                    </div>
                                  </Col>
                                )}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="BranchID"
                                    >
                                      Branch:
                                    </Label>
                                    <Field
                                      name="BranchID"
                                      id="BranchID"
                                      options={
                                        Array.isArray(activeBranches) &&
                                        activeBranches.map((branch) => {
                                          return {
                                            value: Number(branch.ID),
                                            label: branch.BranchName,
                                          };
                                        })
                                      }
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="SectorID"
                                    >
                                      Sector:
                                    </Label>
                                    <Field
                                      name="SectorID"
                                      id="SectorID"
                                      options={
                                        Array.isArray(sectors) &&
                                        sectors.map((sector) => {
                                          return {
                                            value: Number(sector.ID),
                                            label: sector.SectorName,
                                          };
                                        })
                                      }
                                      component={MultiSelectFieldV2}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="UserTypeID"
                                    >
                                      User Type:
                                    </Label>
                                    <Field
                                      name="UserTypeID"
                                      id="UserTypeID"
                                      options={[
                                        {
                                          label: "User",
                                          value: 3,
                                        },
                                        {
                                          label: "Admin",
                                          value: 2,
                                        },
                                        {
                                          label: "SuperAdmin",
                                          value: 1,
                                        },
                                      ]}
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="IsActive"
                                    >
                                      Status:
                                    </Label>
                                    <Field
                                      name="IsActive"
                                      id="IsActive"
                                      options={[
                                        {
                                          label: "Active",
                                          value: true,
                                        },
                                        {
                                          label: "Inactive",
                                          value: false,
                                        },
                                      ]}
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={4}>
                                  <Label
                                    className="form-label"
                                    htmlFor="AadharCard"
                                  >
                                    Aadhar Card:
                                  </Label>
                                  <Field
                                    type="file"
                                    name="AadharCard"
                                    id="AadharCard"
                                    maxFiles={1}
                                    component={SingleFileField}
                                  />
                                </Col>{" "}
                                <Col lg={4}>
                                  <Label
                                    className="form-label"
                                    htmlFor="PanCard"
                                  >
                                    Pan Card:
                                  </Label>
                                  <Field
                                    type="file"
                                    name="PanCard"
                                    id="PanCard"
                                    maxFiles={1}
                                    component={SingleFileField}
                                  />
                                </Col>{" "}
                                <Col lg={4}>
                                  <Label
                                    className="form-label"
                                    htmlFor="License"
                                  >
                                    License:
                                  </Label>
                                  <Field
                                    type="file"
                                    name="License"
                                    id="License"
                                    maxFiles={1}
                                    component={SingleFileField}
                                  />
                                </Col>{" "}
                              </Row>

                              <Row className="mt-2">
                                <Col lg={12}>
                                  <hr></hr>
                                </Col>
                                <Col md="12" className="text-end">
                                  <Button type="submit" className="success">
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateUser;
