import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import {
  ActiveInActiveStateApi,
  deleteStateApi,
  findByIdStateApi,
  getAllActiveStatesApi,
  getAllStateApi,
  getStatesByCountryIdApi,
  insertStateApi,
  updateStateApi,
} from "./state.services";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { toast } from "react-toastify";

export const getAllState = createAsyncThunk("States/FindAll", async () => {
  try {
    const response = await getAllStateApi();
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
});

export const findByIdState = createAsyncThunk(
  "States/FindByID",
  async (params) => {
    try {
      const response = await findByIdStateApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getAllActiveStates = createAsyncThunk(
  "States/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveStatesApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getStatesByCountryID = createAsyncThunk(
  "States/CountryWiseStates",
  async (CountryID) => {
    try {
      const response = await getStatesByCountryIdApi(CountryID);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const insertState = createAsyncThunk("States/Insert", async (data) => {
  try {
    const response = await insertStateApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    return error;
  }
});

export const updateState = createAsyncThunk("States/Update", async (data) => {
  try {
    const response = await updateStateApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    return error;
  }
});

export const ActiveInActiveState = createAsyncThunk(
  "States/Active-InActive",
  async (data) => {
    try {
      const response = await ActiveInActiveStateApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const deleteState = createAsyncThunk("States/Delete", async (data) => {
  try {
    const response = await deleteStateApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    return error;
  }
});
