import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import {
  findByBookingNoPaymentReceipt,
  findByIdPaymentReceipt,
  getAllPaymentReceipt,
  insertPaymentReceipt,
  updatePaymentReceipt,
} from "./thunk";

export const PaymentReceiptForm = {
  ID: null,
  IsActive: true,
  BookingNo: "",
  Name: "",
  PaymentDate: "",
  PaymentType: "",
  Amount: null,
  BankName: "",
  TransactionNo: "",
  TransactionDate: "",
  ChequeNo: "",
  ChequeDate: "",
  Remarks: "",
};

export const PaymentReceiptData = {
  Name: "",
  Amount: null,
};

export const initialState = {
  paymentReceiptDetail: {
    form: PaymentReceiptForm,
  },
  paymentReceiptData: PaymentReceiptData,
  paymentReceipts: [],
  error: {},
  loading: false,
};

const PaymentReceiptReducer = createSlice({
  name: "PaymentReceipt",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.paymentReceiptDetail.form = PaymentReceiptForm;
    },
    resetNameData: (state) => {
      state.paymentReceiptData = PaymentReceiptData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllPaymentReceipt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPaymentReceipt.fulfilled, (state, action) => {
      state.paymentReceipts = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllPaymentReceipt.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });

    // Insert PaymentReceipt
    builder.addCase(insertPaymentReceipt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(insertPaymentReceipt.fulfilled, (state) => {
      state.paymentReceiptDetail.form = PaymentReceiptForm;
      state.loading = false;
    });
    builder.addCase(insertPaymentReceipt.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });

    // Update PaymentReceipt
    builder.addCase(updatePaymentReceipt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePaymentReceipt.fulfilled, (state) => {
      state.paymentReceiptDetail.form = PaymentReceiptForm;
      state.loading = false;
    });
    builder.addCase(updatePaymentReceipt.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });

    // Get By Id PaymentReceipt
    builder.addCase(findByIdPaymentReceipt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findByIdPaymentReceipt.fulfilled, (state, action) => {
      let formObj = action?.payload?.Data;

      let formKeys = Object.keys(PaymentReceiptForm);
      formObj = _.pick(formObj, formKeys);

      state.paymentReceiptDetail.form = formObj;

      state.loading = false;
    });
    builder.addCase(findByIdPaymentReceipt.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });

    // Get By Booking No PaymentReceipt
    builder.addCase(findByBookingNoPaymentReceipt.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      findByBookingNoPaymentReceipt.fulfilled,
      (state, action) => {
        let formObj = action?.payload?.Data;

        let formKeysToPick = ["Name", "PendingAmount"];
        let pickedFormObj = _.pick(formObj, formKeysToPick);

        state.paymentReceiptData.Name = pickedFormObj.Name
          ? pickedFormObj.Name
          : "";
        state.paymentReceiptData.Amount = pickedFormObj.PendingAmount
          ? pickedFormObj.PendingAmount
          : null;

        state.loading = false;
      }
    );
    builder.addCase(findByBookingNoPaymentReceipt.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.loading = false;
    });
  },
});

export const { resetForm, resetNameData } = PaymentReceiptReducer.actions;

export default PaymentReceiptReducer.reducer;
