import React from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  TabContent,
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import classnames from "classnames"
import CreateQuotation from "./CreateQuotation"
import { ActiveTab } from "../../slices/quotation/reducer"
import { useDispatch, useSelector } from "react-redux"
import Accommodation from "./Accommodation"
import Transportation from "./Transportation"
import Others from "./Others"
import { Link, useLocation, useNavigate } from "react-router-dom"
import _ from "lodash"
import PreviewQuotation from "./PreviewQuotation"
const QuotationTab = () => {
  const history = useNavigate()
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const qID = urlParams.get("q")
  const dispatch = useDispatch()
  const {
    activeTab,
    activeTabsList,
    newQuotation: { form },
  } = useSelector((state) => state.Quotation)
  const QuotationTabs = [
    "Quotation",
    "Accommodation",
    "Transportation",
    "Others",
    "Quotation View",
  ]

  const renderQuotationStep = (active) => {
    switch (active) {
      case 0:
        return <CreateQuotation />
      case 1:
        return <Accommodation />
      case 2:
        return <Transportation />
      case 3:
        return <Others />
      case 4:
        return <PreviewQuotation />
      default:
        return <CreateQuotation />
    }
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Quotation"
          pageTitle="Quotations"
          link={"/quotations"}
        />
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">
                      {qID ? "Update Quotation" : "Create Quotation"}
                    </h4>
                  </Col>
                  <Col md="1">
                    <Link to="/quotations">
                      <Button color="primary" block>
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="form-steps">
                <div className="step-arrow-nav mb-4">
                  <Nav
                    className="nav-pills custom-nav nav-justified"
                    role="tablist"
                  >
                    {QuotationTabs.map((tab, index) => {
                      return (
                        <NavItem key={index} disabled>
                          <NavLink
                            href="#"
                            disabled
                            id="steparrow-gen-info-tab"
                            className={classnames({
                              active: activeTab === index,
                              done: activeTab <= QuotationTabs.length,
                            })}
                            onClick={() => {
                              dispatch(ActiveTab(index))
                            }}
                          >
                            {tab}
                          </NavLink>
                        </NavItem>
                      )
                    })}
                  </Nav>
                </div>
                <TabContent activeTab={activeTab}>
                  {renderQuotationStep(activeTab)}
                </TabContent>
                <Row>
                  <Col md={12}>
                    <Pagination className="d-flex justify-content-end">
                      {Array.isArray(activeTabsList) &&
                      _.isNumber(
                        activeTabsList[
                          _.findIndex(activeTabsList, (q) => q === activeTab) -
                            1
                        ]
                      ) ? (
                        <PaginationItem
                          active
                          onClick={() => {
                            dispatch(
                              ActiveTab(
                                activeTabsList[
                                  _.findIndex(
                                    activeTabsList,
                                    (q) => q === activeTab
                                  ) - 1
                                ]
                              )
                            )
                            if (form?.ID) {
                              history(
                                `${location?.pathname}?q=${form?.ID || ""}`
                              )
                            }
                          }}
                        >
                          <PaginationLink>Prev</PaginationLink>
                        </PaginationItem>
                      ) : null}

                      {Array.isArray(activeTabsList) &&
                      _.isNumber(
                        activeTabsList[
                          _.findIndex(activeTabsList, (q) => q === activeTab) +
                            1
                        ]
                      ) ? (
                        <PaginationItem
                          active
                          className="ms-1"
                          onClick={() => {
                            dispatch(
                              ActiveTab(
                                activeTabsList[
                                  _.findIndex(
                                    activeTabsList,
                                    (q) => q === activeTab
                                  ) + 1
                                ]
                              )
                            )
                            if (form?.ID) {
                              history(
                                `${location?.pathname}?q=${form?.ID || ""}`
                              )
                            }
                          }}
                        >
                          <PaginationLink>Next</PaginationLink>
                        </PaginationItem>
                      ) : null}
                    </Pagination>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default QuotationTab
