/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Button,
  Label,
} from "reactstrap"
import { showModal } from "../../../slices/autoquotation/reducer"
import {
  AMOUNT,
  HOTEL_CATEGORIES,
  INVALID,
  NO_OF_DAYS,
  POSITIVE,
  REQUIRED,
  STANDARD_INPUT_DATE,
  STANDARD_OUTPUT_DATE,
  SelectionField,
  TextField,
} from "../../../Components/constants/field"
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik"
import _ from "lodash"
import moment from "moment"

import * as Yup from "yup"
import Flatpickr from "react-flatpickr"
import { AUTO_CLOSE } from "../../../Components/constants/toast"
import { toast } from "react-toastify"
import {
  AccommodationDetail,
  getHotelCityWiseAndRoomCategory,
  getRates,
  updateAccommodation,
} from "../../../slices/thunks"
const AutoQuotationModal = () => {
  const dispatch = useDispatch()
  const { GSTActiveList } = useSelector((state) => state.Gst)
  const { cities } = useSelector((state) => state.City)
  const {
    accommodation: { form },
  } = useSelector((state) => state.Quotation)

  const {
    modal: { modalShow, modalData },
  } = useSelector((state) => state.AutoQuotation)
  const { mealTypes } = useSelector((state) => state.MealType)
  useEffect(() => {
    if (modalShow && modalData?.ID) {
      dispatch(AccommodationDetail(modalData))

      // dispatch(getCityByTourID())
    }
  }, [modalShow, modalData?.ID])

  const closeModal = () => {
    dispatch(
      showModal({
        modalShow: false,
      })
    )
  }

  const handleSubmit = (values, { resetForm }) => {
    let obj = {
      ...values,
    }

    delete obj.CityName
    delete obj.GSTTitle
    delete obj.HotelName
    delete obj.MealPlanName
    delete obj.RoomCategoryName
    obj.ItineraryDate = obj.ItineraryDate
      ? moment(obj.ItineraryDate, STANDARD_INPUT_DATE).format(
          STANDARD_OUTPUT_DATE
        )
      : ""
    obj[`OperationType`] = 7
    dispatch(updateAccommodation(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        resetForm()

        dispatch(
          showModal({
            modalShow: false,
          })
        )
      }
    })

    // obj.CityID = +obj.CityID
    // obj.ID = +obj.ID
    // // eslint-disable-next-line no-self-assign
    // obj.HotelCategory = obj.HotelCategory
    // obj.HotelID = +obj.HotelID
    // obj.MealPlanID = +obj.MealPlanID
    // obj.RoomCategoryID = +obj.RoomCategoryID
    // obj.ItineraryDate = obj.ItineraryDate
    //   ? moment(obj.ItineraryDate, STANDARD_INPUT_DATE).format(
    //       STANDARD_OUTPUT_DATE
    //     )
    //   : ""
    // obj[`IsActive`] = true
    // // createAccommodation
    // if (!obj.ID) {
    //   delete obj.ID
    //   obj[`OperationType`] = 6
    //   obj[`QuoteID`] = Number(qID)
    //   dispatch(createAccommodation(obj)).then((res) => {
    //     setReload(!reload)
    //     if (res.payload && res.payload.Status === 200) {
    //       resetForm()
    //       if (modal) {
    //         let obj = {
    //           modal: false,
    //           modalType: "",
    //           modalData: {},
    //         }
    //         dispatch(showModal(obj))
    //       }
    //     }
    //   })
    // } else {
    //   obj[`OperationType`] = 7
    //   obj[`QuoteID`] = Number(qID)
    //   // IsActive : true

    // }
  }

  return (
    <div>
      <Modal isOpen={modalShow} toggle={closeModal} size="xl">
        <ModalHeader toggle={closeModal}>Accommodation</ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              ...form,
              KitchenCharges: 0,
            }}
            enableReinitialize={true}
            validationSchema={Yup.object({
              ItineraryNoOfDay: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .positive(POSITIVE)
                .lessThan(100, NO_OF_DAYS)
                .required(REQUIRED),
              ItineraryDate: Yup.string().required(REQUIRED),
              PaidPax: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .positive(POSITIVE)
                .required(REQUIRED),
              Adults: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .positive(POSITIVE)
                .required(REQUIRED),

              CityID: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .required(REQUIRED)
                .positive(POSITIVE),
              HotelCategory: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .positive(POSITIVE)
                .required(REQUIRED),
              HotelID: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .positive(POSITIVE)
                .required(REQUIRED),
              RoomCategoryID: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .positive(POSITIVE)
                .required(REQUIRED),
              MealPlanID: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .positive(POSITIVE)
                .required(REQUIRED),
              GSTID: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable(),
              DORoom: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              SORoom: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              ExtraBedAdults: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              ExtraBedCWB: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              ExtraBedCNB: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              SORate: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              DORate: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              CWBRate: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              CNBRate: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              EARate: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              KitchenCharges: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              ExtraBF: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              ExtraLunch: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              HiTea: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              ExtraDinner: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              EarlyCheckInRate: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              LateCheckOutRate: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              Total: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              Margin: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              MarginTotal: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              GSTAmount: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
              CNB: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, INVALID),
              GrandTotal: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .min(0, AMOUNT),
            })}
            onSubmit={handleSubmit}
          >
            {(data) => (
              <Form noValidate autoComplete="off">
                <div>
                  {/* Auto Fill */}
                  {console.log("data 009", data)}

                  <Row>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accomodation-name"
                        >
                          Day No
                        </Label>
                        <Field
                          name="ItineraryNoOfDay"
                          type="number"
                          className="form-control"
                          id="accomodation-name"
                          placeholder="Enter Day no"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accomodation-name"
                        >
                          Date
                        </Label>
                        <Date />
                      </div>
                    </Col>
                    <Total />
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-TotalPax"
                        >
                          Total No Of Person
                        </Label>
                        <Field
                          name="TotalPax"
                          disabled
                          type="number"
                          className="form-control"
                          id="accommodation-TotalPax"
                          placeholder="Total No of Person"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-Adults"
                        >
                          Total Adult ({`> 12 Yr`})
                        </Label>
                        <Field
                          name="Adults"
                          type="number"
                          className="form-control"
                          id="accommodation-Adults"
                          placeholder="Total Adult (> 12 Yr)"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-CWB"
                        >
                          Total Kids ({`6-12 Yr`})
                        </Label>
                        <Field
                          name="CWB"
                          type="number"
                          className="form-control"
                          id="accommodation-CWB"
                          placeholder="Total Kids (6-12 Yr)"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-CNB"
                        >
                          Complimentary ({`0-6 Yr`})
                        </Label>
                        <Field
                          name="CNB"
                          type="number"
                          className="form-control"
                          id="accommodation-CNB"
                          placeholder="Total No of Person"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-PaidPax"
                        >
                          Paid Guest
                        </Label>
                        <Field
                          name="PaidPax"
                          type="number"
                          className="form-control"
                          id="accommodation-PaidPax"
                          placeholder="Paid Guest"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-SORoom"
                        >
                          No Of Single Occ Room
                        </Label>
                        <Field
                          name="SORoom"
                          type="number"
                          className="form-control"
                          id="accommodation-SORoom"
                          placeholder="No of Single Occ Room"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-DORoom"
                        >
                          No Of Double occ room
                        </Label>
                        <Field
                          name="DORoom"
                          type="number"
                          className="form-control"
                          id="accommodation-DORoom"
                          placeholder="No of Double Occ Room"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-ExtraBedAdults"
                        >
                          No Of Extra Bed for Adults {`(>12 yr)`}
                        </Label>
                        <Field
                          name="ExtraBedAdults"
                          type="number"
                          className="form-control"
                          id="accommodation-ExtraBedAdults"
                          placeholder="No of Extra Bed for Adults"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-ExtraBedCWB"
                        >
                          No Of Extra Bed for Kids(6-12 Yr)
                        </Label>
                        <Field
                          name="ExtraBedCWB"
                          type="number"
                          className="form-control"
                          id="accommodation-ExtraBedCWB"
                          placeholder="No of Extra Bed for Kids"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-ExtraBedCNB"
                        >
                          No Of Kids Without Bed(0-6 Yr)
                        </Label>
                        <Field
                          name="ExtraBedCNB"
                          type="number"
                          className="form-control"
                          id="accommodation-ExtraBedCNB"
                          placeholder=" No of Kids Without Bed"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-city"
                        >
                          City
                        </Label>
                        <Field
                          id="accommodation-city"
                          name="CityID"
                          options={
                            Array.isArray(cities)
                              ? cities.map((city) => {
                                  return {
                                    value: city?.ID || "",
                                    label: city?.CityName || "",
                                  }
                                })
                              : []
                          }
                          disabled
                          component={SelectionField}
                        />
                      </div>
                    </Col>{" "}
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-HotelCategory"
                        >
                          Hotel Category
                        </Label>
                        <Field
                          id="accommodation-HotelCategory"
                          name="HotelCategory"
                          options={HOTEL_CATEGORIES.map((hc, index) => {
                            return {
                              value: index + 1,
                              label: hc,
                            }
                          })}
                          component={SelectionField}
                        />
                      </div>
                    </Col>{" "}
                    <HotelDropDown />
                    {/*  */}
                    <RoomCategory />
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-MealPlanID"
                        >
                          Meal Plan
                        </Label>
                        <Field
                          id="accommodation-MealPlanID"
                          name="MealPlanID"
                          options={
                            Array.isArray(mealTypes)
                              ? mealTypes.map((meal) => {
                                  return {
                                    value: meal?.ID || "",
                                    label: meal?.MealType || "",
                                  }
                                })
                              : []
                          }
                          component={SelectionField}
                        />
                      </div>
                    </Col>
                    <QuotationRate />
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-SORate"
                        >
                          Single Occ Rate
                        </Label>
                        <Field
                          name="SORate"
                          type="number"
                          className="form-control"
                          id="accommodation-SORate"
                          placeholder="Single Occ Rate"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>{" "}
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-DORate"
                        >
                          Double Occ Rate
                        </Label>
                        <Field
                          name="DORate"
                          type="number"
                          className="form-control"
                          id="accommodation-DORate"
                          placeholder="Double Occ Rate"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-EARate"
                        >
                          Extra Adult Rate
                        </Label>
                        <Field
                          name="EARate"
                          type="number"
                          className="form-control"
                          id="accommodation-EARate"
                          placeholder="Extra Adult Rate"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-CWBRate"
                        >
                          CWB Rate
                        </Label>
                        <Field
                          name="CWBRate"
                          type="number"
                          className="form-control"
                          id="accommodation-CWBRate"
                          placeholder="CWB Rate"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>{" "}
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-CNBRate"
                        >
                          CNB Rate
                        </Label>
                        <Field
                          name="CNBRate"
                          type="number"
                          className="form-control"
                          id="accommodation-CNBRate"
                          placeholder="CNB Rate"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-ExtraBF"
                        >
                          Extra BF
                        </Label>
                        <Field
                          name="ExtraBF"
                          type="number"
                          className="form-control"
                          id="accommodation-ExtraBF"
                          placeholder="Extra BF"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-ExtraLunch"
                        >
                          Extra Lunch
                        </Label>
                        <Field
                          name="ExtraLunch"
                          type="number"
                          className="form-control"
                          id="accommodation-ExtraLunch"
                          placeholder="Extra Lunch"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-HiTea"
                        >
                          Hi-Tea
                        </Label>
                        <Field
                          name="HiTea"
                          type="number"
                          className="form-control"
                          id="accommodation-HiTea"
                          placeholder="Hi-Tea"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-ExtraDinner"
                        >
                          Extra Dinner
                        </Label>
                        <Field
                          name="ExtraDinner"
                          type="number"
                          className="form-control"
                          id="accommodation-ExtraDinner"
                          placeholder="Extra Dinner"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-KitchenCharges"
                        >
                          Kitchen & Dinning Charges
                        </Label>
                        <Field
                          name="KitchenCharges"
                          type="number"
                          className="form-control"
                          id="accommodation-KitchenCharges"
                          placeholder="Kitchen & Dinning Charges"
                          value={0}
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-EarlyCheckInRate"
                        >
                          Early Check In Charges
                        </Label>
                        <Field
                          name="EarlyCheckInRate"
                          type="number"
                          className="form-control"
                          id="accommodation-EarlyCheckInRate"
                          placeholder="Early Check In Charges"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-LateCheckOutRate"
                        >
                          Late CheckOut Charges
                        </Label>
                        <Field
                          name="LateCheckOutRate"
                          type="number"
                          className="form-control"
                          id="accommodation-LateCheckOutRate"
                          placeholder="Late CheckOut Charges"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-Total"
                        >
                          Accommodation Total
                        </Label>
                        <Field
                          name="Total"
                          type="number"
                          // disabled
                          className="form-control"
                          id="accommodation-Total"
                          placeholder="Accommodation Total"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-Margin"
                        >
                          Margin
                        </Label>
                        <Field
                          name="Margin"
                          type="number"
                          className="form-control"
                          id="accommodation-Margin"
                          placeholder="Margin"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-MarginTotal"
                        >
                          Total With Margin
                        </Label>
                        <Field
                          name="MarginTotal"
                          // disabled
                          type="number"
                          className="form-control"
                          id="accommodation-MarginTotal"
                          placeholder="Total With Margin"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-GSTID"
                        >
                          GST
                        </Label>
                        <Field
                          id="accommodation-GSTID"
                          name="GSTID"
                          options={
                            Array.isArray(GSTActiveList)
                              ? GSTActiveList.map((gst) => {
                                  return {
                                    value: gst?.ID || "",
                                    label: gst?.Title || "",
                                  }
                                })
                              : []
                          }
                          component={SelectionField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-GSTAmount"
                        >
                          GST Amount
                        </Label>
                        <Field
                          name="GSTAmount"
                          type="number"
                          className="form-control"
                          id="accommodation-GSTAmount"
                          placeholder="GST Amount"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-GrandTotal"
                        >
                          Total Amount
                        </Label>
                        <Field
                          name="GrandTotal"
                          type="number"
                          className="form-control"
                          id="accommodation-GrandTotal"
                          placeholder="Total Amount"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={12}>
                      <hr></hr>
                    </Col>
                    <Col md="12" className="text-end">
                      <Button className="success" type="submit">
                        Update
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default AutoQuotationModal
// Date Picker
function Date() {
  const {
    newQuotation: { form },
  } = useSelector((state) => state.Quotation)
  const { values, setFieldValue } = useFormikContext()

  const handleDateChange = (ev) => {
    setFieldValue(
      "ItineraryDate",
      Array.isArray(ev) && ev.length
        ? moment(ev[0]).format(STANDARD_INPUT_DATE)
        : null
    )
  }
  return (
    <React.Fragment>
      <Field>
        {({ field }) => (
          <Flatpickr
            name="ItineraryDate"
            id="ItineraryDate"
            {...field}
            value={values.ItineraryDate}
            placeholder="Select Date.."
            className="form-control"
            options={{
              altInput: true,
              altFormat: "d-m-Y",
              dateFormat: "d-m-Y",
              minDate: form?.DepartureDate || null,
            }}
            disabled={true}
            onChange={handleDateChange}
          />
        )}
      </Field>
      <ErrorMessage
        name={"ItineraryDate"}
        component="div"
        className="text-danger"
      />
    </React.Fragment>
  )
}
function HotelDropDown() {
  const dispatch = useDispatch()
  const { values } = useFormikContext()
  const { HotelCityWiseAndRoomCategory } = useSelector((state) => state.Hotel)

  useEffect(() => {
    if (values?.CityID && values?.HotelCategory) {
      let data = {
        CityID: Number(values?.CityID) || "",
        HotelCategory: values?.HotelCategory || "",
      }
      dispatch(getHotelCityWiseAndRoomCategory(data))
    }
  }, [values?.CityID, values?.HotelCategory])

  return (
    <Col lg={2}>
      <div className="mb-3">
        <Label className="form-label" htmlFor="accommodation-HotelID">
          Hotel Name
        </Label>
        <Field
          id="accommodation-HotelID"
          name="HotelID"
          options={
            Array.isArray(HotelCityWiseAndRoomCategory)
              ? HotelCityWiseAndRoomCategory.map((hotel) => {
                  return {
                    value: hotel?.HotelID || "",
                    label: hotel?.HotelName || "",
                  }
                })
              : []
          }
          component={SelectionField}
        />
      </div>
    </Col>
  )
}
function RoomCategory() {
  const { HotelCityWiseAndRoomCategory } = useSelector((state) => state.Hotel)

  const { values } = useFormikContext()
  const roomList = useMemo(() => {
    let list = []
    if (values?.HotelID) {
      list = _.get(
        _.find(
          HotelCityWiseAndRoomCategory,
          (h) => Number(h?.HotelID) === Number(values?.HotelID)
        ),
        "RoomCategoryList",
        []
      )
    }
    return list
  }, [values?.HotelID, HotelCityWiseAndRoomCategory])

  return (
    <Col lg={2}>
      <div className="mb-3">
        <Label className="form-label" htmlFor="accommodation-RoomCategoryID">
          Room Category
        </Label>
        <Field
          id="accommodation-RoomCategoryID"
          name="RoomCategoryID"
          options={
            Array.isArray(roomList)
              ? roomList.map((room) => {
                  return {
                    value: room?.ID || "",
                    label: room?.RoomCategoryName || "",
                  }
                })
              : []
          }
          component={SelectionField}
        />
      </div>
    </Col>
  )
}

function QuotationRate() {
  const dispatch = useDispatch()

  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    if (values?.HotelID && values.MealPlanID && values?.RoomCategoryID) {
      let obj = {
        HotelID: Number(values?.HotelID),
        RoomCategoryID: Number(values?.RoomCategoryID),
        MealPlanID: Number(values.MealPlanID),

        ItineraryDate: values?.ItineraryDate
          ? moment(values.ItineraryDate, STANDARD_INPUT_DATE).format(
              STANDARD_OUTPUT_DATE
            )
          : "",
        SORoom: values?.SORoom || 0,
        DORoom: values?.DORoom || 0,
        Adults: values?.Adults,
        CWB: values?.CWB || 0,
        CNB: values?.CNB || 0,
        ExtraBedAdults: values?.ExtraBedAdults || 0,
      }
      dispatch(getRates(obj)).then((res) => {
        if (res?.payload.Message === "Failure") {
          toast.error(res?.payload?.Details, { autoClose: AUTO_CLOSE })
        }
        if (res?.payload.Message === "Success") {
          const Data = res?.payload?.Data

          setFieldValue("CNBRate", Data?.CNBRate)
          setFieldValue("CWBRate", Data?.CWBRate)
          setFieldValue("DORate", Data?.DORate)
          setFieldValue("EARate", Data?.EARate)
          // setFieldValue("KitchenCharges", Data?.KitchenCharges)
          setFieldValue("SORate", Data?.SORate)
        }
      })
    }
  }, [
    values?.ItineraryDate,
    values?.HotelID,
    values.MealPlanID,
    values?.RoomCategoryID,
    values?.SORoom,
    values?.DORoom,
    values?.CWB,
    values?.CNB,
  ])
  return null
}
//
export function TotalSumComponent() {
  const { values, setFieldValue } = useFormikContext()
  const { GSTActiveList } = useSelector((state) => state.Gst)

  const Sum = useCallback(
    (values, subArray) => {
      let value = _.sum(
        _.filter(_.values(_.pick(values, subArray)), function (num) {
          return _.isNumber(num)
        })
      )

      return value
    },
    [values]
  )

  useMemo(() => {
    setFieldValue("TotalPax", Sum(values, ["Adults", "CNB", "CWB"]))
  }, [values?.Adults, values?.CNB, values?.CWB])
  useMemo(() => {
    setFieldValue("PaidPax", Sum(values, ["Adults", "CWB"]))
  }, [values?.Adults, values?.CWB])
  useMemo(() => {
    setFieldValue(
      "Total",
      Sum(values, [
        "SORate",
        "DORate",
        "CWBRate",
        "CNBRate",
        "EARate",
        "KitchenCharges",
        "ExtraBF",
        "ExtraLunch",
        "Hitea",
        "ExtraDinner",
        // "SORate",
        // "DORate",
        // "EARate",
        // "CWBRate",
        // "CNBRate",
        // "ExtraBF",
        // "ExtraLunch",
        // "HiTea",
        // "ExtraDinner",
        // "KitchenCharges",
        // "EarlyCheckInRate",
        // "LateCheckOutRate",
      ])
    )
  }, [
    values?.SORate,
    values?.DORate,
    values?.EARate,
    values?.CWBRate,
    values?.CNBRate,
    values?.ExtraBF,
    values?.ExtraLunch,
    values?.HiTea,
    values?.ExtraDinner,
    values?.KitchenCharges,
  ])

  // Total Margin Total
  useMemo(() => {
    setFieldValue("MarginTotal", Sum(values, ["Total", "Margin"]))
  }, [values?.Total, values?.Margin])

  // GST Total Amount
  useMemo(() => {
    // const findGst = _.find(GSTActiveList, (q) => q.ID === Number(values?.GSTID))
    const findGst = _.find(GSTActiveList, (q) => q.GSTPer === 5)

    let totalGst = _.round(
      values?.MarginTotal * ((findGst?.GSTPer || 0) / 100),
      2
    )

    setFieldValue("GSTAmount", totalGst)
    setFieldValue("GSTID", findGst?.ID || 0)
    // }
    setFieldValue("GSTPer", findGst?.GSTPer)
  }, [GSTActiveList, values?.GSTID, values?.MarginTotal, values?.Margin])

  //  Grand Total
  //
  useMemo(() => {
    setFieldValue("GrandTotal", Sum(values, ["MarginTotal", "GSTAmount"]))
  }, [values?.MarginTotal, values?.GSTAmount])
  return null
}
export const Total = React.memo(TotalSumComponent)

// function AutoFillComponent() {
//   const { GSTActiveList } = useSelector((state) => state.Gst)

//   const {
//     modal: { modalShow },
//   } = useSelector((state) => state.AutoQuotation)

//   const { setFieldValue, values } = useFormikContext()

//   let selectGST = _.find(GSTActiveList, (g) => g?.GSTPer === 5)
//   console.log(
//     "🚀 ~ file: AutoQuotationModal.js:1251 ~ AutoFillComponent ~ selectGST:",
//     selectGST?.ID
//   )

//   useEffect(() => {
//     // set gst by default 5 per
//     if (Array.isArray(GSTActiveList) && selectGST?.GSTPer && selectGST?.ID) {
//       // eslint-disable-next-line no-debugger
//       setFieldValue("GSTPer", selectGST?.GSTPer || "")
//       setFieldValue("GSTID", selectGST?.ID || 0)
//     }
//   }, [GSTActiveList, modalShow, selectGST?.GSTPer, selectGST?.ID])

//   return null
// }

// export const AutoFill = React.memo(AutoFillComponent)
