import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import {
  ActiveInActiveSpecialityTypeApi,
  deleteSpecialityTypeApi,
  findByIdSpecialityTypeApi,
  getAllActiveSpeciallityTypesApi,
  getAllSpecialityTypeApi,
  insertSpecialityTypeApi,
  updateSpecialityTypeApi,
} from "./specialitytype.services";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";

export const getAllActiveSpeciallityTypes = createAsyncThunk(
  "SpecialityTypes/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveSpeciallityTypesApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getAllSpeciallityTypes = createAsyncThunk(
  "SpecialityTypes/FindAll",
  async () => {
    try {
      const response = await getAllSpecialityTypeApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);
export const findByIdSpeciallityTypes = createAsyncThunk(
  "SpecialityTypes/FindByID",
  async (params) => {
    try {
      const response = await findByIdSpecialityTypeApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const insertSpeciallityTypes = createAsyncThunk(
  "SpecialityTypes/Insert",
  async (data) => {
    try {
      const response = await insertSpecialityTypeApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const updateSpeciallityTypes = createAsyncThunk(
  "SpecialityTypes/Update",
  async (data) => {
    try {
      const response = await updateSpecialityTypeApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const ActiveInActiveSpeciallityTypes = createAsyncThunk(
  "SpecialityTypes/Active-InActive",
  async (data) => {
    try {
      const response = await ActiveInActiveSpecialityTypeApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const deleteSpeciallityTypes = createAsyncThunk(
  "SpecialityTypes/Delete",
  async (data) => {
    try {
      const response = await deleteSpecialityTypeApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
