import { createSlice } from "@reduxjs/toolkit"
import {
  ActiveInActiveHotelStatus,
  addRoomCategories,
  createHotelContact,
  deleteHotel,
  deleteHotelRates,
  getAllHotelContact,
  getAllHotelList,
  getHotelByID,
  getHotelByRoomCategory,
  getHotelCityWiseAndRoomCategory,
  getHotelCityWiseTour,
  getHotelContactById,
  getHotelRatesByID,
  getHotelRoomcatgeoryByHotelID,
  getRoomCategoriesByHotelIDOrSessionID,
  getRoomCategoryById,
  insertHotel,
  insertHotelRates,
  updateHotel,
} from "./thunk"
import moment from "moment"
import {
  STANDARD_INPUT_DATE,
  STANDARD_OUTPUT_DATE,
} from "../../Components/constants/field"
import { toast } from "react-toastify"
import { AUTO_CLOSE } from "../../Components/constants/toast"

export const emptyContact = {
  ContactPerson: undefined,
  ContactNo: undefined,
  EmailID: undefined,
}

export const emptyHotelRateWeekDays = {
  RoomCategoryID: undefined,
  EPAI: 0,
  CPAI: 0,
  MAPAI: 0,
  APAI: 0,
  CWB: 0,
  CNB: 0,
  ExtraAdultRate: 0,
  EarlyCheckInRate: 0,
  LateCheckOutRate: 0,
}

export const emptyHotelRateWeekEnds = {
  RoomCategoryID: 8,
  EPAI: 0,
  CPAI: 0,
  MAPAI: 0,
  APAI: 0,
  CWB: 0,
  CNB: 0,
  ExtraAdultRate: 0,
  EarlyCheckInRate: 0,
  LateCheckOutRate: 0,
}

export const emptyHotelRateSpecilDates = {
  RoomCategoryID: undefined,
  EPAI: 0,
  CPAI: 0,
  MAPAI: 0,
  APAI: 0,
  CWB: 0,
  CNB: 0,
  ExtraAdultRate: 0,
  EarlyCheckInRate: 0,
  LateCheckOutRate: 0,
}

export const hotelContact = {
  HotelID: "",
  ID: "",
  ContactPersonName: "",
  ContactPersonMobileNo: "",
  ContactPersonEmailID: "",
  ContactPersonDesignation: "",
  IsActive: true,
}

export const roomCategories = {
  RoomCategoryName: "",
  IsActive: true,
}

export const hotelRates = {
  DateType: "",
  HotelID: "",
  HotelName: "",
  RoomCategoryID: "",
  RoomCategoryName: "",
  FestivalID: "",
  CWBAge: 5,
  FromDate: "",
  ToDate: "",
  SOEPAI: 0,
  SOCPAI: 0,
  SOMAPAI: 0,
  SOAPAI: 0,
  DOEPAI: 0,
  DOCPAI: 0,
  DOMAPAI: 0,
  DOAPAI: 0,
  CWBEPAI: 0,
  CWBCPAI: 0,
  CWBMAPAI: 0,
  CWBAPAI: 0,
  CNBEPAI: 0,
  CNBCPAI: 0,
  CNBMAPAI: 0,
  CNBAPAI: 0,
  // EAEPAI: 0,
  // EACPAI: 0,
  // EAMAPAI: 0,
  // EAAPAI: 0,
  KitchenCharges: 0,
  IsActive: true,
}

export const initialState = {
  HotelID: undefined,
  hotelDetails: {
    form: {
      HotelName: undefined,
      Address: undefined,
      CityID: undefined,
      HotelCategory: undefined,
      IsActive: true,
      WeekDays: [],
      WeekEnds: [],
      WeekEndRateList: [],
      WeekDaysRateList: [],
    },
    loading: false,
  },
  hotelContact: {
    form: hotelContact,
    hotelContactList: [],
    loading: false,
  },
  roomCategories: {
    form: roomCategories,
    roomCategoryList: [],
    loading: false,
  },
  // hotelContact: hotelContact,
  // hotelContactList: [],
  // roomCategories:

  roomCategoriesByHotelID: {},
  hotelSpecialRates: {
    WeekDaysRateList: [],
    WeekEndRateList: [],
    SpecialDateRateList: [],
    form: hotelRates,
  },
  hotels: [],
  HotelCityWiseAndRoomCategory: [],
  error: {},
  loading: false,
  loadingRoomCategories: false,
}

const HotelReducer = createSlice({
  name: "Hotel",
  initialState,
  reducers: {
    resetHotel: (state) => {
      state.HotelID = undefined
      state.roomCategories = []
      state.hotelDetails = initialState.hotelDetails
      state.hotelContact = initialState.hotelContact
      state.roomCategories = initialState.roomCategories
    },
    resetHotelRates: (state) => {
      state.hotelSpecialRates.form = initialState.hotelSpecialRates.form
    },
    setHotelID: (state, action) => {
      state.HotelID = action.payload
    },
    resetHotelCityWiseAndRoomCategory: (state) => {
      state.HotelCityWiseAndRoomCategory = []
    },
  },
  extraReducers: (builder) => {
    // Hotel
    builder.addCase(getAllHotelList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllHotelList.fulfilled, (state, action) => {
      state.loading = false
      state.hotels = action.payload.Data
    })
    builder.addCase(getAllHotelList.rejected, (state, action) => {
      state.loading = false
      state.error = action.payload?.error || null
    })

    builder.addCase(insertHotel.pending, (state) => {
      state.loading = false
    })
    builder.addCase(insertHotel.fulfilled, (state, action) => {
      if (action?.payload.Message === "Failure") {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
      state.loading = false
    })
    builder.addCase(insertHotel.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(updateHotel.pending, (state) => {
      state.loading = false
    })
    builder.addCase(updateHotel.fulfilled, (state, action) => {
      if (action?.payload.Message === "Failure") {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }

      state.loading = false
    })
    builder.addCase(updateHotel.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getHotelByID.pending, (state) => {
      state.hotelDetails.loading = true
    })
    builder.addCase(getHotelByID.fulfilled, (state, action) => {
      if (action.payload && action.payload.Data) {
        const response = action.payload.Data
        state.hotelDetails.form = {
          ...response,
          WeekDays: response.WeekDays ? response.WeekDays.split(",") : [],
          WeekEnds: response.WeekEnds ? response.WeekEnds.split(",") : [],
          FromDate:
            response.HotelRateSpecialDates &&
            response.HotelRateSpecialDates.length > 0
              ? moment(
                  response.HotelRateSpecialDates[0].FromDate,
                  STANDARD_INPUT_DATE
                ).format(STANDARD_OUTPUT_DATE)
              : "",
          ToDate:
            response.HotelRateSpecialDates &&
            response.HotelRateSpecialDates.length > 0
              ? moment(
                  response.HotelRateSpecialDates[0].ToDate,
                  STANDARD_INPUT_DATE
                ).format(STANDARD_OUTPUT_DATE)
              : "",
        }
        state.hotelSpecialRates.SpecialDateRateList =
          response.SpecialDateRateList
        state.hotelSpecialRates.WeekEndRateList = response.WeekEndRateList
        state.hotelSpecialRates.WeekDaysRateList = response.WeekDaysRateList
        state.HotelID = response.ID
        state.hotelDetails.loading = false
      }
      // state.loading = false
    })
    builder.addCase(getHotelByID.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(deleteHotel.pending, (state) => {
      state.hotelDetails.loading = true
    })
    builder.addCase(deleteHotel.fulfilled, (state, action) => {
      if (action?.payload.Message === "Failure") {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
      state.hotelDetails.loading = false
    })
    builder.addCase(deleteHotel.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(insertHotelRates.fulfilled, (state, action) => {
      if (action?.payload.Message === "Failure") {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
      if (action.payload.Message == "Success") {
        state.hotelSpecialRates.SpecialDateRateList =
          action.payload.Data.SpecialDateRateList
        state.hotelSpecialRates.WeekEndRateList =
          action.payload.Data.WeekEndRateList
        state.hotelSpecialRates.WeekDaysRateList =
          action.payload.Data.WeekDaysRateList
        state.hotelSpecialRates.form = hotelRates
      }
      state.loading = false
    })
    builder.addCase(insertHotelRates.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(deleteHotelRates.pending, (state) => {
      state.loading = false
    })
    builder.addCase(deleteHotelRates.fulfilled, (state, action) => {
      if (action?.payload.Message === "Failure") {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
      state.hotelSpecialRates.form = initialState.hotelSpecialRates.form
      //if (action.payload.Message == "Success") {
      //  state.hotelSpecialRates.SpecialDateRateList =
      //    action.payload.Data.SpecialDateRateList
      //  state.hotelSpecialRates.WeekEndRateList =
      //    action.payload.Data.WeekEndRateList
      //  state.hotelSpecialRates.WeekDaysRateList =
      //    action.payload.Data.WeekDaysRateList
      //}
      state.loading = false
    })
    builder.addCase(deleteHotelRates.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(getHotelRatesByID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getHotelRatesByID.fulfilled, (state, action) => {
      if (action?.payload.Message === "Success") {
        state.hotelSpecialRates.form = action.payload.Data
      }
      state.loading = false
    })
    builder.addCase(getHotelRatesByID.rejected, (state) => {
      state.loading = false
    })

    // Hotel Contact
    builder.addCase(createHotelContact.pending, (state) => {
      state.hotelContact.loading = false
    })
    builder.addCase(createHotelContact.fulfilled, (state, action) => {
      if (action?.payload.Message === "Failure") {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
      if (action?.payload.Message === "Success") {
        state.hotelContact.form = hotelContact
      }
      // state.hotelContact.hotelContactList =
      //   action?.payload?.Data?.HotelContactList || []
      state.hotelContact.loading = false
    })
    builder.addCase(createHotelContact.rejected, (state) => {
      state.hotelContact.loading = false
    })
    builder.addCase(getAllHotelContact.pending, (state) => {
      state.hotelContact.loading = false
    })
    builder.addCase(getAllHotelContact.fulfilled, (state, action) => {
      state.hotelContact.hotelContactList =
        action?.payload?.Data?.HotelContactList || []

      state.hotelContact.loading = false
    })
    builder.addCase(getAllHotelContact.rejected, (state) => {
      state.hotelContact.loading = false
    })
    builder.addCase(getHotelContactById.pending, (state) => {
      state.hotelContact.loading = false
    })
    builder.addCase(getHotelContactById.fulfilled, (state, action) => {
      state.hotelContact.form = action?.payload?.Data

      state.hotelContact.loading = false
    })
    builder.addCase(getHotelContactById.rejected, (state) => {
      state.hotelContact.loading = false
    })
    builder.addCase(ActiveInActiveHotelStatus.pending, (state) => {
      state.hotelContact.loading = false
    })
    builder.addCase(ActiveInActiveHotelStatus.fulfilled, (state, action) => {
      if (action?.payload.Message === "Failure") {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
      state.hotelContact.loading = false
    })
    builder.addCase(ActiveInActiveHotelStatus.rejected, (state) => {
      state.hotelContact.loading = false
    })

    //  Room Category

    builder.addCase(getHotelByRoomCategory.pending, (state) => {
      state.loading = false
    })
    builder.addCase(getHotelByRoomCategory.fulfilled, (state, action) => {
      state.hotels = action.payload.Data
      state.loading = false
    })
    builder.addCase(getHotelByRoomCategory.rejected, (state, action) => {
      toast.success(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      state.loading = false
    })

    builder.addCase(getRoomCategoryById.pending, (state) => {
      state.roomCategories.loading = false
    })
    builder.addCase(getRoomCategoryById.fulfilled, (state, action) => {
      state.roomCategories.form = action?.payload?.Data || {}
      state.roomCategories.loading = false
    })
    builder.addCase(getRoomCategoryById.rejected, (state) => {
      state.roomCategories.loading = false
    })

    builder.addCase(addRoomCategories.pending, (state) => {
      state.roomCategories.loading = false
    })
    builder.addCase(addRoomCategories.fulfilled, (state, action) => {
      if (action?.payload.Message === "Failure") {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
      if (action?.payload.Message === "Success") {
        state.roomCategories.form = roomCategories
      }
      state.roomCategories.loading = false
    })
    builder.addCase(addRoomCategories.rejected, (state) => {
      state.roomCategories.loading = false
    })
    builder.addCase(getRoomCategoriesByHotelIDOrSessionID.pending, (state) => {
      state.loadingRoomCategories = true
    })
    builder.addCase(
      getRoomCategoriesByHotelIDOrSessionID.fulfilled,
      (state, action) => {
        state.roomCategories = action.payload.Data
        state.loadingRoomCategories = false
      }
    )
    builder.addCase(
      getRoomCategoriesByHotelIDOrSessionID.rejected,
      (state, action) => {
        state.error = action?.payload?.error || null
        state.loadingRoomCategories = false
      }
    )
    builder.addCase(getHotelRoomcatgeoryByHotelID.pending, (state) => {
      state.loading = false
    })
    builder.addCase(
      getHotelRoomcatgeoryByHotelID.fulfilled,
      (state, action) => {
        state.roomCategories.roomCategoryList =
          action?.payload?.Data?.RoomCategoryList || []
        state.roomCategories.loading = false
      }
    )
    builder.addCase(getHotelRoomcatgeoryByHotelID.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getHotelCityWiseAndRoomCategory.pending, (state) => {
      state.loading = false
    })
    builder.addCase(
      getHotelCityWiseAndRoomCategory.fulfilled,
      (state, action) => {
        state.HotelCityWiseAndRoomCategory =
          action?.payload?.Data?.HotelList || []
        state.loading = false
      }
    )
    builder.addCase(getHotelCityWiseAndRoomCategory.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getHotelCityWiseTour.pending, (state) => {
      state.loading = false
    })
    builder.addCase(getHotelCityWiseTour.fulfilled, (state, action) => {
      state.hotels = action?.payload?.Data || []
      state.loading = false
    })
    builder.addCase(getHotelCityWiseTour.rejected, (state) => {
      state.loading = false
    })
  },
})

export const {
  resetHotel,
  setHotelID,
  resetHotelRates,
  resetHotelCityWiseAndRoomCategory,
} = HotelReducer.actions
export default HotelReducer.reducer
