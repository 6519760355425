import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { FAILED } from "../../Components/constants/field";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { toast } from "react-toastify";
import { getAllActiveBranchesApi } from "./branch.services";

export const getAllActiveBranches = createAsyncThunk(
  "Branch/ActiveBranches",
  async () => {
    try {
      const response = await getAllActiveBranchesApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);
