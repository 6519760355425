import { createSlice } from "@reduxjs/toolkit";
import { findByIdTersAndConditions, getAllActiveTersAndConditions, getAllTersAndConditions, insertTersAndConditions, updateTersAndConditions } from "./thunk";
import _ from "lodash"

export const termsAndConditionsForm={
    ID:null,
    Title:"",
    Description:"",
    IsActive: true,
}

export const initialState = {
    termsAndConditionsDetail:{
        form:termsAndConditionsForm
    },
    termsAndConditions: [],
    error: {},
    loading: false,
};

const TermsAndConditionsReducer = createSlice({
    name: "TermsAndConditions",
    initialState,
    reducers: {
        resetForm: (state) => {
            state.termsAndConditionsDetail.form = termsAndConditionsForm;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllActiveTersAndConditions.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getAllActiveTersAndConditions.fulfilled,
            (state, action) => {
                state.termsAndConditions = action.payload.Data;
                state.loading = false;
            }
        );
        builder.addCase(
            getAllActiveTersAndConditions.rejected,
            (state, action) => {
                state.error = action.payload.error || null;
                state.loading = false;
            }
        );
        builder.addCase(getAllTersAndConditions.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(getAllTersAndConditions.fulfilled,(state,action)=>{
            state.termsAndConditions=action.payload.Data || []
            state.loading=false
        });
        builder.addCase(getAllTersAndConditions.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(insertTersAndConditions.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(insertTersAndConditions.fulfilled,(state)=>{
            state.termsAndConditionsDetail.form=termsAndConditionsForm
            state.loading=false
        });
        builder.addCase(insertTersAndConditions.rejected, (state,action) => {
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(updateTersAndConditions.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(updateTersAndConditions.fulfilled,(state)=>{
            state.termsAndConditionsDetail.form=termsAndConditionsForm
            state.loading=false
        });
        builder.addCase(updateTersAndConditions.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(findByIdTersAndConditions.pending,(state)=>{
            state.loading=true
           });
        builder.addCase(findByIdTersAndConditions.fulfilled,(state,action)=>{
            let formObj = action?.payload?.Data

        let formKeys=Object.keys(termsAndConditionsForm)
        formObj = _.pick(formObj,formKeys)
        state.termsAndConditionsDetail.form=formObj
        state.loading=false
        });
        builder.addCase(findByIdTersAndConditions.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
           })
    },
});

export const {resetForm}=TermsAndConditionsReducer.actions
export default TermsAndConditionsReducer.reducer;
