import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import AdditionalServicePerDay from "./AdditionalServicePerDay"
import AdditionalServicePerPerson from "./AdditionalServicePerPerson"
import Modals from "./Modal"
// import DeleteModal from "../../../Components/Common/DeleteModal"

const AdditionalService = () => {
  return (
    <React.Fragment>
      <Card className="border border-black">
        <CardBody>
          <Row>
            <Col md={12} className="mb-4">
              <h5>Additional Service</h5>
              <hr></hr>
            </Col>
          </Row>
          <AdditionalServicePerDay />
          <AdditionalServicePerPerson />
        </CardBody>
      </Card>
      <Modals />
    </React.Fragment>
  )
}

export default AdditionalService
