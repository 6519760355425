import { APIClient } from "../../helpers/api_helper"
import * as url from "../../helpers/url_helper"

const api = new APIClient()

// Get Rates
export const getRatesApi = (rateDetails) =>
  api.create(url.GET_RATES, rateDetails)

// Search Agent Using Name
export const searchAgentUsingNameApi = (agentDetail) =>
  api.create(url.SEARCH_AGENT_USING_NAME, agentDetail)

// Get All Sectors
export const getAllSectorsApi = () => api.get(url.GET_ALL_ACTIVE_SECTOR)

// Get All Tours
export const getAllToursApi = () => api.get(url.GET_ALL_ACTIVE_TOURS)

// Create new Quotation step-1
export const createQuotationStep1Api = (data) =>
  api.create(url.CREATE_QUOTATION_STEP1, data)

// Update Quotation step 1
export const updateQuotationStep1Api = (data) =>
  api.create(url.CREATE_QUOTATION_STEP1, data)

export const getQuotationDetailApi = (quotationData) =>
  api.create(url.GET_QUOTATION_BY_ID, quotationData)
export const getQuotationAllApi = (quotationData) =>
  api.create(url.GET_ALL_QUOTATION, quotationData)

export const deleteQuotationApi = (quotationData) =>
  api.create(url.CREATE_QUOTATION_STEP1, quotationData)
// Accommodation
export const createAccommodationApi = (accommodationData) =>
  api.create(url.CREATE_ACCOMMODATION, accommodationData)
export const updateAccommodationApi = (accommodationData) =>
  api.create(url.CREATE_ACCOMMODATION, accommodationData)
export const AccommodationDetailApi = (accommodationData) =>
  api.create(url.DETAIL_ACCOMMODATION, accommodationData)
export const AccommodationCopyApi = (accommodationData) =>
  api.create(url.COPY_ACCOMMODATION_URL, accommodationData)
// Transportation

export const createTransportationApi = (transportData) =>
  api.create(url.INSERT_TRANSPORTATION, transportData)
export const getTransportationApi = (transportData) =>
  api.create(url.GET_TRANSPORTATION, transportData)
export const updateTransportationApi = (transportData) =>
  api.create(url.UPDATE_TRANSPORTATION, transportData)

// Additional Services
// Accommodation
export const createAdditionalServicesApi = (additionalServicesData) =>
  api.create(url.CREATE_ADDITIONAL_SERVICES, additionalServicesData)
export const updateAdditionalServicesApi = (additionalServicesData) =>
  api.create(url.CREATE_ADDITIONAL_SERVICES, additionalServicesData)
export const AdditionalServicesDetailsPerDayApi = (additionalServicesData) =>
  api.create(url.DETAIL_ADDITIONAL_SERVICES_PER_DAY, additionalServicesData)
export const AdditionalServicesDetailsPerPersonApi = (additionalServicesData) =>
  api.create(url.DETAIL_ADDITIONAL_SERVICES_PER_PERSON, additionalServicesData)

// Auto Quotation
export const createAutoQuotationApi = (data) => {
  return api.create(url.INSERT_AUTO_QUOTATION, data)
}

export const exportFixedQuotePDFApi = async (ID) => {
  return await api.create(url.EXPORT_FIXED_QUOTE_PDF, { ID })
}
