import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  TabPane,
  Table,
} from "reactstrap"
import {
  TextField,
  SelectionField,
  HOTEL_CATEGORIES,
  STANDARD_INPUT_DATE,
  REQUIRED,
  POSITIVE,
  AMOUNT,
  INVALID,
  STANDARD_OUTPUT_DATE,
  NO_OF_DAYS,
} from "../../Components/constants/field"
import Flatpickr from "react-flatpickr"
import { useEffect } from "react"
import Loader from "../../Components/Common/Loader"
import {
  AccommodationDetail,
  createAccommodation,
  getActiveGst,
  getAllActiveMealTypes,
  getCitiesBySectorID,
  getHotelCityWiseAndRoomCategory,
  getQuotationDetail,
  getRates,
  updateAccommodation,
} from "../../slices/thunks"
import { resetHotelCityWiseAndRoomCategory } from "../../slices/hotel/reducer"
import _ from "lodash"
import { useMemo } from "react"
import moment from "moment"
import * as Yup from "yup"
import DeleteModal from "../../Components/Common/DeleteModal"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { AUTO_CLOSE } from "../../Components/constants/toast"
import PropTypes from "prop-types"
import { showClipModal, showModal } from "../../slices/quotation/reducer"
import AccommodationClipModal from "./AccommodationClipModal"
//

const Accommodation = (props) => {
  const { showTable = true } = props
  const dispatch = useDispatch()
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const qID = urlParams.get("q")
  const {
    activeTab,
    accommodation: { form, list },
    modal: { modal },
    newQuotation: { form: QuotationFrom },
    clipModal: { modal: clipModalShow },
  } = useSelector((state) => state.Quotation)

  const { cities, loading: cityLoading } = useSelector((state) => state.City)
  const { mealTypes, loading: mealTypeLoading } = useSelector(
    (state) => state.MealType
  )
  const { GSTActiveList, loading: GstLoading } = useSelector(
    (state) => state.Gst
  )

  //  reload

  const [reload, setReload] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  //
  useEffect(() => {
    dispatch(getAllActiveMealTypes())
    dispatch(getActiveGst())
  }, [])

  useEffect(() => {
    if (QuotationFrom?.SectorID) {
      dispatch(getCitiesBySectorID(QuotationFrom?.SectorID))
    }
  }, [QuotationFrom?.SectorID])
  useEffect(() => {
    if (qID && !modal && !clipModalShow) {
      let quotationDetail = {
        ID: Number(qID),
      }

      dispatch(getQuotationDetail(quotationDetail))
    }
  }, [reload, qID, modal, clipModalShow])

  const handleSubmit = (values, { resetForm }) => {
    let obj = {
      ...values,
    }
    delete obj.CityName
    delete obj.GSTTitle
    delete obj.HotelName
    delete obj.MealPlanName
    delete obj.RoomCategoryName
    obj.CityID = +obj.CityID
    obj.ID = +obj.ID
    // eslint-disable-next-line no-self-assign
    obj.HotelCategory = obj.HotelCategory
    obj.HotelID = +obj.HotelID
    obj.MealPlanID = +obj.MealPlanID
    obj.RoomCategoryID = +obj.RoomCategoryID
    obj.ItineraryDate = obj.ItineraryDate
      ? moment(obj.ItineraryDate, STANDARD_INPUT_DATE).format(
          STANDARD_OUTPUT_DATE
        )
      : ""
    obj[`IsActive`] = true
    // createAccommodation
    if (!obj.ID) {
      delete obj.ID
      obj[`OperationType`] = 6
      obj[`QuoteID`] = Number(qID)
      dispatch(createAccommodation(obj)).then((res) => {
        setReload(!reload)
        if (res.payload && res.payload.Status === 200) {
          resetForm()
          if (modal) {
            let obj = {
              modal: false,
              modalType: "",
              modalData: {},
            }
            dispatch(showModal(obj))
          }
        }
      })
    } else {
      obj[`OperationType`] = 7
      obj[`QuoteID`] = Number(qID)
      // IsActive : true

      dispatch(updateAccommodation(obj)).then((res) => {
        setReload(!reload)
        if (res.payload && res.payload.Status === 200) {
          resetForm()
          if (modal) {
            let obj = {
              modal: false,
              modalType: "",
              modalData: {},
            }
            dispatch(showModal(obj))
          }
        }
      })
    }
  }
  const editAccommodation = (data) => {
    let detailObj = {
      ID: data?.ID || "",
      QuoteID: data?.QuoteID || "",
    }
    // dispatch(accommodationDetails(data))
    dispatch(AccommodationDetail(detailObj))
  }

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
      QuoteID: +deleteData?.QuoteID,
      OperationType: 8,
      IsActive: false,
    }
    dispatch(updateAccommodation(obj)).then((res) => {
      setReload(!reload)
      if (res.payload && res.payload.Status === 200) {
        setDeleteModal(false)
      }
    })
  }
  const onClickDelete = (data) => {
    setDeleteModal(true)
    setDeleteData(data)
  }
  const onClickClipBoard = (data) => {
    console.log(data)
    let modalData = {
      modal: true,
      data: {
        QuoteID: data?.QuoteID,
        ItineraryNoOfDay: data?.ItineraryNoOfDay,
        PlaceDayNo: null,
      },
    }
    dispatch(showClipModal(modalData))
  }

  if (cityLoading || mealTypeLoading || GstLoading) return <Loader />
  return (
    <TabPane id="steparrow-gen-info" tabId={activeTab}>
      <Card className="border border-black">
        <Formik
          initialValues={form}
          enableReinitialize={true}
          validationSchema={Yup.object({
            ItineraryNoOfDay: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .lessThan(100, NO_OF_DAYS)
              .required(REQUIRED),
            ItineraryDate: Yup.string().required(REQUIRED),
            PaidPax: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .required(REQUIRED),
            Adults: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .required(REQUIRED),

            CityID: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .required(REQUIRED)
              .positive(POSITIVE),
            HotelCategory: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .required(REQUIRED),
            HotelID: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .required(REQUIRED),
            RoomCategoryID: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .required(REQUIRED),
            MealPlanID: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .required(REQUIRED),
            GSTID: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            DORoom: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            SORoom: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            ExtraBedAdults: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            ExtraBedCWB: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            ExtraBedCNB: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            SORate: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            DORate: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            CWBRate: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            CNBRate: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            EARate: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            KitchenCharges: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            ExtraBF: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            ExtraLunch: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            HiTea: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            ExtraDinner: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            EarlyCheckInRate: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            LateCheckOutRate: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            Total: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            Margin: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            MarginTotal: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            GSTAmount: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            CNB: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, INVALID),
            GrandTotal: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
          })}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form noValidate autoComplete="off">
              {/* Auto Fill Component */}
              <AutoFill />
              {/* {console.log(data?.errors)} */}
              <CardHeader>
                <Row>
                  <Col md="9">
                    <h4>Accommodation</h4>
                  </Col>
                  <Col md="3">
                    <div className="d-flex align-items-center justify-content-between">
                      <Label
                        htmlFor="QuoteItineraryGrandTotal"
                        className="w-100"
                      >
                        Grand Total
                      </Label>

                      <Field
                        name="QuoteItineraryGrandTotal"
                        type="number"
                        className="form-control"
                        id="QuoteItineraryGrandTotal"
                        placeholder="Grand Total"
                        component={TextField}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div>
                  <Row>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accomodation-name"
                        >
                          Day No
                        </Label>
                        <Field
                          name="ItineraryNoOfDay"
                          type="number"
                          className="form-control"
                          id="accomodation-name"
                          placeholder="Enter Day no"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accomodation-name"
                        >
                          Date
                        </Label>
                        <Date />
                      </div>
                    </Col>
                    <Total />
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-TotalPax"
                        >
                          Total No Of Person
                        </Label>
                        <Field
                          name="TotalPax"
                          disabled
                          type="number"
                          className="form-control"
                          id="accommodation-TotalPax"
                          placeholder="Total No of Person"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-Adults"
                        >
                          No Of Adults ({`> 12 Yr`})
                        </Label>
                        <Field
                          name="Adults"
                          type="number"
                          className="form-control"
                          id="accommodation-Adults"
                          placeholder="b.	No Of Adults (> 12 Yr)"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-Child"
                        >
                          Child (0-5 Year).
                        </Label>
                        <Field
                          name="Child"
                          type="number"
                          className="form-control"
                          id="accommodation-Child"
                          placeholder="Child (0-5 Year)"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-CWB"
                        >
                          CWB (6-12 Year)
                          {/* No of Extra Bed for Adults */}
                        </Label>
                        <Field
                          name="CWB"
                          type="number"
                          className="form-control"
                          id="accommodation-CWB"
                          placeholder="No of Extra Bed for Kids"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-CNB"
                        >
                          CNB (6-12 Year)
                        </Label>
                        <Field
                          name="CNB"
                          type="number"
                          className="form-control"
                          id="accommodation-CNB"
                          placeholder=" No of Kids Without Bed"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-PaidPax"
                        >
                          Paid Guest
                        </Label>
                        <Field
                          name="PaidPax"
                          type="number"
                          className="form-control"
                          id="accommodation-PaidPax"
                          placeholder="Paid Guest"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-SORoom"
                        >
                          No Of Single Occ Room
                        </Label>
                        <Field
                          name="SORoom"
                          type="number"
                          className="form-control"
                          id="accommodation-SORoom"
                          placeholder="No of Single Occ Room"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-DORoom"
                        >
                          No Of Double occ room
                        </Label>
                        <Field
                          name="DORoom"
                          type="number"
                          className="form-control"
                          id="accommodation-DORoom"
                          placeholder="No of Double Occ Room"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-ExtraBedAdults"
                        >
                          No Of Extra Bed for Adults {`(>12 yr)`}
                        </Label>
                        <Field
                          name="ExtraBedAdults"
                          type="number"
                          className="form-control"
                          id="accommodation-ExtraBedAdults"
                          placeholder="No of Extra Bed for Adults"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-city"
                        >
                          City
                        </Label>
                        <Field
                          id="accommodation-city"
                          name="CityID"
                          options={
                            Array.isArray(cities)
                              ? cities.map((city) => {
                                  return {
                                    value: city?.CityID || "",
                                    label: city?.CityName || "",
                                  }
                                })
                              : []
                          }
                          component={SelectionField}
                        />
                      </div>
                    </Col>{" "}
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-HotelCategory"
                        >
                          Hotel Category
                        </Label>
                        <Field
                          id="accommodation-HotelCategory"
                          name="HotelCategory"
                          options={HOTEL_CATEGORIES.map((hc, index) => {
                            return {
                              value: index + 1,
                              label: hc,
                            }
                          })}
                          component={SelectionField}
                        />
                      </div>
                    </Col>{" "}
                    <HotelDropDown />
                    {/*  */}
                    <RoomCategory />
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-MealPlanID"
                        >
                          Meal Plan
                        </Label>
                        <Field
                          id="accommodation-MealPlanID"
                          name="MealPlanID"
                          onBlur={() => {
                            console.log("called")
                          }}
                          options={
                            Array.isArray(mealTypes)
                              ? mealTypes.map((meal) => {
                                  return {
                                    value: meal?.ID || "",
                                    label: meal?.MealType || "",
                                  }
                                })
                              : []
                          }
                          component={SelectionField}
                        />
                      </div>
                    </Col>
                    <QuotationRate />
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-SORate"
                        >
                          Single Occ Rate
                        </Label>
                        <Field
                          name="SORate"
                          type="number"
                          className="form-control"
                          id="accommodation-SORate"
                          placeholder="Single Occ Rate"
                          component={TextField}
                        />
                      </div>
                    </Col>{" "}
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-DORate"
                        >
                          Double Occ Rate
                        </Label>
                        <Field
                          name="DORate"
                          type="number"
                          className="form-control"
                          id="accommodation-DORate"
                          placeholder="Double Occ Rate"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-EARate"
                        >
                          Extra Adult Rate
                        </Label>
                        <Field
                          name="EARate"
                          type="number"
                          className="form-control"
                          id="accommodation-EARate"
                          placeholder="Extra Adult Rate"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-CWBRate"
                        >
                          CWB Rate
                        </Label>
                        <Field
                          name="CWBRate"
                          type="number"
                          className="form-control"
                          id="accommodation-CWBRate"
                          placeholder="CWB Rate"
                          component={TextField}
                        />
                      </div>
                    </Col>{" "}
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-CNBRate"
                        >
                          CNB Rate
                        </Label>
                        <Field
                          name="CNBRate"
                          type="number"
                          className="form-control"
                          id="accommodation-CNBRate"
                          placeholder="CNB Rate"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-ExtraBF"
                        >
                          Extra BF
                        </Label>
                        <Field
                          name="ExtraBF"
                          type="number"
                          className="form-control"
                          id="accommodation-ExtraBF"
                          placeholder="Extra BF"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-ExtraLunch"
                        >
                          Extra Lunch
                        </Label>
                        <Field
                          name="ExtraLunch"
                          type="number"
                          className="form-control"
                          id="accommodation-ExtraLunch"
                          placeholder="Extra Lunch"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-HiTea"
                        >
                          Hi-Tea
                        </Label>
                        <Field
                          name="HiTea"
                          type="number"
                          className="form-control"
                          id="accommodation-HiTea"
                          placeholder="Hi-Tea"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-ExtraDinner"
                        >
                          Extra Dinner
                        </Label>
                        <Field
                          name="ExtraDinner"
                          type="number"
                          className="form-control"
                          id="accommodation-ExtraDinner"
                          placeholder="Extra Dinner"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-KitchenCharges"
                        >
                          Kitchen & Dinning Charges
                        </Label>
                        <Field
                          name="KitchenCharges"
                          type="number"
                          className="form-control"
                          id="accommodation-KitchenCharges"
                          placeholder="Kitchen & Dinning Charges"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-EarlyCheckInRate"
                        >
                          Early Check In Charges
                        </Label>
                        <Field
                          name="EarlyCheckInRate"
                          type="number"
                          className="form-control"
                          id="accommodation-EarlyCheckInRate"
                          placeholder="Early Check In Charges"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-LateCheckOutRate"
                        >
                          Late CheckOut Charges
                        </Label>
                        <Field
                          name="LateCheckOutRate"
                          type="number"
                          className="form-control"
                          id="accommodation-LateCheckOutRate"
                          placeholder="Late CheckOut Charges"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-Total"
                        >
                          Accommodation Total
                        </Label>
                        <Field
                          name="Total"
                          type="number"
                          disabled
                          className="form-control"
                          id="accommodation-Total"
                          placeholder="Accommodation Total"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-Margin"
                        >
                          Margin
                        </Label>
                        <Field
                          name="Margin"
                          type="number"
                          className="form-control"
                          id="accommodation-Margin"
                          placeholder="Margin"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-MarginTotal"
                        >
                          Total With Margin
                        </Label>
                        <Field
                          name="MarginTotal"
                          disabled
                          type="number"
                          className="form-control"
                          id="accommodation-MarginTotal"
                          placeholder="Total With Margin"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-GSTID"
                        >
                          GST
                        </Label>
                        <Field
                          id="accommodation-GSTID"
                          name="GSTID"
                          options={GSTActiveList.map((gst) => {
                            return {
                              value: gst?.ID || "",
                              label: gst?.Title || "",
                            }
                          })}
                          component={SelectionField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-GSTAmount"
                        >
                          GST Amount
                        </Label>
                        <Field
                          name="GSTAmount"
                          type="number"
                          className="form-control"
                          id="accommodation-GSTAmount"
                          placeholder="GST Amount"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label
                          className="form-label"
                          htmlFor="accommodation-GrandTotal"
                        >
                          Total Amount
                        </Label>
                        <Field
                          name="GrandTotal"
                          type="number"
                          className="form-control"
                          id="accommodation-GrandTotal"
                          placeholder="Total Amount"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={12}>
                      <hr></hr>
                    </Col>
                    <Col md="12" className="text-end">
                      <Button className="success" type="submit">
                        Save & Add Another
                      </Button>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Form>
          )}
        </Formik>
        {showTable ? (
          <CardBody>
            <hr></hr>
            <Col lg={12}>
              <div className="table-responsive mt-2">
                <Table
                  className="align-middle table-nowrap mb-0"
                  striped
                  responsive
                  hover
                >
                  <thead>
                    <tr>
                      <th scope="col">Day No</th>
                      <th scope="col">Day</th>
                      <th scope="col">Date</th>
                      <th scope="col">City</th>
                      <th scope="col">Hotel Name</th>
                      <th scope="col">Room Category</th>
                      <th scope="col">Meal Plan</th>
                      <th scope="col">Total Person</th>
                      <th scope="col">Total Amount</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(list) && list.length ? (
                      list.map((acc, i) => {
                        return (
                          <tr key={i}>
                            <td scope="col">{acc?.ItineraryNoOfDay}</td>
                            <td scope="col">{acc?.ItineraryDay}</td>
                            <td scope="col">{acc?.ItineraryDate}</td>
                            <td scope="col">{acc?.CityName}</td>
                            <td scope="col">{acc?.HotelName}</td>
                            <td scope="col">{acc?.HotelCategory}*</td>
                            <td scope="col">{acc?.MealPlanName}</td>
                            <td scope="col">{acc?.TotalPax}</td>
                            <td scope="col">{acc?.GrandTotal}</td>
                            <td scope="col">
                              {" "}
                              <div
                                className="hstack gap-3 flex-wrap "
                                role="button"
                              >
                                <i
                                  className="ri-edit-line text-success fs-18"
                                  onClick={() => editAccommodation(acc)}
                                ></i>
                                <div className="gap-3 " role="button">
                                  <i
                                    className="las la-clipboard text-primary fs-18"
                                    onClick={() => onClickClipBoard(acc)}
                                  ></i>
                                </div>
                                <div className="gap-3 " role="button">
                                  <i
                                    className="ri-delete-bin-fill text-danger fs-18"
                                    onClick={() => onClickDelete(acc)}
                                  ></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : list.length === 0 ? (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No accommodation found
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </div>
              <DeleteModal
                show={deleteModal}
                onCloseClick={() => setDeleteModal(false)}
                onDeleteClick={() => onDeleted()}
              />
              <AccommodationClipModal />
            </Col>
          </CardBody>
        ) : null}
      </Card>
    </TabPane>
  )
}

Accommodation.propTypes = {
  showTable: PropTypes.bool,
}
export default Accommodation

// Date Picker
function Date() {
  const {
    newQuotation: { form },
  } = useSelector((state) => state.Quotation)
  const { values, setFieldValue } = useFormikContext()

  const handleDateChange = (ev) => {
    setFieldValue(
      "ItineraryDate",
      Array.isArray(ev) && ev.length
        ? moment(ev[0]).format(STANDARD_INPUT_DATE)
        : null
    )
  }
  return (
    <React.Fragment>
      <Field>
        {({ field }) => (
          <Flatpickr
            name="ItineraryDate"
            id="ItineraryDate"
            {...field}
            value={values.ItineraryDate}
            placeholder="Select Date.."
            className="form-control"
            options={{
              altInput: true,
              altFormat: "d-m-Y",
              dateFormat: "d-m-Y",
              minDate: form?.DepartureDate || null,
            }}
            onChange={handleDateChange}
          />
        )}
      </Field>
      <ErrorMessage
        name={"ItineraryDate"}
        component="div"
        className="text-danger"
      />
    </React.Fragment>
  )
}
function HotelDropDown() {
  const dispatch = useDispatch()
  const { values } = useFormikContext()
  const { HotelCityWiseAndRoomCategory } = useSelector((state) => state.Hotel)

  useEffect(() => {
    if (values?.CityID && values?.HotelCategory) {
      let data = {
        CityID: Number(values?.CityID) || "",
        HotelCategory: values?.HotelCategory || "",
      }
      dispatch(getHotelCityWiseAndRoomCategory(data))
    } else {
      dispatch(resetHotelCityWiseAndRoomCategory())
    }
  }, [values?.CityID, values?.HotelCategory])

  return (
    <Col lg={2}>
      <div className="mb-3">
        <Label className="form-label" htmlFor="accommodation-HotelID">
          Hotel Name
        </Label>
        <Field
          id="accommodation-HotelID"
          name="HotelID"
          options={
            Array.isArray(HotelCityWiseAndRoomCategory)
              ? HotelCityWiseAndRoomCategory.map((hotel) => {
                  return {
                    value: hotel?.HotelID || "",
                    label: hotel?.HotelName || "",
                  }
                })
              : []
          }
          component={SelectionField}
        />
      </div>
    </Col>
  )
}
function RoomCategory() {
  const { HotelCityWiseAndRoomCategory } = useSelector((state) => state.Hotel)

  const { values } = useFormikContext()
  const roomList = useMemo(() => {
    let list = []
    if (values?.HotelID) {
      list = _.get(
        _.find(
          HotelCityWiseAndRoomCategory,
          (h) => Number(h?.HotelID) === Number(values?.HotelID)
        ),
        "RoomCategoryList",
        []
      )
    }
    return list
  }, [values?.HotelID, HotelCityWiseAndRoomCategory])

  return (
    <Col lg={2}>
      <div className="mb-3">
        <Label className="form-label" htmlFor="accommodation-RoomCategoryID">
          Room Category
        </Label>
        <Field
          id="accommodation-RoomCategoryID"
          name="RoomCategoryID"
          options={
            Array.isArray(roomList)
              ? roomList.map((room) => {
                  return {
                    value: room?.ID || "",
                    label: room?.RoomCategoryName || "",
                  }
                })
              : []
          }
          component={SelectionField}
        />
      </div>
    </Col>
  )
}

function QuotationRate() {
  const dispatch = useDispatch()

  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    if (values?.HotelID && values.MealPlanID && values?.RoomCategoryID) {
      let obj = {
        HotelID: Number(values?.HotelID),
        RoomCategoryID: Number(values?.RoomCategoryID),
        MealPlanID: Number(values.MealPlanID),

        ItineraryDate: values?.ItineraryDate
          ? moment(values.ItineraryDate, STANDARD_INPUT_DATE).format(
              STANDARD_OUTPUT_DATE
            )
          : "",
        SORoom: values?.SORoom || 0,
        DORoom: values?.DORoom || 0,
        Adults: values?.Adults,
        CWB: values?.CWB || 0,
        CNB: values?.CNB || 0,
        ExtraBedAdults: values?.ExtraBedAdults || 0,
      }
      dispatch(getRates(obj)).then((res) => {
        if (res?.payload.Message === "Failure") {
          toast.error(res?.payload?.Details, {
            autoClose: AUTO_CLOSE,
          })
        }
        if (res?.payload.Message === "Success") {
          const Data = res?.payload?.Data
          setFieldValue("CNBRate", Data?.CNBRate)
          setFieldValue("CWBRate", Data?.CWBRate)
          setFieldValue("DORate", Data?.DORate)
          setFieldValue("KitchenCharges", Data?.KitchenCharges)
          setFieldValue("SORate", Data?.SORate)
        }
      })
    }
  }, [
    values?.ItineraryDate,
    values?.HotelID,
    values.MealPlanID,
    values?.RoomCategoryID,
    values?.SORoom,
    values?.DORoom,
    values?.CWB,
    values?.CNB,
    values?.ExtraBedAdults,
  ])
  return null
}
//
export function TotalSumComponent() {
  const { values, setFieldValue } = useFormikContext()
  const { GSTActiveList } = useSelector((state) => state.Gst)

  const Sum = useCallback(
    (values, subArray) => {
      let value = _.sum(
        _.filter(_.values(_.pick(values, subArray)), function (num) {
          return _.isNumber(num)
        })
      )

      return _.round(value)
    },
    [values]
  )

  useMemo(() => {
    setFieldValue("TotalPax", Sum(values, ["Adults", "Child", "CNB", "CWB"]))
  }, [values?.Adults, values?.CNB, values?.CWB, values?.Child])
  useMemo(() => {
    setFieldValue("PaidPax", Sum(values, ["Adults", "CWB", "CNB"]))
  }, [values?.Adults, values?.CWB, values?.CNB])
  useMemo(() => {
    setFieldValue(
      "Total",
      Sum(values, [
        "SORate",
        "DORate",
        "EARate",
        "CWBRate",
        "CNBRate",
        "ExtraBF",
        "ExtraLunch",
        "HiTea",
        "ExtraDinner",
        "KitchenCharges",
        "EarlyCheckInRate",
        "LateCheckOutRate",
      ])
    )
  }, [
    values?.SORate,
    values?.DORate,
    values?.EARate,
    values?.CWBRate,
    values?.CNBRate,
    values?.ExtraBF,
    values?.ExtraLunch,
    values?.HiTea,
    values?.ExtraDinner,
    values?.KitchenCharges,
    values?.EarlyCheckInRate,
    values?.LateCheckOutRate,
  ])

  // Total Margin Total
  useMemo(() => {
    setFieldValue("MarginTotal", Sum(values, ["Total", "Margin"]))
  }, [values?.Total, values?.Margin])

  // GST Total Amount
  useMemo(() => {
    const findGst = _.find(GSTActiveList, (q) => q.ID === Number(values?.GSTID))
    if (findGst?.IsApplyOnServiceMargin) {
      let totalGst = _.round(values?.Margin * ((findGst?.GSTPer || 0) / 100), 2)
      setFieldValue("GSTAmount", totalGst)
    } else {
      let totalGst = _.round(
        values?.MarginTotal * ((findGst?.GSTPer || 0) / 100),
        2
      )
      setFieldValue("GSTAmount", totalGst)
    }
    setFieldValue("GSTPer", findGst?.GSTPer)
  }, [GSTActiveList, values?.GSTID, values?.MarginTotal, values?.Margin])

  //  Grand Total
  //
  useMemo(() => {
    setFieldValue("GrandTotal", Sum(values, ["MarginTotal", "GSTAmount"]))
  }, [values?.MarginTotal, values?.GSTAmount])
  return null
}
export const Total = React.memo(TotalSumComponent)

function AutoFillComponent() {
  const {
    newQuotation: { form },
    accommodation: { form: accForm },
  } = useSelector((state) => state.Quotation)

  const { setFieldValue } = useFormikContext()

  useEffect(() => {
    if (!accForm?.ItineraryNoOfDay) {
      setFieldValue("ItineraryNoOfDay", form?.NoOfDays || 0)
    }

    // Adult
    if (!accForm?.Adults) {
      setFieldValue("Adults", form?.Adults || 0)
    }

    // CWB
    if (!accForm?.CWB) {
      setFieldValue("CWB", form?.CWB || 0)
    }
    if (!accForm?.Child) {
      setFieldValue("Child", form?.Child || 0)
    }
    if (!accForm?.CNB) {
      setFieldValue("CNB", form?.CNB || 0)
    }

    // SORoom
    if (!accForm?.SORoom) {
      setFieldValue("SORoom", form?.SORoom || 0)
    }

    // // DORoom
    if (!accForm?.DORoom) {
      setFieldValue("DORoom", form?.DORoom || 0)
    }

    // //ExtraBedAdults

    if (!accForm?.ExtraBedAdults) {
      setFieldValue("ExtraBedAdults", form?.ExtraBedAdults || 0)
    }

    // //ExtraBedCNB

    if (!accForm?.ExtraBedCNB) {
      setFieldValue("ExtraBedCNB", form?.ExtraBedCNB || 0)
    }

    // // ExtraBedCWB
    if (!accForm?.ExtraBedCWB) {
      setFieldValue("ExtraBedCWB", form?.ExtraBedCWB || 0)
    }
  }, [
    form?.NoOfDays,
    accForm?.ItineraryNoOfDay,
    accForm?.Adults,
    form?.Adults,
    accForm?.CWB,
    form?.CWB,
    form?.CNB,
    accForm?.CNB,
    form?.DORoom,
    accForm?.DORoom,
    form?.SORoom,
    accForm?.SORoom,
    form?.ExtraBedAdults,
    accForm?.ExtraBedAdults,
    form?.ExtraBedCNB,
    accForm?.ExtraBedCNB,
    form?.ExtraBedCWB,
    accForm?.ExtraBedCWB,
    accForm?.Child,
  ])

  return null
}

export const AutoFill = React.memo(AutoFillComponent)
