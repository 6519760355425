import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, Col, Label, Row, TabPane, Table } from "reactstrap"
import {
  NO_OF_DAYS,
  POSITIVE,
  REQUIRED,
  STANDARD_INPUT_DATE,
  STANDARD_OUTPUT_DATE,
  SelectionField,
  TextField,
} from "../../Components/constants/field"
import Flatpickr from "react-flatpickr"
import PickUpAndDropForm from "./PickUpAndDropForm"
import {
  createTransportation,
  getActiveGst,
  getAllCities,
  getQuotationDetail,
  getTransportation,
  getVehicleTypeByNoOfPax,
  updateTransportation,
} from "../../slices/thunks"
import { useEffect } from "react"
import Loader from "../../Components/Common/Loader"
import moment from "moment"
import * as Yup from "yup"
import { useMemo } from "react"
import { useCallback } from "react"
import DeleteModal from "../../Components/Common/DeleteModal"
import { useLocation } from "react-router-dom"
import _ from "lodash"
import PropTypes from "prop-types"
import { showModal } from "../../slices/quotation/reducer"
//
const pickUpAndDropArr = [
  {
    headerTitle: "Pickup Charges",
    field: "PickUp",
  },
  {
    headerTitle: "Drop Charges",
    field: "Drop",
  },
]

const Transportation = (props) => {
  const { showTable = true } = props
  const dispatch = useDispatch()
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const qID = urlParams.get("q")
  const {
    activeTab,
    transportation: { form, list },
    modal: { modal },
  } = useSelector((state) => state.Quotation)
  const { cities, loading: cityLoading } = useSelector((state) => state.City)
  const { GSTActiveList, loading: GstLoading } = useSelector(
    (state) => state.Gst
  )

  //  reload

  const [reload, setReload] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  //
  useEffect(() => {
    dispatch(getAllCities())
    dispatch(getActiveGst())
  }, [])
  useEffect(() => {
    if (qID && !modal) {
      let quotationDetail = {
        ID: Number(qID),
      }

      dispatch(getQuotationDetail(quotationDetail))
    }
  }, [qID, reload, modal])

  const handleSubmit = (values, { resetForm }) => {
    let obj = {
      ...values,
    }

    obj.CityID = +obj.CityID
    obj.ID = +obj.ID
    obj.VehicleTypeID = +obj.VehicleTypeID
    obj.GSTID = +obj.GSTID
    obj.GSTPer = +obj.GSTPer
    obj.KmPerDay = String(obj.KmPerDay)
    obj.PickUpChargeDate = obj.PickUpChargeDate
      ? moment(obj.PickUpChargeDate, STANDARD_INPUT_DATE).format(
          STANDARD_OUTPUT_DATE
        )
      : ""
    obj.DropChargeDate = obj.DropChargeDate
      ? moment(obj.DropChargeDate, STANDARD_INPUT_DATE).format(
          STANDARD_OUTPUT_DATE
        )
      : ""
    obj.DropDate = obj.DropDate
      ? moment(obj.DropDate, STANDARD_INPUT_DATE).format(STANDARD_OUTPUT_DATE)
      : ""
    obj.PickUpDate = obj.PickUpDate
      ? moment(obj.PickUpDate, STANDARD_INPUT_DATE).format(STANDARD_OUTPUT_DATE)
      : ""
    obj[`IsActive`] = true
    if (!obj.ID) {
      delete obj.ID
      obj[`OperationType`] = 10
      obj[`QuoteID`] = Number(qID)
      dispatch(createTransportation(obj)).then((res) => {
        setReload(!reload)
        if (res.payload && res.payload.Status === 200) {
          resetForm()
          if (modal) {
            let obj = {
              modal: false,
              modalType: "",
              modalData: {},
            }
            dispatch(showModal(obj))
          }
        }
      })
    } else {
      obj[`OperationType`] = 11
      obj[`QuoteID`] = Number(qID)
      dispatch(updateTransportation(obj)).then((res) => {
        setReload(!reload)
        if (res.payload && res.payload.Status === 200) {
          resetForm()
          if (modal) {
            let obj = {
              modal: false,
              modalType: "",
              modalData: {},
            }
            dispatch(showModal(obj))
          }
        }
      })
    }
  }
  const editTransportation = (data) => {
    let obj = {
      ID: data?.ID || "",
      QuoteID: data?.QuoteID || "",
    }
    dispatch(getTransportation(obj))
  }

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
      QuoteID: +deleteData?.QuoteID,
      OperationType: 12,
      IsActive: false,
    }
    dispatch(updateTransportation(obj)).then((res) => {
      setReload(!reload)
      if (res.payload && res.payload.Status === 200) {
        setDeleteModal(false)
      }
    })
  }

  const onClickDelete = (data) => {
    setDeleteModal(true)
    setDeleteData(data)
  }

  if (cityLoading || GstLoading) return <Loader />

  return (
    <TabPane id="steparrow-gen-info" tabId={activeTab}>
      <Card body className="border border-black">
        <Formik
          initialValues={form}
          enableReinitialize={true}
          validationSchema={Yup.object({
            CityID: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .required(REQUIRED),
            VehicleTypeID: Yup.string().required(REQUIRED),
            NoOfDays: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .lessThan(100, NO_OF_DAYS)
              .required(REQUIRED),
            KmPerDay: Yup.string().required(REQUIRED),

            RatePerKm: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .required(REQUIRED),
            RatesByKmTotal: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE),
            DriverAllounce: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            TollParking: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            BorderCharges: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            DriverChargesTotal: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            PickUpCharges: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            DropCharges: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            AdditionalTransportCharges: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            Total: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            GSTAmount: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            GrandTotal: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            PickUpDate: Yup.string().required(REQUIRED),
            DropDate: Yup.string().required(REQUIRED),
          })}
          onSubmit={handleSubmit}
        >
          {() => {
            return (
              <Form noValidate autoComplete="off">
                <Row>
                  <Col md="9" className="mb-3">
                    <h4>Transport</h4>
                  </Col>
                  <Col md="3" className="mb-3">
                    <Row className="align-items-center">
                      <Col md={5}>
                        <Label htmlFor="TransportGrandTotal">
                          Total Amount
                        </Label>
                      </Col>
                      <Col md={7}>
                        <Field
                          name="TransportGrandTotal"
                          type="number"
                          className="form-control"
                          id="TransportGrandTotal"
                          placeholder="Total Amount"
                          component={TextField}
                          disabled
                        />
                      </Col>
                    </Row>
                  </Col>
                  <hr></hr>
                </Row>
                <Row>
                  <Col lg={2}>
                    <AutoFill />
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="transportation-city"
                      >
                        City
                      </Label>
                      <Field
                        id="transportation-city"
                        name="CityID"
                        options={
                          Array.isArray(cities)
                            ? cities.map((city) => {
                                return {
                                  value: city?.ID || "",
                                  label: city?.CityName || "",
                                }
                              })
                            : []
                        }
                        component={SelectionField}
                      />
                    </div>
                  </Col>
                  <VehicleType />
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="transportation-PickUpDate"
                      >
                        Pick Up Date
                      </Label>
                      <PickUpDate />
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="transportation-DropDate"
                      >
                        Drop Date
                      </Label>
                      <DropDate />
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-NoOfDays"
                      >
                        No of Days
                      </Label>
                      <Field
                        name="NoOfDays"
                        type="number"
                        className="form-control"
                        id="accomodation-NoOfDays"
                        placeholder=" No of Days"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-KmPerDay"
                      >
                        Km/Day
                      </Label>
                      <Field
                        name="KmPerDay"
                        type="number"
                        className="form-control"
                        id="accomodation-KmPerDay"
                        placeholder="Km/Day"
                        component={TextField}
                      />
                    </div>
                  </Col>{" "}
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-RatePerKm"
                      >
                        Rate/Km
                      </Label>
                      <Field
                        name="RatePerKm"
                        type="number"
                        className="form-control"
                        id="accomodation-RatePerKm"
                        placeholder="Rate/Km"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Total />
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-RatesByKmTotal"
                      >
                        Total
                      </Label>
                      <Field
                        name="RatesByKmTotal"
                        type="number"
                        className="form-control"
                        id="accomodation-RatesByKmTotal"
                        placeholder="Rate/Km"
                        component={TextField}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-DriverAllounce"
                      >
                        Driver Baata/Day
                      </Label>
                      <Field
                        name="DriverAllounce"
                        type="number"
                        className="form-control"
                        id="accomodation-DriverAllounce"
                        placeholder="Driver Bata/Day"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-TollParking"
                      >
                        Toll & PKG
                      </Label>
                      <Field
                        name="TollParking"
                        type="number"
                        className="form-control"
                        id="accomodation-TollParking"
                        placeholder="Toll & PKG"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-BorderCharges"
                      >
                        Border Tax
                      </Label>
                      <Field
                        name="BorderCharges"
                        type="number"
                        className="form-control"
                        id="accomodation-BorderCharges"
                        placeholder="Toll & PKG"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-DriverChargesTotal"
                      >
                        Drive Allounce Total
                      </Label>
                      <Field
                        name="DriverChargesTotal"
                        type="number"
                        className="form-control"
                        id="accomodation-DriverChargesTotal"
                        placeholder="Drive Allounce Total"
                        component={TextField}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>

                {/* Pickup Charges  And Drop Charges Form*/}
                {Array.isArray(pickUpAndDropArr)
                  ? pickUpAndDropArr.map((list, i) => (
                      <React.Fragment key={i}>
                        <PickUpAndDropForm
                          headerTitle={list?.headerTitle || ""}
                          field={list.field}
                        />
                      </React.Fragment>
                    ))
                  : null}
                <Row>
                  {/* <hr></hr> */}
                  <Col md="12" className="mb-2 mt-4">
                    <h4>Additional Transport</h4>
                  </Col>
                  <hr></hr>
                </Row>
                <Row>
                  <Col lg={10}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-Remarks"
                      >
                        Remarks
                      </Label>
                      <Field
                        name="Remarks"
                        type="textarea"
                        className="form-control"
                        id="accomodation-Remarks"
                        placeholder="Remarks"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col lg={2}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-AdditionalTransportCharges"
                      >
                        Rate
                      </Label>
                      <Field
                        name="AdditionalTransportCharges"
                        type="number"
                        className="form-control"
                        id="accomodation-AdditionalTransportCharges"
                        placeholder="Driver Bata/Day"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-Total"
                      >
                        Total
                      </Label>
                      <Field
                        name="Total"
                        type="number"
                        className="form-control"
                        id="accomodation-Total"
                        placeholder="Total"
                        component={TextField}
                        disabled
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="transportation-GST"
                      >
                        GST
                      </Label>
                      <Field
                        id="transportation-GST"
                        name="GSTID"
                        options={GSTActiveList.map((gst) => {
                          return {
                            value: gst?.ID || "",
                            label: gst?.Title || "",
                          }
                        })}
                        component={SelectionField}
                      />
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-GSTAmount"
                      >
                        GST Amount
                      </Label>
                      <Field
                        name="GSTAmount"
                        type="number"
                        className="form-control"
                        id="accomodation-GSTAmount"
                        placeholder="GST Amount"
                        component={TextField}
                        disabled
                      />
                    </div>
                  </Col>{" "}
                  <Col lg={3}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="accomodation-GrandTotal"
                      >
                        Total Amount
                      </Label>
                      <Field
                        name="GrandTotal"
                        type="number"
                        className="form-control"
                        id="accomodation-GrandTotal"
                        placeholder="Total Amount"
                        component={TextField}
                        disabled
                      />
                    </div>
                  </Col>
                  <Row className="mt-2">
                    <Col lg={12}>
                      <hr></hr>
                    </Col>
                    <Col md="12" className="text-end">
                      <Button className="success">Save & Add Another</Button>
                    </Col>
                  </Row>
                </Row>
              </Form>
            )
          }}
        </Formik>
        {/* <CardBody> */}
        {showTable ? (
          <React.Fragment>
            <hr></hr>

            <Col lg={12}>
              <div className="table-responsive mt-2">
                <Table
                  className="align-middle table-nowrap mb-0"
                  striped
                  responsive
                  hover
                >
                  <thead>
                    <tr>
                      <th scope="col">Sr No</th>
                      <th scope="col">City</th>
                      <th scope="col">Vehicle Type</th>
                      <th scope="col">Pickup Date</th>
                      <th scope="col">Drop Date</th>
                      <th scope="col">No of Days</th>
                      <th scope="col">Km Per Day</th>
                      <th scope="col">Rate Per Km</th>
                      <th scope="col">Grand Total</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(list) && list.length ? (
                      list.map((trans, i) => {
                        return (
                          <tr key={i}>
                            <td scope="col">{i + 1}</td>
                            <td scope="col">{trans?.CityName || ""}</td>
                            <td scope="col">{trans?.VehicleTypeName || ""}</td>
                            <td scope="col">{trans?.PickUpDate || ""}</td>
                            <td scope="col">{trans?.DropDate || ""}</td>
                            <td scope="col">{trans?.NoOfDays || ""}</td>
                            <td scope="col">{trans?.KmPerDay || ""}</td>
                            <td scope="col">{trans?.RatePerKm || ""}</td>
                            <td scope="col">{trans?.GrandTotal || ""}</td>
                            <td scope="col">
                              {" "}
                              <div
                                className="hstack gap-3 flex-wrap "
                                role="button"
                              >
                                <i
                                  className="ri-edit-line text-success fs-18"
                                  //
                                  onClick={() => editTransportation(trans)}
                                ></i>
                                <div className="gap-3 " role="button">
                                  <i
                                    className="ri-delete-bin-fill text-danger fs-18"
                                    onClick={() => onClickDelete(trans)}
                                  ></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : list.length === 0 ? (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No transportation found
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </div>

              <DeleteModal
                show={deleteModal}
                onCloseClick={() => setDeleteModal(false)}
                onDeleteClick={() => onDeleted()}
              />
            </Col>
          </React.Fragment>
        ) : null}

        {/* </CardBody> */}
      </Card>
    </TabPane>
  )
}
Transportation.propTypes = {
  showTable: PropTypes.bool,
}
export default Transportation

// Pick up date
function PickUpDate() {
  const { values, setFieldValue } = useFormikContext()

  const handleDateChange = (ev) => {
    setFieldValue("PickUpDate", moment(ev[0]).format(STANDARD_INPUT_DATE))
  }

  return (
    <React.Fragment>
      <Field>
        {({ field }) => (
          <Flatpickr
            name="PickUpDate"
            id="PickUpDate"
            {...field}
            value={values.PickUpDate}
            placeholder="Select Date.."
            className="form-control"
            options={{
              altInput: true,
              altFormat: "d-m-Y",
              dateFormat: "d-m-Y",
              // minDate:
            }}
            onChange={handleDateChange}
          />
        )}
      </Field>
      <ErrorMessage
        name={"PickUpDate"}
        component="div"
        className="text-danger"
      />
    </React.Fragment>
  )
}

// Drop Date
function DropDate() {
  const { values, setFieldValue } = useFormikContext()
  const handleDateChange = (ev) => {
    setFieldValue("DropDate", moment(ev[0]).format(STANDARD_INPUT_DATE))
  }

  return (
    <React.Fragment>
      <Field>
        {({ field }) => (
          <Flatpickr
            name="DropDate"
            id="DropDate"
            {...field}
            value={values.DropDate}
            placeholder="Select Date.."
            className="form-control"
            options={{
              altInput: true,
              altFormat: "d-m-Y",
              dateFormat: "d-m-Y",
              minDate: values?.PickUpDate,
            }}
            onChange={handleDateChange}
          />
        )}
      </Field>
      <ErrorMessage name={"DropDate"} component="div" className="text-danger" />
    </React.Fragment>
  )
}

// Vehicle Type
function VehicleType() {
  const {
    newQuotation: { form },
  } = useSelector((state) => state.Quotation)
  const dispatch = useDispatch()
  const { vehicleTypes } = useSelector((state) => state.Vehicle)

  const { values } = useFormikContext()
  useEffect(() => {
    if (values?.CityID && form?.TotalPax) {
      let obj = {
        NoOfPax: form?.TotalPax || "",

        CityID: values?.CityID,
      }
      dispatch(getVehicleTypeByNoOfPax(obj))
    }
  }, [values?.CityID, form?.TotalPax])

  return (
    <Col lg={2}>
      <div className="mb-3">
        <Label className="form-label" htmlFor="transportation-VehicleTypeID">
          Vehicle Type
        </Label>
        <Field
          id="transportation-VehicleTypeID"
          name="VehicleTypeID"
          options={
            Array.isArray(vehicleTypes)
              ? vehicleTypes.map((vehicle) => {
                  return {
                    value: vehicle?.ID || "",
                    label: vehicle?.VehicleType || "",
                  }
                })
              : []
          }
          component={SelectionField}
        />
      </div>
    </Col>
  )
}

export function TotalSumComponent() {
  const { values, setFieldValue } = useFormikContext()
  const { GSTActiveList } = useSelector((state) => state.Gst)
  const TotalValue = useCallback(
    (values, subArray, type = "sum") => {
      let value = _.filter(
        _.map(_.values(_.pick(values, subArray)), (n) => Number(n)),
        function (num) {
          return _.isNumber(num)
        }
      )

      switch (type) {
        case "multiply":
          value = _.sum(value)
            ? value.reduce((acc, curr) => Number(acc) * Number(curr), 1)
            : 0
          break
        default:
          value = _.sum(value)
          break
      }

      return _.round(value)
    },
    [values]
  )
  // Rate By Km Total
  useMemo(() => {
    setFieldValue(
      "RatesByKmTotal",
      TotalValue(values, ["NoOfDays", "KmPerDay", "RatePerKm"], "multiply")
    )
  }, [values?.NoOfDays, values?.KmPerDay, values?.RatePerKm])

  // DriverCharges
  useMemo(() => {
    let DriveMulti = TotalValue(
      values,
      ["DriverAllounce", "NoOfDays"],
      "multiply"
    )

    const Sum = TotalValue(values, ["TollParking", "BorderCharges"], "sum")

    setFieldValue("DriverChargesTotal", DriveMulti + Sum)
  }, [
    values?.DriverAllounce,
    values?.NoOfDays,
    values?.TollParking,
    values?.BorderCharges,
  ])
  // Total in additional transportation rate
  useMemo(() => {
    setFieldValue(
      "Total",
      TotalValue(
        values,
        [
          "RatesByKmTotal",
          "DriverChargesTotal",
          "PickUpCharges",
          "DropCharges",
          "AdditionalTransportCharges",
        ],
        "sum"
      )
    )
  }, [
    values?.RatesByKmTotal,
    values?.DriverChargesTotal,
    values?.PickUpCharges,
    values?.DropCharges,
    values?.AdditionalTransportCharges,
  ])

  // Gst Calculation

  useMemo(() => {
    const findGst = _.find(GSTActiveList, (q) => q.ID === Number(values?.GSTID))
    // if (findGst?.IsApplyOnServiceMargin) {
    let totalGst = _.round(values?.Total * ((findGst?.GSTPer || 0) / 100), 2)
    setFieldValue("GSTAmount", totalGst)
    // } else {

    // }
    setFieldValue("GSTPer", findGst?.GSTPer)
  }, [GSTActiveList, values?.GSTID, values?.Total])

  //

  useMemo(() => {
    setFieldValue(
      "GrandTotal",
      TotalValue(values, ["Total", "GSTAmount"], "sum")
    )
  }, [values?.Total, values?.GSTAmount])
}

export const Total = React.memo(TotalSumComponent)

function AutoFillComponent() {
  const {
    newQuotation: { form },
    transportation: { form: transFrom },
  } = useSelector((state) => state.Quotation)

  const { vehicleTypes } = useSelector((state) => state.Vehicle)
  const { setFieldValue, values } = useFormikContext()

  useEffect(() => {
    if (!transFrom?.NoOfDays && !values?.ID) {
      setFieldValue("NoOfDays", form?.NoOfDays || 0)
    }
    if (!transFrom?.PickUpDate && !values?.ID) {
      setFieldValue("PickUpDate", form?.PickUpDate || "")
    }
    if (!transFrom?.DropDate && !values?.ID) {
      setFieldValue("DropDate", form?.DropDate || "")
    }
  }, [
    form?.NoOfDays,
    transFrom?.NoOfDays,
    transFrom?.PickUpDate,
    form?.PickUpDate,
    transFrom?.DropDate,
    form?.DropDate,
    !values?.ID,
  ])
  useEffect(() => {
    if (values?.VehicleTypeID && !values?.ID) {
      let findVehicle = _.find(
        vehicleTypes,
        (q) => q.ID === Number(values?.VehicleTypeID)
      )

      setFieldValue("DriverAllounce", findVehicle?.DriverAllowancePerDay)
      setFieldValue("KmPerDay", Number(findVehicle?.KmPerDay))
      setFieldValue("RatePerKm", Number(findVehicle?.RatePerKm))
    }
  }, [values?.VehicleTypeID])
  return null
}

export const AutoFill = React.memo(AutoFillComponent)
