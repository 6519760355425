/* eslint-disable no-debugger */
import { Field, Form, Formik } from "formik"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Label, Modal, ModalBody, Row } from "reactstrap"
import * as Yup from "yup"
import {
  NO_OF_DAYS,
  POSITIVE,
  REQUIRED,
  TextField,
} from "../../Components/constants/field"
import { AccommodationCopy } from "../../slices/thunks"
import { showClipModal } from "../../slices/quotation/reducer"

const AccommodationClipModal = () => {
  const dispatch = useDispatch()
  const { modal, form } = useSelector((state) => state.Quotation.clipModal)

  const handleSubmit = (values, { resetForm }) => {
    let obj = {
      ...values,
    }
    // debugger
    dispatch(AccommodationCopy(obj)).then((res) => {
      if (res) {
        let obj = {
          modal: false,
          form: {
            QuoteID: null,
            ItineraryNoOfDay: null,
            PlaceDayNo: null,
          },
        }
        dispatch(showClipModal(obj))
        resetForm()
      }
    })
  }

  const modalClose = () => {
    let obj = {
      modal: false,
      form: {
        QuoteID: null,
        ItineraryNoOfDay: null,
        PlaceDayNo: null,
      },
    }
    dispatch(showClipModal(obj))
  }
  return (
    <Modal isOpen={modal} centered toggle={modalClose}>
      <ModalBody toggle={modalClose}>
        <Row>
          <Col sm="12">
            <Formik
              initialValues={form}
              enableReinitialize={true}
              validationSchema={Yup.object({
                PlaceDayNo: Yup.number()
                  .transform((value) => (Number.isNaN(value) ? null : value))
                  .nullable()
                  .positive(POSITIVE)
                  .lessThan(100, NO_OF_DAYS)
                  .required(REQUIRED),
              })}
              onSubmit={handleSubmit}
            >
              <Form noValidate autoComplete="off">
                <Col lg={12}>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="PlaceDayNo">
                      Place Day No
                    </Label>
                    <Field
                      name="PlaceDayNo"
                      type="number"
                      className="form-control"
                      id="PlaceDayNo"
                      placeholder="Enter Place Day No"
                      component={TextField}
                    />
                  </div>
                </Col>
                <Col md="12" className="text-end">
                  <Button className="success" type="submit">
                    Save
                  </Button>
                </Col>
              </Form>
            </Formik>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default AccommodationClipModal
