import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getSubSectorBySectorIDApi = (SectorID) =>
    api.create(url.GET_SUBSECTOR_BY_SECTOR_ID, { SectorID: SectorID });

    export const getAllSubSectorApi=()=>api.get(url.FIND_ALL_SUBSECTOR_URL);

export const getAllActiveSubSectorApi = () =>
    api.get(url.GET_ALL_ACTIVE_SUBSECTOR);


    export const findByIdSubSectorApi = (params) =>
    api.get(url.FIND_BY_ID_SUBSECTOR_URL, params);

    
export const insertSubSectorApi = (data) => api.create(url.INSERT_SUBSECTOR_URL, data);

export const updateSubSectorApi = (data) => api.create(url.UPDATE_SUBSECTOR_URL, data);

export const deleteSubSectorApi = (data) => api.create(url.DELETE_SUBSECTOR_URL, data);


export const ActiveInActiveSubSectorApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_SUBSECTOR_URL, data);

  