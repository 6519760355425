import { createSlice } from "@reduxjs/toolkit"
import {
  AccommodationDetail,
  AdditionalServicesDetailsPerDay,
  AdditionalServicesDetailsPerPerson,
  createAccommodation,
  createAdditionalServices,
  createAutoQuotation,
  createQuotationStep1,
  createTransportation,
  deleteAdditionalServices,
  deleteQuotation,
  getAllActiveTours,
  getAllSectors,
  getQuotationAll,
  getQuotationDetail,
  getTransportation,
  searchAgentUsingName,
  updateAccommodation,
  updateAdditionalServices,
  updateQuotationStep1,
  updateTransportation,
} from "./thunk"
import _ from "lodash"
import {
  FAILURE_STATUS,
  SUCCESS_STATUS,
  services,
} from "../../Components/constants/field"
import { toast } from "react-toastify"
import { AUTO_CLOSE } from "../../Components/constants/toast"

//
export const accommodation = {
  ID: 0,
  ItineraryNoOfDay: 0,
  ItineraryDate: "",
  TotalPax: 0,
  Adults: 0,
  Child: 0,
  CWB: 0,
  CNB: 0,
  PaidPax: 0,
  DORoom: 0,
  SORoom: 0,
  ExtraBedAdults: 0,
  CityID: "",
  HotelID: "",
  HotelCategory: "",
  RoomCategoryID: "",
  MealPlanID: "",
  SORate: 0,
  DORate: 0,
  CWBRate: 0,
  CNBRate: 0,
  EARate: 0,
  KitchenCharges: 0,
  ExtraBF: 0,
  ExtraLunch: 0,
  HiTea: 0,
  ExtraDinner: 0,
  EarlyCheckInRate: 0,
  LateCheckOutRate: 0,
  Total: 0,
  Margin: 0,
  MarginTotal: 0,
  GSTID: 0,
  GSTPer: 0,
  GSTAmount: 0,
  GrandTotal: 0,
  QuoteItineraryGrandTotal: 0,
}

export const newQuotationForm = {
  SectorID: null,
  Name: null,
  MobileNo: null,
  EmailID: null,
  Address: null,
  AgentID: null,
  AgentName: null,
  AgentMobileNo: null,
  AgentEmailID: null,
  CompanyName: null,
  CompanyPanNo: null,
  CompanyGSTNo: null,
  DepartureDate: null,
  NoOfDays: 0,
  TotalPax: 0,
  DORoom: 0,
  SORoom: 0,
  ExtraBedAdults: 0,
  Adults: 0,
  CWB: 0,
  CNB: 0,
  Child: 0,
  PickUpDate: null,
  PickUpPlace: null,
  PickUpTime: null,
  DropDate: null,
  DropPlace: null,
  DropTime: null,
  ArrivalAt: null,
  ArrivalRemarks: null,
  DropAt: null,
  DropRemarks: null,
  TermsAndCondition: null,
  Services: null,
}

export const transportation = {
  ID: null,
  CityID: "",
  NoOfDays: 0,
  VehicleTypeID: "",
  PickUpDate: "",
  DropDate: "",
  KmPerDay: 0,
  RatePerKm: 0,
  RatesByKmTotal: 0,
  DriverAllounce: 0,
  TollParking: 0,
  BorderCharges: 0,
  DriverChargesTotal: 0,
  //
  PickUpCharges: 0,
  PickUpChargeDate: "",
  PickUpFrom: "",
  PickUpAt: "",

  //
  DropCharges: 0,
  DropChargeDate: "",
  DropFrom: "",
  DropAt: "",
  //
  Remarks: "",
  AdditionalTransportCharges: 0,
  Total: 0,
  GSTID: 0,
  GSTPer: 0,
  GSTAmount: 0,
  GrandTotal: 0,
  TransportGrandTotal: 0,
}

export const addtionalServicePerDay = {
  Title: "",
  NoOfDays: 0,
  Rate: 0,
  Total: 0,
  Margin: 0,
  MarginTotal: 0,
  GSTID: 0,
  GSTPer: 0,
  GSTAmount: 0,
  GrandTotal: 0,
  MiscDayGrandTotal: 0,
}
export const addtionalServicePerPerson = {
  Title: "",
  NoOfPax: 0,
  Rate: 0,
  Total: 0,
  Margin: 0,
  MarginTotal: 0,
  GSTID: 0,
  GSTPer: 0,
  GSTAmount: 0,
  GrandTotal: 0,
  MiscPaxGrandTotal: 0,
}

export const initialState = {
  activeTab: 0,
  activeTabsList: [],
  quotationList: [],
  newQuotation: {
    form: newQuotationForm,
    agentList: [],
    sectors: [],
    tours: [],
    loading: false,
  },
  accommodation: {
    form: accommodation,
    list: [],
    loading: true,
  },
  transportation: {
    form: transportation,
    list: [],
    loading: true,
  },
  addtionalServicePerDay: {
    form: addtionalServicePerDay,
    list: [],
    loading: true,
  },
  addtionalServicePerPerson: {
    form: addtionalServicePerPerson,
    list: [],
    loading: true,
  },
  quotationRate: {},
  modal: {
    modal: false,
    modalType: "",
    modalData: {},
  },
  // accommodation copy modal
  clipModal: {
    modal: false,
    form: {
      QuoteID: null,
      ItineraryNoOfDay: null,
      PlaceDayNo: null,
    },
  },

  error: {},
  loading: false,
}

const QuotationReducer = createSlice({
  name: "Quotation",
  initialState,
  reducers: {
    ActiveTab: (state, action) => {
      state.activeTab = action.payload
    },
    resetTabs: (state) => {
      state.activeTab = 0
      state.activeTabsList = []
      state.accommodation.form = accommodation
      state.newQuotation.form = newQuotationForm
      state.transportation.form = transportation
      state.addtionalServicePerDay.form = addtionalServicePerDay
      state.addtionalServicePerPerson.form = addtionalServicePerPerson
    },

    SettingAgentDetails: (state, action) => {
      const agentData = action.payload[0]
      state.newQuotation.form = {
        ...state.newQuotation.form,
        AgentID: agentData.AgentID,
        AgentName: agentData.AgentName,
        AgentMobileNo: agentData.AgentMobileNo,
        AgentEmailID: agentData.AgentEmailID,
        CompanyName: agentData.CompanyName,
        Address: agentData.Address,
        CompanyPanNo: agentData.CompanyPanNo,
        CompanyGSTNo: agentData.CompanyGSTNo,
      }
    },
    accommodationDetails: (state, action) => {
      state.accommodation.form = action.payload
    },
    addAgentInList: (state, action) => {
      const agentData = action.payload
      state.newQuotation.agentList = agentData
    },
    activeTabsList: (state, action) => {
      state.activeTabsList = action.payload
    },
    showModal: (state, action) => {
      state.modal.modal = action?.payload?.modal || false
      state.modal.modalType = action?.payload?.modalType || ""
      state.modal.modalData = action?.payload?.modalData || {}
    },
    showClipModal: (state, action) => {
      state.clipModal.modal = action?.payload?.modal || false
      state.clipModal.form = action?.payload?.data || false
    },
  },
  extraReducers: (builder) => {
    // Search Agent Using Name
    builder.addCase(searchAgentUsingName.pending, () => {
      // state.newQuotation.loading = true
    })
    builder.addCase(searchAgentUsingName.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        state.newQuotation.agentList = action.payload.Data
      }
      state.newQuotation.loading = false
    })
    builder.addCase(searchAgentUsingName.rejected, (state) => {
      state.newQuotation.loading = false
    })

    // Get All Sectors
    builder.addCase(getAllSectors.pending, (state) => {
      state.newQuotation.loading = true
    })
    builder.addCase(getAllSectors.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        state.newQuotation.sectors = action.payload.Data
      }
      state.newQuotation.loading = false
    })
    builder.addCase(getAllSectors.rejected, (state) => {
      state.newQuotation.loading = false
    })
    // Get All Tours
    builder.addCase(getAllActiveTours.pending, (state) => {
      state.newQuotation.loading = true
    })
    builder.addCase(getAllActiveTours.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        state.newQuotation.tours = action.payload.Data
      }
      state.newQuotation.loading = false
    })
    builder.addCase(getAllActiveTours.rejected, (state) => {
      state.newQuotation.loading = false
    })
    // Create Quotation step 1
    builder.addCase(createQuotationStep1.pending, (state) => {
      state.newQuotation.loading = true
    })
    builder.addCase(createQuotationStep1.fulfilled, (state) => {
      state.newQuotation.loading = false
    })
    builder.addCase(createQuotationStep1.rejected, (state) => {
      state.newQuotation.loading = false
    })

    // update quotation step 1
    builder.addCase(updateQuotationStep1.pending, (state) => {
      state.newQuotation.loading = true
    })
    builder.addCase(updateQuotationStep1.fulfilled, (state) => {
      //if (action?.payload.Message === "Success") {
      //  state.QuoteID = action.payload.QuoteID
      //}
      state.newQuotation.loading = false
    })
    builder.addCase(updateQuotationStep1.rejected, (state) => {
      state.newQuotation.loading = false
    })

    builder.addCase(getQuotationDetail.pending, (state) => {
      state.newQuotation.loading = true
      state.accommodation.form = accommodation
      state.transportation.form = transportation
      state.addtionalServicePerDay.form = addtionalServicePerDay
      state.addtionalServicePerPerson.form = addtionalServicePerPerson
    })
    builder.addCase(getQuotationDetail.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        //

        state.accommodation.form.QuoteItineraryGrandTotal =
          action?.payload?.Data?.QuoteItineraryGrandTotal || 0
        state.transportation.form.TransportGrandTotal =
          action?.payload?.Data?.TransportGrandTotal || 0
        state.addtionalServicePerPerson.form.MiscPaxGrandTotal =
          action.payload.Data.MiscPaxGrandTotal || 0

        state.addtionalServicePerDay.form.MiscDayGrandTotal =
          action?.payload?.Data?.MiscDayGrandTotal || 0
        //
        state.newQuotation.form = action.payload.Data

        state.accommodation.list = action?.payload?.Data?.AcommodationList || []
        state.transportation.list =
          action?.payload?.Data?.TransportationList || []
        state.addtionalServicePerDay.list =
          action?.payload?.Data?.DaysList || []

        state.addtionalServicePerPerson.list =
          action?.payload?.Data?.PaxsList || []

        let getActiveTabs = _.find(
          services,
          (o) => o.value === action?.payload?.Data?.Services
        )
        state.activeTabsList = getActiveTabs?.activeTab
      }
      state.newQuotation.loading = false
    })
    builder.addCase(getQuotationDetail.rejected, (state) => {
      state.newQuotation.loading = false
    })
    builder.addCase(deleteQuotation.pending, () => {})
    builder.addCase(deleteQuotation.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
    })
    builder.addCase(deleteQuotation.rejected, (state) => {
      state.newQuotation.loading = false
    })

    // Accommodation
    // createAccommodation
    builder.addCase(createAccommodation.pending, () => {})
    builder.addCase(createAccommodation.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        // state.accommodation = accommodation
      }
      // failure
      if (_.upperCase(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
    })
    builder.addCase(createAccommodation.rejected, (state) => {
      state.newQuotation.loading = false
    })
    builder.addCase(getQuotationAll.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getQuotationAll.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        state.quotationList = action?.payload?.Data
      } else if (_.upperCase(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      } else {
        state.quotationList = []
      }
      state.loading = false
    })

    builder.addCase(getQuotationAll.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(updateAccommodation.pending, () => {})
    builder.addCase(updateAccommodation.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        state.accommodation.form = accommodation
      }
      if (_.upperCase(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
    })
    builder.addCase(updateAccommodation.rejected, (state) => {
      state.newQuotation.loading = false
    })
    builder.addCase(AccommodationDetail.pending, (state) => {
      state.accommodation.loading = false
    })
    builder.addCase(AccommodationDetail.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        let data = _.first(action?.payload?.Data?.AcommodationList)
        state.accommodation.form = data ? data : accommodation
        state.accommodation.form.QuoteItineraryGrandTotal =
          action?.payload?.Data?.QuoteItineraryGrandTotal || 0
      }
      if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
      state.accommodation.loading = false
    })
    builder.addCase(AccommodationDetail.rejected, (state) => {
      state.accommodation.loading = false
    })

    // transportation

    builder.addCase(createTransportation.pending, () => {})
    builder.addCase(createTransportation.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        state.transportation.form = transportation
      }
      if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
    })
    builder.addCase(createTransportation.rejected, (state) => {
      state.transportation.loading = false
    })
    builder.addCase(updateTransportation.pending, () => {})
    builder.addCase(updateTransportation.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        state.transportation.form = transportation
      }
      if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
    })
    builder.addCase(updateTransportation.rejected, (state) => {
      state.transportation.loading = false
    })
    builder.addCase(getTransportation.pending, () => {})
    builder.addCase(getTransportation.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        let data = _.first(action?.payload?.Data?.TransportationList)
        state.transportation.form = data ? data : transportation
        state.transportation.form.TransportGrandTotal =
          action?.payload?.Data?.TransportGrandTotal || 0
      }
      if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
    })
    builder.addCase(getTransportation.rejected, (state) => {
      state.newQuotation.loading = false
    })

    //Others
    // create additional services
    builder.addCase(createAdditionalServices.pending, () => {})
    builder.addCase(createAdditionalServices.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        // console.log("Created additional service", action.payload)
        state.addtionalServicePerDay.form = addtionalServicePerDay
      }
      if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
    })
    builder.addCase(createAdditionalServices.rejected, (state) => {
      state.newQuotation.loading = false
    })

    // update additional services
    builder.addCase(updateAdditionalServices.pending, () => {})
    builder.addCase(updateAdditionalServices.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        state.addtionalServicePerDay.form = addtionalServicePerDay
        state.addtionalServicePerPerson.form = addtionalServicePerPerson
      }
      if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
    })
    builder.addCase(updateAdditionalServices.rejected, (state) => {
      state.newQuotation.loading = false
    })

    // update additional services
    builder.addCase(deleteAdditionalServices.pending, () => {})
    builder.addCase(deleteAdditionalServices.fulfilled, (state, action) => {
      if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
        console.log("deleted additional service", action.payload)
      }
      if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
    })
    builder.addCase(deleteAdditionalServices.rejected, (state) => {
      state.newQuotation.loading = false
    })

    // Find additional services per day
    builder.addCase(AdditionalServicesDetailsPerDay.pending, () => {})
    builder.addCase(
      AdditionalServicesDetailsPerDay.fulfilled,
      (state, action) => {
        if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
          // console.log("found additional services per day", action.payload)
          state.addtionalServicePerDay.form =
            action.payload.Data.MiscDaysList[0] || {}

          state.addtionalServicePerDay.form.MiscDayGrandTotal =
            action?.payload?.Data?.MiscDayGrandTotal || 0
        }
        if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
          toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
        }
      }
    )
    builder.addCase(AdditionalServicesDetailsPerDay.rejected, (state) => {
      state.newQuotation.loading = false
    })

    // Find additional services per person
    builder.addCase(AdditionalServicesDetailsPerPerson.pending, () => {})
    builder.addCase(
      AdditionalServicesDetailsPerPerson.fulfilled,
      (state, action) => {
        if (_.toUpper(action?.payload.Message) === SUCCESS_STATUS) {
          console.log("found additional services per person", action.payload)
          state.addtionalServicePerPerson.form =
            action.payload.Data.MiscPaxsList[0] || {}
          state.addtionalServicePerPerson.form.MiscPaxGrandTotal =
            action.payload.Data.MiscPaxGrandTotal || 0
        }
        if (_.toUpper(action?.payload.Message) === FAILURE_STATUS) {
          toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
        }
      }
    )
    builder.addCase(AdditionalServicesDetailsPerPerson.rejected, (state) => {
      state.newQuotation.loading = false
    })

    // Auto Quotation
    builder.addCase(createAutoQuotation.pending, () => {})
    builder.addCase(createAutoQuotation.fulfilled, (state, action) => {
      if (action?.payload.Message === FAILURE_STATUS) {
        toast.error(action?.payload?.Details, { autoClose: AUTO_CLOSE })
      }
    })
    builder.addCase(createAutoQuotation.rejected, () => {})
  },
})

export const {
  ActiveTab,
  SettingAgentDetails,
  accommodationDetails,
  activeTabsList,
  resetTabs,
  addAgentInList,
  showClipModal,
  showModal,
} = QuotationReducer.actions
export default QuotationReducer.reducer
