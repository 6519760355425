import React from "react"
import { useSelector } from "react-redux"
import { Card, CardBody, Col, Row } from "reactstrap"

const Quotation = () => {
  const {
    newQuotation: { form: quotationDetail },
  } = useSelector((state) => state.Quotation)
  return (
    <Card className="border border-black">
      <CardBody>
        <Row>
          <Col md={12}>
            <h5>Quotation View</h5>
            <hr></hr>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Agent Name:</strong>
              </div>
              <div className="ms-2">{quotationDetail?.AgentName || ""}</div>
            </div>
          </Col>
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Mobile:</strong>
              </div>
              <div className="ms-2">{quotationDetail?.AgentMobileNo || ""}</div>
            </div>
          </Col>
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Email:</strong>
              </div>
              <div className="ms-2">{quotationDetail?.AgentEmailID || ""}</div>
            </div>
          </Col>
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Person Name:</strong>
              </div>
              <div className="ms-2">{quotationDetail?.Name || ""}</div>
            </div>
          </Col>{" "}
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Mobile:</strong>
              </div>
              <div className="ms-2">{quotationDetail?.MobileNo || ""}</div>
            </div>
          </Col>{" "}
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Email:</strong>
              </div>
              <div className="ms-2">{quotationDetail?.EmailID || ""}</div>
            </div>
          </Col>{" "}
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Total no of Person:</strong>
              </div>
              <div className="ms-2">{quotationDetail?.TotalPax || ""}</div>
            </div>
          </Col>{" "}
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Total Adults (Above 12):</strong>
              </div>
              <div className="ms-2">{quotationDetail?.Adults || ""}</div>
            </div>
          </Col>{" "}
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Total Kids (6 - 12):</strong>
              </div>
              <div className="ms-2">{quotationDetail?.CWB || ""}</div>
            </div>
          </Col>{" "}
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Complimentary (0 - 1Year):</strong>
              </div>
              <div className="ms-2">{quotationDetail?.CNB || ""}</div>
            </div>
          </Col>
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Paid Guest:</strong>
              </div>
              <div className="ms-2">
                {(quotationDetail?.Adults || 0) + (quotationDetail?.CWB || 0)}
              </div>
            </div>
          </Col>{" "}
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Total Single Occu. Room:</strong>
              </div>
              <div className="ms-2">{quotationDetail?.SORoom || 0}</div>
            </div>
          </Col>
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>Total Double Occu. Room:</strong>
              </div>
              <div className="ms-2">{quotationDetail?.DORoom || 0}</div>
            </div>
          </Col>{" "}
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>No of Extra Bed for Adults:</strong>
              </div>
              <div className="ms-2">{quotationDetail?.ExtraBedAdults || 0}</div>
            </div>
          </Col>{" "}
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>No of Extra Bed for Kids (6 - 12 Year):</strong>
              </div>
              <div className="ms-2">{quotationDetail?.ExtraBedCWB || 0}</div>
            </div>
          </Col>{" "}
          <Col md="3">
            <div className="d-flex flex-row flex-wrap my-2">
              <div>
                <strong>No of Kids Without Bed (6 - 12 Year):</strong>
              </div>
              <div className="ms-2">{quotationDetail?.ExtraBedCNB || 0}</div>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default Quotation
