import React, { useEffect } from "react"
import {
  Button,
  // Button,
  // Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap"

import * as Yup from "yup"
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik"
import { useDispatch, useSelector } from "react-redux"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import { useNavigate, useParams } from "react-router-dom"
import Loader from "../../../Components/Common/Loader"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import {
  HOTEL_CATEGORIES,
  INVALID_EMAIL,
  INVALID_PHONE,
  REQUIRED,
  STANDARD_INPUT_DATE,
  STANDARD_OUTPUT_DATE,
  SelectionField,
  TextField,
  phoneRegex,
} from "../../../Components/constants/field"
import {
  createAutoQuotation,
  getAllActiveMealTypes,
  getAllCities,
  getTourByID,
  getVehicleTypeByTour,
} from "../../../slices/thunks"
import RoomFieldArray from "./RoomFieldArray"
import _ from "lodash"
import { DEVICE_TYPE } from "../../../common/config"

function ChangeWatcher() {
  const { TOURID } = useParams()

  const dispatch = useDispatch()
  const { values } = useFormikContext()

  useEffect(() => {
    if (values?.Adults || values?.CWB || values?.CNB) {
      let NoOfPax = _.sum([values?.Adults, values?.CWB, values?.CNB])

      let obj = {
        NoOfPax: NoOfPax,
        TourID: +TOURID,
      }
      dispatch(getVehicleTypeByTour(obj))
    }
  }, [values?.Adults, values?.CWB, values?.CNB])
}

function DepartureDate() {
  const { values, setFieldValue } = useFormikContext()

  const handleDateChange = (ev) => {
    setFieldValue("DepartureDate", moment(ev[0]).format(STANDARD_INPUT_DATE))
  }

  return (
    <React.Fragment>
      <Field>
        {({ field }) => (
          <Flatpickr
            name="DepartureDate"
            {...field}
            value={values?.DepartureDate || ""}
            placeholder="Select Date.."
            className="form-control"
            options={{
              altInput: true,
              altFormat: "d-m-Y",
              dateFormat: "d-m-Y",
            }}
            onChange={handleDateChange}
          />
        )}
      </Field>
      <ErrorMessage
        name={"DepartureDate"}
        component="div"
        className="text-danger"
      />
    </React.Fragment>
  )
}

function CreateAutoQuotation() {
  const { TOURID } = useParams()
  const dispatch = useDispatch()
  const history = useNavigate()
  const { form } = useSelector(
    (state) => state.AutoQuotation.autoQuotationDetails
  )

  const { mealTypes, loading: mealTypeLoading } = useSelector(
    (state) => state.MealType
  )
  const { tourDetails } = useSelector((state) => state.Tour)
  const { vehicleTypes } = useSelector((state) => ({
    vehicleTypes: state.Vehicle.vehicleTypes,
  }))
  const { citLoading } = useSelector((state) => ({
    loading: state.City.loading,
  }))

  const { hotelLoading } = useSelector((state) => ({
    loading: state.Hotel.loading,
  }))
  const { loading } = useSelector((state) => ({
    loading: state.Quotation.loading,
  }))

  // const { ID } = useParams()

  useEffect(() => {
    //   if (ID !== "0") {
    //     dispatch(getAutoQuotationByAutoQuotationID(ID))
    //   }
    dispatch(getAllCities())
    dispatch(getAllActiveMealTypes())

    if (TOURID) {
      dispatch(getTourByID(TOURID))
    }
  }, [TOURID])

  const handleSubmit = (value) => {
    const newValues = {
      ...value,
      DepartureDate: moment(value.DepartureDate, STANDARD_INPUT_DATE).format(
        STANDARD_OUTPUT_DATE
      ),
      MealPlanID: +value.MealPlanID,
      VehicleTypeID: +value.VehicleTypeID,
      DeviceType: DEVICE_TYPE,
      SessionID: sessionStorage.getItem("SessionID"),
      SectorID: +tourDetails?.SectorID,
      SubSectorID: +tourDetails?.SubSectorID,
      TourID: +TOURID,
    }

    dispatch(createAutoQuotation(newValues)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        history(
          `/auto-quotations/${TOURID}/generate-quotation/${res.payload.Data.ID}`
        )
      }
    })
  }

  if (hotelLoading || citLoading || loading || mealTypeLoading) {
    return <Loader />
  }
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Auto Quotation" pageTitle="Quotations" />
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader className="bg-light">
                <h4 className="card-title mb-0">Calculate Rate</h4>
              </CardHeader>
              <CardBody className="form-steps">
                <Row>
                  <Col lg={12}>
                    <Formik
                      initialValues={form}
                      enableReinitialize={true}
                      validationSchema={Yup.object({
                        Name: Yup.string().nullable(true).required(REQUIRED),
                        MobileNo: Yup.string()
                          .typeError(INVALID_PHONE)
                          .matches(phoneRegex, "Invalid phone")
                          .nullable(true)
                          .required(REQUIRED),
                        EmailID: Yup.string()
                          .email(INVALID_EMAIL)
                          .required(REQUIRED),
                        DepartureDate: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        Adults: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable(true)
                          .required(REQUIRED),
                        CWB: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable(true)
                          .required(REQUIRED),
                        CNB: Yup.number()
                          .nullable(true)
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .required(REQUIRED),
                        HotelCategory: Yup.string()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable(true)
                          .required(REQUIRED),
                        MealPlanID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable(true)
                          .required(REQUIRED),
                        VehicleTypeID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable(true)
                          .required(REQUIRED),
                        RoomList: Yup.array().of(
                          Yup.object({
                            RoomNo: Yup.number()
                              .transform((value) =>
                                Number.isNaN(value) ? null : value
                              )
                              .nullable(true),
                            Adults: Yup.number()
                              .transform((value) =>
                                Number.isNaN(value) ? null : value
                              )
                              .nullable(true),
                            CWB: Yup.number()
                              .transform((value) =>
                                Number.isNaN(value) ? null : value
                              )
                              .nullable(true),
                            CNB: Yup.number()
                              .transform((value) =>
                                Number.isNaN(value) ? null : value
                              )
                              .nullable(true),
                          })
                        ),
                      })}
                      onSubmit={handleSubmit}
                    >
                      <Form noValidate autoComplete="off">
                        <ChangeWatcher />
                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-name"
                              >
                                Name
                              </Label>
                              <Field
                                name="Name"
                                type="text"
                                className="form-control"
                                id="autoquote-name"
                                placeholder="Enter Name"
                                component={TextField}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-mobile"
                              >
                                Mobile No
                              </Label>
                              <Field
                                name="MobileNo"
                                type="string"
                                className="form-control"
                                id="autoquote-mobile"
                                placeholder="Enter Mobile"
                                component={TextField}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-email"
                              >
                                Email
                              </Label>
                              <Field
                                name="EmailID"
                                type="text"
                                className="form-control"
                                id="autoquote-email"
                                placeholder="Enter Email"
                                component={TextField}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="cleave-date-format"
                                htmlFor="cleave-date-format"
                              >
                                Date
                              </Label>
                              <DepartureDate />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="autoquote-Adult"
                                  >
                                    Adults
                                  </Label>
                                  <Field
                                    name="Adults"
                                    type="number"
                                    className="form-control"
                                    id="autoquote-Adult"
                                    placeholder="No Of Pax"
                                    component={TextField}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="autoquote-CWB"
                                  >
                                    CWB
                                  </Label>
                                  <Field
                                    name="CWB"
                                    type="number"
                                    className="form-control"
                                    id="autoquote-CWB"
                                    placeholder="Enter CWB"
                                    component={TextField}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="autoquote-CNB"
                                  >
                                    CNB
                                  </Label>
                                  <Field
                                    name="CNB"
                                    type="number"
                                    className="form-control"
                                    id="autoquote-CNB"
                                    placeholder="Enter CNB"
                                    component={TextField}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-HotelCategory"
                              >
                                Hotel Category
                              </Label>
                              <Field
                                name="HotelCategory"
                                options={HOTEL_CATEGORIES.map((hc, index) => {
                                  return {
                                    value: index + 1,
                                    label: hc,
                                  }
                                })}
                                component={SelectionField}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-MealPlanID"
                              >
                                Meal Plan
                              </Label>
                              <Field
                                name="MealPlanID"
                                options={
                                  Array.isArray(mealTypes)
                                    ? mealTypes.map((mealType) => {
                                        return {
                                          value: mealType.ID,
                                          label: mealType.MealType,
                                        }
                                      })
                                    : []
                                }
                                component={SelectionField}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-VehicleTypeID"
                              >
                                Transportation
                              </Label>
                              <Field
                                name="VehicleTypeID"
                                options={
                                  vehicleTypes &&
                                  vehicleTypes.map((vehicleType) => {
                                    return {
                                      value: vehicleType.ID,
                                      label: vehicleType.VehicleType,
                                    }
                                  })
                                }
                                component={SelectionField}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <hr></hr>
                          </Col>
                          <Col lg={12}>
                            <RoomFieldArray />
                          </Col>
                          <Col md="12" className=" d-flex align-items-end">
                            <Button color="primary" type="submit">
                              Generate Quote
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CreateAutoQuotation
