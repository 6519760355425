import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import {
    getItineraryByCityIDApi,
    getItinerariesApi,
    getAllActiveItineraryFacilitiesApi,
    getAllItineraryApi,
    findByIdItineraryApi,
    insertItineraryApi,
    updateItineraryApi,
    ActiveInActiveItineraryApi,
    deleteItineraryApi,
} from "./itinerary.services";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";

export const getItineraryByCityID = createAsyncThunk(
    "Itinerary/CityItinerary",
    async (CityID) => {
        try {
            const response = getItineraryByCityIDApi(CityID);
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const getItineraries = createAsyncThunk(
    "Itinerary/FindAllActive",
    async () => {
        try {
            const response = getItinerariesApi();
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const getAllActiveItineraryFacilities = createAsyncThunk(
    "ItineraryFacilities/FindAllActive",
    async () => {
        try {
            const response = getAllActiveItineraryFacilitiesApi();
            return response;
        } catch (error) {
            return error;
        }
    }
);

export const getAllACINItineraries = createAsyncThunk("Itinerary/FindAll", async () => {
    try {
      const response = await getAllItineraryApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  });
  
  
  export const findByIdItinerary = createAsyncThunk(
    "Itinerary/FindById",
    async (params) => {
      try {
        const response = await findByIdItineraryApi(params);
        if (response?.Status !== 200) {
          toast.error(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        } else {
          return response;
        }
      } catch (error) {
        toast.error(FAILED, { autoClose: AUTO_CLOSE });
        return error;
      }
    }
  );
  
  export const insertItinerary = createAsyncThunk("Itinerary/Insert", async (data) => {
    try {
      const response = await insertItineraryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  });
  
  export const updateItinerary = createAsyncThunk("Itinerary/Update", async (data) => {
    try {
      const response = await updateItineraryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  });
  
  export const ActiveInActiveItinerary = createAsyncThunk(
    "Itinerary/ActiveInActive",
    async (data) => {
      try {
        const response = await ActiveInActiveItineraryApi(data);
        if (response?.Status !== 200) {
          toast.error(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        } else {
          toast.success(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        }
      } catch (error) {
        toast.error(FAILED, { autoClose: AUTO_CLOSE });
        return error;
      }
    }
  );
  
  export const deleteItinerary = createAsyncThunk("Itinerary/Delete", async (data) => {
    try {
      const response = await deleteItineraryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  });
