import { Field, FieldArray } from "formik"
import React from "react"
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap"
import { TextField } from "../../../Components/constants/field"
import { emptyRoom } from "../../../slices/autoquotation/reducer"

function RoomFieldArray() {
  const handleRoomAdd = (arrayHelpers) => {
    let obj = {
      ...emptyRoom,
      RoomNo: arrayHelpers?.form?.values?.RoomList.length + 1,
    }
    arrayHelpers.push(obj)
  }

  const handleDeleteClick = (arrayHelpers, index) => {
    arrayHelpers.remove(index)
  }
  return (
    <FieldArray
      name="RoomList"
      render={(arrayHelpers) => {
        return (
          <Row>
            <Col lg={4}>
              <Button
                color="primary"
                onClick={() => handleRoomAdd(arrayHelpers)}
              >
                Add Room
              </Button>
            </Col>
            <Col lg={12}>
              {arrayHelpers?.form?.values?.RoomList &&
              arrayHelpers?.form?.values?.RoomList.length > 0
                ? arrayHelpers?.form?.values?.RoomList.map((room, index) => (
                    <Row key={index}>
                      <Col lg={12}>
                        <Card className="border card-border-primary mt-4">
                          <CardBody className="p-4">
                            <Row>
                              <Col lg={12} className="text-end">
                                <Button
                                  color="danger"
                                  className="btn-icon mb-2"
                                  onClick={() =>
                                    handleDeleteClick(arrayHelpers, index)
                                  }
                                >
                                  <i className=" ri-delete-bin-5-line"></i>
                                </Button>
                              </Col>
                              <Col lg={4}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="RoomList-RoomNo"
                                  >
                                    Room No
                                  </Label>
                                  <Field
                                    name={`RoomList.${index}.RoomNo`}
                                    type="number"
                                    className="form-control"
                                    id="RoomList-RoomNo"
                                    placeholder="Room No"
                                    component={TextField}
                                  />
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="RoomList-Adults"
                                  >
                                    Adults
                                  </Label>
                                  <Field
                                    name={`RoomList.${index}.Adults`}
                                    type="number"
                                    className="form-control"
                                    id="RoomList-Adults"
                                    placeholder="Adults"
                                    component={TextField}
                                  />
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="RoomList-CWB"
                                  >
                                    CWB
                                  </Label>
                                  <Field
                                    name={`RoomList.${index}.CWB`}
                                    type="number"
                                    className="form-control"
                                    id="RoomList-CWB"
                                    placeholder="CWB"
                                    component={TextField}
                                  />
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="RoomList-CNB"
                                  >
                                    CNB
                                  </Label>
                                  <Field
                                    name={`RoomList.${index}.CNB`}
                                    type="number"
                                    className="form-control"
                                    id="RoomList-CNB"
                                    placeholder="CNB"
                                    component={TextField}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  ))
                : null}
            </Col>
          </Row>
        )
      }}
    />
  )
}

export default RoomFieldArray
