import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import BreadCrumb from "../../Components/Common/BreadCrumb"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import {
  ActiveInActiveHotelStatus,
  deleteHotel,
  getAllHotelList,
} from "../../slices/thunks"
import DeleteModal from "../../Components/Common/DeleteModal"

// import Switch from "react-switch"

import Loader from "../../Components/Common/Loader"
import Switch from "../../Components/Common/Switch"
const HotelMaster = () => {
  const dispatch = useDispatch()
  const { hotels, loading } = useSelector((state) => state.Hotel)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  const [reload, setReload] = useState(false)
  useEffect(() => {
    let obj = {
      OperationType: 11,
    }
    dispatch(getAllHotelList(obj))
  }, [reload])

  const handleChange = (data) => {
    let obj = { ID: +data.ID, IsActive: !data.IsActive, OperationType: 13 }

    // setChecked(!checked)
    dispatch(ActiveInActiveHotelStatus(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload)
      }
    })
  }

  const onClickDelete = (data) => {
    setDeleteModal(true)
    setDeleteData(data)
  }

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
      OperationType: 8,
    }
    dispatch(deleteHotel(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload)
        setDeleteModal(false)
      }
    })
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Hotel Master" pageTitle="Hotels" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md={4}>
                    <h4 className="card-title mb-0">Hotel Master</h4>
                  </Col>
                  <Col md={8} className="text-end">
                    <Link to="/hotels/create">
                      <Button color="primary">
                        <i className="las la-plus"></i>Add
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <Table
                        className="align-middle table-nowrap mb-0"
                        striped
                        responsive
                        hover
                      >
                        <thead>
                          <tr>
                            <th scope="col">Hotel Name</th>
                            <th scope="col">Category</th>
                            <th scope="col">City</th>
                            <th scope="col">Address</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                          Array.isArray(hotels) &&
                          hotels.length ? (
                            hotels.map((hotel, index) => (
                              <tr key={index}>
                                <td>
                                  <Link to={`/hotels/${hotel.ID}`}>
                                    {hotel.HotelName}
                                  </Link>
                                </td>
                                <td>{hotel.HotelCategory}</td>
                                <td>{hotel.CityName}</td>
                                <td>{hotel.Address}</td>
                                <td>
                                  <Switch
                                    onChange={() => handleChange(hotel)}
                                    checked={hotel?.IsActive}
                                  />
                                </td>
                                {/* <td>{hotel.DepartureDate}</td> */}
                                <td>
                                  <div className="hstack gap-3 flex-wrap">
                                    <Link
                                      to={`/hotels/${hotel.ID}`}
                                      className="link-success fs-18"
                                    >
                                      <i className="ri-edit-line"></i>
                                    </Link>
                                    <div
                                      className="hstack gap-3 flex-wrap "
                                      role="button"
                                      onClick={() => onClickDelete(hotel)}
                                    >
                                      <i className=" ri-delete-bin-fill text-danger fs-18"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : loading ? (
                            <tr>
                              <td colSpan={10}>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={10}>No hotel found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <DeleteModal
            show={deleteModal}
            onCloseClick={() => setDeleteModal(false)}
            onDeleteClick={() => onDeleted()}
          />
        </Row>
      </Container>
    </div>
  )
}

export default HotelMaster
