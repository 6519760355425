import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Formik, Form, useFormikContext } from "formik";
import {
  CONVERT_BOOL,
  REQUIRED,
  SelectionField,
  TextField,
} from "../../Components/constants/field";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import * as Yup from "yup";
import {
  findByIdState,
  getAllActiveCountries,
  getStatesByCountryID,
  insertState,
  updateState,
} from "../../slices/thunks";

const CreateState = () => {
  const history = useNavigate();
  const { ID } = useParams();
  const dispatch = useDispatch();
  const {
    stateDetail: { form },
  } = useSelector((state) => state.State);
  const { countries, loading: CountryLoading } = useSelector(
    (state) => state.Country
  );
  useEffect(() => {
    dispatch(getAllActiveCountries());
  }, []);

  useEffect(() => {
    if (ID) {
      let params = {
        ID: ID,
      };
      dispatch(findByIdState(params));
    }
  }, [ID]);

  const handleSubmit = (values) => {
    let obj = {
      ...values,
    };

    obj.IsActive = CONVERT_BOOL.test(obj?.IsActive);
    obj.CountryID = obj.CountryID ? +obj.CountryID : null;

    if (obj?.ID) {
      dispatch(updateState(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/state`);
        }
      });
    } else {
      delete obj?.ID;
      dispatch(insertState(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/state`);
        }
      });
    }
  };
  if (CountryLoading) {
    return <Loader />;
  }
  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <BreadCrumb title="State" pageTitle="State" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">
                      {ID ? "Update State" : " Create State"}
                    </h4>
                  </Col>
                  <Col md="1">
                    <Link to="/state">
                      <Button color="primary" block>
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={form}
                      validationSchema={Yup.object({
                        CountryID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                        StateName: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {() => {
                        return (
                          <Form noValidate autoComplete="off">
                            <div>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="CountryID"
                                    >
                                      Country:
                                    </Label>
                                    <Field
                                      name="CountryID"
                                      id="CountryID"
                                      options={
                                        Array.isArray(countries) &&
                                        countries.map((country) => {
                                          return {
                                            value: Number(country.ID),
                                            label: country.CountryName,
                                          };
                                        })
                                      }
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="StateName"
                                    >
                                      State:
                                    </Label>
                                    <Field
                                      name="StateName"
                                      className="form-control"
                                      id="StateName"
                                      placeholder="Enter State Name"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                {/* </div>
                                 </Col>{" "} */}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="StateCode"
                                    >
                                      State Code:
                                    </Label>
                                    <Field
                                      name="StateCode"
                                      className="form-control"
                                      id="StateCode"
                                      placeholder="Enter State Code"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="IsActive"
                                    >
                                      Status
                                    </Label>
                                    <Field
                                      name="IsActive"
                                      id="IsActive"
                                      options={[
                                        {
                                          label: "Active",
                                          value: true,
                                        },
                                        {
                                          label: "Inactive",
                                          value: false,
                                        },
                                      ]}
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col lg={12}>
                                  <hr></hr>
                                </Col>
                                <Col md="12" className="text-end">
                                  <Button type="submit" className="success">
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                            <CountryChangeWatcher />
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const CountryChangeWatcher = () => {
  const { values } = useFormikContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (values.CountryID) {
      dispatch(getStatesByCountryID(values.CountryID));
    }
  }, [values.CountryID]);
  return null;
};
export default CreateState;
