import { createAsyncThunk } from "@reduxjs/toolkit"
import "react-toastify/dist/ReactToastify.css"
import {
  ActiveInActiveVehicleApi,
  deleteVehicleApi,
  findByIdVehicleApi,
  getAllActiveVehicleTypeApi,
  getAllVehicleApi,
  getSeatByVehicleTypeApi,
  getVehicleTypeByNoOfPaxApi,
  getVehicleTypeByTourAPI,
  insertVehicleApi,
  updateVehicleApi,
} from "./vehicle.services"
import { AUTO_CLOSE } from "../../Components/constants/toast"
import { FAILED } from "../../Components/constants/field"
import { toast } from "react-toastify"

export const getAllActiveVehicleType = createAsyncThunk(
  "VehicleTypes/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveVehicleTypeApi()
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE })
      return error
    }
  }
)

export const getAllVehicle = createAsyncThunk(
  "Vehicles/FindAllActive",
  async () => {
    try {
      const response = await getAllVehicleApi()
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error
    }
  }
)

export const getSeatByVehicleType = createAsyncThunk(
  "Quotation/GetSeatsListByVehicleType",
  async (vehicleTypeID) => {
    try {
      const response = await getSeatByVehicleTypeApi(vehicleTypeID)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error
    }
  }
)

export const getVehicleTypeByNoOfPax = createAsyncThunk(
  "VehicleTypes/GetRatesByNoOfPax",
  async (NoOfPax) => {
    try {
      const response = await getVehicleTypeByNoOfPaxApi(NoOfPax)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error
    }
  }
)

export const getVehicleTypeByTour = createAsyncThunk(
  "VehicleTypes/Tour",
  async (data) => {
    try {
      const response = await getVehicleTypeByTourAPI(data)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error
    }
  }
)

export const findByIdVehicle = createAsyncThunk(
  "Vehicles/FindById",
  async (params) => {
    try {
      const response = await findByIdVehicleApi(params)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error
    }
  }
)

export const insertVehicle = createAsyncThunk("Vehicles/Insert", async (data) => {
  try {
    const response = await insertVehicleApi(data)
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error
  }
})


export const updateVehicle = createAsyncThunk("Vehicles/Update", async (data) => {
  try {
    const response = await updateVehicleApi(data)
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error
  }
})

export const ActiveInActiveVehicle = createAsyncThunk(
  "Vehicles/ActiveInActive",
  async (data) => {
    try {
      const response = await ActiveInActiveVehicleApi(data)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error
    }
  }
)

export const deleteVehicle = createAsyncThunk("Vehicles/Delete", async (data) => {
  try {
    const response = await deleteVehicleApi(data)
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error
  }
})
