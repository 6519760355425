import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    isOpen: false,
    error: {},
};

const ModalCommonReducer = createSlice({
    name: "ModalCommon",
    initialState,
    reducers: {
        toggleModal: (state, action) => {
            state.isOpen = action.payload;
        },
    },
});

export const { toggleModal } = ModalCommonReducer.actions;

export default ModalCommonReducer.reducer;
