import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Formik, Form, useFormikContext } from "formik";
import {
  REQUIRED,
  STANDARD_INPUT_DATE,
  STANDARD_OUTPUT_DATE,
  SelectionField,
  TextField,
} from "../../Components/constants/field";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Flatpickr from "react-flatpickr";
import {
  findByBookingNoPaymentReceipt,
  findByIdPaymentReceipt,
  insertPaymentReceipt,
  updatePaymentReceipt,
} from "../../slices/payment/thunk";
import moment from "moment";

const BookingNoChangeWatcher = () => {
  const { values, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();

  useEffect(() => {
    if (values.BookingNo) {
      dispatch(findByBookingNoPaymentReceipt({ BookingNo: values.BookingNo }));
    }
  }, [values.BookingNo]);

  const { paymentReceiptData } = useSelector((state) => state.PaymentReceipt);

  useEffect(() => {
    if (paymentReceiptData && values.ID === null) {
      setFieldValue("Name", paymentReceiptData.Name || "");
      setFieldValue("Amount", paymentReceiptData.Amount || null);
    }
  }, [paymentReceiptData]);

  return null; 
};

const CreatePayment = () => {
  const history = useNavigate();
  const { ID } = useParams();
  const dispatch = useDispatch();
  const {
    paymentReceiptDetail: { form },
  } = useSelector((state) => state.PaymentReceipt);

  useEffect(() => {
    if (ID) {
      let params = {
        ID: ID,
      };
      dispatch(findByIdPaymentReceipt(params));
    }
  }, [ID]);

  const handleSubmit = (values) => {
    let obj = {
      ...values,
    };

    obj.Amount = obj.Amount ? +obj.Amount : null;

    obj.PaymentDate = obj?.PaymentDate
      ? moment(obj.PaymentDate, STANDARD_INPUT_DATE).format(
          STANDARD_OUTPUT_DATE
        )
      : "";
    obj.TransactionDate = obj?.TransactionDate
      ? moment(obj.TransactionDate, STANDARD_INPUT_DATE).format(
          STANDARD_OUTPUT_DATE
        )
      : "";
    obj.ChequeDate = obj?.ChequeDate
      ? moment(obj.ChequeDate, STANDARD_INPUT_DATE).format(STANDARD_OUTPUT_DATE)
      : "";

    if (values.PaymentType === "CREDIT CARD") {
      obj.ChequeNo = "";
      obj.ChequeDate = "";
      obj.BankName = "";
    } else if (values.PaymentType === "CHEQUE") {
      obj.TransactionNo = "";
      obj.TransactionDate = "";
    } else if (values.PaymentType === "NEFT/IMPS") {
      obj.ChequeNo = "";
      obj.ChequeDate = "";
    } else if (values.PaymentType === "UPI") {
      obj.ChequeNo = "";
      obj.ChequeDate = "";
      obj.BankName = "";
    }

    if (obj?.ID) {
      dispatch(updatePaymentReceipt(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/payments`);
        }
      });
    } else {
      delete obj?.ID;
      dispatch(insertPaymentReceipt(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/payments`);
        }
      });
    }
  };

  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <BreadCrumb title="Payment Receipt" pageTitle="Payment Receipt" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">
                      {ID ? "Update Payment Receipt" : "Create Payment Receipt"}
                    </h4>
                  </Col>
                  <Col md="1">
                    <Link to="/payments">
                      <Button color="primary" block>
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={form}
                      validationSchema={Yup.object({
                        BookingNo: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        PaymentDate: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        PaymentType: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        Amount: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {({ values }) => (
                        <Form noValidate autoComplete="off">
                          <div>
                            <Row>
                              <Col lg={4}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="BookingNo"
                                  >
                                    Booking No:
                                  </Label>
                                  <Field
                                    name="BookingNo"
                                    className="form-control"
                                    id="BookingNo"
                                    placeholder="Enter Booking No"
                                    component={TextField}
                                  />
                                </div>
                              </Col>{" "}
                              <Col lg={4}>
                                <div className="mb-3">
                                  <Label className="form-label" htmlFor="Name">
                                    Name:
                                  </Label>
                                  <Field
                                    name="Name"
                                    className="form-control"
                                    id="Name"
                                    readOnly={true}
                                    placeholder="Enter Name"
                                    component={TextField}
                                  />
                                </div>
                              </Col>{" "}
                              <Col lg={4}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="Amount"
                                  >
                                    Amount:
                                  </Label>
                                  <Field
                                    name="Amount"
                                    type="number"
                                    className="form-control"
                                    id="Amount"
                                    placeholder="Enter Amount"
                                    component={TextField}
                                  />
                                </div>
                              </Col>{" "}
                              <Col lg={4}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="PaymentDate"
                                  >
                                    Payment Date:
                                  </Label>
                                  <Date name={"PaymentDate"} />
                                </div>
                              </Col>{" "}
                              <Col lg={4}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="PaymentType"
                                  >
                                    Payment Type:
                                  </Label>
                                  <Field
                                    name="PaymentType"
                                    id="PaymentType"
                                    options={[
                                      {
                                        label: "Cash",
                                        value: "CASH",
                                      },
                                      {
                                        label: "Credit Card",
                                        value: "CREDIT CARD",
                                      },
                                      {
                                        label: "Cheque",
                                        value: "CHEQUE",
                                      },
                                      {
                                        label: "NEFT/IMPS",
                                        value: "NEFT/IMPS",
                                      },
                                      {
                                        label: "UPI",
                                        value: "UPI",
                                      },
                                      {
                                        label: "Bank Deposit",
                                        value: "BANK DEPOSIT",
                                      },
                                    ]}
                                    component={SelectionField}
                                  />
                                </div>
                              </Col>
                              <Col lg={4}>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="Remarks"
                                  >
                                    Remarks:
                                  </Label>
                                  <Field
                                    name="Remarks"
                                    className="form-control"
                                    id="Remarks"
                                    placeholder="Enter Remarks"
                                    component={TextField}
                                  />
                                </div>
                              </Col>{" "}
                              {values.PaymentType === "CREDIT CARD" && (
                                <Card className="border card-border-primary mt-2">
                                  <CardBody className="p-2">
                                    <h4 className="my-2">
                                      Credit Card Details
                                    </h4>
                                    <Col lg={12} className="mt-4">
                                      <Row className="contact-add-row">
                                        <CreditCard />
                                      </Row>
                                    </Col>
                                  </CardBody>
                                </Card>
                              )}
                              {values.PaymentType === "CHEQUE" && (
                                <Card className="border card-border-primary mt-2">
                                  <CardBody className="p-2">
                                    <h4 className="my-2">Cheque Details</h4>
                                    <Col lg={12} className="mt-4">
                                      <Row className="contact-add-row">
                                        <Cheque />
                                      </Row>
                                    </Col>
                                  </CardBody>
                                </Card>
                              )}
                              {values.PaymentType === "NEFT/IMPS" && (
                                <Card className="border card-border-primary mt-2">
                                  <CardBody className="p-2">
                                    <h4 className="my-2">NEFT/IMPS Details</h4>
                                    <Col lg={12} className="mt-4">
                                      <Row className="contact-add-row">
                                        <NeftImps />
                                      </Row>
                                    </Col>
                                  </CardBody>
                                </Card>
                              )}
                              {values.PaymentType === "UPI" && (
                                <Card className="border card-border-primary mt-2">
                                  <CardBody className="p-2">
                                    <h4 className="my-2">UPI Details</h4>
                                    <Col lg={12} className="mt-4">
                                      <Row className="contact-add-row">
                                        <Upi />
                                      </Row>
                                    </Col>
                                  </CardBody>
                                </Card>
                              )}
                            </Row>
                            <Row className="mt-2">
                              <Col lg={12}>
                                <hr></hr>
                              </Col>
                              <Col md="12" className="text-end">
                                <Button type="submit" className="success">
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </div>
                          <BookingNoChangeWatcher />
                        </Form>
                      )}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Date = ({ name }) => {
  const { values, setFieldValue, errors } = useFormikContext();

  const handleDateChange = (selectedDate) => {
    setFieldValue(name, moment(selectedDate[0]).format(STANDARD_INPUT_DATE));
  };

  return (
    <React.Fragment>
      <Field>
        {({ field }) => (
          <div>
            <Flatpickr
              name={name}
              id={name}
              {...field}
              value={values[name] || ""}
              placeholder="Select Date.."
              className="form-control"
              options={{
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "d-m-Y",
              }}
              onChange={handleDateChange}
            />
            {errors[name] && <div className="text-danger">{errors[name]}</div>}
          </div>
        )}
      </Field>
    </React.Fragment>
  );
};

const CreditCard = () => {
  return (
    <React.Fragment>
      <Col lg={4}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="TransactionNo">
            Transaction No:
          </Label>
          <Field
            name="TransactionNo"
            className="form-control"
            id="TransactionNo"
            placeholder="Enter Transaction No"
            component={TextField}
          />
        </div>
      </Col>
      <Col lg={4}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="TransactionDate">
            Transaction Date:
          </Label>
          <Date name={"TransactionDate"} />
        </div>
      </Col>
    </React.Fragment>
  );
};

const Cheque = () => {
  return (
    <React.Fragment>
      <Col lg={4}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="ChequeNo">
            Cheque No:
          </Label>
          <Field
            name="ChequeNo"
            className="form-control"
            id="ChequeNo"
            placeholder="Enter Cheque No"
            component={TextField}
          />
        </div>
      </Col>
      <Col lg={4}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="ChequeDate">
            Cheque Date:
          </Label>
          <Date name={"ChequeDate"} />
        </div>
      </Col>
      <Col lg={4}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="BankName">
            Bank Name:
          </Label>
          <Field
            name="BankName"
            className="form-control"
            id="BankName"
            placeholder="Enter Bank Name"
            component={TextField}
          />
        </div>
      </Col>
    </React.Fragment>
  );
};

const NeftImps = () => {
  return (
    <React.Fragment>
      <Col lg={4}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="BankName">
            Bank Name:
          </Label>
          <Field
            name="BankName"
            className="form-control"
            id="BankName"
            placeholder="Enter Bank Name"
            component={TextField}
          />
        </div>
      </Col>
      <Col lg={4}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="TransactionNo">
            Transaction No:
          </Label>
          <Field
            name="TransactionNo"
            className="form-control"
            id="TransactionNo"
            placeholder="Enter Transaction No"
            component={TextField}
          />
        </div>
      </Col>
      <Col lg={4}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="TransactionDate">
            Transaction Date:
          </Label>
          <Date name={"TransactionDate"} />
        </div>
      </Col>
    </React.Fragment>
  );
};

const Upi = () => {
  return (
    <React.Fragment>
      <Col lg={4}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="TransactionNo">
            Transaction No:
          </Label>
          <Field
            name="TransactionNo"
            className="form-control"
            id="TransactionNo"
            placeholder="Enter Transaction No"
            component={TextField}
          />
        </div>
      </Col>
      <Col lg={4}>
        <div className="mb-3">
          <Label className="form-label" htmlFor="TransactionDate">
            Transaction Date:
          </Label>
          <Date name={"TransactionDate"} />
        </div>
      </Col>
    </React.Fragment>
  );
};

export default CreatePayment;
