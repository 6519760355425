/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap"
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth"

//redux
import { useSelector, useDispatch } from "react-redux"

import { Link } from "react-router-dom"
import withRouter from "../../Components/Common/withRouter"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
// actions
import { loginUser, resetLoginFlag } from "../../slices/thunks"

// import logoLight from "../../assets/images/logo-light.png";
import logo from "../../assets/images/logo.png"
const Login = (props) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => ({
    user: state.Account.user,
  }))

  const [userLogin, setUserLogin] = useState([])
  const [showPassword, setShowPassword] = useState(false)

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        email: user.user.email,
        password: user.user.confirm_password,
      })
    }
  }, [user])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      UserName: userLogin.UserName || "9427005604" || "",
      Password: userLogin.Password || "123456" || "",
    },
    validationSchema: Yup.object({
      UserName: Yup.string().required("Please Enter Your Mobile"),
      Password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      let obj = {
        ...values
      }

      obj.UserName = obj?.UserName ? obj.UserName.toString() : ""
      
      dispatch(loginUser(obj, props.router.navigate))
    },
  })

  const { error, errorFlag, loading } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
    errorFlag: state.Login.errorFlag,
  }))

  useEffect(() => {
    if (errorFlag) {
      setTimeout(() => {
        dispatch(resetLoginFlag())
      }, 3000)
    }
  }, [dispatch, errorFlag])

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logo} alt="" height="150" />
                    </Link>
                  </div>
                  <p className="mt-3 fs-15 fw-medium">
                    Travel the world around
                  </p>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="mt-4">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back !</h5>
                      <p className="text-muted">
                        Sign in to continue to AI Travel CRM.
                      </p>
                    </div>
                    {error && error ? (
                      <Alert color="danger"> {error} </Alert>
                    ) : null}
                    <div className="p-2 mt-4">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault()
                          validation.handleSubmit()
                          return false
                        }}
                        action="#"
                      >
                        <div className="mb-3">
                          <Label htmlFor="User Name" className="form-label">
                            User Name
                          </Label>
                          <Input
                            name="UserName"
                            className="form-control"
                            placeholder="Enter UserName"
                            type="number"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.UserName || ""}
                            invalid={
                              validation.touched.UserName &&
                              validation.errors.UserName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.UserName &&
                          validation.errors.UserName ? (
                            <FormFeedback type="invalid">
                              {validation.errors.UserName}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            <Link to="/forgot-password" className="text-muted">
                              Forgot password?
                            </Link>
                          </div>
                          <Label
                            className="form-label"
                            htmlFor="password-input"
                          >
                            Password
                          </Label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <Input
                              name="Password"
                              value={validation.values.Password || ""}
                              type={showPassword ? "text" : "Password"}
                              className="form-control pe-5"
                              placeholder="Enter Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.Password &&
                                validation.errors.Password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.Password &&
                            validation.errors.Password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.Password}
                              </FormFeedback>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                              onClick={() => setShowPassword(!showPassword)}
                              type="button"
                              id="password-addon"
                            >
                              <i className="ri-eye-fill align-middle"></i>
                            </button>
                          </div>
                        </div>

                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="auth-remember-check"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </Label>
                        </div>

                        <div className="mt-4">
                          <Button
                            color="success"
                            disabled={error ? null : loading ? true : false}
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            {loading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            Sign In
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  )
}

export default withRouter(Login)
