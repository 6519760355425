import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";


import { ActiveInActiveCanelationPolicyApi, deleteCanelationPolicyApi, findByIdCanelationPolicyApi, getAllActiveCanelationPolicyApi, getAllCanelationPolicyApi, insertCanelationPolicyApi, updateCanelationPolicyApi } from "./canelationpolicy.services";

export const getAllActiveCanelationPolicy = createAsyncThunk(
    "CancellationPolicies/FindAllActive",
    async () => {
        try {
            const response = getAllActiveCanelationPolicyApi();
            return response;
        } catch (error) {
            return error;
        }
    }
);


export const getAllCanelationPolicy = createAsyncThunk("CancellationPolicies/FindAll", async () => {
    try {
      const response = await getAllCanelationPolicyApi();
      if (response?.Status !== 200) {
          toast.error(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        } else {
          return response;
        }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  });
  
  export const findByIdCanelationPolicy = createAsyncThunk(
    "CancellationPolicies/FindByID",
    async (params) => {
      try {
        const response = await findByIdCanelationPolicyApi(params);
        if (response?.Status !== 200) {
          toast.error(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        } else {
          return response;
        }
      } catch (error) {
        return error;
      }
    }
  );
  
  export const insertCanelationPolicy = createAsyncThunk(
    "CancellationPolicies/Insert",
    async (data) => {
      try {
        const response = await insertCanelationPolicyApi(data);
        if (response?.Status !== 200) {
          toast.error(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        } else {
          toast.success(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        }
      } catch (error) {
        return error;
      }
    }
  );
  
  export const updateCanelationPolicy = createAsyncThunk(
    "CancellationPolicies/Update",
    async (data) => {
      try {
        const response = await updateCanelationPolicyApi(data);
        if (response?.Status !== 200) {
          toast.error(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        } else {
          toast.success(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        }
      } catch (error) {
        return error;
      }
    }
  );
  
  export const ActiveInActiveCanelationPolicy = createAsyncThunk(
    "CancellationPolicies/Active-InActive",
    async (data) => {
      try {
        const response = await ActiveInActiveCanelationPolicyApi(data);
        if (response?.Status !== 200) {
          toast.error(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        } else {
          toast.success(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        } 
      } catch (error) {
        return error;
      }
    }
  );
  
  export const deleteCanelationPolicy = createAsyncThunk(
    "CancellationPolicies/Delete",
    async (data) => {
      try {
        const response = await deleteCanelationPolicyApi(data);
        if (response?.Status !== 200) {
          toast.error(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        } else {
          toast.success(response?.Details, { autoClose: AUTO_CLOSE });
          return response;
        }
      } catch (error) {
        return error;
      }
    }
  );
  
