import { Field, Form, Formik, useFormikContext } from "formik"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Label,
  Row,
  TabPane,
  Table,
} from "reactstrap"
import {
  TextField,
  SelectionField,
  REQUIRED,
  POSITIVE,
  AMOUNT,
} from "../../Components/constants/field"
import { useEffect } from "react"
import Loader from "../../Components/Common/Loader"
import DeleteModal from "../../Components/Common/DeleteModal"
import {
  AdditionalServicesDetailsPerPerson,
  createAdditionalServices,
  deleteAdditionalServices,
  getQuotationDetail,
  updateAdditionalServices,
} from "../../slices/thunks"
import _ from "lodash"
import { useMemo } from "react"
import * as Yup from "yup"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { ActiveTab, showModal } from "../../slices/quotation/reducer"
const AdditionalServicePerPerson = (props) => {
  const { showTable = true, showQuotationBTN = true } = props
  const { GSTActiveList, loading: GstLoading } = useSelector(
    (state) => state.Gst
  )
  const dispatch = useDispatch()
  const location = useLocation()

  const urlParams = new URLSearchParams(location.search)
  const qID = urlParams.get("q")
  const {
    activeTab,
    addtionalServicePerPerson: { form, list },
    modal: { modal },
  } = useSelector((state) => state.Quotation)

  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteData, setDeleteData] = useState(null)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    if (qID && !modal) {
      let quotationDetail = {
        ID: Number(qID),
      }

      dispatch(getQuotationDetail(quotationDetail))
    }
  }, [qID, reload, modal])

  const handleSubmit = (values, { resetForm }) => {
    let obj = {
      ...values,
    }

    obj.QuoteID = +obj.QuoteID
    obj.NoOfPax = +obj.NoOfPax
    obj.Rate = +obj.Rate
    obj.Total = +obj.Total
    obj.Margin = +obj.Margin
    obj.MarginTotal = +obj.MarginTotal
    obj.GSTID = +obj.GSTID
    obj.GSTPer = +obj.GSTPer
    obj.GSTAmount = +obj.GSTAmount
    obj.GrandTotal = +obj.GrandTotal
    obj.IsActive = true

    // createAdditional services per person
    if (!obj.ID) {
      delete obj.ID
      obj[`OperationType`] = 17
      obj[`QuoteID`] = Number(qID)

      dispatch(createAdditionalServices(obj)).then((res) => {
        setReload(!reload)
        if (res.payload && res.payload.Status === 200) {
          resetForm()
          if (modal) {
            let obj = {
              modal: false,
              modalType: "",
              modalData: {},
            }
            dispatch(showModal(obj))
          }
        }
      })
    } else {
      obj[`OperationType`] = 18
      obj[`QuoteID`] = Number(qID)

      dispatch(updateAdditionalServices(obj)).then((res) => {
        setReload(!reload)
        if (res.payload && res.payload.Status === 200) {
          resetForm()
          if (modal) {
            let obj = {
              modal: false,
              modalType: "",
              modalData: {},
            }
            dispatch(showModal(obj))
          }
        }
      })
    }
  }

  const editAdditionalServicePerPerson = (data) => {
    let detailObj = {
      ID: +data?.ID || "",
      QuoteID: +data?.QuoteID || "",
    }
    dispatch(AdditionalServicesDetailsPerPerson(detailObj))
  }

  const onClickDelete = (data) => {
    setDeleteModal(true)
    setDeleteData(data)
  }

  const onDeleted = () => {
    let detailObj = {
      ID: +deleteData?.ID || "",
      QuoteID: +deleteData?.QuoteID || "",
      OperationType: 19,
    }
    dispatch(deleteAdditionalServices(detailObj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload)
        setDeleteModal(true)
        setDeleteData(null)
      }
    })
  }

  const onClickPreviewQuotation = () => {
    console.log("Preview")
    dispatch(
      ActiveTab(4)
      // ActiveTab(
      //   // activeTabsList[_.findIndex(activeTabsList, (q) => q === activeTab) + 1]
      // )
    )
  }
  if (GstLoading) return <Loader />

  return (
    <TabPane id="steparrow-gen-info" tabId={activeTab}>
      <Card className="border border-black">
        <Formik
          initialValues={form}
          enableReinitialize={true}
          validationSchema={Yup.object({
            Title: Yup.string().nullable(true).required(REQUIRED),
            Rate: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .positive(POSITIVE)
              .required(REQUIRED),
            Total: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            Margin: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            MarginTotal: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            GSTID: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable(),
            GSTAmount: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
            GrandTotal: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .min(0, AMOUNT),
          })}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form noValidate autoComplete="off">
              <CardHeader>
                <Row>
                  <Col md="9">
                    <h4>Additional Service Per Person*</h4>
                  </Col>
                  <Col md="3">
                    <div className="d-flex align-items-center justify-content-between">
                      <Label htmlFor="MiscPaxGrandTotal" className="w-100">
                        Grand Total
                      </Label>

                      <Field
                        name="MiscPaxGrandTotal"
                        type="number"
                        className="form-control"
                        id="MiscPaxGrandTotal"
                        placeholder="Grand Total"
                        component={TextField}
                        disabled
                      />
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <div>
                  <Row>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="Title">
                          Title:
                        </Label>
                        <Field
                          name="Title"
                          type="text"
                          className="form-control"
                          id="Title"
                          placeholder="Enter Title"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="Rate">
                          Per Person Rate
                        </Label>
                        <Field
                          name="Rate"
                          type="number"
                          className="form-control"
                          id="Rate"
                          placeholder="Enter Per Person Rate"
                          component={TextField}
                        />
                      </div>
                    </Col>

                    <Col lg={2}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="NoOfPax">
                          Total Person
                        </Label>
                        <Field
                          name="NoOfPax"
                          type="number"
                          className="form-control"
                          id="NoOfPax"
                          placeholder="Enter Total Person"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Total />
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="Total">
                          Total
                        </Label>
                        <Field
                          name="Total"
                          type="number"
                          className="form-control"
                          id="Total"
                          placeholder="Total"
                          component={TextField}
                          disabled
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="Margin">
                          Margin
                        </Label>
                        <Field
                          name="Margin"
                          type="number"
                          className="form-control"
                          id="Margin"
                          placeholder="Margin"
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="MarginTotal">
                          Margin Total
                        </Label>
                        <Field
                          name="MarginTotal"
                          type="number"
                          className="form-control"
                          id="MarginTotal"
                          placeholder="Margin Total"
                          disabled
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="GSTID">
                          GST
                        </Label>
                        <Field
                          id="GSTID"
                          name="GSTID"
                          // eslint-disable-next-line react/prop-types
                          options={
                            Array.isArray(GSTActiveList)
                              ? GSTActiveList.map((gst) => {
                                  return {
                                    value: gst?.ID || "",
                                    label: gst?.Title || "",
                                  }
                                })
                              : []
                          }
                          component={SelectionField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="GSTAmount">
                          GST Amount
                        </Label>
                        <Field
                          name="GSTAmount"
                          type="number"
                          className="form-control"
                          id="GSTAmount"
                          placeholder="GST Amount"
                          disabled
                          component={TextField}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="GrandTotal">
                          Total Amount
                        </Label>
                        <Field
                          name="GrandTotal"
                          type="number"
                          className="form-control"
                          id="GrandTotal"
                          placeholder="GrandTotal"
                          disabled
                          component={TextField}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col lg={12}>
                      <hr></hr>
                    </Col>
                    <Col md="12" className="text-end">
                      <Button className="success" type="submit">
                        Save & Add Another
                      </Button>
                      {showQuotationBTN ? (
                        <Button
                          className="ms-1"
                          color="success"
                          type="button"
                          onClick={onClickPreviewQuotation}
                        >
                          Quotation Preview
                        </Button>
                      ) : null}
                    </Col>

                    <DeleteModal
                      show={deleteModal}
                      onCloseClick={() => setDeleteModal(false)}
                      onDeleteClick={() => onDeleted()}
                    />
                  </Row>
                </div>
              </CardBody>
            </Form>
          )}
        </Formik>
        {showTable ? (
          <CardBody>
            <hr></hr>
            <Row></Row>
            <Col lg={12}>
              <div className="table-responsive mt-2">
                <Table
                  className="align-middle table-nowrap mb-0"
                  striped
                  responsive
                  hover
                >
                  <thead>
                    <tr>
                      <th scope="col">Service</th>
                      <th scope="col">Rate/Person</th>
                      <th scope="col">Total Person</th>
                      <th scope="col">Total Amount</th>
                      <th scope="col">Margin</th>
                      <th scope="col">GST%</th>
                      <th scope="col">GST Amount</th>
                      <th scope="col">Grand Total</th>

                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(list) && list.length ? (
                      list.map((aspp, i) => {
                        return (
                          <tr key={i}>
                            <td scope="col">{aspp?.Title}</td>
                            <td scope="col">{aspp?.Rate}</td>
                            <td scope="col">{aspp?.NoOfPax}</td>
                            <td scope="col">{aspp?.Total}</td>
                            <td scope="col">{aspp?.Margin}</td>
                            <td scope="col">{aspp?.GSTPer}</td>
                            <td scope="col">{aspp?.GSTAmount}</td>
                            <td scope="col">{aspp?.GrandTotal}</td>
                            <td scope="col">
                              {" "}
                              <div
                                className="hstack gap-3 flex-wrap "
                                role="button"
                              >
                                <i
                                  className="ri-edit-line text-success fs-18"
                                  //
                                  onClick={() =>
                                    editAdditionalServicePerPerson(aspp)
                                  }
                                ></i>
                                <div className="gap-3 " role="button">
                                  <i
                                    className="ri-delete-bin-fill text-danger fs-18"
                                    onClick={() => onClickDelete(aspp)}
                                  ></i>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : list.length === 0 ? (
                      <tr>
                        <td colSpan={10} className="text-center">
                          No Additional Service Per Person found
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </Table>
              </div>
            </Col>
          </CardBody>
        ) : null}
      </Card>
    </TabPane>
  )
}
// PickUpAndDropForm.propTypes = {
AdditionalServicePerPerson.propTypes = {
  showTable: PropTypes.bool,
  showQuotationBTN: PropTypes.bool,
}

export default AdditionalServicePerPerson

export function TotalSumComponent() {
  const { values, setFieldValue } = useFormikContext()
  const { GSTActiveList } = useSelector((state) => state.Gst)
  //
  const TotalValue = useCallback(
    (values, subArray, type = "sum") => {
      let value = _.filter(
        _.map(_.values(_.pick(values, subArray)), (n) => Number(n)),
        function (num) {
          return _.isNumber(num)
        }
      )

      switch (type) {
        case "multiply":
          value = _.sum(value)
            ? value.reduce((acc, curr) => Number(acc) * Number(curr), 1)
            : 0
          break
        default:
          value = _.sum(value)
          break
      }

      return _.round(value)
    },
    [values]
  )

  // Total
  useMemo(() => {
    setFieldValue("Total", TotalValue(values, ["NoOfPax", "Rate"], "multiply"))
  }, [values?.NoOfPax, values?.Rate])

  // Total Margin Total
  useMemo(() => {
    setFieldValue("MarginTotal", TotalValue(values, ["Total", "Margin"]))
  }, [values?.Total, values?.Margin])

  // GST Total Amount
  useMemo(() => {
    const findGst = _.find(GSTActiveList, (q) => q.ID === Number(values?.GSTID))
    if (findGst?.IsApplyOnServiceMargin) {
      let totalGst = _.round(values?.Margin * ((findGst?.GSTPer || 0) / 100), 2)
      setFieldValue("GSTAmount", totalGst)
    } else {
      let totalGst = _.round(
        values?.MarginTotal * ((findGst?.GSTPer || 0) / 100),
        2
      )
      setFieldValue("GSTAmount", totalGst)
    }
    setFieldValue("GSTPer", findGst?.GSTPer)
  }, [GSTActiveList, values?.GSTID, values?.MarginTotal, values?.Margin])

  //  Grand Total
  //
  useMemo(() => {
    setFieldValue(
      "GrandTotal",
      TotalValue(values, ["MarginTotal", "GSTAmount"])
    )
  }, [values?.Total, values?.MarginTotal, values?.GSTAmount])
  return null
}
export const Total = React.memo(TotalSumComponent)
