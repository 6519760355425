import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ErrorMessage, Field, Formik, Form, useFormikContext } from "formik";
import {
  CONVERT_BOOL,
  POSITIVE,
  REQUIRED,
  SelectionField,
  TextField,
  STANDARD_OUTPUT_DATE,
  STANDARD_INPUT_DATE,
} from "../../Components/constants/field";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import * as Yup from "yup";
import { findByIdGst, insertGst, updateGst } from "../../slices/thunks";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import PropTypes from "prop-types";

export const CreateGST = () => {
  const history = useNavigate();
  const { ID } = useParams();
  const dispatch = useDispatch();
  const {
    loading,
    gstDetail: { form },
  } = useSelector((state) => state.Gst);

  useEffect(() => {
    if (ID) {
      let params = {
        ID: ID,
      };
      dispatch(findByIdGst(params));
    }
  }, [ID]);


  const handleSubmit = (values) => {
    let obj = {
      ...values,
    };

    obj.GSTPer = obj?.GSTPer ? +obj.GSTPer : null;
    obj.IsActive = CONVERT_BOOL.test(obj?.IsActive);
    obj.IsApplyOnServiceMargin = CONVERT_BOOL.test(obj?.IsApplyOnServiceMargin);

    obj.EffectFrom = obj?.EffectFrom
      ? moment(obj.EffectFrom, STANDARD_INPUT_DATE).format(STANDARD_OUTPUT_DATE)
      : "";

    obj.EffectTo = obj?.EffectTo
      ? moment(obj.EffectTo, STANDARD_INPUT_DATE).format(STANDARD_OUTPUT_DATE)
      : "";

    if (obj?.ID) {
      dispatch(updateGst(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history("/gst");
        }
      });
    } else {
      delete obj?.ID;
      dispatch(insertGst(obj)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history("/gst");
        }
      });
    }
  };
  if (loading) {
    return <Loader />;
  }

  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <BreadCrumb title="GST" pageTitle="GST" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">
                      {ID ? "Update GST" : " Create GST"}
                    </h4>
                  </Col>
                  <Col md="1">
                    <Link to="/gst">
                      <Button color="primary" block>
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={form}
                      validationSchema={Yup.object({
                        Title: Yup.string().nullable(true).required(REQUIRED),
                        GSTPer: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable(true)
                          .positive(POSITIVE)
                          .required(REQUIRED),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {() => {
                        return (
                          <Form noValidate autoComplete="off">
                            <div>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="Title"
                                    >
                                      Title:
                                    </Label>
                                    <Field
                                      name="Title"
                                      className="form-control"
                                      id="Title"
                                      placeholder="Enter Title"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="GSTPer"
                                    >
                                      GST %:
                                    </Label>
                                    <Field
                                      name="GSTPer"
                                      className="form-control"
                                      id="GSTPer"
                                      placeholder="Enter GST %"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="EffectFrom"
                                    >
                                      Effect From Date:
                                    </Label>
                                    <FromDate />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="EffectTo"
                                    >
                                      Effect To Date:
                                    </Label>
                                    <ToDate />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="IsActive"
                                    >
                                      Is Apply On Service Margin:
                                    </Label>
                                    <Field
                                      name="IsApplyOnServiceMargin"
                                      id="IsApplyOnServiceMargin"
                                      options={[
                                        {
                                          label: "Yes",
                                          value: true,
                                        },
                                        {
                                          label: "No",
                                          value: false,
                                        },
                                      ]}
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="IsActive"
                                    >
                                      Status
                                    </Label>
                                    <Field
                                      name="IsActive"
                                      id="IsActive"
                                      options={[
                                        {
                                          label: "Active",
                                          value: true,
                                        },
                                        {
                                          label: "Inactive",
                                          value: false,
                                        },
                                      ]}
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mt-2">
                                <Col lg={12}>
                                  <hr></hr>
                                </Col>
                                <Col md="12" className="text-end">
                                  <Button type="submit" className="success">
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateGST;

function FromDate() {
  const { values, setFieldValue } = useFormikContext();
  const handleDateChange = (ev) => {
    setFieldValue("EffectFrom", moment(ev[0]).format(STANDARD_INPUT_DATE));
  };

  return (
    <React.Fragment>
      <Field>
        {({ field }) => (
          <Flatpickr
            name="EffectFrom"
            id="Rate-from-date"
            {...field}
            value={values.EffectFrom}
            placeholder="Select Date.."
            className="form-control"
            options={{
              altInput: true,
              altFormat: "d-m-Y",
              dateFormat: "d-m-Y",
            }}
            onChange={handleDateChange}
          />
        )}
      </Field>
      <ErrorMessage
        name={"EffectFrom"}
        component="div"
        className="text-danger"
      />
    </React.Fragment>
  );
}

function ToDate() {
  const { values, setFieldValue } = useFormikContext();

  const handleDateChange = (ev) => {
    setFieldValue("EffectTo", moment(ev[0]).format(STANDARD_INPUT_DATE));
  };

  return (
    <React.Fragment>
      <Field>
        {({ field }) => {
          return (
            <Flatpickr
              name="EffectTo"
              id="to-from-date"
              {...field}
              value={values.EffectTo}
              placeholder="Select Date.."
              className={classnames("form-control", {
              })}
              options={{
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "d-m-Y",
                minDate: values?.EffectFrom,
              }}
              onChange={handleDateChange}
            />
          );
        }}
      </Field>

      <ErrorMessage name={"EffectTo"} component="div" className="text-danger" />
    </React.Fragment>
  );
}


Date.propTypes = {
  fieldName: PropTypes.string,
};
