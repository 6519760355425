import React from "react";
import ReactPaginate from "react-paginate";
import PropTypes from 'prop-types'

const Pagination = (props) => {
  const {
    handlePageClick,
    pageRangeDisplayed = 4,
    pageCount = 1,
    page = 1,
  } = props;
  return (
    <nav aria-label="Page navigation example">
      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        breakClassName="page-item"
        breakLinkClassName="page-link border border-primary"
        onPageChange={handlePageClick}
        containerClassName="pagination justify-content-end mt-2"
        pageClassName="page-item"
        pageLinkClassName="page-link border border-primary"
        previousClassName="page-item"
        previousLinkClassName="page-link border border-primary"
        nextClassName="page-item"
        nextLinkClassName="page-link border border-primary "
        activeClassName="active"
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        forcePage={(page || 1) - 1}
      />
    </nav>
  );
};

Pagination.propTypes = {
  handlePageClick: PropTypes.func,
  pageRangeDisplayed: PropTypes.number,
  pageCount: PropTypes.number,
  page: PropTypes.number,
};

export default Pagination;
