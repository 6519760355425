/* eslint-disable react/prop-types */
import { Field, FieldArray, Form, Formik, useFormikContext } from "formik"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Card, CardBody, Col, Label, Row, TabPane } from "reactstrap"
import * as Yup from "yup"
import { emptyTourPlace, resetTour } from "../../slices/tour/reducer"
import {
  MultiSelectFieldV2,
  REQUIRED,
  SelectionField,
  TextField,
} from "../../Components/constants/field"
import {
  deleteTourItineraries,
  getCitiesBySectorID,
  getHotelCityWiseTour,
  // getItineraryByCityID,
  getTourByID,
  insertTourItineraries,
  updateTourItineraries,
} from "../../slices/thunks"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import Loader from "../../Components/Common/Loader"
import { findIndex } from "lodash"
// import { ToastContainer } from "react-toastify"

function ChangeWatcher(props) {
  const { index } = props
  const { itineraries } = useSelector((state) => ({
    itineraries: state.Itinerary.itineraries,
  }))

  const { values, setFieldValue } = useFormikContext()
  useEffect(() => {
    if (values.ItinerariesData[index].ItineraryID) {
      const inx = findIndex(itineraries, {
        ID: parseInt(values.ItinerariesData[index].ItineraryID),
      })
      if (inx > -1) {
        setFieldValue(
          `ItinerariesData.${index}.ItineraryDescription`,
          itineraries[inx].Description
        )
      }
    }
  }, [values.ItinerariesData[index].ItineraryID])
  return null
}

function TourPlace() {
  const dispatch = useDispatch()
  const history = useNavigate()
  const tID = urlParamsFuc()
  const [editIndex, setEdit] = useState(null)
  const { activeTourStep } = useSelector((state) => ({
    activeTourStep: state.Tour.activeTourStep,
  }))

  // const { ID } = useParams()

  const { itineraruDetails } = useSelector((state) => ({
    itineraruDetails: state.Tour.itineraruDetails,
  }))

  const handleAddTourPlace = (arrayHelpers) => {
    arrayHelpers.push(emptyTourPlace)
  }

  const { tourDetails } = useSelector((state) => state.Tour)

  const { cities } = useSelector((state) => ({
    cities: state.City.cities,
  }))

  const { itineraryFacilities } = useSelector((state) => ({
    itineraryFacilities: state.Itinerary.itineraryFacilities,
  }))

  const { loading } = useSelector((state) => ({
    loading: state.Tour.loading,
  }))

  useEffect(() => {
    if (tourDetails?.SectorID)
      dispatch(getCitiesBySectorID(tourDetails?.SectorID))
  }, [tourDetails?.SectorID])

  useEffect(() => {
    if (tID) dispatch(getTourByID(tID))
    return () => {
      dispatch(resetTour())
    }
  }, [])

  const handleSubmit = (values) => {
    let obj = {
      ...values,
    }
    if (tID) {
      obj.TourID = +tID
    }
    obj.ItinerariesData = obj.ItinerariesData.map((Itinerary) => {
      return {
        DayNo: +Itinerary.DayNo,
        ID: Itinerary.ID ? Itinerary.ID : 0,
        ItineraryID: +Itinerary.ItineraryID,
        ItineraryDescription: Itinerary.ItineraryDescription,
        CityID: +Itinerary.CityID,
        HotelID: +Itinerary.HotelID,
        ItineraryFacilityID: String(Itinerary.ItineraryFacilityID),
      }
    })

    dispatch(insertTourItineraries(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        history("/tours")
      }
    })
  }

  const handleDeleteClick = (arrayHelpers, index) => {
    if (arrayHelpers.form.values.ItinerariesData[index].ID && tourDetails?.ID) {
      dispatch(
        deleteTourItineraries({
          ID: arrayHelpers.form.values.ItinerariesData[index].ID,
        })
      ).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          arrayHelpers.remove(index)
        }
      })
    } else {
      arrayHelpers.remove(index)
    }
  }

  const handleEditClick = (index) => {
    setEdit(index)
  }

  const handleSaveClick = (Itinerary) => {
    const newItinerary = {}
    newItinerary.TourID = +tID
    newItinerary.DayNo = +Itinerary.DayNo
    newItinerary.ID = Itinerary.ID
    newItinerary.ItineraryID = Itinerary.ItineraryID
    newItinerary.IsActive = true
    newItinerary.ItineraryID = +Itinerary.ItineraryID
    newItinerary.ItineraryDescription = Itinerary.ItineraryDescription
    newItinerary.CityID = +Itinerary.CityID
    newItinerary.HotelID = +Itinerary.HotelID
    newItinerary.ItineraryFacilityID = String(Itinerary.ItineraryFacilityID)

    // Object.keys(emptyTourPlace).forEach((key) => {
    //   if (key === "DayNo") {
    //     newItinerary[key] = +Itinerary[key]
    //   } else if (key === "ItineraryID") {
    //     newItinerary[key] = +Itinerary[key]
    //   } else if (key === "CityID") {
    //     newItinerary[key] = +Itinerary[key]
    //   } else if (key === "HotelID") {
    //     newItinerary[key] = +Itinerary[key]
    //   } else if (key === "ItineraryFacilityID" && Itinerary[key].length > 0) {
    //     newItinerary[key] = Itinerary[key]
    //     const newItineraryFacilityID = []
    //     Itinerary[key].forEach((iten) => {
    //       if (typeof iten === "object" && iten.value) {
    //         newItineraryFacilityID.push(iten.value)
    //       } else {
    //         newItineraryFacilityID.push(iten.value)
    //       }
    //     })
    //     newItinerary[key] = newItineraryFacilityID.join(",")
    //   } else {
    //     newItinerary[key] = Itinerary[key]
    //   }
    // })

    dispatch(updateTourItineraries(newItinerary)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setEdit(null)
      }
    })
  }

  if (loading) return <Loader />

  return (
    <TabPane id="steparrow-gen-info" tabId={activeTourStep}>
      <Formik
        initialValues={itineraruDetails}
        validationSchema={Yup.object({
          ItinerariesData: Yup.array().of(
            Yup.object().shape({
              DayNo: Yup.number().nullable(true).required(REQUIRED),
              ItineraryID: Yup.number().nullable(true).required(REQUIRED),
              ItineraryDescription: Yup.string()
                .nullable(true)
                .required(REQUIRED),
              CityID: Yup.number().nullable(true).required(REQUIRED),
              HotelID: Yup.number().nullable(true),
              ItineraryFacilityID: Yup.array()
                .min(1, REQUIRED)
                .required(REQUIRED),
            })
          ),
        })}
        onSubmit={handleSubmit}
      >
        <Form noValidate autoComplete="off">
          <FieldArray
            name="ItinerariesData"
            render={(arrayHelpers) => (
              <Row>
                <Col lg={4}>
                  <Button
                    color="primary"
                    onClick={() => handleAddTourPlace(arrayHelpers)}
                  >
                    Add Tour Place
                  </Button>
                </Col>
                <Col lg={12}>
                  {arrayHelpers.form.values.ItinerariesData &&
                  arrayHelpers.form.values.ItinerariesData.length > 0
                    ? arrayHelpers.form.values.ItinerariesData.map(
                        (Itinerary, index) => (
                          <Row key={index}>
                            <Col lg={12}>
                              <Card className="border card-border-primary mt-4">
                                <ChangeWatcher index={index} />
                                <CardBody className="p-4">
                                  <Row>
                                    <Col lg={12}>
                                      <Row>
                                        <Col lg={12} className="text-end">
                                          {Itinerary.ID ? (
                                            <>
                                              {editIndex === index ? (
                                                <Button
                                                  color="primary"
                                                  className="btn-icon mb-2 me-2"
                                                  onClick={() =>
                                                    handleSaveClick(Itinerary)
                                                  }
                                                >
                                                  <i className="ri-save-2-fill"></i>
                                                </Button>
                                              ) : (
                                                <Button
                                                  color="primary"
                                                  className="btn-icon mb-2 me-2"
                                                  onClick={() =>
                                                    handleEditClick(index)
                                                  }
                                                >
                                                  <i className="ri-edit-2-fill"></i>
                                                </Button>
                                              )}
                                            </>
                                          ) : null}
                                          <Button
                                            color="danger"
                                            className="btn-icon mb-2"
                                            onClick={() =>
                                              handleDeleteClick(
                                                arrayHelpers,
                                                index
                                              )
                                            }
                                          >
                                            <i className=" ri-delete-bin-5-line"></i>
                                          </Button>
                                        </Col>
                                        <Col lg={3}>
                                          <div className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor="tour-TourCode"
                                            >
                                              Day No
                                            </Label>
                                            <Field
                                              name={`ItinerariesData.${index}.DayNo`}
                                              disabled={
                                                Itinerary.ID &&
                                                editIndex !== index
                                              }
                                              type="number"
                                              className="form-control"
                                              placeholder="Enter Day No"
                                              component={TextField}
                                            />
                                          </div>
                                        </Col>
                                        <Col lg={3}>
                                          <div className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor={`ItinerariesData.${index}.City`}
                                            >
                                              City
                                            </Label>
                                            <Field
                                              name={`ItinerariesData.${index}.CityID`}
                                              disabled={
                                                Itinerary.ID &&
                                                editIndex !== index
                                              }
                                              options={
                                                Array.isArray(cities)
                                                  ? cities.map((city) => {
                                                      return {
                                                        value: city.CityID,
                                                        label: city.CityName,
                                                      }
                                                    })
                                                  : []
                                              }
                                              component={SelectionField}
                                            />
                                          </div>
                                        </Col>
                                        {/* Hotel Drop-down */}
                                        <HotelDropDown
                                          index={index}
                                          Itinerary={Itinerary}
                                          editIndex={editIndex}
                                          arrayHelpers={arrayHelpers}
                                        />

                                        <ItineraryDropDown
                                          index={index}
                                          Itinerary={Itinerary}
                                          editIndex={editIndex}
                                          arrayHelpers={arrayHelpers}
                                        />

                                        <Col lg={9}>
                                          <div className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor="ItinerariesData-ItineraryDescription"
                                            >
                                              Itinerary Description
                                            </Label>
                                            <Field
                                              name={`ItinerariesData.${index}.ItineraryDescription`}
                                              disabled={
                                                Itinerary.ID &&
                                                editIndex !== index
                                              }
                                              className="form-control"
                                              id="ItinerariesData-ItineraryDescription"
                                              placeholder="Itinerary Description"
                                              type="textarea"
                                              component={TextField}
                                            />
                                          </div>
                                        </Col>
                                        <Col lg={3}>
                                          <div className="mb-3">
                                            <Label
                                              className="form-label"
                                              htmlFor={`ItinerariesData.${index}.itineraryFacilities`}
                                            >
                                              Itinerary Facility
                                            </Label>
                                            <Field
                                              name={`ItinerariesData.${index}.ItineraryFacilityID`}
                                              options={
                                                itineraryFacilities &&
                                                itineraryFacilities.map(
                                                  (itineraryFacility) => {
                                                    return {
                                                      value:
                                                        itineraryFacility.ID,
                                                      label:
                                                        itineraryFacility.Name,
                                                      isDisabled:
                                                        Itinerary.ID &&
                                                        editIndex !== index
                                                          ? true
                                                          : false,
                                                    }
                                                  }
                                                )
                                              }
                                              component={MultiSelectFieldV2}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        )
                      )
                    : null}
                </Col>
              </Row>
            )}
          />
          <div className="d-flex align-items-start gap-3 mt-4">
            <button
              type="submit"
              className="btn btn-success btn-label right ms-auto nexttab nexttab"
            >
              <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" />
              Save
            </button>
          </div>
        </Form>
      </Formik>
    </TabPane>
  )

  function urlParamsFuc() {
    const location = useLocation()
    const urlParams = new URLSearchParams(location.search)
    const tID = urlParams.get("t")
    return tID
  }
}

export default TourPlace
// // getHotelCityWiseTour
const HotelDropDown = ({ index, Itinerary, editIndex }) => {
  const dispatch = useDispatch()
  const { hotels } = useSelector((state) => ({
    hotels: state.Hotel.hotels,
  }))

  useEffect(() => {
    if (Itinerary.CityID) {
      const obj = {
        CityID: +Itinerary.CityID,
      }
      dispatch(getHotelCityWiseTour(obj))
    }
  }, [Itinerary.CityID])

  return (
    <Col lg={3}>
      <div className="mb-3">
        <Label
          className="form-label"
          htmlFor={`ItinerariesData.${index}.Hotel`}
        >
          Hotel
        </Label>
        <Field
          disabled={Itinerary.ID && editIndex !== index}
          name={`ItinerariesData.${index}.HotelID`}
          options={
            Array.isArray(hotels)
              ? hotels.map((hotel) => {
                  return {
                    value: hotel.HotelID,
                    label: hotel.HotelName,
                  }
                })
              : []
          }
          component={SelectionField}
        />
      </div>
    </Col>
  )
}

const ItineraryDropDown = ({ index, Itinerary, editIndex }) => {
  const { itineraries } = useSelector((state) => ({
    itineraries: state.Itinerary.itineraries,
  }))

  // useEffect(() => {
  //     if (Itinerary.CityID) {
  //         getItineraryByCityID(+Itinerary.CityID);
  //     }
  // }, [Itinerary.CityID]);
  // console.log("Itinerary", Itinerary);
  return (
    <Col lg={3}>
      <div className="mb-3">
        <Label
          className="form-label"
          htmlFor={`ItinerariesData.${index}.Itinerary`}
        >
          Itinerary
        </Label>
        <Field
          disabled={Itinerary.ID && editIndex !== index}
          name={`ItinerariesData.${index}.ItineraryID`}
          options={
            itineraries &&
            itineraries
              .filter((o) => o.CityID == Itinerary.CityID)
              .map((itin) => {
                return {
                  value: itin.ID,
                  label: itin.ItineraryTitle,
                }
              })
          }
          component={SelectionField}
        />
      </div>
    </Col>
  )
}
