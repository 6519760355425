import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
// import Switch from "../../Components/Common/Switch";
import DeleteModal from "../../Components/Common/DeleteModal";
import { resetForm, resetNameData } from "../../slices/payment/reducer";
import {
  deletePaymentReceipt,
  getAllPaymentReceipt,
} from "../../slices/payment/thunk";
import {
  nextAndPrevPage,
  pageCount,
  resetPage,
} from "../../slices/pagination/reducer";
import Pagination from "../../Components/Common/Pagination";
import { PAGE_LIMIT } from "../../Components/constants/field";

const MasterPayment = () => {
  const dispatch = useDispatch();
  const { paymentReceipts, loading } = useSelector(
    (state) => state.PaymentReceipt
  );
  const { page, totalPage } = useSelector((state) => state.Pagination);

  const [reload, setReload] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    if (sessionStorage.getItem("authUser")) {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      const UserID = obj.ID
      const pageData = {
        UserID: +UserID,
        PageNo: page,
        PageSize: PAGE_LIMIT,
      };
      dispatch(resetForm());
      dispatch(resetNameData());
      dispatch(getAllPaymentReceipt(pageData)).then((res) => {
        const TotalPages = res?.payload?.TotalPages || 1;
        dispatch(pageCount(TotalPages || 1));
      });
    }

  }, [page, PAGE_LIMIT, reload]);

  useEffect(() => {
    return () => {
      dispatch(resetPage());
    };
  }, []);

  // const handleChange = (data) => {
  //   let obj = { ID: +data.ID, IsActive: !data.IsActive };

  //   dispatch(ActiveInActiveState(obj)).then((res) => {
  //     if (res.payload && res.payload.Status === 200) {
  //       setReload(!reload);
  //     }
  //   });
  // };
  const onClickDelete = (data) => {
    setDeleteModal(true);
    setDeleteData(data);
  };
  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
    };
    dispatch(deletePaymentReceipt(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload);
        setDeleteModal(false);
      }
    });
  };

  const handlePageClick = ({ selected }) => {
    let page = (Number(selected) || 0) + 1;
    dispatch(nextAndPrevPage(page));
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Payment Receipt Master" pageTitle="Payment" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md={4}>
                    <h4 className="card-title mb-0">Payment Receipt Master</h4>
                  </Col>
                  <Col md={8} className="text-end">
                    <Link to="/payments/create">
                      <Button color="primary">
                        <i className="las la-plus"></i>Add
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <Table
                        className="align-middle table-nowrap mb-0"
                        striped
                        responsive
                        hover
                      >
                        <thead>
                          <tr className="table-header">
                            <th scope="col">Receipt No</th>
                            <th scope="col">Payment Date</th>
                            <th scope="col">Name</th>
                            <th scope="col">Booking No</th>
                            <th scope="col">Payment Type</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Created By</th>
                            {/* <th scope="col" className="col-1 text-center">
                              Status
                            </th> */}
                            <th scope="col" className="col-1 text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                          Array.isArray(paymentReceipts) &&
                          paymentReceipts.length ? (
                            paymentReceipts.map((paymentReceipt, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={`/payments/update/${paymentReceipt.ID}`}
                                  >
                                    {paymentReceipt.ReceiptNo}
                                  </Link>
                                </td>

                                <td>{paymentReceipt.PaymentDate}</td>
                                <td>{paymentReceipt.Name}</td>
                                <td>{paymentReceipt.BookingNo}</td>
                                <td>{paymentReceipt.PaymentType}</td>
                                <td>{paymentReceipt.Amount}</td>
                                <td>{paymentReceipt.CreatedBy}</td>
                                {/* <td className="text-center">
                                  <Switch
                                    onChange={() => handleChange(paymentReceipt)}
                                    checked={paymentReceipt?.IsActive}
                                  />
                                </td> */}

                                <td className="text-center">
                                  <div className="hstack gap-3 justify-content-center">
                                    <Link
                                      to={`/payments/update/${paymentReceipt.ID}`}
                                      className="link-success fs-18"
                                    >
                                      <i className="ri-edit-line"></i>
                                    </Link>
                                    <div
                                      className="hstack gap-3 flex-wrap "
                                      role="button"
                                      onClick={() =>
                                        onClickDelete(paymentReceipt)
                                      }
                                    >
                                      <i className=" ri-delete-bin-fill text-danger fs-18"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : loading ? (
                            <tr>
                              <td colSpan={10}>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={10}>No Transctions found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                    <Pagination
                      pageCount={totalPage}
                      handlePageClick={handlePageClick}
                      page={page}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <DeleteModal
            show={deleteModal}
            onCloseClick={() => setDeleteModal(false)}
            onDeleteClick={() => onDeleted()}
          />
        </Row>
      </Container>
    </div>
  );
};
export default MasterPayment;
