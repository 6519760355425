import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import {
  ActiveInActiveCountryApi,
  deleteCountryApi,
  findByIdCountryApi,
  getAllActiveCountriesApi,
  getAllCountryApi,
  insertCountryApi,
  updateCountryApi,
} from "./country.services";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";

export const getAllActiveCountries = createAsyncThunk(
  "Countries/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveCountriesApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const getAllCountry = createAsyncThunk("Countries/FindAll", async () => {
  try {
    const response = await getAllCountryApi();
    if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const findByIdCountry = createAsyncThunk(
  "Countries/FindByID",
  async (params) => {
    try {
      const response = await findByIdCountryApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const insertCountry = createAsyncThunk(
  "Countries/Insert",
  async (data) => {
    try {
      const response = await insertCountryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const updateCountry = createAsyncThunk(
  "Countries/Update",
  async (data) => {
    try {
      const response = await updateCountryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const ActiveInActiveCountry = createAsyncThunk(
  "Countries/Active-InActive",
  async (data) => {
    try {
      const response = await ActiveInActiveCountryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } 
    } catch (error) {
      return error;
    }
  }
);

export const deleteCountry = createAsyncThunk(
  "Countries/Delete",
  async (data) => {
    try {
      const response = await deleteCountryApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
