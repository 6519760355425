import { createAsyncThunk } from "@reduxjs/toolkit"
import "react-toastify/dist/ReactToastify.css"
import { FAILED, SUCCESS } from "../../Components/constants/field"
import { AUTO_CLOSE } from "../../Components/constants/toast"
import { toast } from "react-toastify"
import {
  getAllActiveRoomCategoryApi,
  getRoomCategoriesByHotelApi,
  getRoomCategoriesByHotelIDApi,
} from "./room.services"

export const getAllActiveRoomCategory = createAsyncThunk(
  "RoomCategories/FindAllActive",
  async () => {
    try {
      const response = getAllActiveRoomCategoryApi()
      // toast.success(SUCCESS, { autoClose: AUTO_CLOSE });
      return response
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE })
      return error
    }
  }
)

export const getRoomCategoriesByHotelID = createAsyncThunk(
  "Hotels/GetRoomCategoryByHotelID",
  async (hotelDetails) => {
    try {
      const response = getRoomCategoriesByHotelIDApi(hotelDetails)
      //   toast.success(SUCCESS, { autoClose: AUTO_CLOSE })
      return response
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE })
      return error
    }
  }
)

export const getRoomCategoriesByHotel = createAsyncThunk(
  "Quotation/GetRoomCategoriesByHotel",
  async (details) => {
    try {
      const response = getRoomCategoriesByHotelApi(details)
      return response
    } catch (error) {
      return error
    }
  }
)
