import { createAsyncThunk } from "@reduxjs/toolkit"
import { ActiveInActiveGstApi, deleteGstApi, findByIdGstApi, getActiveGstApi, getAllGstApi, insertGstApi, updateGstApi } from "./gst.service"
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";


export const getActiveGst = createAsyncThunk("GST/getActiveGST", async () => {
  try {
    const response = await getActiveGstApi()
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      return response;
    }
  } catch (err) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return err
  }
})


export const getAllGst=createAsyncThunk(
  "GST/FindAll",
  async ()=>{
      try{
          const response =await getAllGstApi()
          if (response?.Status !== 200) {
            toast.error(response?.Details, { autoClose: AUTO_CLOSE });
            return response;
          } else {
            return response;
          }
      } catch  (error) {
          toast.error(FAILED , {autoClose:AUTO_CLOSE})
          return error
      }
  }
)

export const findByIdGst=createAsyncThunk(
  "GST/FindByID",
  async (params)=>{
      try {
          const response=await findByIdGstApi(params)
          if (response?.Status !== 200) {
            toast.error(response?.Details, { autoClose: AUTO_CLOSE });
            return response;
          } else {
            return response;
          }
      } catch (error){
        toast.error(FAILED , {autoClose:AUTO_CLOSE})
          return error
      }
  }
)

export const insertGst=createAsyncThunk("GST/Insert",
async (data)=>{
  try {
      const response=await insertGstApi(data)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
  } catch (error){
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error
  }
})

export const updateGst=createAsyncThunk("GST/Update",
async (data)=>{
  try {
      const response= await updateGstApi(data)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
  } catch (error){
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error
  }
}
)

export const ActiveInActiveGst=createAsyncThunk(
  "GST/Active-InActive",
  async (data)=>{
      try {
          const response=await ActiveInActiveGstApi(data)
          if (response?.Status !== 200) {
            toast.error(response?.Details, { autoClose: AUTO_CLOSE });
            return response;
          } else {
            toast.success(response?.Details, { autoClose: AUTO_CLOSE });
            return response;
          }
      } catch (error){
        toast.error(FAILED, { autoClose: AUTO_CLOSE });
          return error
      }
  }
)

export const deleteGst=createAsyncThunk("GST/Delete",
async (data)=>{
  try {
      const response= await deleteGstApi(data)
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
  } catch (error){
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error
  }
})