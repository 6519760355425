import React from "react";
import { Container, Spinner } from "reactstrap";

import "react-toastify/dist/ReactToastify.css";

const Loader = () => {
    return (
        <div className="page-content">
            <Container fluid>
                <div className="d-flex justify-content-center">
                    <Spinner color="primary" type="grow">
                        {" "}
                        Loading...{" "}
                    </Spinner>
                    <Spinner color="secondary" type="grow">
                        {" "}
                        Loading...{" "}
                    </Spinner>
                    <Spinner color="success" type="grow">
                        {" "}
                        Loading...{" "}
                    </Spinner>
                    <Spinner color="info" type="grow">
                        {" "}
                        Loading...{" "}
                    </Spinner>
                    <Spinner color="warning" type="grow">
                        {" "}
                        Loading...{" "}
                    </Spinner>
                    <Spinner color="danger" type="grow">
                        {" "}
                        Loading...{" "}
                    </Spinner>
                    <Spinner color="dark" type="grow">
                        {" "}
                        Loading...{" "}
                    </Spinner>
                    <Spinner color="light" type="grow">
                        {" "}
                        Loading...{" "}
                    </Spinner>
                </div>
            </Container>
        </div>
    );
};

export default Loader;
