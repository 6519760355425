import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isQuotation, setIsQuotation] = useState(false);
  const [isHotel, setIsHotel] = useState(false);
  const [isTour, setIsTour] = useState(false);
  const [isMaster, setIsMaster] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isAgent, setIsAgent] = useState(false);
  const [autoQuotation, setAutoQuotation] = useState(false);
  const [isInquiry, setIsInquiry] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Quotation") {
      setIsQuotation(false);
    }
    if (iscurrentState !== "Hotel") {
      setIsHotel(false);
    }
    if (iscurrentState !== "Tour") {
      setIsTour(false);
    }
    if (iscurrentState !== "Master") {
      setIsMaster(false);
    }
    if (iscurrentState !== "User") {
      setIsUser(false);
    }
    if (iscurrentState !== "Agent") {
      setIsAgent(false);
    }
    if (iscurrentState !== "autoQuotation") {
      setAutoQuotation(false);
    }
    if (iscurrentState !== "Inquiry") {
      setIsInquiry(false);
    }
    if (iscurrentState !== "Payment") {
      setIsPayment(false);
    }
  }, [history, iscurrentState, isDashboard, isQuotation, isHotel, isTour, isInquiry, isPayment]);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");

      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "quotations",
      label: "Manage Quotations",
      icon: "las la-file-invoice-dollar",
      link: "/#",
      stateVariables: isQuotation,
      click: function (e) {
        e.preventDefault();
        setIsQuotation(!isQuotation);
        setIscurrentState("Quotation");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "quotations",
          label: "Quotations Master",
          link: "/quotations",
          parentId: "quotations",
        },
        // {
        //   id: "quotations-create",
        //   label: "Create Quotation",
        //   link: "/quotations/0",
        //   parentId: "quotations",
        // },
      ],
    },
    {
      id: "hotels",
      label: "Manage Hotels",
      icon: "bx bx-hotel",
      link: "/#",
      stateVariables: isHotel,
      click: function (e) {
        e.preventDefault();
        setIsHotel(!isHotel);
        setIscurrentState("Hotel");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "hotels",
          label: "Hotel Master",
          link: "/hotels",
          parentId: "hotels",
        },
        // {
        //     id: "hotels",
        //     label: "Create Hotel",
        //     link: "/hotels/0",
        //     parentId: "hotels",
        // },
      ],
    },
    {
      id: "master",
      label: "Master",
      icon: "las la-city",
      link: "/#",
      stateVariables: isMaster,
      click: function (e) {
        e.preventDefault();
        setIsMaster(!isMaster);
        setIscurrentState("Master");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "sector",
          label: "Sector Master",
          link: "/sector",
          parentId: "master",
        },
        {
          id: "subsector",
          label: "Sub Sector Master",
          link: "/subsector",
          parentId: "master",
        },
        {
          id: "leadsource",
          label: "Lead Source Master",
          link: "/leadsource",
          parentId: "master",
        },
        {
          id: "festivals",
          label: "Festivals Master",
          link: "/festivals",
          parentId: "master",
        },
        {
          id: "vehicle",
          label: "Vehicle Master",
          link: "/vehicle",
          parentId: "master",
        },
        {
          id: "country",
          label: "Country Master",
          link: "/country",
          parentId: "master",
        },
        {
          id: "state",
          label: "State Master",
          link: "/state",
          parentId: "master",
        },

        {
          id: "city",
          label: "City Master",
          link: "/city",
          parentId: "master",
        },
        {
          id: "vehicletype",
          label: "Vehicle Type Master",
          link: "/vehicletype",
          parentId: "master",
        },
        {
          id: "gst",
          label: "GST Master",
          link: "/gst",
          parentId: "master",
        },
        {
          id: "termandcondition",
          label: "Terms & Conditions Master",
          link: "/termandcondition",
          parentId: "master",
        },
        {
          id: "cancellationpolicy",
          label: "Cancellation Policy Master",
          link: "/cancellationpolicy",
          parentId: "master",
        },
        {
          id: "specialitytype",
          label: "Tour Speciality Type Master",
          link: "/specialitytype",
          parentId: "master",
        },
        {
          id: "itinerary",
          label: "Itinerary Master",
          link: "/itinerary",
          parentId: "master",
        },
        {
          id: "tourfacility",
          label: "Tour Facility Master",
          link: "/tourfacility",
          parentId: "master",
        },
      ],
    },
    {
      id: "tours",
      label: "Manage Tours",
      icon: "las la-plane-departure",
      link: "/#",
      stateVariables: isTour,
      click: function (e) {
        e.preventDefault();
        setIsTour(!isTour);
        setIscurrentState("Tour");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "tours",
          label: "Tour Master",
          link: "/tours",
          parentId: "Tours",
        },
        // {
        //   id: "tours",
        //   label: "Create Tour",
        //   link: "/tours/0",
        //   parentId: "Tours",
        // },
      ],
    },
    {
      id: "autoQuotation",
      label: "Manage Auto Quotation",
      icon: "ri-bubble-chart-fill",
      link: "/#",
      stateVariables: autoQuotation,
      click: function (e) {
        e.preventDefault();
        setAutoQuotation(!autoQuotation);
        setIscurrentState("autoQuotation");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "autoQuotation",
          label: "Auto Quotation Master",
          link: "/auto-quotations",
          parentId: "autoQuotation",
        },
        // {
        //   id: "tours",
        //   label: "Create Tour",
        //   link: "/tours/0",
        //   parentId: "Tours",
        // },
      ],
    },
    {
      id: "inquiries",
      label: "Manage Inquiries",
      icon: "-",
      link: "/#",
      stateVariables: isInquiry,
      click: function (e) {
        e.preventDefault();
        setIsInquiry(!isInquiry);
        setIscurrentState("Inquiry");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "inquiries",
          label: "Inquiry Master",
          link: "/inquiry",
          parentId: "inquiries",
        },
      ],
    },
    {
      id: "users",
      label: "Manage Users",
      icon: "-",
      link: "/#",
      stateVariables: isUser,
      click: function (e) {
        e.preventDefault();
        setIsUser(!isUser);
        setIscurrentState("User");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "users",
          label: "User Master",
          link: "/users",
          parentId: "users",
        },
      ],
    },
    {
      id: "agents",
      label: "Manage Agents",
      icon: "-",
      link: "/#",
      stateVariables: isAgent,
      click: function (e) {
        e.preventDefault();
        setIsAgent(!isAgent);
        setIscurrentState("Agent");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "agents",
          label: "Agent Master",
          link: "/agents",
          parentId: "agents",
        },
      ],
    },
    {
      id: "payments",
      label: "Manage Payments",
      icon: "-",
      link: "/#",
      stateVariables: isPayment,
      click: function (e) {
        e.preventDefault();
        setIsPayment(!isPayment);
        setIscurrentState("Payment");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "payments",
          label: "Payment Transctions",
          link: "/payments",
          parentId: "payments",
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
