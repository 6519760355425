import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllActiveFestivalsApi = () =>
    api.get(url.GET_ALL_ACTIVE_FESTIVALS);


    export const getAllFestivalsApi=()=>api.get(url.GET_ALL_FESTIVAL);

    export const findByIdFestivalsApi = (params) =>
    api.create(url.FIND_BY_ID_FESTIVAL, params);

    
export const insertFestivalsApi = (data) => api.create(url.INSERT_FESTIVAL, data);

export const updateFestivalsApi = (data) => api.create(url.UPDATE_FESTIVAL, data);

export const deleteFestivalsApi = (data) => api.create(url.DELETE_FESTIVAL, data);


export const ActiveInActiveCountryApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_FESTIVAL, data);