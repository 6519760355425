import React from "react"
import _switch from "react-switch"
const Switch = (props) => {
  return (
    <_switch
      height={15}
      width={25}
      uncheckedIcon={false}
      checkedIcon={false}
      {...props}
    />
  )
}

export default Switch
