import { ErrorMessage, Field, useFormikContext } from "formik"
import React from "react"
import { Col, Label, Row } from "reactstrap"
import {
  STANDARD_INPUT_DATE,
  TextField,
} from "../../Components/constants/field"
import Flatpickr from "react-flatpickr"
import moment from "moment"
import PropTypes from "prop-types"
const PickUpAndDropForm = (props) => {
  const { headerTitle, field } = props

  function PickUpChargeDate(name) {
    const { values, setFieldValue } = useFormikContext()

    const handleDateChange = (ev) => {
      setFieldValue(name?.name, moment(ev[0]).format(STANDARD_INPUT_DATE))
    }

    return (
      <React.Fragment>
        <Field>
          {({ field }) => (
            <Flatpickr
              name="DropDate"
              id="DropDate"
              {...field}
              value={values[name?.name]}
              placeholder="Select Date.."
              className="form-control"
              options={{
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "d-m-Y",
                // minDate:
              }}
              onChange={handleDateChange}
            />
          )}
        </Field>
        <ErrorMessage name={name} component="div" className="text-danger" />
      </React.Fragment>
    )
  }
  return (
    <React.Fragment>
      <Row>
        {/* <hr></hr> */}
        <Col md="12" className="mb-2 mt-4">
          <h4>{headerTitle}</h4>
        </Col>
        <hr></hr>
      </Row>
      <Row>
        <Col lg={3}>
          {/* PickUpChargeDate */}
          <div className="mb-3">
            <Label
              className="form-label"
              htmlFor="accomodation-PickUpChargeDate"
            >
              Date
            </Label>
            <PickUpChargeDate
              name={field === "PickUp" ? "PickUpChargeDate" : "DropChargeDate"}
            />
          </div>
        </Col>
        <Col lg={3}>
          <div className="mb-3">
            <Label className="form-label" htmlFor={`Transport-${field}From`}>
              From
            </Label>
            <Field
              name={`${field}From`}
              type="text"
              className="form-control"
              id={`Transport-${field}From`}
              placeholder="From"
              component={TextField}
            />
          </div>
        </Col>
        <Col lg={3}>
          <div className="mb-3">
            <Label className="form-label" htmlFor={`Transport-${field}At`}>
              To(At)
            </Label>
            <Field
              name={`${field}At`}
              type="text"
              className="form-control"
              id={`Transport-${field}At`}
              placeholder="To(At)"
              component={TextField}
            />
          </div>
        </Col>
        <Col lg={3}>
          <div className="mb-3">
            <Label className="form-label" htmlFor={`Transport-${field}Charges`}>
              Rate
            </Label>
            <Field
              name={`${field}Charges`}
              type="number"
              className="form-control"
              id={`Transport-${field}Charges`}
              placeholder="Rate"
              component={TextField}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

PickUpAndDropForm.propTypes = {
  // headerTitle, field
  headerTitle: PropTypes.any,
  field: PropTypes.any,
}
export default PickUpAndDropForm
