import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllActiveVehicleTypeApi = () =>
  api.get(url.GET_ALL_ACTIVE_VEHICLE_TYPE);

export const getAllActiveVehicleApi = () => api.get(url.GET_ALL_ACTIVE_VEHICLE);
export const getAllVehicleApi = () => api.get(url.GET_ALL_VEHICLE);

export const getSeatByVehicleTypeApi = (vehicleTypeID) =>
  api.create(url.GET_SEAT_BY_VEHICLE_TYPE, { VehicleTypeID: vehicleTypeID });

export const getVehicleTypeByNoOfPaxApi = (NoOfPax) =>
  api.create(url.GET_VEHICLE_TYPE_BY_VEHICLE_TYPE, NoOfPax);
export const getVehicleTypeByTourAPI = (data) =>
  api.create(url.GET_VEHICLE_TYPE_BY_TOUR, data);

export const findByIdVehicleApi = (params) =>
  api.create(url.FIND_BY_ID_VEHICLE_URL, params);

export const insertVehicleApi = (data) =>
  api.create(url.INSERT_VEHICLE_URL, data);

export const updateVehicleApi = (data) =>
  api.create(url.UPDATE_VEHICLE_URL, data);

export const ActiveInActiveVehicleApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_VEHICLE_URL, data);

export const deleteVehicleApi = (data) =>
  api.create(url.DELETE_VEHICLE_URL, data);
