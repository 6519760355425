import { createSlice } from "@reduxjs/toolkit";
import { findByIdCountry, getAllActiveCountries, getAllCountry, insertCountry, updateCountry } from "./thunk";
import _ from "lodash"

export const countryForm={
    ID:null,
    CountryName:"",
    CountryCode:"",
    IsActive: true,
}

export const initialState = {
    countryDetail:{
        form:countryForm
    },
    countries: [],
    error: {},
    loading: false,
};

const CountryReducer = createSlice({
    name: "Country",
    initialState,
    reducers: {
        resetForm: (state) => {
            state.countryDetail.form = countryForm;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllActiveCountries.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllActiveCountries.fulfilled, (state, action) => {
            state.countries = action.payload.Data;
            state.loading = false;
        });
        builder.addCase(getAllActiveCountries.rejected, (state, action) => {
            state.error = action.payload?.error || null;
            state.loading = false;
        });
        builder.addCase(getAllCountry.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(getAllCountry.fulfilled,(state,action)=>{
            state.countries=action.payload.Data || []
            state.loading=false
        });
        builder.addCase(getAllCountry.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(insertCountry.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(insertCountry.fulfilled,(state)=>{
            state.countryDetail.form=countryForm
            state.loading=false
        });
        builder.addCase(insertCountry.rejected, (state,action) => {
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(updateCountry.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(updateCountry.fulfilled,(state)=>{
            state.countryDetail.form=countryForm
            state.loading=false
        });
        builder.addCase(updateCountry.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
       builder.addCase(findByIdCountry.pending,(state)=>{
        state.loading=true
       });
       builder.addCase(findByIdCountry.fulfilled,(state,action)=>{
        let formObj = action?.payload?.Data

        let formKeys=Object.keys(countryForm)
        formObj = _.pick(formObj,formKeys)
        state.countryDetail.form=formObj
        state.loading=false
       });
       builder.addCase(findByIdCountry.rejected,(state,action)=>{
        state.error=action.payload?.error || null
        state.loading=false
       })
    },
});

export const {resetForm}=CountryReducer.actions

export default CountryReducer.reducer;
