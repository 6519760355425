import { createAsyncThunk } from "@reduxjs/toolkit"
import "react-toastify/dist/ReactToastify.css"
import { FAILED, SUCCESS } from "../../Components/constants/field"
import { AUTO_CLOSE } from "../../Components/constants/toast"
import { toast } from "react-toastify"
import { getAllActiveMealTypesApi } from "./meal.services"

export const getAllActiveMealTypes = createAsyncThunk(
  "MealTypes/FindAllActive",
  async () => {
    try {
      const response = getAllActiveMealTypesApi()
      // toast.success(SUCCESS, { autoClose: AUTO_CLOSE });
      return response
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE })
      return error
    }
  }
)
