import { createSlice } from "@reduxjs/toolkit"
import { findByIdCity, getAllACINCities, getAllCities, getCitiesBySectorID, getCitiesByStateID, getCityByTourID, insertCity, updateCity } from "./thunk"
import _ from "lodash"

export const cityForm = {
  ID: null,
  CountryID: null,
  StateID: null,
  IsActive: true,
  CityName: "",
  CityCode: "",
}

export const initialState = {
  cityDetail: {
    form: cityForm,
  },
  cities: [],
  allCities: [],
  stateWiseCities: [],
  error: {},
  loading: false,
}

const CityReducer = createSlice({
  name: "City",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.cityDetail.form = cityForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCities.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllCities.fulfilled, (state, action) => {
      state.cities = action.payload.Data || []
      state.loading = false
    })
    builder.addCase(getAllCities.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })
    builder.addCase(getAllACINCities.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllACINCities.fulfilled, (state, action) => {
      state.allCities = action.payload.Data || []
      state.loading = false
    })
    builder.addCase(getAllACINCities.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })
    builder.addCase(getCitiesBySectorID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCitiesBySectorID.fulfilled, (state, action) => {
      if (action.payload) {
        state.cities = action.payload.Data
      }
      state.loading = false
    })
    builder.addCase(getCitiesBySectorID.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })
    builder.addCase(getCityByTourID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCityByTourID.fulfilled, (state, action) => {
      if (action.payload && action.payload.Data && action.payload.Data.CityID) {
        state.cities = action.payload.Data.CityID
      }
      state.loading = false
    })
    builder.addCase(getCityByTourID.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })
    builder.addCase(getCitiesByStateID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCitiesByStateID.fulfilled, (state, action) => {
      if (action.payload && action.payload.Data && action.payload.Data) {
        state.stateWiseCities = action.payload.Data
      }
      state.loading = false
    })
    builder.addCase(getCitiesByStateID.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })

    // Insert City
    builder.addCase(insertCity.pending, (state) => {
      state.loading = true
    })
    builder.addCase(insertCity.fulfilled, (state) => {
      state.cityDetail.form = cityForm
      state.loading = false
    })
    builder.addCase(insertCity.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })

    // Update City
    builder.addCase(updateCity.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateCity.fulfilled, (state) => {
      state.cityDetail.form = cityForm
      state.loading = false
    })
    builder.addCase(updateCity.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })

    // Get By Id City
    builder.addCase(findByIdCity.pending, (state) => {
      state.loading = true
    })
    builder.addCase(findByIdCity.fulfilled, (state, action) => {
      let formObj = action?.payload?.Data

      let formKeys = Object.keys(cityForm)
      formObj = _.pick(formObj, formKeys)

      state.cityDetail.form = formObj

      state.loading = false
    })
    builder.addCase(findByIdCity.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })
  },
})

export const { resetForm } = CityReducer.actions

export default CityReducer.reducer
