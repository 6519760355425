import { createAsyncThunk } from "@reduxjs/toolkit"
import { getAllRegionsApi } from "./region.service"

export const getAllRegions = createAsyncThunk("Regions/GetAll", async () => {
  try {
    const res = getAllRegionsApi()
    return res
  } catch (err) {
    return err
  }
})
