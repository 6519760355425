import React from "react"
// import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Label,
  Row,
} from "reactstrap"
import BreadCrumb from "../../../Components/Common/BreadCrumb"
import { Field, Formik, useFormikContext } from "formik"
// import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import Flatpickr from "react-flatpickr"
import * as Yup from "yup"
import {
  // HOTEL_CATEGORIES,
  INVALID_EMAIL,
  INVALID_PHONE,
  REQUIRED,
  STANDARD_INPUT_DATE,
  SelectionField,
  TextField,
  phoneRegex,
} from "../../../Components/constants/field"
import { useParams } from "react-router-dom"
import { useEffect } from "react"
import _ from "lodash"
import {
  getActiveGst,
  getAllActiveMealTypes,
  getAllCities,
  getQuotationDetail,
  getVehicleTypeByTour,
} from "../../../slices/thunks"
import moment from "moment"
import Loader from "../../../Components/Common/Loader"
import GenerateQuotationList from "./GenerateQuotationList"
import { APIClient } from "../../../helpers/api_helper"
import * as url from "../../../helpers/url_helper"

// import PropTypes from "prop-types"

const GenerateQuotation = () => {
  //   const history = useNavigate()
  const { ID } = useParams()
  const dispatch = useDispatch()
  const { form } = useSelector((state) => state.Quotation.newQuotation)
  const {
    modal: { modalShow },
  } = useSelector((state) => state.AutoQuotation)
  const { mealTypes, loading: mealTypeLoading } = useSelector(
    (state) => state.MealType
  )
  const { vehicleTypes } = useSelector((state) => ({
    vehicleTypes: state.Vehicle.vehicleTypes,
  }))
  useEffect(() => {
    dispatch(getAllCities())
    dispatch(getAllActiveMealTypes())
    dispatch(getActiveGst())
  }, [])
  useEffect(() => {
    if (ID && !modalShow) {
      let obj = { ID }
      dispatch(getQuotationDetail(obj))
    }
  }, [ID, modalShow])

  const handleDownloadQuotation = async () => {
    const api = new APIClient()
    const result = await api.createWithOptions(
      url.EXPORT_FIXED_QUOTE_PDF,
      {
        ID,
      },
      {
        responseType: "arraybuffer", // Important to get binary data (arraybuffer)
      }
    )
    const fileUrl = window.URL.createObjectURL(
      new Blob([result], { type: "application/pdf" })
    )
    const filename = "quotation.pdf"
    const link = document.createElement("a")
    link.href = fileUrl
    link.download = filename // Specify the desired filename here
    link.click()
    window.URL.revokeObjectURL(fileUrl)
  }

  if (mealTypeLoading) {
    return <Loader />
  }
  return (
    <div className="page-content">
      <Container fluid>
        {/* <ToastContainer /> */}
        <BreadCrumb title="Generate Quotation" pageTitle="Generate Quotation" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">Generate Quotation</h4>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="form-steps">
                <Row>
                  <Col lg={12}>
                    <Formik
                      initialValues={form}
                      enableReinitialize={true}
                      validationSchema={Yup.object({
                        Name: Yup.string().nullable(true).required(REQUIRED),
                        MobileNo: Yup.string()
                          .typeError(INVALID_PHONE)
                          .matches(phoneRegex, "Invalid phone")
                          .nullable(true)
                          .required(REQUIRED),
                        EmailID: Yup.string()
                          .email(INVALID_EMAIL)
                          .required(REQUIRED),
                        DepartureDate: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        Adults: Yup.number().nullable(true).required(REQUIRED),
                        CWB: Yup.number().nullable(true).required(REQUIRED),
                        CNB: Yup.number().nullable(true).required(REQUIRED),
                        HotelCategory: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        MealPlanID: Yup.number()
                          .nullable(true)
                          .required(REQUIRED),
                        VehicleTypeID: Yup.number()
                          .nullable(true)
                          .required(REQUIRED),
                        RoomList: Yup.array().of(
                          Yup.object({
                            RoomNo: Yup.number().nullable(true),
                            NoOfPax: Yup.number().nullable(true),
                            CWB: Yup.number().nullable(true),
                            CNB: Yup.number().nullable(true),
                          })
                        ),
                      })}
                      //   onSubmit={handleSubmit}
                    >
                      <Form noValidate autoComplete="off">
                        <ChangeWatcher />
                        <Row>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-name"
                              >
                                Name
                              </Label>
                              <Field
                                disabled={true}
                                name="Name"
                                type="text"
                                className="form-control"
                                id="autoquote-name"
                                placeholder="Enter Name"
                                component={TextField}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-mobile"
                              >
                                Mobile No
                              </Label>
                              <Field
                                disabled={true}
                                name="MobileNo"
                                type="string"
                                className="form-control"
                                id="autoquote-mobile"
                                placeholder="Enter Mobile"
                                component={TextField}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-email"
                              >
                                Email
                              </Label>
                              <Field
                                disabled={true}
                                name="EmailID"
                                type="text"
                                className="form-control"
                                id="autoquote-email"
                                placeholder="Enter Email"
                                component={TextField}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="cleave-date-format"
                                htmlFor="cleave-date-format"
                              >
                                Date
                              </Label>
                              <DepartureDate />
                            </div>
                          </Col>

                          <Col lg={4}>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="autoquote-Adult"
                                  >
                                    Adults
                                  </Label>
                                  <Field
                                    disabled={true}
                                    name="Adults"
                                    type="number"
                                    className="form-control"
                                    id="autoquote-Adult"
                                    placeholder="No Of Pax"
                                    component={TextField}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="autoquote-CWB"
                                  >
                                    CWB
                                  </Label>
                                  <Field
                                    disabled={true}
                                    name="CWB"
                                    type="number"
                                    className="form-control"
                                    id="autoquote-CWB"
                                    placeholder="Enter CWB"
                                    component={TextField}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label
                                    className="form-label"
                                    htmlFor="autoquote-CNB"
                                  >
                                    CNB
                                  </Label>
                                  <Field
                                    disabled={true}
                                    name="CNB"
                                    type="number"
                                    className="form-control"
                                    id="autoquote-CNB"
                                    placeholder="Enter CNB"
                                    component={TextField}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          {/* <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-HotelCategory"
                              >
                                Hotel Category
                              </Label>
                              <Field
                                disabled={true}
                                name="HotelCategory"
                                options={HOTEL_CATEGORIES.map((hc) => {
                                  return {
                                    value: hc,
                                    label: hc,
                                  }
                                })}
                                component={SelectionField}
                              />
                            </div>
                          </Col> */}
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-MealPlanID"
                              >
                                Meal Plan
                              </Label>
                              <Field
                                disabled={true}
                                name="MealPlanID"
                                options={
                                  Array.isArray(mealTypes)
                                    ? mealTypes.map((mealType) => {
                                        return {
                                          value: mealType.ID,
                                          label: mealType.MealType,
                                        }
                                      })
                                    : []
                                }
                                component={SelectionField}
                              />
                            </div>
                          </Col>
                          <Col lg={4}>
                            <div className="mb-3">
                              <Label
                                className="form-label"
                                htmlFor="autoquote-VehicleTypeID"
                              >
                                Transportation
                              </Label>
                              <Field
                                disabled={true}
                                name="VehicleTypeID"
                                options={
                                  Array.isArray(vehicleTypes)
                                    ? vehicleTypes.map((vehicleType) => {
                                        return {
                                          value: vehicleType.ID,
                                          label: vehicleType.VehicleType,
                                        }
                                      })
                                    : []
                                }
                                component={SelectionField}
                              />
                            </div>
                          </Col>

                          {/* <Col md="12" className=" d-flex align-items-end">
                            <Button color="primary" type="submit">
                              Generate Quote
                            </Button>
                          </Col> */}
                        </Row>
                      </Form>
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <GenerateQuotationList />
          </Col>
          <Col md="12" className="text-end my-3">
            <Button onClick={handleDownloadQuotation}>
              {" "}
              Download Quotation
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

GenerateQuotation.propTypes = {}

export default GenerateQuotation

function ChangeWatcher() {
  const { TOURID } = useParams()

  const dispatch = useDispatch()
  const { values } = useFormikContext()

  useEffect(() => {
    if (values?.Adults || values?.CWB || values?.CNB) {
      let NoOfPax = _.sum([values?.Adults, values?.CWB, values?.CNB])
      let obj = {
        NoOfPax: NoOfPax,
        TourID: +TOURID,
      }
      dispatch(getVehicleTypeByTour(obj))
    }
  }, [values?.Adults, values?.CWB, values?.CNB])
}

function DepartureDate() {
  const { values, setFieldValue } = useFormikContext()

  const handleDateChange = (ev) => {
    setFieldValue("DepartureDate", moment(ev[0]).format(STANDARD_INPUT_DATE))
  }

  return (
    <Field>
      {({ field }) => (
        <Flatpickr
          name="DepartureDate"
          {...field}
          value={values?.DepartureDate || ""}
          placeholder="Select Date.."
          className="form-control"
          disabled={true}
          options={{
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "d-m-Y",
          }}
          onChange={handleDateChange}
        />
      )}
    </Field>
  )
}
