import { Field, FieldArray } from "formik";
import React, { useState } from "react";
import { Button, Card, CardBody, Col, Label, Row } from "reactstrap";
import { TextField } from "../../Components/constants/field";
import { emptyBankDetails } from "../../slices/agent/reducer";

function BankDetailsArray() {
  const [isInitialRender, setIsInitialRender] = useState(true);
  const handleRoomAdd = (arrayHelpers) => {
    let obj = {
      ...emptyBankDetails,
    };
    arrayHelpers.push(obj);
  };

  const handleDeleteClick = (arrayHelpers, index) => {
    arrayHelpers.remove(index);
  };
  return (
    <FieldArray
      name="BankDetails"
      render={(arrayHelpers) => {
        if (
          isInitialRender &&
          (!arrayHelpers.form.values ||
            !arrayHelpers.form.values.ContactDetails ||
            arrayHelpers.form.values.ContactDetails.length === 0)
        ) {
          handleRoomAdd(arrayHelpers);
          setIsInitialRender(false);
        }
        return (
          <Row>
            <Col lg={4}>
              <Button
                color="primary"
                onClick={() => handleRoomAdd(arrayHelpers)}
              >
                Add Bank Details
              </Button>
            </Col>
            <Col lg={12}>
              {arrayHelpers?.form?.values?.BankDetails &&
              arrayHelpers?.form?.values?.BankDetails?.length > 0
                ? arrayHelpers?.form?.values?.BankDetails.map(
                    (bankDetail, index) => (
                      <Row key={index}>
                        <Col lg={12}>
                          <Card className="border card-border-primary mt-4">
                            <CardBody className="p-4">
                              <Row className="bank-add-row">
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="BankDetails-BankName"
                                    >
                                      Bank Name:
                                    </Label>
                                    <Field
                                      name={`BankDetails.${index}.BankName`}
                                      className="form-control"
                                      id="BankDetails-BankName"
                                      placeholder="Bank Name"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="BankDetails-BranchName"
                                    >
                                      Branch:
                                    </Label>
                                    <Field
                                      name={`BankDetails.${index}.BranchName`}
                                      className="form-control"
                                      id="BankDetails-BranchName"
                                      placeholder="Branch Name"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={3}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="BankDetails-AccountNo"
                                    >
                                      Account No:
                                    </Label>
                                    <Field
                                      name={`BankDetails.${index}.AccountNo`}
                                      type="number"
                                      className="form-control"
                                      id="BankDetails-AccountNo"
                                      placeholder="Account No"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={2}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="BankDetails-IFSCCode"
                                    >
                                      IFSC Code:
                                    </Label>
                                    <Field
                                      name={`BankDetails.${index}.IFSCCode`}
                                      className="form-control"
                                      id="BankDetails-IFSCCode"
                                      placeholder="IFSC Code"
                                      component={TextField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={1} className="text-end">
                                  <Button
                                    color="danger"
                                    className="btn-icon"
                                    onClick={() =>
                                      handleDeleteClick(arrayHelpers, index)
                                    }
                                  >
                                    <i className=" ri-delete-bin-5-line"></i>
                                  </Button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    )
                  )
                : null}
            </Col>
          </Row>
        );
      }}
    />
  );
}

export default BankDetailsArray;
