import React from "react"
import AdditionalServicePerDay from "./AdditionalServicePerDay"
import AdditionalServicePerPerson from "./AdditionalServicePerPerson"
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { getActiveGst } from "../../slices/thunks"

const Others = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getActiveGst())
  }, [])

  return (
    <React.Fragment>
      <AdditionalServicePerDay />
      <AdditionalServicePerPerson />
    </React.Fragment>
  )
}

export default Others
