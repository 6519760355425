import { Field, Form, Formik, useFormikContext } from "formik"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Label, Row, TabPane } from "reactstrap"
import * as Yup from "yup"
import {
  FileField,
  MultiSelectField,
  MultiSelectFieldV2,
  REQUIRED,
  SECTOR_TYPES,
  SelectionField,
  TRAVEL_TYPES,
  TextField,
} from "../../Components/constants/field"
import {
  getSubSectorBySectorID,
  getTourByID,
  insertTour,
  updateTour,
} from "../../slices/thunks"
import Loader from "../../Components/Common/Loader"
import { setActiveTourTab } from "../../slices/tour/reducer"
import { useNavigate } from "react-router"
import { useLocation } from "react-router-dom"
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
// import { ToastContainer } from "react-toastify"

function SectorChangeWatcher() {
  const dispatch = useDispatch()

  const { values } = useFormikContext()
  useEffect(() => {
    if (values.SectorID) {
      dispatch(getSubSectorBySectorID(values.SectorID))
    }
  }, [values.SectorID])

  return null
}

function NoOfDays() {
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    if (values.NoOfNights) {
      setFieldValue("NoOfDays", parseInt(values.NoOfNights + 1))
    }
  }, [values.NoOfNights])

  return (
    <Col lg={4}>
      <div className="mb-3">
        <Label className="form-label" htmlFor="tour-NoOfDays">
          No Of days
        </Label>
        <Field
          name="NoOfDays"
          type="number"
          className="form-control"
          id="tour-NoOfDays"
          placeholder="Enter No Of Days"
          component={TextField}
          disabled
        />
      </div>
    </Col>
  )
}

function TourDetails() {
  const dispatch = useDispatch()
  const history = useNavigate()
  const tID = urlParamsFuc()

  const { TourID } = useSelector((state) => ({
    TourID: state.Tour.TourID,
  }))

  const { activeTourStep } = useSelector((state) => ({
    activeTourStep: state.Tour.activeTourStep,
  }))
  const { tourDetails } = useSelector((state) => ({
    tourDetails: state.Tour.tourDetails,
  }))

  const { sectors } = useSelector((state) => ({
    sectors: state.Sector.sectors,
  }))

  const { subSectors } = useSelector((state) => ({
    subSectors: state.SubSector.subSectors,
  }))

  const { specialityTypes } = useSelector((state) => ({
    specialityTypes: state.SpecialityType.specialityTypes,
  }))

  const { sectorLoading } = useSelector((state) => ({
    loading: state.Sector.loading,
  }))

  const { loading } = useSelector((state) => ({
    loading: state.Tour.loading,
  }))

  const { termsAndConditions } = useSelector((state) => ({
    termsAndConditions: state.TermsAndCondition.termsAndConditions,
  }))

  const { cancelationPolicies } = useSelector((state) => ({
    cancelationPolicies: state.CancellationPolicy.cancelationPolicies,
  }))

  useEffect(() => {
    if (tID) dispatch(getTourByID(tID))
  }, [tID])

  const handleSubmit = (values) => {
    let obj = {
      ...values,
    }
    let tour = new FormData()
    if (TourID) {
      obj.UpdatedBy = getLoggedInUser().ID
    }
    Object.keys(obj).map((key) => {
      if (key === "TourImages") {
        tour.append(key, obj[key][0])
      } else if (key === "TourSpecialityTypeID") {
        obj[key] = obj[key]
          .map((val) => {
            if (typeof val === "object") {
              return val.value
            }
            return val
          })
          .join(",")

        tour.append(key, obj[key])
      } else {
        tour.append(key, obj[key])
      }
    })
    if (tID) {
      tour.append("ID", tID)
      dispatch(updateTour(tour)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          dispatch(getTourByID(TourID)).then((innerRes) => {
            if (innerRes.payload && innerRes.payload.Status === 200) {
              history(`/tours/update/${tID}?t=${tID}`)
              dispatch(setActiveTourTab(activeTourStep + 1))
            }
          })
        }
      })
    } else {
      dispatch(insertTour(tour)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/tours/create?t=${res.payload.Data.ID}`)
          dispatch(getTourByID(res.payload.Data.ID))
          dispatch(setActiveTourTab(activeTourStep + 1))
        }
      })
    }
  }

  if (sectorLoading || loading) return <Loader />

  return (
    <TabPane id="steparrow-gen-info" tabId={activeTourStep}>
      <Formik
        initialValues={tourDetails}
        validationSchema={Yup.object({
          SectorTypeID: Yup.number().nullable(true).required(REQUIRED),
          TravelTypeID: Yup.string().nullable(true).required(REQUIRED),
          SectorID: Yup.number().nullable(true).required(REQUIRED),
          SubSectorID: Yup.number().nullable(true),
          TermsConditionID: Yup.number().nullable(true).required(REQUIRED),
          CancellationPolicyID: Yup.number().nullable(true).required(REQUIRED),
          TourSpecialityTypeID: Yup.array().min(1, REQUIRED).required(REQUIRED),
          TourName: Yup.string().nullable(true).required(REQUIRED),
          TourCode: Yup.string().nullable(true).required(REQUIRED),
          NoOfNights: Yup.number().nullable(true).required(REQUIRED),
          NoOfDays: Yup.number().nullable(true),
          OverallKm: Yup.number().nullable(true).required(REQUIRED),
          SpecialRemarks: Yup.string().nullable(true),
        })}
        onSubmit={handleSubmit}
      >
        {() => {
          return (
            <Form noValidate autoComplete="off">
              <div>
                <Row>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="tour-SectorType">
                        Sector Type
                      </Label>
                      <Field
                        name="SectorTypeID"
                        options={SECTOR_TYPES.map((SectorType, index) => {
                          return {
                            value: index + 1,
                            label: SectorType,
                          }
                        })}
                        component={SelectionField}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="tour-traveltype">
                        Travel Type
                      </Label>
                      <Field
                        name="TravelTypeID"
                        options={TRAVEL_TYPES.map((travelType) => {
                          return {
                            value: travelType,
                            label: travelType,
                          }
                        })}
                        component={SelectionField}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="tour-sector">
                        Sector
                      </Label>
                      <Field
                        name="SectorID"
                        options={
                          sectors &&
                          sectors.map((sector) => {
                            return {
                              value: sector.ID,
                              label: sector.SectorName,
                            }
                          })
                        }
                        component={SelectionField}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="tour-subsector">
                        Sub Sector
                      </Label>
                      <Field
                        name="SubSectorID"
                        options={
                          subSectors &&
                          subSectors.map((subSector) => {
                            return {
                              value: subSector.ID,
                              label: subSector.SubSectorName,
                            }
                          })
                        }
                        component={SelectionField}
                      />
                    </div>
                    <SectorChangeWatcher />
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="tour-specialityType"
                      >
                        Speciality Type
                      </Label>
                      <Field
                        name="TourSpecialityTypeID"
                        options={
                          specialityTypes &&
                          specialityTypes.map((specialityType) => {
                            return {
                              value: specialityType.ID,
                              label: specialityType.SpecialityType,
                            }
                          })
                        }
                        component={MultiSelectFieldV2}
                      />
                    </div>
                  </Col>

                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="tour-name">
                        Tour Name
                      </Label>
                      <Field
                        name="TourName"
                        type="text"
                        className="form-control"
                        id="tour-name"
                        placeholder="Enter Tour Name"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="tour-TourCode">
                        Tour Code
                      </Label>
                      <Field
                        name="TourCode"
                        type="text"
                        className="form-control"
                        id="tour-TourCode"
                        placeholder="Enter Tour Code"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="tour-OverallKm">
                        Overall Km
                      </Label>
                      <Field
                        name="OverallKm"
                        type="number"
                        className="form-control"
                        id="tour-OverallKm"
                        placeholder="Enter Overall Km"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="tour-NoOfNights">
                        No Of Nights
                      </Label>
                      <Field
                        name="NoOfNights"
                        type="number"
                        className="form-control"
                        id="tour-NoOfNights"
                        placeholder="Enter No Of Nights"
                        component={TextField}
                      />
                    </div>
                  </Col>

                  <NoOfDays />
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="tour-termsAndConditions"
                      >
                        Terms Condition
                      </Label>
                      <Field
                        name="TermsConditionID"
                        options={termsAndConditions.map((termsAndCondition) => {
                          return {
                            value: termsAndCondition.ID,
                            label: termsAndCondition.Title,
                          }
                        })}
                        component={SelectionField}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="tour-CancellationPolicy"
                      >
                        Cancellation policy
                      </Label>
                      <Field
                        name="CancellationPolicyID"
                        options={
                          cancelationPolicies &&
                          cancelationPolicies.map((cancelationPolicy) => {
                            return {
                              value: cancelationPolicy.ID,
                              label: cancelationPolicy.Title,
                            }
                          })
                        }
                        component={SelectionField}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label
                        className="form-label"
                        htmlFor="tour-SpecialRemarks"
                      >
                        Special Remarks
                      </Label>
                      <Field
                        name="SpecialRemarks"
                        className="form-control"
                        id="tour-SpecialRemarks"
                        placeholder="Enter Special Remarks"
                        component={TextField}
                      />
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div className="mb-3">
                      <Label className="form-label" htmlFor="tour-IsActive">
                        Status
                      </Label>
                      <Field
                        name="IsActive"
                        options={[
                          {
                            value: true,
                            label: "Active",
                          },
                          {
                            value: false,
                            label: "Inactive",
                          },
                        ]}
                        component={SelectionField}
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <Row>
                      <Col lg={4}>
                        <Field
                          type="file"
                          name="TourImages"
                          maxFiles={1}
                          placeholder="Upload Images"
                          component={FileField}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
              <div className="d-flex align-items-start gap-3 mt-4">
                <button
                  type="submit"
                  className="btn btn-success btn-label right ms-auto nexttab nexttab"
                >
                  <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" />
                  Save and Next
                </button>
              </div>
            </Form>
          )
        }}
      </Formik>
    </TabPane>
  )
}

function urlParamsFuc() {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const tID = urlParams.get("t")
  return tID
}

export default TourDetails

//
