import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import TourDetails from "./TourDetails"
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
} from "reactstrap"
import classNames from "classnames"
import BreadCrumb from "../../Components/Common/BreadCrumb"
// import logoDark from "../../assets/images/logo-dark.png"
import { resetTour, setActiveTourTab } from "../../slices/tour/reducer"
import {
  getAllActiveItineraryFacilities,
  getAllActiveSectors,
  getAllCities,
  getItineraries,
  getTourByID,
} from "../../slices/thunks"
import { getAllActiveSpeciallityTypes } from "../../slices/specialitytype/thunk"
import { getAllActiveTersAndConditions } from "../../slices/termsandconditions/thunk"
import { getAllActiveCanelationPolicy } from "../../slices/canelationpolicy/thunk"
import TourPlace from "./TourPlaces"
import { useParams } from "react-router-dom"
// import { ToastContainer } from "react-toastify"

function CreateTour() {
  const dispatch = useDispatch()
  const TourTabs = ["Tour Details", "Tour Places"]

  const { TourID } = useSelector((state) => ({
    TourID: state.Tour.TourID,
  }))

  const { activeTourStep } = useSelector((state) => ({
    activeTourStep: state.Tour.activeTourStep,
  }))

  const { ID } = useParams()

  useEffect(() => {
    dispatch(getAllActiveSectors())
    dispatch(getAllActiveSpeciallityTypes())
    dispatch(getAllActiveTersAndConditions())
    dispatch(getAllActiveTersAndConditions())
    dispatch(getAllActiveCanelationPolicy())
    dispatch(getAllCities())
    dispatch(getItineraries())
    dispatch(getAllActiveItineraryFacilities())
    if (ID) {
      dispatch(getTourByID(ID))
    }
    return () => {
      dispatch(resetTour())
    }
  }, [])

  function renderTourStep() {
    switch (activeTourStep) {
      case 0:
        return <TourDetails />
      default:
        return <TourPlace />
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Tour" pageTitle="Tour" />
        <Row>
          <Col xl={12}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  {TourID ? "Update Tour" : "Create Tour"}
                </h4>
              </CardHeader>
              <CardBody className="form-steps">
                {/* <div className="text-center pt-3 pb-4 mb-1">
                  <img src={logoDark} alt="" height="17" />
                </div> */}
                <div className="step-arrow-nav mb-4">
                  <Nav
                    className="nav-pills custom-nav nav-justified"
                    role="tablist"
                  >
                    {TourTabs.map((tab, index) => (
                      <NavItem key={index}>
                        <NavLink
                          href="#"
                          id="steparrow-gen-info-tab"
                          className={classNames({
                            active: activeTourStep === index,
                            done: activeTourStep <= TourTabs.length,
                          })}
                          onClick={() => {
                            dispatch(setActiveTourTab(index))
                          }}
                        >
                          {tab}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </div>

                <TabContent activeTab={activeTourStep}>
                  {renderTourStep(activeTourStep)}
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default CreateTour
