import { createSlice } from "@reduxjs/toolkit"
import { getAllRegions } from "./thunk"
export const initialState = {
  regionsList: [],
  error: {},
  loading: false,
}

const Region = createSlice({
  name: "Regions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRegions.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAllRegions.fulfilled, (state, action) => {
      state.regionsList = action.payload.Data
      state.loading = false
    })
    builder.addCase(getAllRegions.rejected, (state, action) => {
      state.error = action.payload.error || null
      state.loading = false
    })
  },
})

export default Region.reducer
