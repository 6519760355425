import { createSlice } from "@reduxjs/toolkit";
import { findByIdFestivals, getAllActiveFestivals, getAllFestivals, insertFestivals, updateFestivals } from "./thunk";
import _ from "lodash"

export const festivalForm={
    ID:null,
    Title:"",
    Description:"",
    IsActive: true,
}

export const initialState = {
    festivalDetail:{
        form:festivalForm
    },
    festivals: [],
    error: {},
    loading: false,
};

const FestivalReducer = createSlice({
    name: "Festival",
    initialState,
    reducers: {
        resetForm: (state) => {
            state.festivalDetail.form = festivalForm;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllActiveFestivals.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllActiveFestivals.fulfilled, (state, action) => {
            state.festivals = action.payload.Data;
            state.loading = false;
        });
        builder.addCase(getAllActiveFestivals.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.loading = false;
        });
        builder.addCase(getAllFestivals.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(getAllFestivals.fulfilled,(state,action)=>{
            state.festivals=action.payload.Data || []
            state.loading=false
        });
        builder.addCase(getAllFestivals.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(insertFestivals.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(insertFestivals.fulfilled,(state)=>{
            state.festivalDetail.form=festivalForm
            state.loading=false
        });
        builder.addCase(insertFestivals.rejected, (state,action) => {
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(updateFestivals.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(updateFestivals.fulfilled,(state)=>{
            state.festivalDetail.form=festivalForm
            state.loading=false
        });
        builder.addCase(updateFestivals.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(findByIdFestivals.pending,(state)=>{
            state.loading=true
           });
           builder.addCase(findByIdFestivals.fulfilled,(state,action)=>{
            let formObj = action?.payload?.Data
    
            let formKeys=Object.keys(festivalForm)
            formObj = _.pick(formObj,formKeys)
            state.festivalDetail.form=formObj
            state.loading=false
           });
           builder.addCase(findByIdFestivals.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
           })
    },
});

export const {resetForm}=FestivalReducer.actions
export default FestivalReducer.reducer;
