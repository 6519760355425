import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { AUTO_CLOSE } from "../../Components/constants/toast";
import { FAILED } from "../../Components/constants/field";
import {
  ActiveInActiveTersAndConditionsApi,
  deleteTersAndConditionsApi,
  findByIdTersAndConditionsApi,
  getAllActiveTersAndConditionsApi,
  getAllTersAndConditionsApi,
  insertTersAndConditionsApi,
  updateTersAndConditionsApi,
} from "./termsandconditions";

export const getAllActiveTersAndConditions = createAsyncThunk(
  "TermsAndConditions/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveTersAndConditionsApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const getAllTersAndConditions = createAsyncThunk(
  "TermsAndConditions/FindAll",
  async () => {
    try {
      const response = await getAllTersAndConditionsApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const findByIdTersAndConditions = createAsyncThunk(
  "TermsAndConditions/FindByID",
  async (params) => {
    try {
      const response = await findByIdTersAndConditionsApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const insertTersAndConditions = createAsyncThunk(
  "TermsAndConditions/Insert",
  async (data) => {
    try {
      const response = await insertTersAndConditionsApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const updateTersAndConditions = createAsyncThunk(
  "TermsAndConditions/Update",
  async (data) => {
    try {
      const response = await updateTersAndConditionsApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const ActiveInActiveTersAndConditions = createAsyncThunk(
  "TermsAndConditions/Active-InActive",
  async (data) => {
    try {
      const response = await ActiveInActiveTersAndConditionsApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);

export const deleteTersAndConditions = createAsyncThunk(
  "TermsAndConditions/Delete",
  async (data) => {
    try {
      const response = await deleteTersAndConditionsApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      return error;
    }
  }
);
