import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import {
  ActiveInActiveItinerary,
  deleteItinerary,
  getAllACINItineraries,
} from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import Switch from "../../Components/Common/Switch";
import DeleteModal from "../../Components/Common/DeleteModal";
import { resetForm } from "../../slices/itinerary/reducer";
const MasterItinerary = () => {
  const dispatch = useDispatch();
  const { allItineraries, loading } = useSelector((state) => state.Itinerary);

  //
  const [reload, setReload] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  //
  useEffect(() => {
    dispatch(resetForm());
    dispatch(getAllACINItineraries());
  }, [reload]);

  const handleChange = (data) => {
    let obj = { ID: +data.ID, IsActive: !data.IsActive };

    // setChecked(!checked)
    dispatch(ActiveInActiveItinerary(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload);
      }
    });
  };

  const onClickDelete = (data) => {
    setDeleteModal(true);
    setDeleteData(data);
  };

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
    };
    dispatch(deleteItinerary(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload);
        setDeleteModal(false);
      }
    });
  };
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Itinerary Master" pageTitle="Itinerary" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md={4}>
                    <h4 className="card-title mb-0">Itinerary Master</h4>
                  </Col>
                  <Col md={8} className="text-end">
                    <Link to="/itinerary/create">
                      <Button color="primary">
                        <i className="las la-plus"></i>Add
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <Table
                        className="align-middle table-nowrap mb-0"
                        striped
                        responsive
                        hover
                      >
                        <thead>
                          <tr className="table-header">
                            <th scope="col">Title</th>
                            <th scope="col">City</th>
                            <th scope="col"> Description </th>
                            <th scope="col" className="col-1 text-center">
                              Status
                            </th>
                            <th scope="col" className="col-1 text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                          Array.isArray(allItineraries) &&
                          allItineraries.length ? (
                            allItineraries.map((itinerary, index) => (
                              <tr key={index}>
                                <td
                                  style={{
                                    width: "300px",
                                    maxWidth: "400px",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  <Link
                                    to={`/itinerary/update/${itinerary.ID}`}
                                  >
                                    {itinerary.ItineraryTitle}
                                  </Link>
                                </td>
                                <td>{itinerary.CityName}</td>
                                <td
                                  style={{
                                    width: "500px",
                                    maxWidth: "900px",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {itinerary.Description}
                                </td>
                                <td className="text-center">
                                  <Switch
                                    onChange={() => handleChange(itinerary)}
                                    checked={itinerary?.IsActive}
                                  />
                                </td>

                                <td className="text-center">
                                  <div className="hstack gap-3 justify-content-center">
                                    <Link
                                      to={`/itinerary/update/${itinerary.ID}`}
                                      className="link-success fs-18"
                                    >
                                      <i className="ri-edit-line"></i>
                                    </Link>
                                    <div
                                      className="hstack gap-3 flex-wrap "
                                      role="button"
                                      onClick={() => onClickDelete(itinerary)}
                                    >
                                      <i className=" ri-delete-bin-fill text-danger fs-18"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : loading ? (
                            <tr>
                              <td colSpan={10}>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={10}>No Itinerary found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <DeleteModal
            show={deleteModal}
            onCloseClick={() => setDeleteModal(false)}
            onDeleteClick={() => onDeleted()}
          />
        </Row>
      </Container>
    </div>
  );
};

export default MasterItinerary;
