import { createSlice } from "@reduxjs/toolkit";
import {
    getAllActiveRoomCategory,
    getRoomCategoriesByHotel,
    getRoomCategoriesByHotelID,
} from "./thunk";
export const initialState = {
    roomCategories: [],
    error: {},
    loading: false,
};

const RoomCategories = createSlice({
    name: "RoomCategories",
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        builder.addCase(getAllActiveRoomCategory.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllActiveRoomCategory.fulfilled, (state, action) => {
            state.roomCategories = action.payload.Data;
            state.loading = false;
        });
        builder.addCase(getAllActiveRoomCategory.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.loading = false;
        });
        builder.addCase(getRoomCategoriesByHotelID.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getRoomCategoriesByHotelID.fulfilled,
            (state, action) => {
                state.roomCategories = action.payload.Data;
                state.loading = false;
            }
        );
        builder.addCase(
            getRoomCategoriesByHotelID.rejected,
            (state, action) => {
                state.error = action.payload.error || null;
                state.loading = false;
            }
        );
        builder.addCase(getRoomCategoriesByHotel.pending, (state, action) => {
            state.loading = true;
            console.log("Auto Quote Get", action);
        });
        builder.addCase(getRoomCategoriesByHotel.fulfilled, (state, action) => {
            state.loading = false;
            console.log("Auto Quote Get", action);
            state.roomCategories = action.payload.Data;
        });
        builder.addCase(getRoomCategoriesByHotel.rejected, (state, action) => {
            state.loading = false;
            console.log("Auto Quote Get Failed", action);
        });
    },
});

export default RoomCategories.reducer;
