import PropTypes from "prop-types";
import React, { useState } from "react";
import { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { getAllActiveUser } from "../../slices/user/thunk";
import { useDispatch, useSelector } from "react-redux";
import { REQUIRED, SelectionField } from "../../Components/constants/field";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { AssignedUser } from "../../slices/inquiry/thunk";
import { useNavigate } from "react-router-dom";

const AssignUserModal = ({ show, onAssignClick, onCloseClick, assignData }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [initialValues, setInitialValues] = useState({
    UserID: null,
  });
  const { activeUsers } = useSelector(
    (state) => state.User
  );

  useEffect(() => {
    dispatch(getAllActiveUser());
    setInitialValues({
      UserID: null,
    });
  }, []);

  const inquiryAssignHandler = (values) => {
    let obj = {
      ...values,
    };

    obj.UserID = obj.UserID ? +obj.UserID : null;
    obj.InquiryID = assignData?.ID?.toString() || "";

    dispatch(AssignedUser(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        history(`/inquiry`);
      }
    });
  };
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-2">
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object({
            UserID: Yup.number()
              .transform((value) => (Number.isNaN(value) ? null : value))
              .nullable()
              .required(REQUIRED),
          })}
          onSubmit={inquiryAssignHandler}
        >
          <Form className="form-inline">
            <div className="mt-2 text-center">
              <lord-icon
                src="https://cdn.lordicon.com/gsqxdxog.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#f06548"
                style={{ width: "100px", height: "100px" }}
              ></lord-icon>
              <div className="fs-15">
                <h2 className="user-modal-header">Assign Lead</h2>
                <hr />
              </div>
            </div>
            <div className="mx-2 my-2 px-2 py-2 text-dark">
              <h5 className="mt-1">
                <strong>Lead Name: {assignData?.Name}</strong>
              </h5>
              <h5 className="mt-4">
                <strong>Destination: {assignData?.SectorName}</strong>
              </h5>
              <div className="mt-3">
                <div className="d-flex align-items-center">
                  <h5 className="mt-2 me-3">
                    <strong>User:</strong>
                  </h5>
                  <div>
                    <Field
                      name="UserID"
                      id="UserID"
                      options={
                        Array.isArray(activeUsers) &&
                        activeUsers.map((user) => {
                          return {
                            value: Number(user.ID),
                            label: user.FirstName + user.LastName,
                          };
                        })
                      }
                      placeholder="Username"
                      component={SelectionField}
                      style={{ display: "inline-block", width: "200px" }}
                    />
                  </div>
                </div>
              </div>
              <h5 className="mt-3">
                <strong>Lead Source: {assignData?.Source}</strong>
              </h5>
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                className="btn w-sm btn-light me-4"
                data-bs-dismiss="modal"
                onClick={onCloseClick}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn w-sm btn-primary "
                id="assign-record"
                onClick={onAssignClick}
              >
                Assign
              </button>
            </div>
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  );
};

AssignUserModal.propTypes = {
  onCloseClick: PropTypes.func,
  onAssignClick: PropTypes.func,
  show: PropTypes.any,
};

export default AssignUserModal;
