import { createSlice } from "@reduxjs/toolkit";
import {
  findByIdAgent,
  getAllActiveAgents,
  getAllAgents,
  insertAgent,
  updateAgent,
} from "./thunk";

export const emptyContactDetails = {
  Name: "",
  MobileNo: "",
  EmailID: "",
  Designation: "",
};

export const emptyBankDetails = {
  BankName: "",
  BranchName: "",
  AccountNo: "",
  IFSCCode: "",
};

export const AgentForm = {
  ID: undefined,
  IsActive: true,
  SubAgentName: "",
  CompanyName: "",
  ContactNo: "",
  GSTNo: "",
  PanNo: "",
  AadharNo: "",
  EmailID: "",
  Password: "",
  CompanyAddress: "",
  CPassword: "",
  CityID: null,
  StateID: null,
  CountryID: null,
  UniversalMarkupTypeID: null,
  UniversalMarkup: "",
  PinCode: "",
  ContactDetails: [emptyContactDetails],
  BankDetails: [emptyBankDetails],
};

export const AgentDocuments = {
  AadharDocument: "",
  PanDocument: "",
  GSTDocument: "",
  Logo: "",
};

export const initialState = {
  AgentID: undefined,
  agentDetail: {
    form: AgentForm,
    loading: false,
  },
  agentDocuments: {
    form: AgentDocuments,
    loading: false,
  },
  agents: [],
  allAgents: [],
  error: {},
  loading: false,
};

const AgentReducer = createSlice({
  name: "Agent",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.agentDetail.form = AgentForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllActiveAgents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllActiveAgents.fulfilled, (state, action) => {
      state.agents = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllActiveAgents.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    builder.addCase(getAllAgents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllAgents.fulfilled, (state, action) => {
      state.allAgents = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllAgents.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    // Insert Vehicle
    builder.addCase(insertAgent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(insertAgent.fulfilled, (state) => {
      state.agentDetail.form = AgentForm;
      state.loading = false;
    });
    builder.addCase(insertAgent.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    // Update Vehicle
    builder.addCase(updateAgent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateAgent.fulfilled, (state) => {
      state.agentDetail.form = AgentForm;
      state.loading = false;
    });
    builder.addCase(updateAgent.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    // Get By Id Vehicle
    builder.addCase(findByIdAgent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findByIdAgent.fulfilled, (state, action) => {
      if (action.payload && action.payload.Data) {
        state.agentDetail.form = {
          ...action.payload.Data,
        };
      }

      state.loading = false;
    });
    builder.addCase(findByIdAgent.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });
  },
});

export const { resetForm } = AgentReducer.actions;

export default AgentReducer.reducer;
