import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ActiveInActiveUserApi,
  deleteUserApi,
  findByIdUserApi,
  getAllActiveUserApi,
  getAllUserApi,
  insertUserApi,
  updateUserApi,
} from "./user.services";
import { FAILED } from "../../Components/constants/field";
import { AUTO_CLOSE } from "../../Components/constants/toast";

export const getAllUser = createAsyncThunk("Users/FindAll", async () => {
  try {
    const response = await getAllUserApi();
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const getAllActiveUser = createAsyncThunk(
  "Users/FindAllActive",
  async () => {
    try {
      const response = await getAllActiveUserApi();
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE })
      return error;
    }
  }
);

export const findByIdUser = createAsyncThunk(
  "Users/FindById",
  async (params) => {
    try {
      const response = await findByIdUserApi(params);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const insertUser = createAsyncThunk("Users/Insert", async (data) => {
  try {
    const response = await insertUserApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const updateUser = createAsyncThunk("Users/Update", async (data) => {
  try {
    const response = await updateUserApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});

export const ActiveInActiveUser = createAsyncThunk(
  "Users/ActiveInActive",
  async (data) => {
    try {
      const response = await ActiveInActiveUserApi(data);
      if (response?.Status !== 200) {
        toast.error(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      } else {
        toast.success(response?.Details, { autoClose: AUTO_CLOSE });
        return response;
      }
    } catch (error) {
      toast.error(FAILED, { autoClose: AUTO_CLOSE });
      return error;
    }
  }
);

export const deleteUser = createAsyncThunk("Users/Delete", async (data) => {
  try {
    const response = await deleteUserApi(data);
    if (response?.Status !== 200) {
      toast.error(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    } else {
      toast.success(response?.Details, { autoClose: AUTO_CLOSE });
      return response;
    }
  } catch (error) {
    toast.error(FAILED, { autoClose: AUTO_CLOSE });
    return error;
  }
});
