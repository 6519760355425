import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, TabPane } from "reactstrap";
import Quotation from "./PreviewQuotationComponent/Quotation";
import Accommodation from "./PreviewQuotationComponent/Accommodation";
import Transportation from "./PreviewQuotationComponent/Transportation";
import AdditionalService from "./PreviewQuotationComponent/AdditionalService";
import { getQuotationDetail } from "../../slices/thunks";
import { useLocation } from "react-router-dom";
import * as url from "../../helpers/url_helper";
import { APIClient } from "../../helpers/api_helper";
const PreviewQuotation = () => {
    const dispatch = useDispatch();
    const { activeTab } = useSelector((state) => state.Quotation);
    const location = useLocation();

    const urlParams = new URLSearchParams(location.search);
    const qID = urlParams.get("q");
    const handleDownloadQuotation = async () => {
        const api = new APIClient();
        const result = await api.createWithOptions(
            url.EXPORT_CUSTOM_QUOTE_PDF,
            {
                ID: +qID,
            },
            {
                responseType: "arraybuffer", // Important to get binary data (arraybuffer)
            }
        );
        const fileUrl = window.URL.createObjectURL(
            new Blob([result], { type: "application/pdf" })
        );
        const filename = "quotation.pdf";
        const link = document.createElement("a");
        link.href = fileUrl;
        link.download = filename; // Specify the desired filename here
        link.click();
        window.URL.revokeObjectURL(fileUrl);
    };
    useEffect(() => {
        if (qID) {
            let quotationDetail = {
                ID: Number(qID),
            };

            dispatch(getQuotationDetail(quotationDetail));
        }
    }, [qID]);
    return (
        <TabPane id="steparrow-gen-info" tabId={activeTab}>
            <Quotation />
            <Accommodation />
            <Transportation />
            <AdditionalService />
            <Row>
                <Col md="12" className="text-end my-3">
                    <Button onClick={handleDownloadQuotation}>
                        {" "}
                        Download Quotation
                    </Button>
                </Col>
            </Row>
        </TabPane>
    );
};

export default PreviewQuotation;
