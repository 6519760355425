import { APIClient } from "../../helpers/api_helper";
import axios from "axios";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllTourFacilitiesApi = () =>
  api.get(url.GET_ALL_TOUR_ITENERARIES);

export const getAllActiveTourFacilitiesApi = () =>
  api.get(url.GET_ALL_ACTIVE_TOUR_ITENERARIES);

export const findByIdTourFacilitiesApi = (params) =>
  api.create(url.FIND_BY_ID_TOUR_ITENERARIES, params);

export const insertTourFacilitieMasterApi = (data) =>
  axios.post(url.INSERTTOUR_FECILITY_ITENERARIES, data, {
    headers: { "content-type": "multipart/form-data" },
  });

export const updateTourFacilitiesApi = (data) =>
  axios.post(url.UPDATE_TOUR_FECILITY_ITENERARIES, data, {
    headers: { "content-type": "multipart/form-data" },
  });

export const deleteTourFacilitiesApi = (data) =>
  api.create(url.DELETE_TOUR_FECILITY_ITENERARIES, data);

export const ActiveInActiveTourFacilitiesApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_TOUR_ITENERARIES, data);
