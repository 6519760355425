import { createSlice } from "@reduxjs/toolkit";
import { findByIdSpeciallityTypes, getAllActiveSpeciallityTypes, getAllSpeciallityTypes, insertSpeciallityTypes, updateSpeciallityTypes } from "./thunk";
import _ from "lodash"

export const specialityTypeForm={
    ID:null,
    SpecialityType:"",
    Prefix:"",
    IsActive: true,
}

export const initialState = {
    specialityTypeDetail:{
        form:specialityTypeForm
    },
    specialityTypes: [],
    error: {},
    loading: false,
};

const SpecialityTypeReducer = createSlice({
    name: "SpecialityType",
    initialState,
    reducers: {
        resetForm: (state) => {
            state.specialityTypeDetail.form = specialityTypeForm;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getAllActiveSpeciallityTypes.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(
            getAllActiveSpeciallityTypes.fulfilled,
            (state, action) => {
                state.specialityTypes = action.payload.Data;
                state.loading = false;
            }
        );
        builder.addCase(
            getAllActiveSpeciallityTypes.rejected,
            (state, action) => {
                state.error = action.payload.error || null;
                state.loading = false;
            }
        );
        builder.addCase(getAllSpeciallityTypes.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(getAllSpeciallityTypes.fulfilled,(state,action)=>{
            state.specialityTypes=action.payload.Data || []
            state.loading=false
        });
        builder.addCase(getAllSpeciallityTypes.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(insertSpeciallityTypes.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(insertSpeciallityTypes.fulfilled,(state)=>{
            state.specialityTypeDetail.form=specialityTypeForm
            state.loading=false
        });
        builder.addCase(insertSpeciallityTypes.rejected, (state,action) => {
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(updateSpeciallityTypes.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(updateSpeciallityTypes.fulfilled,(state)=>{
            state.specialityTypeDetail.form=specialityTypeForm
            state.loading=false
        });
        builder.addCase(updateSpeciallityTypes.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
        });
        builder.addCase(findByIdSpeciallityTypes.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(findByIdSpeciallityTypes.fulfilled,(state,action)=>{
            let formObj=action?.payload?.Data
            let formKeys=Object.keys(specialityTypeForm)
            formObj=_.pick(formObj,formKeys)
            state.specialityTypeDetail.form=formObj
            state.loading=false
        });
        builder.addCase(findByIdSpeciallityTypes.rejected,(state,action)=>{
            state.error=action.payload?.error || null
            state.loading=false
           })
    },
});

export const {resetForm}=SpecialityTypeReducer.actions
export default SpecialityTypeReducer.reducer;
