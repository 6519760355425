import { createSlice } from "@reduxjs/toolkit"
import { findByIdGst, getActiveGst, getAllGst, insertGst, updateGst } from "./thunk"
import _ from "lodash"

export const gstForm={
  ID:null,
  Title:"",
  GSTPer:null,
  EffectFrom:"",
  EffectTo:"",
  IsApplyOnServiceMargin: true,
  IsActive: true,
}

export const initialState = {
  gstDetail:{
    form:gstForm
},
  GSTActiveList: [],
  error: {},
  loading: false,
}

const GstReducer = createSlice({
  name: "GST",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.gstDetail.form = gstForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveGst.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getActiveGst.fulfilled, (state, action) => {
      state.GSTActiveList = action.payload.Data
      state.loading = false
    })
    builder.addCase(getActiveGst.rejected, (state, action) => {
      state.error = action.payload.error || null
      state.loading = false
    })
    builder.addCase(getAllGst.pending,(state)=>{
      state.loading=true
  });
  builder.addCase(getAllGst.fulfilled,(state,action)=>{
      state.GSTActiveList=action.payload.Data || []
      state.loading=false
  });
  builder.addCase(getAllGst.rejected,(state,action)=>{
      state.error=action.payload?.error || null
      state.loading=false
  });
  builder.addCase(insertGst.pending,(state)=>{
    state.loading=true
});
builder.addCase(insertGst.fulfilled,(state)=>{
    state.gstDetail.form=gstForm
    state.loading=false
});
builder.addCase(insertGst.rejected, (state,action) => {
    state.error=action.payload?.error || null
    state.loading=false
});
builder.addCase(updateGst.pending,(state)=>{
  state.loading=true
});
builder.addCase(updateGst.fulfilled,(state)=>{
  state.gstDetail.form=gstForm
  state.loading=false
});
builder.addCase(updateGst.rejected,(state,action)=>{
  state.error=action.payload?.error || null
  state.loading=false
});
builder.addCase(findByIdGst.pending,(state)=>{
  state.loading=true
 });
 builder.addCase(findByIdGst.fulfilled,(state,action)=>{
  let formObj = action?.payload?.Data

  let formKeys=Object.keys(gstForm)
  formObj = _.pick(formObj,formKeys)
  state.gstDetail.form=formObj
  state.loading=false
 });
 builder.addCase(findByIdGst.rejected,(state,action)=>{
  state.error=action.payload?.error || null
  state.loading=false
 })
  },
})

export const {resetForm}=GstReducer.actions;
export default GstReducer.reducer
