import { createSlice } from "@reduxjs/toolkit";
import { getAllActiveMealTypes } from "./thunk";
export const initialState = {
    mealTypes: [],
    error: {},
    loading: false,
};

const MealType = createSlice({
    name: "MealType",
    initialState,
    reducer: {},
    extraReducers: (builder) => {
        builder.addCase(getAllActiveMealTypes.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllActiveMealTypes.fulfilled, (state, action) => {
            state.mealTypes = action.payload.Data;
            state.loading = false;
        });
        builder.addCase(getAllActiveMealTypes.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.loading = false;
        });
    },
});

export default MealType.reducer;
