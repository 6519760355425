import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Input,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import Switch from "../../Components/Common/Switch";
import DeleteModal from "../../Components/Common/DeleteModal";
import { resetForm } from "../../slices/user/reducer";
import {
  ActiveInActiveUser,
  deleteUser,
  getAllUser,
} from "../../slices/user/thunk";
const MasterUser = () => {
  const dispatch = useDispatch();
  const { users, loading } = useSelector((state) => state.User);

  const [reload, setReload] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  useEffect(() => {
    dispatch(resetForm());
    dispatch(getAllUser());
  }, [reload]);

  const handleChange = (data) => {
    let obj = { ID: +data.ID, IsActive: !data.IsActive };

    dispatch(ActiveInActiveUser(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload);
      }
    });
  };

  const onClickDelete = (data) => {
    setDeleteModal(true);
    setDeleteData(data);
  };

  const onDeleted = () => {
    let obj = {
      ID: +deleteData?.ID,
    };
    dispatch(deleteUser(obj)).then((res) => {
      if (res.payload && res.payload.Status === 200) {
        setReload(!reload);
        setDeleteModal(false);
      }
    });
  };

  //filter functionality
  const [columnFilters, setColumnFilters] = useState({
    name: "",
    mobileNo: "",
    email: "",
    destination: "",
  });

  const handleColumnFilterChange = (event, columnName) => {
    const { value } = event.target;
    setColumnFilters((prevFilters) => ({
      ...prevFilters,
      [columnName]: value,
    }));
  };

  const applyColumnFilters = (user) => {
    const nameFilter = (user.FirstName || "")
      .concat(" ", user.LastName || "")
      .toLowerCase();
    const mobileNoFilter = (user.MobileNo || "").toLowerCase();
    const emailFilter = (user.EmailID || "").toLowerCase();
    const destinationFilter = (user.SectorName || "").toLowerCase();

    const nameFilterText = columnFilters.name.toLowerCase();
    const mobileNoFilterText = columnFilters.mobileNo.toLowerCase();
    const emailFilterText = columnFilters.email.toLowerCase();
    const destinationFilterText = columnFilters.destination.toLowerCase();

    return (
      nameFilter.includes(nameFilterText) &&
      mobileNoFilter.includes(mobileNoFilterText) &&
      emailFilter.includes(emailFilterText) &&
      destinationFilter.includes(destinationFilterText)
    );
  };

  const filteredUsers = !loading
    ? users.filter((user) => applyColumnFilters(user))
    : [];

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="User Master" pageTitle="User" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md={4}>
                    <h4 className="card-title mb-0">User Master</h4>
                  </Col>
                  <Col md={8} className="text-end">
                    <Link to="/users/create">
                      <Button color="primary">
                        <i className="las la-plus"></i>Add
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <div className="table-responsive">
                      <Table
                        className="align-middle table-nowrap mb-0"
                        striped
                        responsive
                        hover
                      >
                        <thead>
                          <tr className="table-header">
                            <th scope="col">
                              <Input
                                type="text"
                                name="name"
                                value={columnFilters.name}
                                onChange={(e) =>
                                  handleColumnFilterChange(e, "name")
                                }
                                placeholder="Filter by Name"
                              />
                            </th>
                            <th scope="col">
                              <Input
                                type="text"
                                name="mobileNo"
                                value={columnFilters.mobileNo}
                                onChange={(e) =>
                                  handleColumnFilterChange(e, "mobileNo")
                                }
                                placeholder="Filter by Mobile No"
                              />
                            </th>
                            <th scope="col">
                              <Input
                                type="text"
                                name="email"
                                value={columnFilters.email}
                                onChange={(e) =>
                                  handleColumnFilterChange(e, "email")
                                }
                                placeholder="Filter by Email ID"
                              />
                            </th>
                            <th scope="col">
                              <Input
                                type="text"
                                name="destination"
                                value={columnFilters.destination}
                                onChange={(e) =>
                                  handleColumnFilterChange(e, "destination")
                                }
                                placeholder="Filter by Destination"
                              />
                            </th>
                            {/* ... */}
                          </tr>
                          <tr className="table-header">
                            <th scope="col">Name</th>
                            <th scope="col">Mobile No</th>
                            <th scope="col">Email ID</th>
                            <th scope="col">Destination</th>
                            <th scope="col">Created On</th>
                            <th scope="col" className="col-1 text-center">
                              Status
                            </th>
                            <th scope="col" className="col-1 text-center">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {!loading &&
                          Array.isArray(filteredUsers) &&
                          filteredUsers.length ? (
                            filteredUsers.map((user, index) => (
                              <tr key={index}>
                                <td>
                                  <Link to={`/users/update/${user.ID}`}>
                                    {`${user.FirstName}  ${user.LastName}`}
                                  </Link>
                                </td>
                                <td>{user.MobileNo}</td>
                                <td>{user.EmailID}</td>
                                <td
                                  style={{
                                    width: "400px",
                                    maxWidth: "500px",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {user.SectorName}
                                </td>
                                <td>{user.CreatedOn}</td>
                                <td className="text-center">
                                  <Switch
                                    onChange={() => handleChange(user)}
                                    checked={user?.IsActive}
                                  />
                                </td>

                                <td className="text-center">
                                  <div className="hstack gap-3 justify-content-center">
                                    <Link
                                      to={`/users/update/${user.ID}`}
                                      className="link-success fs-18"
                                    >
                                      <i className="ri-edit-line"></i>
                                    </Link>
                                    <div
                                      className="hstack gap-3 flex-wrap "
                                      role="button"
                                      onClick={() => onClickDelete(user)}
                                    >
                                      <i className=" ri-delete-bin-fill text-danger fs-18"></i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : loading ? (
                            <tr>
                              <td colSpan={10}>
                                <Loader />
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td colSpan={10}>No User found</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <DeleteModal
            show={deleteModal}
            onCloseClick={() => setDeleteModal(false)}
            onDeleteClick={() => onDeleted()}
          />
        </Row>
      </Container>
    </div>
  );
};

export default MasterUser;
