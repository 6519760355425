import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllActiveRoomCategoryApi = () =>
    api.get(url.GET_ALL_ACTIVE_ROOM_CATAGORIES);

export const getRoomCategoriesByHotelIDApi = (hotelDetails) =>
    api.create(url.GET_ROOM_CATEGORIES_BY_HOTELID, hotelDetails);

export const getRoomCategoriesByHotelApi = (details) =>
    api.create(url.GET_ROOM_CATEGORY_BY_HOTEL_AUTO, details);
