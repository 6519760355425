import { createSlice } from "@reduxjs/toolkit";
import {
    findByIdInquiry,
  getAllInquiryByFilter, getAllPendingInquiryByFilter, insertInquiry, updateInquiry,
} from "./thunk";
import _ from "lodash"

export const emptyContactDetails = {
  ContactNo: "",
  EmailID: ""
};

export const InquiryForm = {
  ID: null,
  IsActive: true,
  Name: "",
  InquirySourceID: null,
  SectorID: null,
  Budget: "",
  NoOfNights: null,
  NoOfPax: null,
  Reference:"",
  PriorityID: "",
  TravelDate:"",
  ContactDetails:[emptyContactDetails],
}

export const initialState = {
  inquiryDetail: {
    form: InquiryForm,
  },
  inquiries: [],
  pendingInquiries: [],
  error: {},
  loading: false,
};

const InquiryReducer = createSlice({
  name: "Inquiry",
  initialState,
  reducers: {
    resetForm: (state) => {
      state.inquiryDetail.form = InquiryForm;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllInquiryByFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllInquiryByFilter.fulfilled, (state, action) => {
      state.inquiries = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllInquiryByFilter.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    builder.addCase(getAllPendingInquiryByFilter.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllPendingInquiryByFilter.fulfilled, (state, action) => {
      state.pendingInquiries = action.payload.Data;
      state.loading = false;
    });
    builder.addCase(getAllPendingInquiryByFilter.rejected, (state, action) => {
      state.error = action.payload?.error || null;
      state.loading = false;
    });

    // Insert Inquiry
    builder.addCase(insertInquiry.pending, (state) => {
      state.loading = true
    })
    builder.addCase(insertInquiry.fulfilled, (state) => {
      state.inquiryDetail.form = InquiryForm
      state.loading = false
    })
    builder.addCase(insertInquiry.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })

    // Update Inquiry
    builder.addCase(updateInquiry.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updateInquiry.fulfilled, (state) => {
      state.inquiryDetail.form = InquiryForm
      state.loading = false
    })
    builder.addCase(updateInquiry.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })

    // Get By Id Inquiry
    builder.addCase(findByIdInquiry.pending, (state) => {
      state.loading = true
    })
    builder.addCase(findByIdInquiry.fulfilled, (state, action) => {
      let formObj = action?.payload?.Data

      let formKeys = Object.keys(InquiryForm)
      formObj = _.pick(formObj, formKeys)

      state.inquiryDetail.form = formObj

      state.loading = false
    })
    builder.addCase(findByIdInquiry.rejected, (state, action) => {
      state.error = action.payload?.error || null
      state.loading = false
    })
  },
});

export const { resetForm } = InquiryReducer.actions

export default InquiryReducer.reducer;
