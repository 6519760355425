import axios from "axios";
import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

const api = new APIClient();

export const getAllActiveUserApi = () => api.get(url.GET_ALL_ACTIVE_USER);
export const getAllUserApi = () => api.get(url.GET_ALL_USER);

export const findByIdUserApi = (params) =>
  api.get(url.FIND_BY_ID_USER_URL, params);

export const insertUserApi = (data) =>
  axios.post(url.INSERT_USER_URL, data, {
    headers: { "content-type": "multipart/form-data" },
  });

  export const updateUserApi = (data) =>
    axios.post(url.UPDATE_USER_URL, data, {
        headers: { "content-type": "multipart/form-data" },
    });

export const ActiveInActiveUserApi = (data) =>
  api.create(url.ACTIVE_INACTIVE_USER_URL, data);

export const deleteUserApi = (data) => api.create(url.DELETE_USER_URL, data);
