//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
    postLogin,
    postSocialLogin,
} from "../../../helpers/fakebackend_helper";

import {
    loginSuccess,
    logoutUserSuccess,
    apiError,
    reset_login_flag,
} from "./reducer";
import { setAuthToken } from "../../../helpers/api_helper";

const fireBaseBackend = getFirebaseBackend();

export const loginUser = (user, history) => async (dispatch) => {
    try {
        let response;
        response = postLogin({
            UserName: user.UserName,
            Password: user.Password,
        });

        var data = await response;

        if (data && data.Data) {
            sessionStorage.setItem("authUser", JSON.stringify(data.Data));
            setAuthToken();
            if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
                var finallogin = JSON.stringify(data.Data);
                finallogin = JSON.parse(finallogin);
                if (data.Status === 200) {
                    dispatch(loginSuccess(data.Data));
                    history("/quotations");
                } else {
                    dispatch(apiError(finallogin));
                }
            } else {
                dispatch(loginSuccess(data));
                history("/quotations");
            }
        }
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const logoutUser = () => async (dispatch) => {
    try {
        sessionStorage.removeItem("authUser");

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const response = fireBaseBackend.logout;
            dispatch(logoutUserSuccess(response));
        } else {
            dispatch(logoutUserSuccess(true));
        }
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const socialLogin = (data, history, type) => async (dispatch) => {
    try {
        let response;

        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
            const fireBaseBackend = getFirebaseBackend();
            response = fireBaseBackend.socialLoginUser(data, type);
        } else {
            response = postSocialLogin(data);
        }

        const socialdata = await response;

        if (socialdata) {
            sessionStorage.setItem("authUser", JSON.stringify(response));
            dispatch(loginSuccess(response));
            history("/dashboard");
        }
    } catch (error) {
        dispatch(apiError(error));
    }
};

export const resetLoginFlag = () => async (dispatch) => {
    try {
        const response = dispatch(reset_login_flag());
        return response;
    } catch (error) {
        dispatch(apiError(error));
    }
};
