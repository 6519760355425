import { createSlice } from "@reduxjs/toolkit";
import { findByIdSubSector, getAllActiveSubSector, getAllSubSector, getSubSectorBySectorID, insertSubSector, updateSubSector } from "./thunk";
import _ from "lodash"

export const subSectorForm={
    ID:null,
    SectorID:null,
    SubSectorName:"",
    IsActive: true,
}


export const initialState = {
    subSectorDetail:{
        form:subSectorForm
    },
    subSecs:[],
    subSectors: [],
    error: {},
    loading: false,
};

const SubSector = createSlice({
    name: "SubSector",
    initialState,
    reducers: {
        resetForm: (state) => {
            state.subSectorDetail.form = subSectorForm;
          },
    },
    extraReducers: (builder) => {
        builder.addCase(getSubSectorBySectorID.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getSubSectorBySectorID.fulfilled, (state, action) => {
            state.subSectors = action.payload.Data || [];
            state.loading = false;
        });
        builder.addCase(getSubSectorBySectorID.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.loading = false;
        });

        builder.addCase(getAllActiveSubSector.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllActiveSubSector.fulfilled, (state, action) => {
            state.subSecs = action.payload.Data;
            state.loading = false;
        });
        builder.addCase(getAllActiveSubSector.rejected, (state, action) => {
            state.error = action.payload?.error || null;
            state.loading = false;
        });
        // builder.addCase(getSubSectorBySectorID.pending, (state) => {
        //     state.loading = true;
        // });
        // builder.addCase(getSubSectorBySectorID.fulfilled, (state, action) => {
        //     state.sectorWiseSubSectors = action.payload.Data;
        //     state.loading = false;
        // });
        // builder.addCase(getSubSectorBySectorID.rejected, (state, action) => {
        //     state.error = action.payload.error || null;
        //     state.loading = false;
        // });
        builder.addCase(getAllSubSector.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(getAllSubSector.fulfilled,(state,action)=>{
            state.subSecs=action.payload.Data || []
            state.loading=false
        });
        builder.addCase(getAllSubSector.rejected,(state,action)=>{
            state.error = action.payload?.error || null
            state.loading=false
        });
        //insert
        builder.addCase(insertSubSector.pending,(state)=>{
            state.loading=true
        });
        builder.addCase(insertSubSector.fulfilled,(state)=>{
            state.subSectorDetail.form=subSectorForm
            state.loading=false
        });
        builder.addCase(insertSubSector.rejected,(state,action)=>{
            state.error = action.payload?.error || null
            state.loading = false
        });
        //update
        builder.addCase(updateSubSector.pending, (state) => {
            state.loading = true
          })
          builder.addCase(updateSubSector.fulfilled, (state) => {
            state.subSectorDetail.form = subSectorForm
            state.loading = false
          })
          builder.addCase(updateSubSector.rejected, (state, action) => {
            state.error = action.payload?.error || null
            state.loading = false
          });
            //get id
            builder.addCase(findByIdSubSector.pending, (state) => {
                state.loading = true
              });
              builder.addCase(findByIdSubSector.fulfilled,(state,action)=>{
                let formObj = action?.payload?.Data
                let formKeys=Object.keys(subSectorForm)
                formObj = _.pick(formObj,formKeys);
                state.subSectorDetail.form=formObj
                state.loading = false
              })
              builder.addCase(findByIdSubSector.rejected, (state, action) => {
                state.error = action.payload?.error || null
                state.loading = false
              })

    },
});

export const { resetForm } = SubSector.actions
export default SubSector.reducer;
