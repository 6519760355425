import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Field, Formik, Form } from "formik";
import {
  CONVERT_BOOL,
  REQUIRED,
  SelectionField,
  SingleFileField,
  TextField,
} from "../../Components/constants/field";
import {
  findByIdItinerary,
  getAllCities,
  insertItinerary,
  updateItinerary,
} from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import * as Yup from "yup";

const CreateItinerary = () => {
  const history = useNavigate();
  const { ID } = useParams();
  const dispatch = useDispatch();
  const { cities, loading: CityLoading } = useSelector((state) => state.City);
  const {
    itineraryDetail: { form },
  } = useSelector((state) => state.Itinerary);

  useEffect(() => {
    dispatch(getAllCities());
  }, []);

  useEffect(() => {
    if (ID) {
      let params = {
        ID: ID,
      };
      dispatch(findByIdItinerary(params));
    }
  }, [ID]);

  const handleSubmit = (values) => {
    let obj = {
      ...values,
    };

    obj.IsActive = CONVERT_BOOL.test(obj?.IsActive);
    obj.CityID = obj.CityID ? +obj.CityID : null;

    let itinerary = new FormData();

    Object.keys(values).map((key) => {
      if (key === "ItineraryImage") {
        itinerary.append(key, values[key][0]);
      } else {
        itinerary.append(key, values[key]);
      }
    });

    if (ID) {
      if (typeof itinerary.get("ItineraryImage") === "string") {
        itinerary.delete("ItineraryImage");
        itinerary.append("ItineraryImage", "");
      }
      itinerary.append("ID", ID);
      dispatch(updateItinerary(itinerary)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/itinerary`);
        }
      });
    } else {
      console.log("insert call");
      itinerary.delete("ID");
      dispatch(insertItinerary(itinerary)).then((res) => {
        if (res.payload && res.payload.Status === 200) {
          history(`/itinerary`);
        }
      });
    }
  };

  if (CityLoading) {
    return <Loader />;
  }
  return (
    <div className="page-content">
      <Container fluid>
        <ToastContainer />
        <BreadCrumb title="Itinerary" pageTitle="Itinerary" />
        <Row>
          <Col xl={12}>
            <Card color="dark" outline>
              <CardHeader className="bg-light">
                <Row className="align-items-center">
                  <Col md="11">
                    <h4 className="card-title mb-0">
                      {ID ? "Update Itinerary" : " Create Itinerary"}
                    </h4>
                  </Col>
                  <Col md="1">
                    <Link to="/itinerary">
                      <Button color="primary" block>
                        Back
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col lg={12}>
                    <Formik
                      enableReinitialize={true}
                      initialValues={form}
                      validationSchema={Yup.object({
                        CityID: Yup.number()
                          .transform((value) =>
                            Number.isNaN(value) ? null : value
                          )
                          .nullable()
                          .required(REQUIRED),
                        ItineraryTitle: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                        Description: Yup.string()
                          .nullable(true)
                          .required(REQUIRED),
                      })}
                      onSubmit={handleSubmit}
                    >
                      {() => {
                        return (
                          <Form noValidate autoComplete="off">
                            <div>
                              <Row>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="CityID"
                                    >
                                      City:
                                    </Label>
                                    <Field
                                      name="CityID"
                                      id="CityID"
                                      options={
                                        Array.isArray(cities) &&
                                        cities.map((city) => {
                                          return {
                                            value: Number(city.ID),
                                            label: city.CityName,
                                          };
                                        })
                                      }
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>{" "}
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="ItineraryTitle"
                                    >
                                      Title:
                                    </Label>
                                    <Field
                                      name="ItineraryTitle"
                                      id="ItineraryTitle"
                                      component={TextField}
                                      placeholder="Enter Itinerary Title"
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="Description"
                                    >
                                      Description:
                                    </Label>
                                    <Field
                                      name="Description"
                                      type="textarea"
                                      id="Description"
                                      component={TextField}
                                      placeholder="Enter Itinerary Description"
                                    />
                                  </div>
                                </Col>
                                <Col lg={6}>
                                  <div className="mb-3">
                                    <Label
                                      className="form-label"
                                      htmlFor="hotel-status"
                                    >
                                      Status
                                    </Label>
                                    <Field
                                      name="IsActive"
                                      id="IsActive"
                                      options={[
                                        {
                                          label: "Active",
                                          value: true,
                                        },
                                        {
                                          label: "Inactive",
                                          value: false,
                                        },
                                      ]}
                                      component={SelectionField}
                                    />
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <Label
                                    className="form-label"
                                    htmlFor="ItineraryImage"
                                  >
                                    Itinerary Image:
                                  </Label>
                                  <Field
                                    type="file"
                                    name="ItineraryImage"
                                    id="ItineraryImage"
                                    maxFiles={1}
                                    component={SingleFileField}
                                  />
                                </Col>{" "}
                              </Row>

                              <Row className="mt-2">
                                <Col lg={12}>
                                  <hr></hr>
                                </Col>
                                <Col md="12" className="text-end">
                                  <Button className="success">Save</Button>
                                </Col>
                              </Row>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateItinerary;
